import { BORDER_RADIUS } from "../../../core/measurements";

export function PitchName({ colors })
{
    return (
        <div
            style={ {
                maxWidth: 600,
                width: '100%',
                margin: '0 auto',
                backgroundColor: colors.background,
                position: 'relative',
                borderRadius: BORDER_RADIUS,
                overflow: 'clip',
                padding: 20,
                marginBottom: 20,
                display: 'flex',
                flexDirection: 'column',
                gap: 10,
            } }
        >
            <p><span style={ { fontWeight: 'bold' } }>Stadium :</span> Orlando Stadium</p>
        </div>
    )
}