import { useAtomValue } from "jotai";
import { useState } from "react";
import { matchDayTextAtom } from "./match-day-text";

export const useMatchDay = () => {
  const matchDayText = useAtomValue(matchDayTextAtom)
  const [matchDayIndex, setMatchDayIndex] = useState(4);

  const handleChange = (event, newMathDay) => {
    setMatchDayIndex(newMathDay);
  };

  let matchDays = [];
  for (let index = 0; index < 20; index++) {
    matchDays.push(`${matchDayText.match_day} ${index + 1}`);
  }

  return { matchDays, matchDayIndex, setMatchDayIndex, handleChange, matchDayText };
};
