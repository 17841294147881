import { useMatchDayStatsHook } from "../../../use-match-stats";
import { TeamArrangement } from "./team-arrangement";
import { TeamInfo } from "./team-info"
import { useNavigate, useParams } from "react-router-dom"
import toast from 'react-hot-toast'
import axios from "axios"
import { useEffect, useState } from "react"

export function TeamLineup({ _borderColor}){
    let { match_id } = useParams()
    const [ team1, setteam1 ] = useState(null)
    const [ team2, setteam2 ] = useState(null)
    const [ homeLineUp, setHomeLineUp ] = useState(null)
    const [ awayLineUp, setAwayLineUp ] = useState(null)
    const [ homeTeam, setHomeTeam ] = useState(null)
    const [ awayTeam, setAwayTeam ] = useState(null)

    const getMatchDetails = async() => {
        try { 
            const response = await axios.get(`https://api-v2.ligi.co.tz/match/${match_id}`)
            console.log(response)
            if(response.statusText === "OK") {
              setHomeTeam(response.data.home_team)
              setAwayTeam(response.data.away_team)
              listLineUps()
            }
      
            if(!response.statusText) {
               
            }
        } catch(err) {
            toast.error('Error while listing  match details')
        }
     }
    
     const listLineUps = async() => {
        try { 
            const response = await axios.get(`https://api-v2.ligi.co.tz/lineup/match/${match_id}`)
            //console.log(response)
            if(response.statusText === "OK") {
               if(response.data.length > 0) {
                splitTeamId(response.data)
               } else {
                toast.success("No line-ups")
               }
            }
      
            if(!response.statusText === "OK") {
                toast.error("Faild to list match detais")
            }
        } catch(err) {
          console.log(err,'err while listing line-ups')
            toast.error('Error while listing line ups')
        }
      }
      
      const splitTeamId = (responseData) => { 
        const { _id:homeTeamId } = homeTeam || ""
        responseData.map( item => {
             if(item.team._id === homeTeamId) {
                 sortHomeLineUp(item)
                return  setHomeLineUp(item)
             }
             sortAwayLineUp(item)
             return setAwayLineUp(item)
        })
      }
      
      const sortHomeLineUp = (item) => {
           let goalKeeper = {}
           let defenders = []
           let midfielders = []
           let strikers = []
      
           const defendersPositions = ['LB','LCB','RCB','RB','CB']
           const midfieldersPositions = ['LM','RM','CAM','LCM','RCM','CDM','CM','LDM','RDM']
           const strickersPositions = ['LW','RW','LCF','RCF','CF']
      
           item.players.map(Player => {
            if(defendersPositions.includes(Player.position)) {
               return defenders.push(Player)
            }
      
            if(midfieldersPositions.includes(Player.position)) {
              return midfielders.push(Player)
            }
      
            if(strickersPositions.includes(Player.position)) {
              return strikers.push(Player)
            }
      
            return goalKeeper = Player
      
           })
      
           setteam1({
              goalKeeper: goalKeeper,
              defenders: defenders,
              midfielders: midfielders,
              strikers:strikers,
              formation:item.formation,
              team:item.team
           })
        
      }

      const sortAwayLineUp = (item) => {
        let goalKeeper = {}
        let defenders = []
        let midfielders = []
        let strikers = []
   
        const defendersPositions = ['LB','LCB','RCB','RB','CB']
        const midfieldersPositions = ['LM','RM','CAM','LCM','RCM','CDM','CM','LDM','RDM']
        const strickersPositions = ['LW','RW','LCF','RCF','CF']
   
        item.players.map(Player => {
         if(defendersPositions.includes(Player.position)) {
            return defenders.push(Player)
         }
   
         if(midfieldersPositions.includes(Player.position)) {
           return midfielders.push(Player)
         }
   
         if(strickersPositions.includes(Player.position)) {
           return strikers.push(Player)
         }
   
         return goalKeeper = Player
   
        })
   
        setteam2({
           goalKeeper: goalKeeper,
           defenders: defenders,
           midfielders: midfielders,
           strikers:strikers,
           formation:item.formation,
           team:item.team
        })
       // console.log(goalKeeper,'goal keeper')
     
   }


    useEffect(()=> {
      getMatchDetails()
    },[match_id])

    console.log("Team 1 lineup", team1)
    console.log("Team 2 lineup", team2)
    return (
        <>
        { team1 === null ? <h3>line up</h3> :
        <div>
        <TeamInfo teamName={ team1.team.name  } formation ={ team1.formation } isHome={true} />
            <TeamArrangement zones={ team1.formation.split('-') } isHome={ true } _team={ team1 } _borderColor={ _borderColor } />
        </div> }

        { team2 === null ? <h3>line up</h3> :
        <div>
        <TeamInfo teamName={ team2.team.name  } formation ={ team2.formation } isHome={false} />
            <TeamArrangement zones={ team2.formation.split('-') } isHome={ false } _team={ team2 } _borderColor={ _borderColor } />
        </div> }
          
            {/* away team info */ }
           {/* <TeamInfo teamName={ team2?.teamName } formation={ team2?.formation } />*/}
            {/* away team formation lineup */ }
          {/*  <TeamArrangement zones={ team2?.zones } isHome={ false } _team={ team2 } _borderColor={ _borderColor } />*/}

           
        </>
    )
}