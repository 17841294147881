import { useEffect, useState } from "react"
import { URLS } from "../../../../../../core/urls"
import getSeason from '../../../../../../functions/get_season'

export default function useTeamStats() {
    const currentSeason = getSeason(new Date())
    const [loading, setLoading] = useState(false)
    const [teamData, setTeamData] = useState([])

    useEffect(() => {
        const _call = async () => {
            try {
                setLoading(true)
                const response = await fetch(`${ URLS.allTeamsBySeason }/${ currentSeason }`)
                .then(async resp => {
                    let response = await resp.json()
                    return response

                })
                const structuredData = response.map(data => {
                    return {
                        ...data,
                        season: data.seasons.find(season => season.year === currentSeason)
                    }
                })
                setTeamData(previous => { previous = structuredData; return previous })
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }
        _call()
    }, [])

    return { loading, teamData }
}