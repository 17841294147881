import { Row } from "../../../../../../shared-components/row";
import { Column } from "../../../../../../shared-components/column";
import { useAtom, useAtomValue } from "jotai";
import { colorAtom } from "../../../../../../state/shared-state-atoms/colors";
import { BORDER_RADIUS } from "../../../../../../core/measurements";

import logo from "../../../../../../assets/logo-grouped.png";
import playerPlaceHolder from '../../../../../../assets/placeholder-images/player-placeholder-full.svg'
import noImage from "../../../../../../assets/teams/noImage.jpg";
import { useWindowWidth } from "../../../../../../hooks/use-window-width";
import useStatsPagePlayerStats from "./useStatsPagePlayerStats";
import Label from "../../../../../../shared-components/Label";
import { useNavigate } from "react-router-dom";
import { _routeNames } from "../../../../../../app/routes";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { languageAtom } from "../../../../../../state/shared-state-atoms/language";
import GridLayout from '../../../../../home/components/home-tab/components/player-stats/components/gridLayout'
import CardItem from "../../../home-tab/components/player-stats/components/CardItem";
import playersInfoList from "./data";



export function StatsPagePlayerStats() {
  let navigate = useNavigate();
  const [colors] = useAtom(colorAtom);
  const { windowWidth } = useWindowWidth();
  const statsPagePlayerStatsHook = useStatsPagePlayerStats();

  let key = statsPagePlayerStatsHook.statCriteria[statsPagePlayerStatsHook.selectedStatCriteria];
  const lang = useAtomValue(languageAtom);

  if (statsPagePlayerStatsHook.loading) {
    return (
      <Column alignCenter justifyCenter style={ { padding: 50 } }>
        <Label text={ "Loading ..." } />
      </Column>
    );
  }


  return (
    <>
      <Row alignCenter gap={ 10 } style={ { ...headerNavigationStyle(colors), flexDirection: windowWidth < 600 ? 'column' : 'row' } }>
        <Row alignCenter gap={ 10 }>
          <img src={ logo } alt="" style={ { height: windowWidth < 1000 ? 50 : 80 } } />
          <Column gap={ 10 }>
            <p style={ { fontSize: windowWidth < 1000 ? 15 : 20, whiteSpace: "nowrap", fontWeight: 800 } }>
              {
                lang.isEnglish
                  ? "NBC Premier League"
                  : "Ligi Kuu ya NBC"
              }
            </p>
            {
              lang.isEnglish
                ? (
                  <p style={ { fontSize: "small", whiteSpace: "nowrap", fontWeight: "bold" } }>
                    { statsPagePlayerStatsHook.seasons[statsPagePlayerStatsHook.selectedSeason] }
                    <span style={ { fontWeight: "normal", marginLeft: 5 } }>season</span>
                  </p>)
                : (
                  <p style={ { fontSize: "small", whiteSpace: "nowrap", fontWeight: "bold" } }>
                    <span style={ { fontWeight: "normal", marginLeft: 5 } }>msimu wa </span>
                    { statsPagePlayerStatsHook.seasons[statsPagePlayerStatsHook.selectedSeason] }
                  </p>
                )
            }
          </Column>
        </Row>

        <Row alignCenter gap={ 10 } style={ { justifyContent: windowWidth < 600 ? 'flex-start' : "flex-end" } }>
          { statsPagePlayerStatsHook.selectOptions?.map((dt, index) => {
            return (
              <SelectField
                key={ index }
                label={ dt.label }
                value={ dt.value }
                onChange={ dt.onChange }
                options={ dt.options }
                fullWidth={ windowWidth < 600 ? true : false }
              />
            )
          }) }

        </Row>
      </Row>

      <Column gap={ 10 } style={ { marginTop: 10, marginBottom: 50, minHeight: "50vh" } }>
        { ((playerData) => {
          let data = [];
          if (playerData) {
            playerData?.forEach((dt) => {
              if (dt) {
                /**
                 * finding player stats for the season specified
                 * by the user in the drop down in the page
                 * variable "y" = player stats of the season
                 */
                let y = dt.stats?.find((x) => x.season === statsPagePlayerStatsHook.seasons[statsPagePlayerStatsHook.selectedSeason]);
                /**
                 * finding the player's current club by matching the
                 * team id from the player's object to the player's stats history
                 * Definaely prefferred if the player's team data came in the current_team's object
                 * variable "current_club" = player's current club
                 */
                let current_club
                try {
                  current_club = dt?.stats?.find((x) => x.team._id === dt.player.current_club);
                } catch (error) {
                  current_club = {}
                }

                if (y)
                  data.push({
                    ...dt,
                    filteredStats: y,
                    current_club: current_club,
                  });
              }
            });
          }

          /**
           * sorting players data in descending order
           * based on the selected stat filter
           */
          let sorted = data.sort((a, b) => {
            let obj = {
              a: a.filteredStats[key] ?? 0,
              b: b.filteredStats[key] ?? 0,
            };

            return obj.b - obj.a;
          });

          return sorted ?? [];
        })(statsPagePlayerStatsHook.playersData ?? []).map((player, index) => {
          return (
            <Row
              key={ index }
              alignCenter
              gap={ 20 }
              onClick={ () => navigate(`${ _routeNames.allPlayers }/${ player?.player?._id }`) }
              style={ {
                backgroundColor: colors.secondary,
                padding: 20,
                borderRadius: 10,
                overflow: "clip",
                cursor: "pointer",
              } }
            >
              <Label xBold noWrap text={ index + 1 } />
              {/* <img src={ player?.player?.pic } style={ playerImageStyle() } /> */}
              <img src={ playerPlaceHolder } style={ playerImageStyle() } />
              <Column justifyCenter gap={ 5 }>
                <Label xBold noWrap text={ player?.player?.name } />

                <Row alignCenter gap={ 10 }>
                  <img src={ player.current_club.team.logo? player.current_club.team.logo: noImage  } style={ teamLogoStyle() } /> 
                  <Label small xBold noWrap text={ player?.current_club?.team?.name ?? "" } />
                </Row>
              </Column>

              <Label xBold text={ player.filteredStats[key] ?? 0 } />
            </Row>
          );
        }) }
      </Column>
    </>
  );

  function headerNavigationStyle(colors) {
    return {
      backgroundColor: colors.secondary,
      padding: "10px 20px",
      borderRadius: BORDER_RADIUS,
      width: "100%",
      maxWidth: 1200,
      margin: "0 auto",
    };
  }

  function playerImageStyle() {
    return {
      height: 50,
      aspectRatio: 1,
      borderRadius: "50%",
      backgroundColor: colors.secondary,
      objectFit: "cover",
    };
  }

  function teamLogoStyle() {
    return {
      height: 30,
      aspectRatio: 1,
      borderRadius: "50%",
      backgroundColor: colors.secondary,
      objectFit: "cover",
    };
  }
}
export function SelectField({ label, value, onChange, options, fullWidth = false }) {
  const colors = useAtomValue(colorAtom);
  return (
    <FormControl fullWidth={ fullWidth } style={ { minWidth: 150 } }>
      <InputLabel id="demo-simple-select-label" style={ { color: colors.text, backgroundColor: colors.secondary, paddingInline: 10 } }>
        { label ?? "" }
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        label="Criteria"
        value={ value }
        onChange={ onChange }
        style={ { color: colors.text, outline: `1px solid ${ colors.text }`, fill: colors.text, textTransform: "capitalize" } }
      >
        { options?.map((option, index) => (
          <MenuItem key={ index } value={ option.value } style={ { textTransform: "capitalize" } }>
            { option.label }
          </MenuItem>
        )) }
      </Select>
    </FormControl>
  );
}
