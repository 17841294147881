import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Open: false,
  FavOpen: false,
};

export const ModalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setOpen: (state) => {
      state.Open = !state.Open;
    },
    setFavOpen: (state) => {
      state.FavOpen = !state.FavOpen;
    },
  },
});

export const { setOpen, setFavOpen } = ModalSlice.actions;

export default ModalSlice.reducer;
