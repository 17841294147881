import { FacebookAuthProvider, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../core/config/firebase-config";
import { postRequestWithOutToken } from "../core/network-request-functions";
import { URLS } from "../core/urls";

const handleLoginWithGoogle = async ({ settokenAtom, setuserProfileAtom }) => {
    const provider = new GoogleAuthProvider();

    provider.setCustomParameters({
        'login_hint': 'user@example.com'
    });

    try {
        const result = await signInWithPopup(auth, provider)
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;

        try {
            const response = await postRequestWithOutToken(URLS.auth.social, { access_token: token, provider: 'google' })
            settokenAtom(response.data.access_token)
            setuserProfileAtom({
                firstName: response.data.user.first_name,
                lastName: response.data.user.last_name,
                fullName: response.data.user.full_name,
                email: response.data.user.email,
                phone: response.data.user.phone,
                userId: response.data.user.user_uuid,
                loginMethod: 'social'
            })
            return true
        } catch (errorFromServer) {
            console.log("errorFromServer", errorFromServer)
            return false
        }
    } catch (errorFromFirebase) {
        // Handle Errors here.
        const errorCode = errorFromFirebase.code;
        const errorMessage = errorFromFirebase.message;
        // The email of the user's account used.
        const email = errorFromFirebase.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(errorFromFirebase);
        // ...
        console.log({ errorCode, errorMessage, email, credential })
        return false
    }
}

export default handleLoginWithGoogle