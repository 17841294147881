import React from "react";

const SwahiliPolicy = () => {
  return (
    <div>
      <h2 className=" flex  mb-1">
        <strong>Sera yetu ya usiri</strong>
      </h2>
      <p>
        <span className=" text-[#999999] text-sm">
          Benki ya Taifa ya Biashara inazingatia utunzaji siri wa taarifa za
          wateja wetu. Tunapozungumzia ‘taarifa za wateja wetu’ tunamaanisha
          taarifa zozote kuhusu mteja ambazo mteja mwenyewe au mtu mwingine
          ameziwasilisha kwetu.
        </span>
      </p>
      <h2 className=" flex  mb-1">
        <strong>Chaguo la mteja</strong>
      </h2>
      <p>
        <span className=" text-[#999999] text-sm">
          Uamuzi wa mteaja kujipatia NBC taarifa zake kwa njia ya mtandao huwa
          ni chaguo la mteja mwenyewe. Hata hivyo, mteja anapaswa kujua kwamba
          miamala yoyote ya kifedha zinazozuia Baadhi ya taarifa inaweza kuipa
          NBC mipaka katika huduma tunazozitoa.
        </span>
      </p>
      <h2 className=" flex  my-2">
        <strong>Saidia katika kulinda siri za mteja</strong>
      </h2>
      <p className=" mb-3">
        <span className=" text-[#999999] text-sm ">
          Mteja pia anaweza kusaidia katika kudumisha usiri wa taarifa za
          kibenki kwa:
        </span>
      </p>
      <ul>
        <li className=" mb-1">
          <span className=" text-[#999999] text-sm">
            Kutomshirikisha mwingine kuhusiana na taarifa za Kitambulisho cha
            Mteja au namba yake ya siri
          </span>
        </li>
        <li className=" mb-1">
          <span className=" text-[#999999] text-sm">
            Kubadili namba ya siri ya mteja mara kwa mara
          </span>
        </li>
        <li className=" mb-1">
          <span className=" text-[#999999] text-sm">
            Kufunga akaunti kila mara anapofanya miamala ya kibenki kwa njia ya
            mtandao
          </span>
        </li>
        <li className=" mb-1">
          <span className=" text-[#999999] text-sm">
            Kutoacha kompyuta ya mteja bila mtu wakati wa kutekeleza miamala ya
            mtandaoni
          </span>
        </li>
        <li className=" mb-1">
          <span className=" text-[#999999] text-sm">
            Kutoa taarifa ya upotevu au kuibiwa kwa kadi
          </span>
        </li>
        <li className=" mb-1">
          <span className=" text-[#999999] text-sm">
            Kuisaidia benki ya NBC kuwa na kumbukumbu sahihi kwa kutoa taarifa
            kunapotokea mabadiliko yoyote kwa upande wa mteja ili waweza pia
            kusasaisha kumbukumbu zake
          </span>
        </li>
        <li className=" mb-1">
          <span className=" text-[#999999] text-sm">
            Kutotuma taarifa yoyote ya siri kupitia b-pepe isiyo na namba ya
            sirising, archiving, destroying or disposing of personal
            information.
          </span>
        </li>
        <li className=" mb-1">
          <span className=" text-[#999999] text-sm">
            Kutotoa taarifa yoyote ya binafsi na ya kifedha kwenye mazingira
            yoyote au kwa mtu yeyeyote ambaye humwamini
          </span>
        </li>
        <li className=" mb-1">
          <span className=" text-[#999999] text-sm">
            Kutofanya miamala wala kuwezesha miamala ya NBC kwa kutumia chombo
            kisicho na usalama (simu ya mkononi, mtandao, nk.)
          </span>
        </li>
      </ul>
      {/* second part */}
      <h2 className=" flex  my-3">
        <strong>Kulinda taarifa za mteja</strong>
      </h2>
      <h2 className=" flex  mb-1">
        <strong className="  text-gray-600">Taarifa za mteja ni nini?</strong>
      </h2>
      <p>
        <span className=" text-[#999999] text-sm">
          Taarifa za mteja ni taarifa binafsi na za siri zinazomtambulisha au
          zinazohusiana naye ambazo hupelekwa NBC kupitia tovuti, mteja anaweza
          kuwa na mtu binafsi au biashara. Taarifa hizi zinahusisha jina, umri,
          namba za Kitambulisho, namba za usajili, anuani na mawasiliano
          mengine, utambulisho wa kijamii, jinsia, dini, madeni, taarifa za
          kipato na malipo, taarifa za fedha na taarifa za kibenki kama vile
          namba ya akaunti.
        </span>
      </p>
      <h2 className=" flex  mb-1">
        <strong className="  text-gray-600">
          Kwa nini benki ya NBC hukusanya taarifa za mteja?
        </strong>
      </h2>
      <p>
        <span className=" text-[#999999] text-sm">
          Benki ya NBC hutumia taarifa za mteja ili kumtambua na kumpatia huduma
          bora za mtandaoni. Taarifa za mteja ni muhimu pia katika kuisaidia NBC
          kuwasiliana na mteja tunapokuwa na maswali yoyote. NBC pia hutumia
          taarifa za mteja ili kumfahamisha mteja juu ya huduma na bidhaa
          nyingine zinazotolewa na NBC. Hivyo, kadiri tunavyokufahamu vizuri,
          ndivyo tunavyoweza kukuhudumia vizuri pia.
        </span>
      </p>
      <h2 className=" flex  mb-1">
        <strong className="  text-gray-600">
          Benki ya NBC inalindaje usalama wa taarifa zako?
        </strong>
      </h2>
      <p>
        <span className=" text-[#999999] text-sm">
          Kutunza kwa usalama taarifa zako za kifedha ni moja ya majukumu makuu
          ya NBC. Sera hii inaongoza hata mwenendo wa wafanyakazi katika masuala
          mazima ya usahihi, usiri na usalama wa taarifa zote za mteja.
        </span>
      </p>
      {/* Third part */}
      <h2 className=" flex  my-3">
        <strong>Uwajibikaji katika kutoa taarifa za mteja</strong>
      </h2>
      <p className=" mb-3">
        <span className=" text-[#999999] text-sm ">
          Mazingira pekee ambayo NBC inaruhusiwa kutoa taarifa za mteja ni pale
          tu: unapoziomba wewe mwenyewe na pale inapolazimu katika kukuhudumia.
        </span>
      </p>
      <h2 className=" flex  mb-1">
        <strong className="  text-gray-600">Kwa Idhini ya Mteja</strong>
      </h2>
      <p>
        <span className=" text-[#999999] text-sm">
          Benki ya NBC haitachukua wala kutumia taarifa ya mteja bila kupata
          idhini ya mteja mwenyewe. Katika mazingira ya kutangaza huduma au
          bidhaa zetu, tutahitaji idhini ya kukusanya au kutumia taarifa hiyo
          kwa maandishi (kwa mfano, kusaini mkataba) inapoonekana hivyo (mfano,
          kama mteja atapewa fursa ya kuchagua aina ya taarifa za kutoa, lakini
          akaamua kutozitoa, itaonekana kwamba mteja amechagua kutoa taarifa
          hizi).
        </span>
      </p>
      <ul>
        <li className=" mb-2">
          <span className=" text-[#999999] text-sm">
            Aina ya taarifa atakazoombwa mteja zitatofautiana kulingana na aina
            ya bidhaa au huduma inayohitajika.
          </span>
        </li>
        <li className=" mb-2">
          <span className=" text-[#999999] text-sm">
            Benki ya NBC ina vitengo vingi vya biashara. Kutegemeana na mahitaji
            ya mteja na pale tu atakaporidhia, na kwamba kwa kushirikishana
            taarifa za mteja kati ya vitengo mbalimbali vya NBC inasaidia katika
            kumhudumia mteja kwa ufanisi.
          </span>
        </li>
        <li className=" mb-2">
          <span className=" text-[#999999] text-sm">
            Makampuni mengine huwasiliana na Benki ya NBC kwa ajili ya mikopo na
            taarifa za kifedha. Hata hivyo, NBC hutoa taarifa hizo katika
            mazingira ambayo mteja mwenyewe ameomba au ameridhia kutolewa kwa
            taarifa hizo.
          </span>
        </li>
      </ul>
      <h2 className=" flex  mb-1">
        <strong className="  text-gray-600">Kwa mujibu wa sheria</strong>
      </h2>
      <p>
        <span className=" text-[#999999] text-sm">
          Hii kwa kiasi kikubwa inahusiana na masharti ya taarifa za kodi za
          serikali au agizo la mahakama au pale inapohitajika kwa mujibu wa
          sheria au kulingana na sheria ya nchi. Hata hivyo, Benki ya NBC katika
          mazingira yoyote ya kisheria, itatoa taarifa mahsusi tu zilizoombwa.
        </span>
      </p>
      <h2 className=" flex  mb-1">
        <strong className="  text-gray-600">Maslahi ya Umma</strong>
      </h2>
      <p>
        <span className=" text-[#999999] text-sm">
          Hii haitatumika kama sababu ya kutoa taarifa za mteja au akaunti ya
          mteja (ikiwamo jina na anuani) kwa mtu yeyote kwa madhumuni ya
          biashara. Hata hivyo, taratibu za kawaida zinazolinda maslahi ya NBC
          wakati mwingine hufanya taarifa za mteja zitolewe kwa mtu mwingine,
          mfano hundi inayorudishwa kutokana na upungufu wa fedha kwenye akaunti
          ya mteja.
        </span>
      </p>
      <h2 className=" flex  mb-1">
        <strong className="  text-gray-600">Maslahi ya kijamii</strong>
      </h2>
      <p>
        <span className=" text-[#999999] text-sm">
          Wakati mwingine, NBC huombwa kutoa taarifa za mteja kwa maslahi ya
          kijamii, mfano taarifa za kusaidia kuzuia uharifu. Kabla ya kutekeleza
          maombi ya namna hii, NBC huchukua hadhari zote kuhakikisha kwamba
          mamlaka zote zinazohusika zina sababu za msingi za kuleta maombi hayo.
        </span>
      </p>
      <h2 className=" flex  mb-1">
        <strong className="  text-gray-600">Wakala wa mikopo</strong>
      </h2>
      <p>
        <span className=" text-[#999999] text-sm">
          Taarifa kuhusu salio la mteja lililopo NBC linaweza kuelezwa kwa
          wakala wa mikopo aliyetambulishwa, pale ambapo mteja ametoa idhini ya
          maandishi kwa NBC kufanya hivyo. Benki ya NBC ina wajibu wa kutoa
          taarifa ya mikopo kila mwezi kuhusu mkopo huo kwa vyombo vyote vya
          mikopo vilivyopo na vitakavyoanzishwa.
        </span>
      </p>
      <h2 className=" flex  my-5">
        <strong>Zana za Mipango</strong>
      </h2>
      <p>
        <span className=" text-[#999999] text-sm">
          NBC haitunzi taarifa za mteja zinazotolewa kwenye program za mipango
          wanazotumia wateja (kwa mfano kukokotoa kiwango cha riba ya mkopo au
          ya akiba). Zana hizi ni kwa ajili ya kumsaidia mteja kufanya maamuzi
          yake ya kifedha kwa siri.
        </span>
      </p>
      <h2 className=" flex  my-5">
        <strong>Haki ya kurekebisha sera ya usiri</strong>
      </h2>
      <p>
        <span className=" text-[#999999] text-sm">
          NBC ina haki ya kurekebisha sera hii wakati wowote. Marekebisho yote
          kwenye sera hii yatawekwa kwenye tovuti. Isipokuwa kama itaelezwa
          vinginevyo, toleo hili la sasa hivi linafuta matoleo mengine yote
          yaliyotangulia ya sera hii.
        </span>
      </p>
      <h2 className=" flex  my-4">
        <strong>
          Matamko ya usiri yanayohusiana na huduma mahsusi za mtandaoni
        </strong>
      </h2>
      <p>
        <span className=" text-[#999999] text-sm">
          Huduma mbalimbali zinazotolewa kwa njia ya mtandao, vitengo mbalimbali
          vya NBC vinaweza kuwa na sera zake za usiri kwa sababu aina ya huduma
          au bidhaa inalazimu kukiuka masharti ya sera hii. Sera hizo zitatumika
          tu kumwongoza mteja wa huduma au bidhaa hizo mahsusi za mtandaoni.
        </span>
      </p>
      <h2 className=" flex  my-4">
        <strong>Watu wengine</strong>
      </h2>
      <p>
        <span className=" text-[#999999] text-sm">
          Isipokuwa tu kama imetajwa kwenye sehemu ya "Utoaji wa Taarifa za
          Mteja", NBC haitauza wala kutoa taarifa za mteja kwa taasisi au mtu
          mwingine, zikiwamo kampuni
        </span>
      </p>
      <h2 className=" flex  my-4">
        <strong>Mawasiliano ya Beruapepe</strong>
      </h2>
      <ul>
        <li className=" mb-2">
          <span className=" text-[#999999] text-sm">
            NBC inaweza kutumia taariza za mteja kumtumia mteja huyo taarifa za
            huduma au bidhaa mpya ambazo anaweza kuzihitaji na wakati fulani
            kumtumia mteja ujumbe kwa njia ya posta, baruapepe au ujumbe kwa
            njia ya simu yake ya mkononi ili kumjulisha kuhusu bidhaa na huduma
            za NBC.
          </span>
        </li>
        <li className=" mb-2">
          <span className=" text-[#999999] text-sm">
            Mteja atakuwa na uchaguzi wa kueleza kwamba hahitaji kupokea ujumbe
            kama huo kwa siku za baadae, mara tu anapoona ujumbe huo. Hata
            hivyo, mteja ataendelea kupokea ujumbe kuhusu bidhaa nyingine mpaka
            hapo atakapochagua kutopokea taarifa za bidhaa hiyo tena.
          </span>
        </li>
        <li className=" mb-2">
          <span className=" text-[#999999] text-sm">
            Kumbuka kuwa upokeaji wa baruapepe zisizo na usiri si salama. Kwa
            mantiki hiyo, NBC haitaweka taarifa za siri za akaunti yako kwenye
            majibu ya baruapepe.
          </span>
        </li>
        <li className=" mb-2">
          <span className=" text-[#999999] text-sm">
            Aidha, NBC haitamuomba mteja kutoa taarifa binafsi kwa njia ya
            baruapepe, mfano namba ya akaunti, Namba ya Utambulisho BInafsi wala
            namba ya siri.
          </span>
        </li>
      </ul>
      <h2 className=" flex  my-4">
        <strong>Maadili ya Kibenki</strong>
      </h2>
      <p>
        <span className=" text-[#999999] text-sm">
          Benki ya NBC inaunga mkono na kusisitiza utekelezaji wa Maadili ya
          Kibenki.
        </span>
      </p>
    </div>
  );
};

export default SwahiliPolicy;
