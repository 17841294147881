import { FilledButton } from "./filled-button";

export function FramerButtonFilled({
  label,
  marginTop,
  buttonText,
  backgroundColor,
  onClick,
  disabled,
  Radius,
  width,
  height,
}) {
  return (
    <FilledButton
      disabled={disabled}
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      style={{
        marginTop: marginTop ?? "",
        backgroundColor: backgroundColor ?? "",
        color: buttonText ?? "",
        borderRadius: Radius ?? "",
        width: width ?? "100%",
        height: height ?? "",
        padding: 10,
      }}
      onClick={onClick}
    >
      {label}
    </FilledButton>
  );
}
