import { atomWithStorage } from 'jotai/utils'

export const userProfileAtom = atomWithStorage({
    firstName: "",
    lastName: "",
    fullName: "",
    email: "",
    phone: "",
    userId: "",
    token: "",
    fvTeam: "",
    loginMethod: ""
})