// export const NEW_BASE_URL = "http://api-v2.ligi.co.tz:4000";
// export const NEW_BASE_URL = "http://localhost:4000";
// export const NEW_BASE_URL = 'http://51.195.254.183:4422';
export const NEW_BASE_URL = 'http://nbcleague1.zesha.net:4422';

// export const BASE_URL = "https://nbc-api.ellipsis-dev.com/v1";
// export const BASE_URL = "https://api-v2.ligi.co.tz";

export const _MOCK_BASE_URL = "http://localhost:3001/articles";

const AUTH = "visitor_auth";
const PAGES = "pages";

export const URLS = {
  auth: {
    register: `/${AUTH}/register`,
    login: `/${AUTH}/login`,
    social: `/${AUTH}/social`,
    forgotPassword: `/${AUTH}/forgot-password`,
    resetPassword: `/${AUTH}/reset-password`,
    verifyPhone: `/${AUTH}/verifyOtp`,
  },
  user: {
    profile: "/profile",
    profileUpdate: "/profile/update",
    profilePasswordUpdate: "/profile/password",
  },
  pages: {
    privacyPolicy: `https://nbc-api.ellipsis-dev.com/v1/pages/privacy-policy`,
    termsOfService: `https://nbc-api.ellipsis-dev.com/v1/pages/terms-of-service`,
    aboutUs: `${PAGES}/about-us`,
  },
  feedback: `/contact-us`,
  allMatches: `${NEW_BASE_URL}/match`,
  allRounds: `${NEW_BASE_URL}/round`,
  allSeasons: `${NEW_BASE_URL}/season`,
  allMatchesByStatus: `${NEW_BASE_URL}/match/status`,
  allPlayerStats: `${NEW_BASE_URL}/player_stats_frontend`,
  confirmPhoneNumber: `${NEW_BASE_URL}/visitor_auth/confirmPhoneNumber`,
  verifyPhone: `${NEW_BASE_URL}/visitor_auth/verifyCode`,
  signup: `${NEW_BASE_URL}/visitor_auth/signup`,
  login: `${NEW_BASE_URL}/visitor_auth/signin`,
  updateProfile: `${NEW_BASE_URL}/visitor_auth/update-user`,
  verifyEdit: `${NEW_BASE_URL}/visitor_auth/update-user/verify-edit`,
  changePassword: `${NEW_BASE_URL}/visitor_auth/change-password`,
  forgotPassword: `${NEW_BASE_URL}/visitor_auth/reset`,
  recoverPassword: `${NEW_BASE_URL}/visitor_auth/recover`,
  deactivateAccount: `${NEW_BASE_URL}/visitor_auth/deactivate`,
  fantasy: `${NEW_BASE_URL}/fantasy/register`,
  allMatchesBySeason: `${NEW_BASE_URL}/match/season`,
  matchFacts: `${NEW_BASE_URL}/moment/match`,
  matchStatistics: `${NEW_BASE_URL}/stat/match`,
  matchStatisticsH2H: `${NEW_BASE_URL}/match/team/h2h`,
  allTeams: `${NEW_BASE_URL}/team`,
  allTeamsBySeason: `${NEW_BASE_URL}/team/seasons`,
  teamByName: `${NEW_BASE_URL}/team/name`,
  teamManager: `${NEW_BASE_URL}/manager/team`,
  allPlayers: `${NEW_BASE_URL}/player`,
  playerHistory: `${NEW_BASE_URL}/player_team/player`,
  allPlayersHistory: `${NEW_BASE_URL}/player_team`,
  managers: `${NEW_BASE_URL}/manager`,
  managerHistory: `${NEW_BASE_URL}/manager_history/manager`,
  partners: `${NEW_BASE_URL}/partners`,
  broadcasters: `${NEW_BASE_URL}/broadcasters`,
  news: `${NEW_BASE_URL}/news`,
  kingOfTheMatch: `${NEW_BASE_URL}/kings/match`,
  getAwards: `${NEW_BASE_URL}/awards`,
  stadiums: `${NEW_BASE_URL}/stadiums`,
  favouriteTeam: `${NEW_BASE_URL}/visitor_auth/update-user/fav-teams`,
  newsArticles: _MOCK_BASE_URL,
};
