import nbc_logo from '../../assets/nbc_logo.png'
import tbc_logo from '../../assets/tbc_logo.png'
import tff_logo from '../../assets/tff_logo.png'
import azam_logo from '../../assets/azam_logo.png'
import tplb_logo from '../../assets/tplb_logo.png'

import { useAtomValue } from 'jotai'

import { useNavigate } from 'react-router-dom'

import { colorAtom } from '../../state/shared-state-atoms/colors'
import apiCall from '../../functions/api_call'
import { URLS } from '../../core/urls'
import { useEffect, useState } from 'react'

export function useBroadcastersHook()
{
    const broadcasters = [
        { label: "TBC", image: tbc_logo },
        { label: "Azam Media Group", image: azam_logo },
    ]

    let navigate = useNavigate()
    const colors = useAtomValue(colorAtom)

    const [broadcastersList, setBroadcastersList] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() =>
    {
        const api_call = async () =>
        {
            setLoading(true)
            const response = await apiCall({ url: URLS.broadcasters })
            if (response.status === 200)
            {
                setBroadcastersList(prev => { prev = response.data; return prev })
            }
            setLoading(false)
        }
        api_call()
    }, [])

    return { broadcasters, colors, navigate, broadcastersList, loading }
}