import { motion } from 'framer-motion';
import styled from 'styled-components'
import { Colors } from '../core/colors';
import { BORDER_RADIUS, MEDIUM_SPACING } from '../core/measurements';

export const FilledButton = styled(motion.button)`
    outline: none;
    border: none;
    width: 100%;
    font-weight: bold;
    cursor: pointer;
    color: ${Colors.text};
    padding: ${MEDIUM_SPACING};
    border-radius: ${BORDER_RADIUS};
    background-color: ${Colors.action};
`;