import styled from "@emotion/styled";
import { motion } from "framer-motion";
import { useAtom, useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Colors } from "../../../../core/colors";
import { BORDER_RADIUS, SMALL_SPACING } from "../../../../core/measurements";
import { useWindowWidth } from "../../../../hooks/use-window-width";
import { Column } from "../../../../shared-components/column";
import { Row } from "../../../../shared-components/row";
import { colorAtom } from "../../../../state/shared-state-atoms/colors";
import { newsTextAtom } from "./news-text";

import { BsArrowRight, BsDot } from "react-icons/bs";
import { _routeNames } from "../../../../app/routes";
import { NEW_BASE_URL, URLS } from "../../../../core/urls";
import apiCall from "../../../../functions/api_call";
import DataNotFound from "../../../../shared-components/data_not_found";
import { languageAtom } from "../../../../state/shared-state-atoms/language";
import { tabIndexAtom } from "../../../../state/shared-state-atoms/tab-index";
import { themeAtom } from "../../../../state/shared-state-atoms/_theme";
import ModalPopUp from "../../../../shared-components/ModalPopUp";
import moment from "moment";

export function News({ height }) {
  const { windowWidth } = useWindowWidth();
  let location = useLocation();
  const pathName = location.pathname.slice(1);
  const [colors] = useAtom(colorAtom);
  const newsText = useAtomValue(newsTextAtom);
  const theme = useAtomValue(themeAtom);
  const language = useAtomValue(languageAtom);

  const {
    handleShowAll,
    showButton,
    news_list,
    newsLoading,
    newsList,
    newsLoading2,
  } = useNewsHook();
  return (
    <NewsContainer
      style={{
        position: "relative",
        maxWidth: 700,
        backgroundColor: "transparent",
        color: colors.text,
        alignSelf:
          windowWidth > 1600 && height === null
            ? "center"
            : windowWidth > 1000 && height !== null
            ? "flex-start"
            : "center",
        margin: windowWidth <= 1600 ? "60px 0" : "",
        margin:
          pathName === "" && windowWidth < 1000
            ? "200px 0"
            : pathName === "" && windowWidth > 1000
            ? "0"
            : "50px 0",
        marginLeft: "auto",
        marginRight: "auto",
        height: height,
        width: "fit-content",
      }}
    >
      {/* <Row style={{ height: 70, backgroundColor: colors.secondary }}>
        <p
          style={{
            fontWeight: "bold",
            fontSize: "xx-large",
            color: colors.text,
          }}
          className="text-center w-full"
        >
          {newsText.title}
        </p>
      </Row> */}

      {newsLoading ? (
        <p className="text-center">
          {" "}
          {language.isEnglish
            ? "loading news ..."
            : "tafadhali subiri ..."}{" "}
        </p>
      ) : (
        <>
          {newsList?.length === 0 ? (
            <Column alignCenter justifyCenter style={{ padding: 50 }}>
              <DataNotFound
                message={
                  language.isEnglish
                    ? "News not available"
                    : "Habari hazipo kwa sasa"
                }
              />
            </Column>
          ) : (
            <Column
              alignCenter
              gap={20}
              style={{
                maxHeight: height ? height : "",
                height: "100%",
                overflow: height ? "scroll" : "",
              }}
            >
              {newsList?.map((newsItem, index) => {
                return (
                  <>
                    {index === 0 ? (
                      <MainNews
                        url={newsItem.image_url}
                        sourceUrl={newsItem.image_url}
                        title={newsItem.title}
                        description={newsItem.description}
                        source={newsItem.category}
                        author={newsItem.author ?? "author"}
                        publishedAt={moment(newsItem.published_date).format(
                          "dddd MMM DD, YYYY"
                        )}
                      />
                    ) : (
                      <NewsTile
                        url={newsItem.image_url}
                        sourceUrl={newsItem.image_url}
                        title={newsItem.title}
                        description={newsItem.description}
                        source={newsItem.category}
                        author={newsItem.author ?? "author"}
                        publishedAt={moment(newsItem.published_date).format(
                          "dddd MMM DD, YYYY"
                        )}
                      />
                    )}
                  </>
                );
              })}

              {/* {!showButton ? (
                <ShowAllButton atomColors={colors} onClick={handleShowAll}>
                  <Row alignCenter gap={10}>
                    <p>{language.isEnglish ? "See all" : "Angalia zote"}</p>
                    <BsArrowRight />
                  </Row>
                </ShowAllButton>
              ) : (
                ""
              )} */}

              <br />
            </Column>
          )}
        </>
      )}
    </NewsContainer>
  );
}

const ShowAllButton = styled.button`
  border: none;
  outline: none;
  padding-inline: 15px;
  padding-block: 10px;
  background-color: ${(props) => props.atomColors.primary};
  color: #fff;
  border-radius: ${BORDER_RADIUS};
  cursor: pointer;
`;

const useNewsHook = () => {
  let location = useLocation();
  let navigate = useNavigate();

  const [selectedTabIndex, setSelectedTabIndex] = useAtom(tabIndexAtom);

  const [showButton, setshowButton] = useState();

  const jsonData = require("./mock-data.json");
  const [newsLoading2, setNewsLoading2] = useState(false);
  const [newsLoading, setNewsLoading] = useState(true);
  const [newsList, setnewsList] = useState([]);

  useEffect(() => {
    const _api_call = async () => {
      const response = await apiCall({ url: URLS.news });
      if (response.status === 200) {
        const result = response.data.slice(0, 3);
        setnewsList(result);
        let check =
          location.pathname.toString().slice(1) ===
          _routeNames.root.news.slice(1);
        setshowButton(check);
        setNewsLoading((newsLoading) => !newsLoading);
      } else {
        setNewsLoading((newsLoading) => !newsLoading);
      }
    };
    _api_call();
  }, []);

  // for re-running the use effect
  const [reRun, setReRun] = useState(0);

  const [news_list, setNewsList] = useState([]);

  // useEffect(() => {
  //   const _api_call = async () => {
  //     setNewsLoading(true);
  //     const response = apiCall({ url: URLS.news });
  //     if (response.status === 200) {
  //       setNewsList((prev) => {
  //         prev = response.data;
  //         return prev;
  //       });
  //       setNewsLoading(false);
  //     } else {
  //       setNewsLoading(false);
  //     }
  //   };
  //   _api_call();
  // }, [reRun]);

  const handleShowAll = () => {
    setSelectedTabIndex(2);
    navigate(_routeNames.root.news);
  };

  const handleShowAll1 = () => {
    setSelectedTabIndex(2);
    navigate(_routeNames.root.news);
  };

  return {
    newsList,
    handleShowAll,
    handleShowAll1,
    showButton,
    news_list,
    newsLoading,
    newsLoading2,
    newsList,
  };
};

const MainNews = ({
  url,
  sourceUrl,
  title,
  description,
  source,
  author,
  publishedAt,
}) => {
  const colors = useAtomValue(colorAtom);
  const { handleShowAll } = useNewsHook();

  return (
    <>
      <MainNewsContainer
        atomColors={colors}
        // onClick={() => handleShowAll()}
      >
        <MainNewsImage src={`${NEW_BASE_URL}${url}`} alt="" />
        <Column gap={2} style={{ paddingInline: 5 }}>
          <MainNewsTitle atomColors={colors}> {title} </MainNewsTitle>
          <Row alignCenter style={{ fontSize: 15, flexWrap: "wrap" }}>
            <p style={{ color: colors.text, opacity: 0.6, fontSize: 13 }}>
              {publishedAt}
            </p>
          </Row>
        </Column>
      </MainNewsContainer>
    </>
  );
};

const MainNewsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  padding-bottom: 20px;

  color: ${(props) => props.atomColors.text};
  cursor: pointer;

  transition: 0.4s;

  &:hover {
    background-color: ${(props) => props.atomColors.background};
    filter: grayscale();
  }
`;

const NewsTile = ({
  url,
  sourceUrl,
  title,
  description,
  source,
  author,
  publishedAt,
}) => {
  const colors = useAtomValue(colorAtom);
  const { handleShowAll } = useNewsHook();

  return (
    <NewsTitleContainer
      atomColors={colors}
      //  onClick={() => handleShowAll()}
    >
      <Row gap={10}>
        <NewsImage src={`${NEW_BASE_URL}${url}`} alt="" />
        <Column paddingVertical={5} justifyCenter gap={10}>
          <Row alignCenter style={{ fontSize: 15, flexWrap: "wrap" }}>
            <p style={{ color: colors.text, fontWeight: 400, fontSize: 15 }}>
              {title}
            </p>
            {/* <BsDot /> */}
          </Row>
          <p style={{ color: colors.text, opacity: 0.6, fontSize: 12 }}>
            {publishedAt}
          </p>
          {/* <NewsTitle> {publishedAt} </NewsTitle> */}
        </Column>
      </Row>
      {/* <p style={ { color: colors.text, opacity: 0.6 } }>{ description }</p> */}
    </NewsTitleContainer>
  );
};

const NewsTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  text-overflow: ellipsis;

  padding-block: 5px;
  padding-inline: 5px;
  /* border-bottom: 1px solid grey; */

  @media (max-width: 800px) {
    flex-direction: column;
  }

  color: ${(props) => props.atomColors.text};
  cursor: pointer;

  transition: 0.4s;

  &:hover {
    background-color: ${(props) => props.atomColors.background};
    filter: grayscale();
  }
`;

const NewsContainer = styled(motion.div)`
  width: 90%;
  background-color: ${Colors.background};
  border-radius: ${BORDER_RADIUS};
  min-height: 100vh;
`;

const MainNewsImage = styled.img`
  width: 100%;
  aspect-ratio: 16 / 9;
  object-fit: cover;
  border-radius: ${BORDER_RADIUS};
  cursor: pointer;
  transition: 0.4s;

  &:hover {
    filter: grayscale();
  }
`;

const MainNewsTitle = styled.h2`
  margin-top: ${SMALL_SPACING};
  margin-bottom: 5px;
  font-weight: 400;
  color: ${(props) => props.atomColors.text};
`;

const NewsImage = styled.img`
  width: 150px;
  height: 100px;
  aspect-ratio: 16 / 10;
  object-fit: cover;
  border-radius: ${BORDER_RADIUS};

  @media (max-width: 800px) {
    width: 150px;
    height: 100px;
  }
`;

const NewsTitle = styled.h3`
  font-weight: bold;

  @media (max-width: 1000px) {
    font-size: 15px;
  }
`;
