import { Row } from "./row";
import { CgArrowLongLeft } from "react-icons/cg";
import { useAtomValue } from "jotai";
import { languageAtom } from "../state/shared-state-atoms/language";
import { colorAtom } from "../state/shared-state-atoms/colors";

export function GoBack({ handleGoBack, style }) {
  const { label } = useGoBackHook();
  const colors = useAtomValue(colorAtom);
  return (
    <Row
      alignCenter
      gap={5}
      onClick={handleGoBack}
      style={{
        width: "fit-content",
        cursor: "pointer",
        marginBottom: 10,
        color: "black",
        ...style,
      }}
    >
      <CgArrowLongLeft />
      <p>{label}</p>
    </Row>
  );
}

const useGoBackHook = () => {
  const language = useAtomValue(languageAtom);
  let label = language.isEnglish ? "Go back" : "Rudi nyuma";
  return { label };
};
