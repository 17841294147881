import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getRequestWithOutToken } from "../../core/network-request-functions";
import { URLS } from "../../core/urls";
import { colorAtom } from "../../state/shared-state-atoms/colors";
import { languageAtom } from "../../state/shared-state-atoms/language";
import apiCall from "../../functions/api_call";

export const usePrivacyPolicyHook = () => {
  let navigate = useNavigate();
  const colors = useAtomValue(colorAtom);
  const language = useAtomValue(languageAtom);
  const _path = URLS.pages.privacyPolicy;
  const [contents, setContents] = useState();
  const [loading, setloading] = useState(false);

  // useEffect(() => {
  //   const call = async () => {
  //     try {
  //       setloading(true);
  //       const response = await apiCall({ url: URLS.pages.privacyPolicy });
  //       setContents(
  //         language.isEnglish
  //           ? response.data.content.en
  //           : response.data.content.sw
  //       );
  //       setloading(false);
  //     } catch (error) {
  //       console.log("error getting privcy policy info", error);
  //       setContents(
  //         language.isEnglish
  //           ? "Sorry no data could be retrieved"
  //           : "Samahani, taarifa hizi hazipo kwa sasa"
  //       );
  //       setloading(false);
  //     }
  //   };
  //   call();
  // }, [language]);

  return { navigate, colors, contents, loading, language };
};
