import { useAtomValue } from "jotai";
import { Column } from "../../../../../../../shared-components/column";
import Label from "../../../../../../../shared-components/Label";
import { Row } from "../../../../../../../shared-components/row";
import { colorAtom } from "../../../../../../../state/shared-state-atoms/colors";
import playerPlaceHolder from '../../../../../../../assets/placeholder-images/player-placeholder-full.svg'
import noImage from '../../../../../../../assets/teams/noImage.jpg'

import Card from "./card";
import CardBottom from "./cardBottom";
import CardTop from "./cardTop";
import PlayerImage from "./playerImage";
import RowItem from "./rowItem";
import TeamLogo from "./teamLogo";
import TeamLogoSmall from "./teamLogoSmall";
import fontColorContrast from 'font-color-contrast'
import { themeAtom } from "../../../../../../../state/shared-state-atoms/_theme";

const Rectangle = ({ width = "100%", small, medium, imageLarge }) => {
    const colors = useAtomValue(colorAtom)
    return (
        <div
            style={ {
                width: width,
                height: small ? 14 : medium ? 20 : imageLarge ? "60%" : 30,
                backgroundColor: '#132F65',
                color: '#212121',
                alignSelf: imageLarge ? 'flex-end' : "",
                borderRadius: 10,
                marginRight: imageLarge ? 10 : 0,
                marginBottom: imageLarge ? 10 : 0,
            } } />
    )
}

export default function CardItem({ playerList, button }) {
    const colors = useAtomValue(colorAtom)
    const theme = useAtomValue(themeAtom)
    return (
        <Card>
            { ((player, category) => {
                if (!player) {
                    return (
                        <CardTop _bgColor={ colors.primary } style={ { color: fontColorContrast(colors.primary) } }>
                            <Column gap={ 10 } paddingAll={ 10 }>
                                <Rectangle width="40%" small />
                                <Rectangle width="10%" medium />
                                <Rectangle width="60%" />
                                <Rectangle width="50%" small />
                                <Rectangle width="10%" />
                            </Column>
                            <Rectangle width="40%" imageLarge />
                        </CardTop>
                    );
                }

                return (
                    <CardTop _bgColor={ player.team.color } style={ { color: fontColorContrast(player.team.color) } }>
                        <Column gap={ 10 } paddingAll={ 10 }>
                            <Label text={ category } />
                            <TeamLogo src={ player.team.logo ? player.team.logo: noImage } alt="team logo" />
                            { ((name) => {
                                const firstName = name.split(" ")[0];
                                const restOfName = name.split(" ").slice(1).join(" ");
                                return (
                                    <Row gap={ 4 } style={ { width: 'fit-content' } }>
                                        <Label large xBold text={ firstName } />
                                        <Label large text={ restOfName } />
                                    </Row>
                                );
                            })(player.name) }
                            <Label small text={ player.team.name } />
                            <Label xLarge xBold text={ player.stat } />
                        </Column>
                        <PlayerImage src={ player.image? player.Image: playerPlaceHolder } alt="player image" />
                    </CardTop>
                );
            })(playerList?.data ? playerList?.data[0] : null, playerList?.category ?? "") }
            <CardBottom atomColors={ colors } style={ { backgroundColor: !theme.lightTheme? colors.action : "white"  } }>
                {
                    playerList.data
                        ? (
                            <>
                                { playerList.data.slice(1, 3).map((player, index) => {
                                    return (
                                        <RowItem key={ index } style={ { borderBottom: index === 1 ? '' : `0.5px solid ${ colors.text }` } }>
                                            <Row gap={ 10 }>
                                                <Label text={ index + 2 } />
                                                { ((name) => {
                                                    const firstName = name.split(" ")[0];
                                                    const restOfName = name.split(" ").slice(1).join(" ");
                                                    return (
                                                        <Row gap={ 4 } style={ { width: 'fit-content' } }>
                                                            <Label xBold text={ firstName } />
                                                            <Label text={ restOfName } />
                                                        </Row>
                                                    );
                                                })(player.name) }
                                            </Row>
                                            <Row alignCenter gap={ 10 } style={ { width: 'fit-content' } }>
                                                <TeamLogoSmall src={ player.team.logo ? player.team.logo : noImage } alt="small team logo" />
                                                <Label text={ player.stat } />
                                            </Row>
                                        </RowItem>
                                    );
                                }) }
                            </>
                        )
                        : (
                            [2, 3].map((_, index) => {
                                return (
                                    <RowItem
                                        key={ index }
                                        style={ {
                                            backgroundColor: '',
                                            height: 40,
                                            flexGrow: 1,
                                            paddingBlock: 10,
                                        } } >
                                        <Rectangle width="100%" />
                                    </RowItem>
                                )
                            })
                        )
                }
            </CardBottom>
            { button && (
                <button
                    style={ {
                        paddingBlock: 15,
                        backgroundColor: theme.lightTheme ? "#fff" : "#1C345F",
                        outline: 'none',
                        border: `none`,
                        color: colors.text,
                        cursor: 'pointer'
                    } }
                >Show full list</button>
            ) }
        </Card>
    );
}