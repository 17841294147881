import { useTeamTabHook } from "./team-tab-hook"
import styled from "styled-components"
import { BORDER_RADIUS } from "../../../../../../core/measurements"
import { Column } from "../../../../../../shared-components/column"
import { Row } from "../../../../../../shared-components/row"
import { useWindowWidth } from "../../../../../../hooks/use-window-width"

import { useNavigate } from 'react-router-dom'
import { useSetAtom } from "jotai"
import { allStatsPlayerListAtom } from "../../../../../all-stats-list-player/all-stats-list-player-atom"
import { _routeNames } from "../../../../../../app/routes"

export const TeamTab = () =>
{
    const { windowWidth } = useWindowWidth()
    const { data, colors, otherPlayersMaxIndexExclusive } = useTeamTabHook()
    return (
        <TeamStatsGrid style={ { gridTemplateColumns: windowWidth > 900 ? 'repeat(3, 1fr)' : windowWidth > 600 ? 'repeat(2, 1fr)' : 'repeat(1, 1fr)' } } >
            {
                data?.map((datum, index) => 
                {
                    return (
                        <>
                            {
                                datum.data?.map((info, indexx) =>
                                {
                                    return (
                                        <StatsCardv2
                                            key={ indexx }
                                            info={ info }
                                            colors={ colors }
                                            otherPlayersMaxIndexExclusive={ otherPlayersMaxIndexExclusive }
                                        />
                                    )
                                })
                            }
                        </>
                    )
                })
            }

        </TeamStatsGrid>
    )
}

function StatsCardv2({
    info,
    colors,
    otherPlayersMaxIndexExclusive
})
{
    let navigate = useNavigate()
    const allStatsListAtomSetter = useSetAtom(allStatsPlayerListAtom)

    return (
        <Card>
            <CardTopHalf teamColor={ info.values[0].teamColor }>
                <Column justifyBetween gap={ 10 } style={ { zIndex: 50, height: '100%', width: 'fit-content' } }>
                    <CardLabel small>{ info.label }</CardLabel>
                    <CardLabel>{ info.values[0].name }</CardLabel>
                    <CardLabel large>{ info.values[0].stat }</CardLabel>
                </Column>
                <CardImage src={ info.values[0].logo } />
            </CardTopHalf>
            <CardBottomHalf atomColors={ colors }>
                { info.values?.slice(1, otherPlayersMaxIndexExclusive).map((team, indexx) =>
                {
                    return (
                        <Row
                            key={ indexx }
                            alignCenter
                            justifyBetween
                            gap={ 10 }
                            style={ {
                                borderBottom: indexx === info.values.slice(1, otherPlayersMaxIndexExclusive).length - 1 ? 'none' : `1px solid ${ colors.faintText }`,
                                paddingBlockEnd: indexx === info.values.slice(1, otherPlayersMaxIndexExclusive).length - 1 ? 0 : 10,
                                paddingInline: 20
                            } }
                        >
                            <Row alignCenter gap={ 10 } style={ { width: 'fit-content' } }>
                                <p style={ { fontSize: 'small' } }>{ indexx + 2 }</p>
                                <p style={ { fontWeight: 'bold' } }>{ team.name }</p>
                            </Row>
                            <Row alignCenter gap={ 10 } style={ { width: 'fit-content' } }>
                                <CardSmallImage src={ team.logo } />
                                <p style={ { fontWeight: 'bold' } }>{ team.stat }</p>
                            </Row>
                        </Row>
                    )
                }) }
                <Row
                    alignCenter
                    justifyCenter
                    style={ {
                        marginTop: 10,
                        paddingBlock: 20,
                        backgroundColor: colors.secondary,
                        fontWeight: 'bold',
                        fontSize: 'small',
                        cursor: 'pointer'
                    } }
                    onClick={ () =>
                    {
                        navigate(_routeNames.allTeamStats)
                        allStatsListAtomSetter({ category: info.label, subject: 'team' })
                    } }
                >
                    Show full list
                </Row>
            </CardBottomHalf>
        </Card>
    )
}

const CardLabel = styled.h3`
    width: fit-content;
    color: #fff;
    background-color: #21212155;
    padding: 5px;
    border-radius: ${ BORDER_RADIUS };
    font-size: ${ props => props.small ? 'small' : props.large ? 'xx-large' : '' };
    font-weight: ${ props => props.noBold && 'normal' };
`

const TeamStatsGrid = styled.div`
    display: grid;
    gap: 10px;
`

const Card = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    border-radius: ${ BORDER_RADIUS };
    overflow: clip;
`

const CardTopHalf = styled.div`
    width: 100%;
    height: 220px;
    padding: 20px;
    background-color: ${ props => props.teamColor };
    display: flex;
    flex-direction: column;
    position: relative;
`

const CardBottomHalf = styled.div`
    width: 100%;
    padding-top: 10px;
    background-color: ${ props => props.atomColors.background };
    display: flex;
    flex-direction: column;
    gap: 10px;
`

const CardImage = styled.img`
    height: 150px;
    object-fit: fill;
    position: absolute;
    bottom: 10px;
    right: 10px;
`

const CardSmallImage = styled.img`
    height: 30px;
    width: 30px;
    border-radius: 50%;
`

