import Modal from "@mui/material/Modal";
import { Column } from "./column";

function ModalPopUp({
  open,
  handleClose,
  children,
  width,
  height,
  verticalCenter,
  hideBackdrop,
  backgroundColor,
  color,
  padding = 50,
  top,
  max,
}) {
  return (
    <Modal
      hideBackdrop={hideBackdrop}
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Column
        justifyCenter={verticalCenter}
        style={{
          backgroundColor: backgroundColor ?? "whitesmoke",
          color: color ?? "#212121",
          width: width ?? 700,
          maxHeight: max ?? 1200,
          height: height ?? "95%",
          overflowY: "scroll",
          margin: "auto",
          position: "absolute",
          top: top ?? "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: 10,
          padding: padding,
        }}
      >
        {children ?? ""}
      </Column>
    </Modal>
  );
}

export default ModalPopUp;
