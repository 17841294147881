import { BiArrowBack } from 'react-icons/bi'
import { Column } from '../../shared-components/column'
import { Row } from '../../shared-components/row'
import nbcLogo from '../../assets/logo-grouped.png'
import { playerStatsDummyData } from '../home/components/stats/tabs-v2/player-tab/player-tab-dummy-data'
import { useAtom, useAtomValue } from 'jotai'
import { allStatsPlayerListAtom } from './all-stats-list-player-atom'
import { useEffect, useState } from 'react'
import { BORDER_RADIUS } from '../../core/measurements'
import { teamStatsDummyData } from '../home/components/stats/tabs-v2/team-tab/team-tab-dummy-data'
import { awardStatsDummyData } from '../home/components/stats/tabs-v2/awards-tab/awards-tab-dummy-data'
import { useNavigate } from 'react-router-dom'
import { colorAtom } from '../../state/shared-state-atoms/colors'

export function AllStatsPlayerList() 
{
    const { season, statsCategory, categories, handleCategoryClick, displayData } = useAllStatsHook()
    let navigate = useNavigate()
    const colors = useAtomValue(colorAtom)

    return (
        <Column
            style={ {
                color: '#000',
                backgroundColor: colors.background,
                minHeight: '100vh',
                padding: 50,
                width: '100%',
                margin: '0 auto',
                color: colors.text,
            } }
        >
            <Column
                gap={ 20 }
                style={ {
                    backgroundColor: colors.background,
                    height: '100%',
                    padding: 50,
                    maxWidth: 1200,
                    margin: '0 auto',
                } }>
                <Row alignCenter gap={ 10 } onClick={ () => navigate(-1) } style={ { cursor: 'pointer' } } >
                    <BiArrowBack />
                    <p>Back</p>
                </Row>
                <Row gap={ 10 }>
                    <img src={ nbcLogo } style={ { height: 50 } } />
                    <Column gap={ 10 }>
                        <p>NBC Premier League</p>
                        <p>{ season }</p>
                    </Column>
                </Row>
                {
                    categories
                        ? (
                            <>
                                <Row alignCenter gap={ 10 } style={ { overflowX: 'scroll', flexWrap: 'wrap', padding: 20 } } >
                                    {
                                        categories?.map((category, index) =>
                                        {
                                            return (
                                                <p
                                                    key={ index }
                                                    style={ {
                                                        fontWeight: category === statsCategory.category ? 'bold' : '',
                                                        fontSize: category === statsCategory.category ? 'large' : 'small',
                                                        cursor: 'pointer',
                                                        whiteSpace: 'nowrap',
                                                        padding: 10,
                                                        backgroundColor: category === statsCategory.category ? colors.secondary : '',
                                                        borderRadius: BORDER_RADIUS,
                                                        transition: '0.3s',
                                                        boxShadow: category === statsCategory.category ? 'rgba(0, 0, 0, 0.2) 0px 5px 15px' : '',
                                                    } }
                                                    onClick={ () => handleCategoryClick(category) }
                                                >
                                                    { category }
                                                </p>
                                            )
                                        })
                                    }
                                </Row>
                                {
                                    (function () { }(categories))
                                }
                            </>
                        )
                        : ''
                }
                {
                    // displayData?.data.map((data, index) =>
                    // {
                    //     return (
                    //         <Row
                    //             alignCenter
                    //             justifyBetween
                    //             key={ index }
                    //             gap={ 10 }
                    //             style={ {
                    //                 backgroundColor: colors.secondary,
                    //                 paddingBlock: 10,
                    //                 paddingInline: 15,
                    //                 borderRadius: BORDER_RADIUS,
                    //                 cursor: 'pointer'
                    //             } }
                    //         >
                    //             <Row alignCenter gap={ 20 } style={ { width: 'fit-content' } }>
                    //                 <div
                    //                     style={ {
                    //                         backgroundColor: colors.background,
                    //                         height: 30,
                    //                         aspectRatio: 1,
                    //                         borderRadius: '50%',
                    //                         display: 'flex',
                    //                         alignItems: 'center',
                    //                         justifyContent: 'center'
                    //                     } } >
                    //                     <p>{ index + 1 }</p>
                    //                 </div>
                    //                 <img src={ data.image } alt="" style={ { height: 30, aspectRatio: 1, borderRadius: '50%', backgroundColor: colors.background } } />
                    //                 <p style={ { fontWeight: 'bold' } }>{ data.name }</p>
                    //             </Row>
                    //             <Row alignCenter gap={ 20 } style={ { width: 70 } }>
                    //                 <img src={ data.team.logo } alt="" style={ { height: 30, aspectRatio: 1, borderRadius: '50%', backgroundColor: colors.background } } />
                    //                 <p style={ { fontWeight: 'bold' } } >{ data.stat }</p>
                    //             </Row>
                    //         </Row>
                    //     )
                    // })
                }
            </Column>
        </Column>
    )
}

const useAllStatsHook = () => 
{
    const [statsCategory, setstatsCategory] = useAtom(allStatsPlayerListAtom)
    const [season, setseason] = useState('2021')
    const [displayData, setdisplayData] = useState()
    const [categories, setcategories] = useState()

    const handleCategoryClick = (label) => setstatsCategory({ category: label })

    let data = playerStatsDummyData
    useEffect(() =>
    {

        let _displayData = {}

        data.forEach(element =>
        {
            if (element.season === `${ season }-${ parseInt(season) + 1 }`)
            {
                element.data.forEach(el =>
                {
                    if (el.label === statsCategory.category)
                    {
                        _displayData.data = el.values
                        _displayData.season = season
                    }
                })
            }
        })
        setdisplayData(_displayData)

        let _categories = new Set()
        data.forEach(element =>
        {
            if (element.season === `${ season }-${ parseInt(season) + 1 }`)
            {
                element.data.forEach(el => _categories.add(el.label));
            }
        });
        setcategories([..._categories])


    }, [season, statsCategory])


    return { season, statsCategory, categories, handleCategoryClick, displayData }
}