import { BsArrowRepeat } from 'react-icons/bs'
import styled from 'styled-components'

import {BiFootball} from 'react-icons/bi'
import {GiRunningShoe} from 'react-icons/gi'
import { RED } from '../../../use-match-stats'

export function PlayerCircle({ circleSize, isHome, jerseyNumber, _borderColor, onClick, goals = 0 , assists = 0, card = 0, substitutedAt = 0 })
{
    return (
        <PlayerCircleContainer
            circleSize={ circleSize }
            style={ {
                width: circleSize,
                aspectRatio: 1,
                borderRadius: '50%',
                border: `2px solid ${ _borderColor }`,
                zIndex: 60,
                color: 'white',
                backgroundColor: isHome ? '#F47C7C' : '#4B5D67',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                position: 'relative'
            } }
            onClick={ onClick }
        >
            <p style={ { fontWeight: 'bold' } }>{ jerseyNumber }</p>
            {
                goals
                    ? (
                        <GoalsScoredIcon>
                            <p
                                style={ {
                                    zIndex: 70,
                                    color: '#fff',
                                    aspectRatio: 1,
                                    borderRadius: '50%',
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                } }
                            >{ goals }</p>
                            <div
                                style={ {
                                    zIndex: 70,
                                    backgroundColor: '#fff',
                                    height: 17,
                                    aspectRatio: 1,
                                    borderRadius: '50%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                } }
                            >

                                <BiFootball
                                    style={ {
                                        color: '#000',
                                    } }
                                />
                            </div>
                        </GoalsScoredIcon>
                    )
                    : ''
            }

            {
                assists
                    ? (
                        <AssistsIcon>
                            <p
                                style={ {
                                    zIndex: 70,
                                    color: '#fff',
                                    aspectRatio: 1,
                                    borderRadius: '50%',
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                } }
                            >{ assists }</p>
                            <div
                                style={ {
                                    zIndex: 70,
                                    backgroundColor: '#fff',
                                    height: 17,
                                    aspectRatio: 1,
                                    borderRadius: '50%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                } }
                            >
                                <GiRunningShoe
                                    style={ {
                                        color: '#000',
                                        transform: 'scaleX(-1)',
                                    } }
                                />
                            </div>
                        </AssistsIcon>
                    )
                    : ''
            }

            {
                card
                    ? (
                        <CardIcon>
                            <div
                                style={ {
                                    backgroundColor: card === RED ? '#E51C23' : '#FFEB3B',
                                    border: `1px solid ${ card === RED ? '#AF120A' : '#0A6137' }`,
                                    height: 15,
                                    aspectRatio: 9 / 13,
                                    borderRadius: 2,
                                    transform: 'rotate(20deg)'
                                } }
                            />
                        </CardIcon>
                    )
                    : ''
            }

            {
                substitutedAt
                    ? (
                        <SubstitutionIcon>
                            <p
                                style={ {
                                    position: 'absolute',
                                    bottom: -5,
                                    right: -32,
                                    height: 15,
                                    aspectRatio: 9 / 13,
                                    borderRadius: 2,
                                    fontSize: 'small'
                                } }
                            >{ substitutedAt }"</p>
                            <div
                                style={ {
                                    position: 'absolute',
                                    bottom: -5,
                                    right: -10,
                                    zIndex: 70,
                                    backgroundColor: '#fff',
                                    height: 17,
                                    aspectRatio: 1,
                                    borderRadius: '50%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                } }
                            >

                                <BsArrowRepeat
                                    style={ {
                                        color: '#000',
                                    } }
                                />
                            </div>
                        </SubstitutionIcon>
                    )
                    : ''
            }
        </PlayerCircleContainer>
    )
}



const PlayerCircleContainer = styled.div`
    transition: 0.3s;
    
    &:hover {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }    
`

const GoalsScoredIcon = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
    position: absolute;
    top: -5px;
    left: -20px;
    transition: 0.3s;

    ${ PlayerCircleContainer }:hover & {
        top: -12px;
        left: -27px;
    }
`

const AssistsIcon = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
    position: absolute;
    bottom: -5px;
    left: -20px;
    transition: 0.3s;

    ${ PlayerCircleContainer }:hover & {
        bottom: -12px;
        left: -27px;
    }
`

const SubstitutionIcon = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
    position: absolute;
    bottom: 0px;
    right: -2px;
    transition: 0.3s;

    ${ PlayerCircleContainer }:hover & {
        bottom: -7px;
        right: -9px;
    }
`

const CardIcon = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
    position: absolute;
    top: -5px;
    right: -7px;
    transition: 0.3s;

    ${ PlayerCircleContainer }:hover & {
        top: -12px;
        right: -14px;
    }
`