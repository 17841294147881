import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { NavigationBar } from "../../shared-components/navigation-bar/navigation-bar";
import { MobileMenu } from "../../shared-components/navigation-bar/mobile-menu";
import { useAtomValue } from "jotai";
import styled from "styled-components";
import { _routeNames } from "../../app/routes";
import { BORDER_RADIUS } from "../../core/measurements";
import { handleGoBack } from "../../functions/handleGoBack";
import { Column } from "../../shared-components/column";
import noImage from "../../assets/teams/noImage.jpg";
import avatar from "../../assets/avatar.png";
import { Footer } from "../../shared-components/footer";
import { GoBack } from "../../shared-components/go-back";
import Label from "../../shared-components/Label";
import { Row } from "../../shared-components/row";
import { languageAtom } from "../../state/shared-state-atoms/language";
import { useManagersHook } from "./use-managers-hook";

export function Managers() {
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const { colors, navigate, managers, loading } = useManagersHook();
  const language = useAtomValue(languageAtom);

  if (loading) {
    return (
      <>
        <NavigationBar setOpenMobileMenu={setOpenMobileMenu} />
        <AnimatePresence>
          {openMobileMenu ? (
            <MobileMenu setOpenMobileMenu={setOpenMobileMenu} />
          ) : (
            ""
          )}
        </AnimatePresence>
        <ManagerContainer atomColors={colors}>
          <Column alignCenter justifyCenter style={{ padding: 50 }}>
            <Label
              text={language.isEnglish ? "loading ..." : "Tafadhali subiri ..."}
            />
          </Column>
        </ManagerContainer>
      </>
    );
  }

  return (
    <>
      <NavigationBar setOpenMobileMenu={setOpenMobileMenu} />
      <AnimatePresence>
        {openMobileMenu ? (
          <MobileMenu setOpenMobileMenu={setOpenMobileMenu} />
        ) : (
          ""
        )}
      </AnimatePresence>
      <ManagerContainer atomColors={colors}>
        <Column
          gap={20}
          style={{ maxWidth: 1200, width: "90%", margin: "0 auto" }}
        >
          <Row alignCenter justifyBetween>
            <GoBack handleGoBack={() => handleGoBack(navigate)} />
          </Row>

          <Row
            alignCenter
            justifyBetween
            style={{
              backgroundColor: colors.faintText,
              paddingInline: 25,
              paddingBlock: 20,
              borderRadius: BORDER_RADIUS,
              fontWeight: "bold",
            }}
          >
            <p>{language.isEnglish ? "Manager" : "Kocha"}</p>
            <p style={{ width: 50, textAlign: "end" }}>
              {language.isEnglish ? "Club" : "Klabu"}
            </p>
          </Row>

          {managers?.map((manager, index) => {
            return (
              <Row
                key={index}
                alignCenter
                gap={10}
                style={{
                  backgroundColor: colors.secondary,
                  paddingInline: 25,
                  paddingBlock: 20,
                  borderRadius: BORDER_RADIUS,
                  border: `1px solid ${colors.faintText}`,
                  cursor: "pointer",
                }}
                onClick={() =>
                  navigate(`${_routeNames.allManagers}/${manager._id}`)
                }
              >
                <p>{index + 1}</p>
                <img
                  src={manager.pic ?? avatar}
                  style={{
                    height: 80,
                    aspectRatio: 1,
                    borderRadius: "50%",
                    backgroundColor: "tomato",
                    objectFit: "cover",
                  }}
                />
                <Column gap={5}>
                  <h3>{manager?.name}</h3>
                </Column>
                <Row
                  alignCenter
                  gap={10}
                  style={{
                    width: "fit-content",
                    fontSize: "small",
                    color: "grey",
                    paddingRight: "30px",
                  }}
                >
                  <h4 style={{ whiteSpace: "nowrap" }}>
                    {manager?.current_club?.name}
                  </h4>
                  <img
                    src={manager.current_club.logo ?? noImage}
                    style={{
                      height: 40,
                      aspectRatio: 1,
                      borderRadius: "50%",
                      backgroundColor: colors.secondary,
                      objectFit: "cover",
                    }}
                  />
                </Row>
              </Row>
            );
          })}
        </Column>
      </ManagerContainer>
      <Footer />
    </>
  );
}

const ManagerContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  color: ${(props) => props.atomColors.text};
  background-color: ${(props) => props.atomColors.background};
  position: relative;
  padding-block: 50px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
