import { useAtom, useAtomValue } from "jotai";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Column } from "../../shared-components/column";
import { Row } from "../../shared-components/row";
import { colorAtom } from "../../state/shared-state-atoms/colors";
import { resetPasswordAtom } from "./reset-password-atom";

import { CgArrowLongLeft } from 'react-icons/cg'
import { AiOutlineCode } from 'react-icons/ai'
import { AiOutlineLock } from 'react-icons/ai'

import { InputField } from "../../shared-components/input-field";
import { FramerButtonFilled } from "../../shared-components/framer-button-filled";
import { postRequestWithOutToken } from "../../core/network-request-functions";
import { URLS } from "../../core/urls";
import { languageAtom } from "../../state/shared-state-atoms/language";
import toast from "react-hot-toast";
import { _routeNames } from "../../app/routes";

export function ResetPassword() {
    const {
        colors,
        loading,
        phoneEntered,
        handleGoBack,
        code,
        setcode,
        newPassword,
        setnewPassword,
        confirmNewPassword,
        setconfirmNewPassword,
        handleResetPassword
    } = useResetPasswordHook()
    const language = useAtomValue(languageAtom)
    return (
        <Column
            alignCenter
            justifyCenter
            style={ {
                width: '100vw',
                height: '100vh',
                backgroundColor: colors.background,
                color: colors.text,
            } }
        >
            {
                loading
                    ? (
                        <p>{ language.isEnglish ? "loading" : 'tafadhali subiri' } ...</p>
                    )
                    : (
                        <Column
                            gap={ 10 }
                            style={ {
                                width: '90%',
                                maxWidth: 550,
                                color: colors.text,
                            } }
                        >
                            <Row alignCenter gap={ 5 } onClick={ handleGoBack } style={ { width: 'fit-content', cursor: 'pointer', marginBottom: 10 } }>
                                <CgArrowLongLeft />
                                <p>{ language.isEnglish ? "Go back" : "Rudi nyuma" }</p>
                            </Row>
                            <p style={ { color: colors.text, fontSize: 'xx-large', fontWeight: 'bold' } }>
                                {
                                    language.isEnglish
                                        ? "Enter code and new password"
                                        : "Ingiza tarakimu tulizokutumia pamoja na nenosiri jipya"
                                }
                            </p>
                            <p style={ { marginBottom: 10 } }>
                                {
                                    language.isEnglish
                                        ? (
                                            <>
                                                We have sent a verification code to{ ' ' }
                                                <span style={ { fontWeight: 'bold', textDecoration: 'underline', textUnderlineOffset: 3, color: colors.faintText } }>
                                                    { phoneEntered }
                                                </span>
                                                { '. ' }Enter that code along with your new password below.
                                            </>
                                        )
                                        : (
                                            <>
                                                Tumetuma tarakimu za uthibitisho kwenye namba{ ' ' }
                                                <span style={ { fontWeight: 'bold', textDecoration: 'underline', textUnderlineOffset: 3, color: colors.text } }>
                                                    { ((phoneEntered => {
                                                        return phoneEntered.replace(phoneEntered.slice(0, 6), "******")
                                                    })(phoneEntered)) }
                                                </span>
                                                { '. ' }Ingiza hizo tarakimu pamoja na nenosiri jipya.
                                            </>
                                        )
                                }

                            </p>
                            <InputField
                                type="tel"
                                Icon={ () => <AiOutlineCode style={ { fontSize: 20 } } /> }
                                placeHolder={ language.isEnglish ? "Code sent to you" : "Tarakimu ulizotumiwa" }
                                code
                                value={ code }
                                setter={ setcode }
                            />
                            <InputField
                                type="password"
                                Icon={ () => <AiOutlineLock style={ { fontSize: 20 } } /> }
                                placeHolder={ language.isEnglish ? "New password" : "Neno siri jipya" }
                                password
                                value={ newPassword }
                                setter={ setnewPassword }
                            />
                            <InputField
                                type="password"
                                Icon={ () => <AiOutlineLock style={ { fontSize: 20 } } /> }
                                placeHolder={ language.isEnglish ? "Confirm new password" : "Thibitisha neno siri jipya" }
                                password
                                value={ confirmNewPassword }
                                setter={ setconfirmNewPassword }
                            />
                            <FramerButtonFilled
                                marginTop={ 10 }
                                label={ language.isEnglish ? "Confirm password reset" : "Thibitisha kubadili nenosiri" }
                                onClick={ handleResetPassword }
                            />
                        </Column>
                    )
            }

        </Column>
    )
}

const useResetPasswordHook = () => {
    let navigate = useNavigate()
    const language = useAtomValue(languageAtom)
    const colors = useAtomValue(colorAtom)
    const [resetPasswordAtomValue, setresetPasswordAtomValue] = useAtom(resetPasswordAtom)
    const { username } = resetPasswordAtomValue

    const [loading, setloading] = useState(false)

    const [code, setcode] = useState('')
    const [newPassword, setnewPassword] = useState('')
    const [confirmNewPassword, setconfirmNewPassword] = useState('')

    const handleGoBack = () => {
        setresetPasswordAtomValue(prev => { return { ...prev, phone: '' } })
        navigate(-1)
    }

    const handleResetPassword = async () => {
        if (
            Boolean(code === '') || Boolean(newPassword === '')
        ) {
            toast.error(
                language.isEnglish
                    ? "code and password fields must not be empty"
                    : "Tarakimu na nenosiri vinabidi vijazwe"
            )
            return
        }

        if (code.length !== 5) {
            toast.error(
                language.isEnglish
                    ? "the code should have five digits"
                    : "Tarakimu inabidi iwe na namba tano"
            )
            return
        }

        if (newPassword.length < 6) {
            toast.error(
                language.isEnglish
                    ? "the password must have a minimum of 6 characters"
                    : "Nenosiri linabidi liwe na herufi zisizopungua 6"
            )
            return
        }

        if (newPassword !== confirmNewPassword) {
            toast.error(
                language.isEnglish
                    ? "the password and confirm password fields should be equal"
                    : "Sehemu Nenosiri na Thibitisha nenosiri inabidi ziwe sawa"
            )
            return
        }


        setloading(true)
        const _body = { 
            token: code,
            username: username,
            password: newPassword
        }

        try {
            const respone = await postRequestWithOutToken(URLS.recoverPassword, _body)

            if (respone.status === 200) {
                setloading(false)
                navigate(_routeNames.auth.login)
                toast.success(
                    language.isEnglish
                        ? "password changed successfully"
                        : "umefanikiwa kubadilisha neno siri"
                )
            } else{
                
                toast.error(
                    language.isEnglish
                        ? "Something went wrong"
                        : "TKuna tatizo limetokea"
                )
                setloading(false)

                return
            }   
        } catch (error) {
            toast.error(
                language.isEnglish
                    ? "Invalid credentils provided"
                    : "Umetuma taarifa zisizo sahihi"
            )
            console.log("error when reseting password", error)
            setloading(false)
            return
        }

    }

    return {
        colors,
        loading,
        username,
        handleGoBack,
        code,
        setcode,
        newPassword,
        setnewPassword,
        confirmNewPassword,
        setconfirmNewPassword,
        handleResetPassword
    }
}   