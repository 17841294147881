import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { NavigationBar } from "../../shared-components/navigation-bar/navigation-bar";
import { MobileMenu } from "../../shared-components/navigation-bar/mobile-menu";
import { usePartnersHook } from "./use-partners-hook";
import { Column } from "../../shared-components/column";
import { BORDER_RADIUS } from "../../core/measurements";
import { Row } from "../../shared-components/row";
import { handleGoBack } from "../../functions/handleGoBack";
import { GoBack } from "../../shared-components/go-back";
import Label from "../../shared-components/Label";
import { useAtomValue } from "jotai";
import { languageAtom } from "../../state/shared-state-atoms/language";
import { Footer } from "../../shared-components/footer";

export function Partners() {
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const partnersHook = usePartnersHook();
  const language = useAtomValue(languageAtom);

  return (
    <>
      <NavigationBar setOpenMobileMenu={setOpenMobileMenu} />
      <AnimatePresence>
        {openMobileMenu ? (
          <MobileMenu setOpenMobileMenu={setOpenMobileMenu} />
        ) : (
          ""
        )}
      </AnimatePresence>
      <Column
        alignCenter
        style={{
          backgroundColor: partnersHook.colors.background,
          minHeight: "100vh",
          color: partnersHook.colors.text,
        }}
      >
        <div className="  flex bg-[#FDFEFF] border rounded-xl  justify-center mt-5 w-3/4 p-2 m-2 ">
          <Row alignCenter justifyBetween>
            <GoBack handleGoBack={() => handleGoBack(partnersHook.navigate)} />
          </Row>
        </div>

        <h1 style={{ fontWeight: 800, color: partnersHook.colors.faintText }}>
          {language.isEnglish ? "Partners" : "Washirika"}
        </h1>

        {partnersHook.loading ? (
          <Label
            text={language.isEnglish ? "loading ..." : "Tafadhali subiri ..."}
          />
        ) : (
          <>
            <div
              // style={{
              //   width: 250,
              //   backgroundColor: "#fff",
              //   borderRadius: BORDER_RADIUS,
              //   overflow: "clip",
              //   border: `1px solid ${partnersHook.colors.faintText}`,
              //   display: "grid",
              //   rowGap: 20,
              // }}
              className=" grid grid-cols-4 gap-1 mt-5  w-3/4"
            >
              {partnersHook.partnersList?.map((partner, index) => {
                return (
                  <>
                    {/* <h2
                      style={{
                        fontWeight: 800,
                        width: "100%",
                        backgroundColor: partnersHook.colors.primary,
                        paddingBlock: 20,
                        textAlign: "center",
                        color: "whitesmoke",
                      }}
                    >
                      {partner?.name}
                    </h2> */}
                    <div
                      style={{
                        height: 200,
                        aspectRatio: 1,
                        backgroundColor: "#fff",
                      }}
                    >
                      <img
                        src={partner?.logo}
                        alt=""
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                  </>
                );
              })}
            </div>
          </>
        )}
        {/* <Column
          style={{
            maxWidth: 1200,
            width: "100%",
            margin: "0 auto",
            paddingBlock: 5,
          }}
        >
          
        </Column> */}
      </Column>
      <Footer />
    </>
  );
}
