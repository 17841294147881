import { useLayoutEffect, useState } from "react";

export const useWindowHeight = () => {
  const [windowHeight, setwindowHeight] = useState(null);
  useLayoutEffect(() => {
    setwindowHeight(window.innerWidth);
    const updateHeight = () => setwindowHeight(window.innerHeight);
    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  return { windowHeight };
};
