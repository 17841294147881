import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { colorAtom } from "../../state/shared-state-atoms/colors";
import axios from "axios";
import { URLS } from "../../core/urls";

export const usePlayers = (language) => {
  const colors = useAtomValue(colorAtom);
  const [loading, setloading] = useState(false);
  const [modalLoading, setmodalLoading] = useState(false);
  const [playersInfo, setPlayersInfo] = useState([]);
  const [displayPlayersInfo, setdisplayPlayersInfo] = useState([]);
  const [clubs, setclubs] = useState(["All clubs"]);
  const [selectedClubIndex, setselectedClubIndex] = useState(0);

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    setmodalLoading(true);

    axios.get(URLS.allTeams).then((response) => {
      let _clubList = [];
      _clubList.push("All");
      response.data.forEach((element) => _clubList.push(element));
      setclubs((prev) => {
        prev = [..._clubList];
        return prev;
      });
      setmodalLoading(false);
    });
  };
  const handleClose = () => {
    setOpen(false);
    setmodalLoading(false);
  };

  let _apidata = [];
  let _clubList = new Set();

  useEffect(() => {
    setloading(true);

    /**
     * getting all players in the league
     */
    axios.get(`${ URLS.allPlayers }`).then((response) => {
      setPlayersInfo(response.data);
      setdisplayPlayersInfo(response.data);
      setloading(false);
    });
  }, []);

  const handleClubClick = (index) => {
    setselectedClubIndex(index);
    if (index === 0) setdisplayPlayersInfo(playersInfo.slice(0)); // slice(0) returns a copy of the array
    if (index !== 0) {
      let _list = [];
      playersInfo.forEach((element) => (element.current_club.name === clubs[index].name ? _list.push(element) : ""));
      setdisplayPlayersInfo(_list);
    }
    handleClose();
  };

  return {
    colors,
    loading,
    displayPlayersInfo,
    clubs,
    selectedClubIndex,
    handleClubClick,
    open,
    handleOpen,
    handleClose,
    modalLoading,
  };
};
