import axios from 'axios'
import { NEW_BASE_URL } from './urls'

export const getRequestWithToken = (token, path) =>
{
    axios.defaults.baseURL = NEW_BASE_URL
    axios.defaults.headers.common = { 'Authorization': `Bearer ${ token }`, 'Accept': 'application/json', 'access-control-allow-origin': '*' }
    axios.defaults.headers.get['Content-Type'] = 'application/json'
    axios.defaults.headers.get["access-control-allow-origin"] = "*"
    return axios.get(path)
}

export const getRequestWithOutToken = (path) =>
{
    axios.defaults.baseURL = NEW_BASE_URL
    axios.defaults.headers.common = { 'Accept': 'application/json', 'access-control-allow-origin': '*' }
    axios.defaults.headers.get['Content-Type'] = 'application/json'
    axios.defaults.headers.get["access-control-allow-origin"] = "*"
    return axios.get(path)
}

export const postRequestWithToken = (token, path, body) =>
{
    axios.defaults.baseURL = NEW_BASE_URL
    axios.defaults.headers.common = { 'Authorization': `Bearer ${ token }`, 'Accept': 'application/json' }
    axios.defaults.headers.post = { 'Content-Type': 'application/json' }
    return axios.post(path, body)
}

export const postRequestWithOutToken = (path, body) =>
{
    axios.defaults.baseURL = NEW_BASE_URL
    axios.defaults.headers.common = { 'Accept': 'application/json', 'access-control-allow-origin': '*' }
    axios.defaults.headers.post['Content-Type'] = 'application/json'
    axios.defaults.headers.post["access-control-allow-origin"] = "*"
    return axios.post(path, body)
}
