import teamlogo from '../../../../../../assets/teams/az.png'
import playerImage from '../../../../../../assets/players/1n.png'
import { Team } from '../../../../../../models/team-model'
import { PlayerStat } from '../../../../../../models/player-stat'
import { TeamStat } from '../../../../../../models/team-stat'

export const teamStatsDummyData = [
    {
        season: '2021-2022',
        data: [
            {
                label: 'Goals',
                values: [
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo, teamColor: 'beige' }),
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo }),
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo }),
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo }),
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo }),
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo }),
                ]
            },
            {
                label: 'Shots',
                values: [
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo }),
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo }),
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo }),
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo }),
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo }),
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo }),
                ]
            },
            {
                label: 'Shot efficiency',
                values: [
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo, teamColor: '#121212' }),
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo }),
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo }),
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo }),
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo }),
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo }),
                ]
            },
            {
                label: 'Clean sheet',
                values: [
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo, teamColor: 'grey' }),
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo }),
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo }),
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo }),
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo }),
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo }),
                ]
            },
            {
                label: 'Yellow card',
                values: [
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo, teamColor: '#222' }),
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo }),
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo }),
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo }),
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo }),
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo }),
                ]
            },
            {
                label: 'Red card',
                values: [
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo }),
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo }),
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo }),
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo }),
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo }),
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo }),
                ]
            },
            {
                label: 'Penalty coceeded',
                values: [
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo }),
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo }),
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo }),
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo }),
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo }),
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo }),
                ]
            },
            {
                label: 'Penalty scored',
                values: [
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo }),
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo }),
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo }),
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo }),
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo }),
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo }),
                ]
            },
            {
                label: 'Own goals',
                values: [
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo }),
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo }),
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo }),
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo }),
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo }),
                    new TeamStat({ name: 'Azam FC', stat: 16, logo: teamlogo }),
                ]
            },
        ],
    }
]