import { Outlet, useNavigate } from "react-router-dom";
import { _routeNames } from "../../../../app/routes";
import { Row } from "../../../../shared-components/row";

import styled from "styled-components";
import { useAtomValue } from "jotai";
import { colorAtom } from "../../../../state/shared-state-atoms/colors";
import { useState } from "react";

import { BORDER_RADIUS } from "../../../../core/measurements";
import { MatchDayV2 } from "../match-day-v2";
import { MatchDayV3 } from "../match-day-v2/index2";
import { LeagueTable2 } from "../league-tableV2";
import { LeagueTable3 } from "../league-tableV2/index2";
import { themeAtom } from "../../../../state/shared-state-atoms/_theme";
import { languageAtom } from "../../../../state/shared-state-atoms/language";
// import { useWindowWidth } from "../../../../hooks/use-window-width";
import { useWindowWidth } from "../../../../hooks/use-window-width";

export function League() {
  const { handleTable, handleMatchDay, colors, selectedIndex, handleClick } =
    useLeagueHook();
  const { windowWidth } = useWindowWidth();
  const language = useAtomValue(languageAtom);

  return (
    <div
      style={{
        minHeight: "70vh",
        width: "100%",
        paddingBlock: 1,
      }}
    >
      <Row
        gap={10}
        alignCenter
        justifyCenter
        style={{
          backgroundColor: colors.secondary,
          width: windowWidth,
          height: 50,
          minWidth: 250,
          paddingInline: 20,
          //   paddingBlock: 15,
          margin: "0 auto",
          borderRadius: BORDER_RADIUS,
          marginBottom: 20,
        }}
      >
        {[
          language.isEnglish ? "Fixtures" : "Ratiba",
          language.isEnglish ? "Table" : "Msimamo",
        ].map((label, index) => {
          return (
            <div>
              <Label
                key={index}
                atomColor={colors}
                selected={index === selectedIndex}
                onClick={() => handleClick(index)}
                style={{ cursor: "pointer" }}
              >
                {label}
              </Label>
              <div
                style={{
                  height: 5,
                  width: 100,
                  backgroundColor: "red",
                  borderWidth: 1,
                  borderColor: "red",
                  borderRadius: 10,
                  display: index === selectedIndex ? "flex" : "none",
                }}
              ></div>
            </div>
          );
        })}
      </Row>
      {(function (selectedIndex) {
        switch (selectedIndex) {
          case 0:
            return <MatchDayV3 />;

          case 1:
            return <LeagueTable3 />;

          default:
            break;
        }
      })(selectedIndex)}
    </div>
  );
}

const useLeagueHook = () => {
  let navigate = useNavigate();
  let colors = useAtomValue(colorAtom);
  const [selectedIndex, setselectedIndex] = useState(0);

  const handleTable = () => {
    setselectedIndex(1);
    navigate(`${_routeNames.root.league.leagueV2}`);
  };

  const handleMatchDay = () => {
    setselectedIndex(0);
    navigate(_routeNames.root.league.matchDayV2);
  };

  const handleClick = (index) => setselectedIndex(index);

  return { handleTable, handleMatchDay, colors, selectedIndex, handleClick };
};

const Label = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  transition: 0.3s;
  padding: 10px;

  color: ${(props) => props.atomColor.text};
  font-weight: ${(props) => (props.selected ? "bold" : "")};
  font-size: ${(props) => (props.selected ? "large" : "normal")};

  &:hover {
    font-size: large;
  }
`;
