import { atom } from "jotai";
import { languageAtom } from "../../../../state/shared-state-atoms/language";

export const leagueTableTextAtom = atom(get => {
    const isEnglish = get(languageAtom).isEnglish

    return {
        title: isEnglish ? 'League Table' : 'Msimamo wa ligi',
        league: isEnglish ? 'Table' : 'Msimamo',
    }
})