import React from "react";

const EnglishAbout = () => {
  return (
    <div>
      <h2 className=" flex  mb-1">
        <strong>About us</strong>
      </h2>
      <p>
        <span className=" text-[#999999] text-sm">
          NBC is the oldest serving bank in Tanzania with over five decades of
          experience. We offer a range of retail, business, corporate and
          investment banking, wealth management products and services.
        </span>
      </p>
      <h2 className=" flex  my-4">
        <strong>Our history</strong>
      </h2>
      <p>
        <span className=" text-[#999999] text-sm">
          The Bank traces its origins to 1967 when the Tanzanian Government
          nationalised all financial institutions, including banks. In 1991, the
          banking industry was amended and six years later, in 1997, the
          institution then known as National Bank of Commerce, was split into
          three separate entities:
        </span>
      </p>
      <ul>
        <li className=" mb-1">
          <span className=" text-[#999999] text-sm">
            1 NBC Holding Corporation
          </span>
        </li>
        <li className=" mb-1">
          <span className=" text-[#999999] text-sm">
            2 National Microfinance Bank (NMB)
          </span>
        </li>
        <li className=" mb-4">
          <span className=" text-[#999999] text-sm">3 NBC (1997) Limited</span>
        </li>
        <li className=" mb-2">
          <span className=" text-[#999999] text-sm">
            In 2000, South African financial services group Absa Group Limited
            acquired a majority stake in NBC.
          </span>
        </li>
        <li className=" mb-2">
          <span className=" text-[#999999] text-sm">
            The Government of Tanzania retained a 30% shareholding and the
            International Finance Corporation (IFC), a member of the World Bank
            Group, took up 15% shareholding in the bank. The new entity became
            known as NBC Limited.
          </span>
        </li>
      </ul>
      <h2 className=" flex  my-4">
        <strong>Our benchmarks</strong>
      </h2>
      <ul>
        <li className=" mb-1">
          <span className=" text-[#999999] text-sm">
            NBC is the only international bank with a presence all over the
            country
          </span>
        </li>
        <li className=" mb-1">
          <span className=" text-[#999999] text-sm">
            With 51 branches and over 230 ATMs, NBC delivers a unique collection
            and banking capability to our customers
          </span>
        </li>
        <li className=" mb-4">
          <span className=" text-[#999999] text-sm">
            NBC currently employs around 1,200 staff across the country
          </span>
        </li>
      </ul>
      <h2 className=" flex  my-4">
        <strong>Our accolades and awards</strong>
      </h2>
      <ul>
        <li className=" mb-1">
          <span className=" text-[#999999] text-sm">
            1 2005 Bankers Awards (Corporate Bank of the Year)
          </span>
        </li>
        <li className=" mb-1">
          <span className=" text-[#999999] text-sm">
            2 2005 Bankers Awards (Special Technology Awards)
          </span>
        </li>
        <li className=" mb-1">
          <span className=" text-[#999999] text-sm">
            3 2009 TRA Compliant Large Tax Payer Award
          </span>
        </li>
        <li className=" mb-1">
          <span className=" text-[#999999] text-sm">
            4 2009 African Banker Award (Best Local Bank in Africa)
          </span>
        </li>
        <li className=" mb-1">
          <span className=" text-[#999999] text-sm">
            5 2015 Asian Banker Award (Best Retail Bank)
          </span>
        </li>
        <li className=" mb-1">
          <span className=" text-[#999999] text-sm">
            6 2015 NBC Voted 2015 – 16 Superbrands
          </span>
        </li>
        <li className=" mb-1">
          <span className=" text-[#999999] text-sm">
            7 2017 Best Islamic Retail Bank Award
          </span>
        </li>
        <li className=" mb-1">
          <span className=" text-[#999999] text-sm">
            8 2017 NBC Voted 2017 – 18 Superbrands
          </span>
        </li>
        <li className=" mb-1">
          <span className=" text-[#999999] text-sm">
            9 2018 Best Financial Institution that supports SMEs during 42nd
            DITF 2018
          </span>
        </li>
        <li className=" mb-1">
          <span className=" text-[#999999] text-sm">
            10 2018 2nd Best Financial Institutions after BOT during 2018
            Farmers exhibitions
          </span>
        </li>
      </ul>
    </div>
  );
};

export default EnglishAbout;
