import { useSetAtom } from "jotai";
import { languageAtom } from "../state/shared-state-atoms/language";

export const useChangeLanguage = () => 
{
    const setlanguage = useSetAtom(languageAtom);

    const handleChangeLanguage = () => 
    {
        setlanguage((prev) =>
        {
            return {
                ...prev,
                isEnglish: !prev.isEnglish
            };
        })
    }

    return { handleChangeLanguage }
}