import styled from "styled-components";
import { Colors } from "../../../../../core/colors";
import { Row } from "../../../../../shared-components/row";


import { GoDash } from "react-icons/go";
import { BORDER_RADIUS, SMALL_SPACING } from "../../../../../core/measurements";
import { useWindowWidth } from "../../../../../hooks/use-window-width";
import { useAtom } from "jotai";
import { colorAtom } from "../../../../../state/shared-state-atoms/colors";



export function PlayOffView({ _playOffViewData, onClick })
{
  const { windowWidth } = useWindowWidth();
  const [colors] = useAtom(colorAtom)
  return (
    <PlayOffContainer onClick={ onClick }>
      <PlayOffViewRow
        atomColors={ colors }
        style={ {
          borderBottom: `1px solid ${ colors.faintText }`,
          color: colors.text,
          position: 'relative'
        } }
      >
        <div
          style={ {
            height: 40,
            width: 40,
            borderRadius: BORDER_RADIUS,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: colors.text,
            backgroundColor: _playOffViewData.tag ? colors.background : "transparent",
            border: _playOffViewData.tag ? _playOffViewData.tag.toString().includes('"') ? `3px solid ${colors.faintText}` : '' : '',
            position: 'absolute',
            top: 0,
            left: 0
          } }
        >
          { _playOffViewData.tag }
        </div>

        <Row alignCenter justifyBetween gap={ windowWidth < 400 ? 20 : 30 } >
          <TeamDispay
            teamName={ _playOffViewData.homeTeamName }
            teamLogo={ _playOffViewData.homeTeamLogo }
            windowWidth={ windowWidth }
          />
          <ScoreLine home={ _playOffViewData.homeScore } away={ _playOffViewData.awayScore } />
          <TeamDispay
            teamName={ _playOffViewData.awayTeamName }
            teamLogo={ _playOffViewData.awayTeamLogo }
            windowWidth={ windowWidth }
            right
          />
        </Row>
      </PlayOffViewRow>
    </PlayOffContainer>
  );
}

const PlayOffViewRow = styled.div`
  display: flex;
  align-items: center;
  padding-block: 20px;
  padding-inline: 10px;
  cursor: pointer;
  transition: 0.3s;
  background-color: ${ props => props.atomColors.secondary };
  border-radius: ${ BORDER_RADIUS };

  &:hover {
    background-color: ${ props => props.atomColors.faintText };
  }
`

const tagColors = { ft: Colors.secondary, default: "#2E7D32" }

const tagColorGenerator = (tag, colors) =>
{
  switch (tag)
  {
    case "FT":
      return colors.background

    default:
      return tagColors.default
  }
}

function ScoreLine({ home, away })
{
  return (
    <Row alignCenter gap={ 10 } style={ { width: "fit-content" } }>
      <p>{ home }</p>
      <GoDash />
      <p>{ away }</p>
    </Row>
  );
}

function TeamDispay({ teamName, teamLogo, windowWidth, right })
{
  return (
    <Team style={ { width: '50%' } }>
      { windowWidth > 750 ? (
        <>
          { right ? (
            <Row alignCenter gap={ 10 } >
              <TeamImage src={ teamLogo } alt="team logo" />
              <TeamName>{ teamName }</TeamName>
            </Row>
          ) : (
            <Row alignCenter gap={ 10 } style={ { justifyContent: 'flex-end' } } >
              <TeamName>{ teamName }</TeamName>
              <TeamImage src={ teamLogo } alt="team logo" />
            </Row>
          ) }
        </>
      ) : (
        <>
          <TeamImage src={ teamLogo } alt="team logo" />
          <TeamName>{ teamName }</TeamName>
        </>
      ) }
    </Team>
  );
}

const TeamName = styled.p`
  width: fit-content;
`;

const TeamImage = styled.img`
  height: 70px;

  @media (max-width: 400px) {
    height: 40px;
  }
`;

const PlayOffContainer = styled.div`
  width: 100%;
  color: ${ Colors.text };
  margin-top: ${ SMALL_SPACING };
`;

const Team = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  @media (max-width: 750px) {
    flex-direction: column;
  }
`;

const Circle = styled.div`
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: ${ (props) => (props.tag ? Colors.primary : "transparent") };
`;
