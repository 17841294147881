import countryCodesList from "country-codes-list"
import { useAtom, useAtomValue, useSetAtom } from "jotai"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { postRequestWithOutToken } from "../../core/network-request-functions"
import { URLS } from "../../core/urls"
import { colorAtom } from "../../state/shared-state-atoms/colors"
import { languageAtom } from "../../state/shared-state-atoms/language"
import { resetPasswordAtom } from "../reset-password/reset-password-atom"
import { countryCodeAtom } from "../sign-up-phone-verification/country-code-atom"
import { toast } from 'react-hot-toast'

const useForgotPasseordPageHook = () => {
    let navigate = useNavigate()
    const colors = useAtomValue(colorAtom)
    const language = useAtomValue(languageAtom)
    const setResetPasswordAtom = useSetAtom(resetPasswordAtom)

    let _popUpList = []
    const myCountryCodesObject = countryCodesList.all()
    for (const [_, value] of Object.entries(myCountryCodesObject)) {
        _popUpList.push(value)
    }
    const [countryCode, setCountryCode] = useAtom(countryCodeAtom)
    const [selectedValue, setSelectedValue] = useState(_popUpList[1]);
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
        setSelectedValue(value);
        setCountryCode(value.countryCallingCode)
    };


    const [loading, setloading] = useState(false)

    const [username, setUsername] = useState("")

    const handleSubmission = async () => {
        if (username === '') {
            toast.error(
                language.isEnglish
                    ? "Phone number or email field should be entered"
                    : "Namba ya simu au email inabidi ijazwe"
            )
            return
        }

        const _body = { username }

        setloading(true)

        try {
            const response = await postRequestWithOutToken(URLS.forgotPassword, _body)
            if (response.status === 200) {
                setResetPasswordAtom(prev => { return { ...prev, username: username } })
                setloading(false)
                setUsername('')
                navigate('/reset-password')
            }
        } catch (error) {
            const { response: { data: { message } } } = error
            toast.error(
                language.isEnglish
                    ? "Your phone number could not be verified"
                    : "Namba yako imeshindwa kuthibitishwa"
            )
            console.log({ message })
            setloading(false)
        }
    }

    const handleGoBack = () => navigate(-1)

    return {
        colors,
        username,
        setUsername,
        handleSubmission,
        handleGoBack,
        loading,
        countryCode,
        selectedValue,
        open,
        handleClickOpen,
        handleClose,
        _popUpList
    }
}

export default useForgotPasseordPageHook