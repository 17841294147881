import { Column } from "../../../../../../../shared-components/column";
import Label from "../../../../../../../shared-components/Label";
import { Row } from "../../../../../../../shared-components/row";
import IconBox from "./IconBox";
import Logo from "./Logo";
import ManagerCropped from "./ManagerCropped";
import { RiVipCrownLine } from "react-icons/ri";
import { useAtomValue } from "jotai";
import { languageAtom } from "../../../../../../../state/shared-state-atoms/language";
import { themeAtom } from "../../../../../../../state/shared-state-atoms/_theme";

export default function AwardCard({
  month,
  season,
  name,
  teamName,
  teamLogo,
  image,
  idx,
  display,
}) {
  const language = useAtomValue(languageAtom);
  const theme = useAtomValue(themeAtom);

  return (
    <Column
      style={{
        backgroundColor: idx === 0 ? "#E21D24" : "#7D3CA5",
        color: theme.lightTheme && "black",
        height: "fit-content",
        width: "100%",
        position: "relative",
        borderRadius: 10,
        overflow: "clip",
        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        margin: display === "Grid" ? 0 : 10,
      }}
    >
      <Column
        gap={10}
        style={{ zIndex: 50, height: "fit-content", padding: 20 }}
      >
        <IconBox>
          {" "}
          <RiVipCrownLine />{" "}
        </IconBox>
        <br />
        <Label color={"#FFFF"} uppercase xBold large text={month} />
        <Label
          color={"#FFFF"}
          xBold
          text={language.isEnglish ? "Manager of the month" : "Kocha wa mwezi"}
        />
        <Label color={"#FFFF"} small text={`NBC Premier League ${season}`} />
        <br />
        <Row alignCenter gap={10}>
          <Logo src={teamLogo} alt="" />
          <Column>
            <Label color={"#FFFF"} xBold large text={name} />
            <Label color={"#FFFF"} small text={teamName} />
          </Column>
        </Row>
      </Column>
      <div
        style={{
          height: 250,
          width: "100%",
          position: "absolute",
          rogth: 0,
          top: 0,
        }}
      >
        <ManagerCropped src={image} alt="" />
      </div>
    </Column>
  );
}
