import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { URLS } from "../../../../core/urls";
import apiCall from "../../../../functions/api_call";
import { languageAtom } from "../../../../state/shared-state-atoms/language";

export default function useHomeTab() {
  const language = useAtomValue(languageAtom);
  const months = [
    language.isEnglish ? "January" : "Januari",
    language.isEnglish ? "February" : "Februari",
    language.isEnglish ? "March" : "Machi",
    language.isEnglish ? "April" : "Aprili",
    language.isEnglish ? "May" : "Mei",
    language.isEnglish ? "June" : "Juni",
    language.isEnglish ? "July" : "Julai",
    language.isEnglish ? "August" : "Agosti",
    language.isEnglish ? "September" : "Septemba",
    language.isEnglish ? "October" : "Oktoba",
    language.isEnglish ? "November" : "Novemba",
    language.isEnglish ? "December" : "Desemba",
  ];
  const monthtostring = (month) => months[month - 1];
  const [awards, setAwards] = useState([]);
  const [managers, setManagers] = useState([]);
  const [players, setPlayers] = useState([]);

  useEffect(() => {
    const call = async () => {
      const _awardsCall = apiCall({ url: URLS.getAwards });

      const promises = [_awardsCall];
      try {
        const results = await Promise.allSettled(promises);
        if (results[0].value.data) {
          const _awards = results[0].value.data;
          setAwards((prev) => {
            prev = _awards;
            return prev;
          });
        } else {
          setAwards([]);
        }
      } catch (error) {
        console.log("error getting the awards", error);
      }
    };

    call();
  }, []);
  return { monthtostring, awards, managers, players };
}
