import * as React from 'react';
import { Column } from "../../shared-components/column";
import { useFeedbackHook } from "./use-feedback";

import { handleGoBack } from "../../functions/handleGoBack";
import { Footer } from "../../shared-components/footer";
import { GoBack } from "../../shared-components/go-back";
import { InputField } from "../../shared-components/input-field";
import { FeedbackContainer } from "./components/feedback-container";

import { BsFillPersonFill, BsLayoutTextWindowReverse, BsTelephoneFill } from 'react-icons/bs';
import { MdAlternateEmail, MdOutlineTopic } from 'react-icons/md';
import { TextAreaField } from "../../shared-components/text-area-field";

import { useAtomValue } from 'jotai';
import { FramerButtonFilled } from '../../shared-components/framer-button-filled';
import { languageAtom } from '../../state/shared-state-atoms/language';

export function Feedback() {
    const {
        navigate,
        colors,
        loading,
        name,
        setname,
        email,
        setemail,
        subject,
        setsubject,
        phone,
        setphone,
        message,
        setmessage,
        disableUserFields,
        handleSubmit,
    } = useFeedbackHook()
    const language = useAtomValue(languageAtom)

    return (
        <>
            <FeedbackContainer atomColors={ colors }>
                <Column
                    gap={ 20 }
                    style={ {
                        width: '90%',
                        maxWidth: 1000,
                    } }
                >
                    <GoBack handleGoBack={ () => handleGoBack(navigate) } />
                    <br />
                    <p className='font-[800] text-3xl capitalize'>{ language.isEnglish ? "Feedback" : "Maoni" }</p>

                    {
                        loading
                            ? (<p>{ language.isEnglish ? "loading" : "tafadhali subiri" } ...</p>)
                            : (
                                <>
                                    <InputField
                                        Icon={ () => <BsFillPersonFill style={ { fontSize: 20 } } /> }
                                        placeHolder={ language.isEnglish ? "Full name" : "Jina kamili" }
                                        type="text"
                                        value={ name }
                                        setter={ setname }
                                        disabled={ disableUserFields }
                                    />
                                    <InputField
                                        Icon={ () => <MdAlternateEmail style={ { fontSize: 20 } } /> }
                                        placeHolder={ language.isEnglish ? "Your email" : "Baruapepe" }
                                        type="email"
                                        value={ email }
                                        setter={ setemail }
                                        disabled={ disableUserFields }
                                    />
                                    <InputField
                                        Icon={ () => <BsTelephoneFill style={ { fontSize: 20 } } /> }
                                        placeHolder={ language.isEnglish ? "Phone number" : "Namba ya simu" }
                                        type="text"
                                        value={ phone }
                                        setter={ setphone }
                                    />
                                    <InputField
                                        Icon={ () => <MdOutlineTopic style={ { fontSize: 20 } } /> }
                                        placeHolder={ language.isEnglish ? "The subject" : "Kichwa cha habari" }
                                        type="text"
                                        value={ subject }
                                        setter={ setsubject }
                                    />
                                    <TextAreaField
                                        Icon={ () => <BsLayoutTextWindowReverse style={ { fontSize: 20 } } /> }
                                        placeHolder={ language.isEnglish ? "Your message" : "Ujumbe wako" }
                                        type="text"
                                        value={ message }
                                        setter={ setmessage }
                                    />
                                    <FramerButtonFilled label={ language.isEnglish ? "Submit feedback" : "Tuma maoni yako" } onClick={ handleSubmit } />
                                </>
                            )
                    }
                </Column>
            </FeedbackContainer>
            <Footer />
        </>
    )
}



