import logoGrouped from "../../assets/logo-grouped.png";
import { MainContainer } from "../../shared-components/main-container";

import { useAtomValue } from "jotai";
import { LARGE_SPACING } from "../../core/measurements";
import { FormContainer } from "../../shared-components/form-container";
import { FramerButtonFilled } from "../../shared-components/framer-button-filled";
import { InputField } from "../../shared-components/input-field";
import { LogoImage } from "../../shared-components/logo-image";
import { SimpleDialog } from "../../shared-components/pop-up";
import { SignUpOrLoginInstead } from "../../shared-components/sign-up-instead";
import { colorAtom } from "../../state/shared-state-atoms/colors";

import { IoChevronDown } from "react-icons/io5";
import { GoBack } from "../../shared-components/go-back";
import { Row } from "../../shared-components/row";
import { languageAtom } from "../../state/shared-state-atoms/language";
import useSignUpHook from "./useSignUpHook";


export function SignUpPhoneVerification() {
  const language = useAtomValue(languageAtom)
  const colors = useAtomValue(colorAtom)
  const {
    handleContinue,
    loading,
    phoneNumber,
    setphoneNumber,
    handleLoginGoogle,
    handleLoginFacebook,
    open,
    selectedValue,
    handleClickOpen,
    handleClose,
    _popUpList,
    countryCode, navigate
  } = useSignUpHook()
  return (
    <>
      <MainContainer
        style={ {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          minHeight: '100vh',
          backgroundColor: colors.background,
          paddingBlock: 50
        } }
      >
        <FormContainer>
          <GoBack style={ { alignSelf: 'flex-start' } } handleGoBack={ () => navigate(-1) } />
          <LogoImage src={ logoGrouped } alt="" />
          <InputField
            Icon={ () => {
              return (
                <Row alignCenter gap={ 5 } onClick={ handleClickOpen } style={ { width: 'fit-content', cursor: 'pointer' } }>
                  <h5>+{ countryCode }</h5>
                  <IoChevronDown />
                </Row>
              )
            } }
            placeHolder={ language.isEnglish ? "Phone number" : "Namba ya simu" }
            value={ phoneNumber }
            setter={ setphoneNumber }
          />
          <SimpleDialog
            selectedValue={ selectedValue }
            open={ open }
            onClose={ handleClose }
            dialogItems={ _popUpList }
          />
          <FramerButtonFilled
            disabled={ loading }
            onClick={ handleContinue }
            marginTop={ LARGE_SPACING }
            label={
              loading
                ? language.isEnglish ? "loading ..." : "tafadhali subiri ..."
                : language.isEnglish ? "Continue" : "Endelea"
            }
          />

          <p style={ { color: "#757572" } } >{ language.isEnglish ? "or" : "au" }</p>
          <FramerButtonFilled label={ language.isEnglish ? "Continue with Google" : "Endelea na Google" } onClick={ handleLoginGoogle } />
          <FramerButtonFilled label={ language.isEnglish ? "Continue with Facebook" : "Endelea na Facebook" } onClick={ handleLoginFacebook } />
        </FormContainer>
        <SignUpOrLoginInstead signUp />
      </MainContainer>
    </>
  );
}


