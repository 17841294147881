import teamlogo from '../../../../../../assets/teams/az.png'
import playerImage from '../../../../../../assets/players/1n.png'
import managerImage from '../../../../../../assets/managers/manager.png'
import { Team } from '../../../../../../models/team-model'
import { PlayerStat } from '../../../../../../models/player-stat'
import { AwardsStat } from '../../../../../../models/awards-stat'

const months = (isEnglish) => [
    isEnglish ? "January" : 'Januari',
    isEnglish ? "February" : 'Februari',
    isEnglish ? "March" : 'Machi',
    isEnglish ? "April" : 'Aprili',
    isEnglish ? "May" : 'Mei',
    isEnglish ? "June" : 'Juni',
    isEnglish ? "July" : 'Julai',
    isEnglish ? "August" : 'Agosti',
    isEnglish ? "September" : 'Septemba',
    isEnglish ? "October" : 'Oktoba',
    isEnglish ? "November" : 'Novemba',
    isEnglish ? "December" : 'Desemba',
]

export const awardStatsDummyData = (isEnglish) => [
    {
        season: '2021-2022',
        data: [
            {
                label: 'Player of the month',
                displayLabel: isEnglish ? 'Player of the month' : "Mchezaji wa mwezi",
                values: [
                    new AwardsStat({ time: months(isEnglish).at(0), name: 'Fiston Mayele', image: playerImage, team: new Team({ name: 'Azam FC', logo: teamlogo }) }),
                    new AwardsStat({ time: months(isEnglish).at(1), name: 'Fiston Mayele', image: playerImage, team: new Team({ name: 'Azam FC', logo: teamlogo }) }),
                    new AwardsStat({ time: months(isEnglish).at(2), name: 'Fiston Mayele', image: playerImage, team: new Team({ name: 'Azam FC', logo: teamlogo }) }),
                    new AwardsStat({ time: months(isEnglish).at(3), name: 'Fiston Mayele', image: playerImage, team: new Team({ name: 'Azam FC', logo: teamlogo }) }),
                    new AwardsStat({ time: months(isEnglish).at(4), name: 'Fiston Mayele', image: playerImage, team: new Team({ name: 'Azam FC', logo: teamlogo }) }),
                ]
            },
            {
                label: 'Manager of the month',
                displayLabel: isEnglish ? 'Manager of the month' : "Kocha wa mwezi",
                values: [
                    new AwardsStat({ time: months(isEnglish).at(0), name: 'Fiston Mayele', image: managerImage, team: new Team({ name: 'Azam FC', logo: teamlogo }) }),
                    new AwardsStat({ time: months(isEnglish).at(1), name: 'Fiston Mayele', image: managerImage, team: new Team({ name: 'Azam FC', logo: teamlogo }) }),
                    new AwardsStat({ time: months(isEnglish).at(2), name: 'Fiston Mayele', image: managerImage, team: new Team({ name: 'Azam FC', logo: teamlogo }) }),
                    new AwardsStat({ time: months(isEnglish).at(3), name: 'Fiston Mayele', image: managerImage, team: new Team({ name: 'Azam FC', logo: teamlogo }) }),
                    new AwardsStat({ time: months(isEnglish).at(4), name: 'Fiston Mayele', image: managerImage, team: new Team({ name: 'Azam FC', logo: teamlogo }) }),
                ]
            },
        ],
    }
]