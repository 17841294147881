import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Tab: 0,
};

export const TabSlice = createSlice({
  name: "tabs",
  initialState,
  reducers: {
    setTab: (state, action) => {
      state.Tab = action.payload;
    },
  },
});

export const { setTab } = TabSlice.actions;

export default TabSlice.reducer;
