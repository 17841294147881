import { Colors } from "../../../../core/colors";
import styled from "@emotion/styled";

import { useMatchDay } from "./use-match-day";
import { MatchDayContainer } from "./components/match-day-container";
import { MatchDayContents } from "./components/match-day-contents";
import { Column } from "../../../../shared-components/column";
import { MatchDayTabs } from "./components/match-day-tabs";
import { PlayOffView } from "./components/play-off-view";
import { Row } from "../../../../shared-components/row";
import { useWindowWidth } from "../../../../hooks/use-window-width";
import { useLocation, useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { colorAtom } from "../../../../state/shared-state-atoms/colors";

import azamLogo from "../../../../assets/teams/az.png";
import coastalUnions from "../../../../assets/teams/cu.png";
import { _routeNames } from "../../../../app/routes";

const _playOffViewData = [
  {
    date: 'date 1',
    matches: [
      { tag: '54"', homeTeamLogo: azamLogo, homeTeamName: 'Azam FC', homeScore: 2, awayTeamLogo: coastalUnions, awayTeamName: 'Coastal Unions', awayScore: 1 },
      { tag: '62"', homeTeamLogo: azamLogo, homeTeamName: 'Geita Gold', homeScore: 2, awayTeamLogo: coastalUnions, awayTeamName: 'KMC', awayScore: 1 },
      { tag: 'FT', homeTeamLogo: azamLogo, homeTeamName: 'Polisi Tanzania', homeScore: 2, awayTeamLogo: coastalUnions, awayTeamName: 'Kagera Sugar', awayScore: 1 },
      { tag: '', homeTeamLogo: azamLogo, homeTeamName: 'Mbeya Kwanza', homeScore: 2, awayTeamLogo: coastalUnions, awayTeamName: 'Simba', awayScore: 1 },
    ]
  },
  {
    date: 'date 2',
    matches: [
      { tag: 'SB', homeTeamLogo: azamLogo, homeTeamName: 'Geita Gold', homeScore: 2, awayTeamLogo: coastalUnions, awayTeamName: 'KMC', awayScore: 1 },
      { tag: '', homeTeamLogo: azamLogo, homeTeamName: 'Polisi Tanzania', homeScore: 2, awayTeamLogo: coastalUnions, awayTeamName: 'Kagera Sugar', awayScore: 1 },
      { tag: '', homeTeamLogo: azamLogo, homeTeamName: 'Mbeya Kwanza', homeScore: 2, awayTeamLogo: coastalUnions, awayTeamName: 'Simba', awayScore: 1 },
    ]
  }
]

export function MatchDay({ height })
{
  const { matchDays, matchDayIndex, handleChange, matchDayText } = useMatchDay();
  const { windowWidth } = useWindowWidth();
  let location = useLocation();
  const pathName = location.pathname.slice(1);
  const [colors] = useAtom(colorAtom);

  let navigate = useNavigate()

  return (
    <MatchDayContainer
      colorsAtom={ colors }
      style={ {
        color: "#fff",
        position: "relative",
        backgroundColor: colors.background,
        maxWidth: pathName === "" && windowWidth > 1600 ? 550 : 700,
        alignSelf:
          windowWidth > 1600 && height === null
            ? "center"
            : windowWidth > 1600 && height !== null
              ? "flex-start"
              : "center",
        margin:
          pathName === "" && windowWidth < 1600
            ? "20px 0"
            : pathName === "" && windowWidth > 1600
              ? "0"
              : "50px 0",
        marginLeft: "auto",
        marginRight: "auto",
      } }
    >
      <Row style={ { height: 70, backgroundColor: colors.background } }>
        <p
          style={ {
            fontWeight: "bold",
            fontSize: "xx-large",
            color: colors.faintText,
          } }
        >
          { matchDayText.title }
        </p>
      </Row>
      <MatchDayTabs
        matchDays={ matchDays }
        matchDayIndex={ matchDayIndex }
        handleChange={ handleChange }
      />
      <MatchDayContents
        atomColors={ colors }
        style={ {
          paddingBottom: 40,
          height: height,
          overflowY: height === null ? "clip" : "scroll",
          backgroundColor: colors.secondary,
          color: colors.text,
        } }
      >
        <Column
          alignCenter
          style={ {
            padding: "0 10px",
            marginBottom: 50,
            position: "relative",
          } }
        >
          <Column gap={ 20 }>
            {
              _playOffViewData.map((data, index) =>
              {
                return (
                  <Column key={ index }>
                    <p style={ { color: colors.text, marginTop: 10, textAlign: 'center', width: '100%' } }>
                      { data.date }
                    </p>

                    {
                      data.matches.map((match, index) =>
                      {
                        return (
                          <PlayOffView
                            key={ index }
                            _playOffViewData={ match }
                            onClick={ () =>
                            {
                              navigate(_routeNames.matchStatistics)
                            } }
                          />
                        )
                      })
                    }
                  </Column>
                )
              })
            }
          </Column>
          <SizedBox />
        </Column>
      </MatchDayContents>
    </MatchDayContainer>
  );
}

const SizedBox = styled.div`
  height: 80px;
  width: 50px;
`;

const ButtonCover = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
