import styled from 'styled-components'

export const SubPlayerName = styled.p`
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
transition: 0.3s;
cursor: pointer;
text-align: left;

&:hover {
    text-decoration: underline;
}
`
