import { useEffect, useState } from "react"
import { URLS } from "../../../../../../core/urls"
import getSeason from '../../../../../../functions/get_season'

export default function useTeamStats() {
    const [loading, setLoading] = useState(false)
    const [playerData, setPlayerData] = useState([])

    useEffect(() => {
        const _call = async () => {
            try {
                setLoading(true)
                const response = await fetch(`${ URLS.allPlayerStats }`)
                .then( resp =>  resp.json())

                setPlayerData(response )
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }
        _call()
    }, [])

    return { loading, playerData }
}