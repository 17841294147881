import { AnimatePresence } from "framer-motion";
import { useWindowWidth } from "../../hooks/use-window-width";
import { MainContainer } from "../../shared-components/main-container";
import { MobileMenu } from "../../shared-components/navigation-bar/mobile-menu";
import { NavigationBar } from "../../shared-components/navigation-bar/navigation-bar";
import { Loading } from "../loading/index";
import { useHome } from "./useHome";

import az from "../../assets/teams/az.png";
import bu from "../../assets/teams/bu.png";
import cu from "../../assets/teams/cu.png";
import dj from "../../assets/teams/dj.png";
import gg from "../../assets/teams/gg.png";
import kmc from "../../assets/teams/kmc.png";
import ks from "../../assets/teams/ks.png";
import mc from "../../assets/teams/mc.png";
import mt from "../../assets/teams/mt.png";
import nm from "../../assets/teams/nm.png";
import pl from "../../assets/teams/pl.png";
import pr from "../../assets/teams/pr.png";
import rs from "../../assets/teams/rs.png";
import sm from "../../assets/teams/sm.png";
import yn from "../../assets/teams/yn.png";

import { useAtom } from "jotai";
import { Outlet } from "react-router-dom";
import { Footer } from "../../shared-components/footer";
import { colorAtom } from "../../state/shared-state-atoms/colors";

const teamLogos = [az, bu, cu, dj, gg, kmc, ks, mc, nm, pl, pr, rs, sm, yn, mt];

export function Home() {
  const { loading, openMobileMenu, setOpenMobileMenu } = useHome();
  const { windowWidth } = useWindowWidth();
  const [colors] = useAtom(colorAtom);

  if (loading) return <Loading />;
  else
    return (
      <MainContainer atomColors={ colors } style={ mainContainerStyle(colors) }>
        <NavigationBar
          setOpenMobileMenu={ setOpenMobileMenu }
        />
        <AnimatePresence>
          {
            openMobileMenu
              ? (
                <MobileMenu setOpenMobileMenu={ setOpenMobileMenu } />
              ) : (
                ""
              )
          }
        </AnimatePresence>
        <Outlet />
        <Footer />
      </MainContainer>
    );
}

const mainContainerStyle = (colors) => {
  return {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: colors.background,
  };
};

const teamsRowStyle = (colors) => {
  return {
    height: 80,
    width: "100%",
    backgroundColor: colors.background,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 10,
    padding: "50px 10%",
  };
};

const teamLogoStyle = { height: 50, aspectRatio: 1, borderRadius: "50%" };
