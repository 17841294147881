import { motion } from "framer-motion";
import { useAtomValue } from "jotai";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { BORDER_RADIUS } from "../../../../core/measurements";
import { Column } from "../../../../shared-components/column";
import { Row } from "../../../../shared-components/row";
import { FaChevronDown } from "react-icons/fa";
import useScrollToTop from "../../../../hooks/scroll-to-top";
import Label from "../../../../shared-components/Label";
import ModalPopUp from "../../../../shared-components/ModalPopUp";
import { languageAtom } from "../../../../state/shared-state-atoms/language";
import IconCircleButton from "./components/icon_circle_button";
import MatchDayFixture from "./components/MatchDayFixture";
import MatchDayV2Container from "./components/MatchDayV2Container";
import PopUpItemBox from "./components/PopUpItemBox";
import useMatchDayV2Hook from "./useMatchDayV2Hook";
import moment from "moment";
import { useEffect } from "react";

export function MatchDayV2({ height }) {
  const {
    loading,
    matches,
    colors,
    season,
    seasons,
    setSeason,
    handleArrowLeft,
    handleArrowRight,
    setMatchStatsAtom,
    selectedRoundIndex,
    rounds,
    Rounds,
    setSelectedRoundIndex,
    handleNextRound,
    handlePreviousRound,
    seasonPopUpOpen,
    roundPopUpOpen,
    popUpOpenRound,
    popUpCloseRound,
    popUpOpenSeason,
    popUpCloseSeason,
    loadingStadiums,
    stadiums,
  } = useMatchDayV2Hook();

   // Accessing rounds data
   useEffect(() => {
    console.log('Rounds data:', Rounds);
  }, [Rounds]);


  useScrollToTop();
  const language = useAtomValue(languageAtom);

  if (loading || loadingStadiums) {
    return (
      <Column alignCenter justifyCenter style={{ height: "60vh" }}>
        <p>{language?.isEnglish ? "loading" : "Tafadhali subiri"} ...</p>
      </Column>
    );
  }

  if (!loading) {
    return (
      <MatchDayV2Container
        atomColors={colors}
        style={{ maxWidth: 700, backgroundColor: colors.secondary }}
      >
        <Column
          gap={0}
          style={{
            maxWidth: 1900,
            margin: "0 auto",
          }}
        >
          <Row
            alignCenter
            justifyBetween
            style={{
              height: 60,
              backgroundColor: colors.secondary,
              paddingInline: 20,
              paddingBlock: 10,
              fontSize: 16,
            }}
          >
            <IconCircleButton
              icon={<AiOutlineLeft />}
              onClick={handleArrowLeft}
            />
            <Column
              as={motion.div}
              onClick={popUpOpenSeason}
              gap={5}
              alignCenter
              justifyCenter
              style={{
                color: colors.text,
                cursor: "pointer",
                width: "fit-content",
              }}
              >
              <Row
                alignCenter
                justifyCenter
                gap={10}
                style={{
                  width: "fit-content",
                  borderRadius: 10,
                  paddingBlock: 10,
                  paddingInline: 15,
                  fontSize: 16,
                }}
                >
                {language?.isEnglish ? (
                  <p>
                    <span style={{ fontWeight: "bold" }}>{season}</span> season
                  </p>
                ) : (
                  <p>
                    Msimu wa{" "}
                    <span style={{ fontWeight: "bold" }}>{season}</span>
                  </p>
                )}
                <FaChevronDown />
              </Row>
            </Column>

            <ModalPopUp
              open={seasonPopUpOpen}
              handleClose={popUpCloseSeason}
              backgroundColor={colors.background}
              color={colors.text}
              children={
                <>
                  {(() => {
                    let years = [];
                    for (
                      let year = new Date().getFullYear();
                      year >= 2000;
                      year--
                    ) {
                      years.push(year);
                    }
                    return (
                      <Column alignCenter gap={10}>
                        <Label
                          xBold
                          color={colors.text}
                          xLarge
                          noWrap
                          text={
                            language?.isEnglish
                              ? "Select season"
                              : "Chagua Msimu"
                          }
                        />

                        {seasons.map((season, index) => (
                          <PopUpItemBox
                            key={index}
                            value={season}
                            currentValue={season}
                            label={season}
                            valueSetter={setSeason}
                            handleClose={popUpCloseSeason}
                          />
                        ))}
                      </Column>
                    );
                  })()}
                </>
              }
            />
            <IconCircleButton
              icon={<AiOutlineRight />}
              onClick={handleArrowRight}
            />
          </Row>
          <Column
            style={{
              backgroundColor: colors.accent,
              maxHeight: height ? height : "",
              height: "100%",
              overflow: height ? "scroll" : "",
            }}
          >
            {matches.length < 1 ? (
              <Column
                alignCenter
                justifyCenter
                style={{ padding: 10, color: colors.text }}
              >
                <Row
                  alignCenter
                  justifyBetween
                  gap={20}
                  style={{
                    height: 70,
                    backgroundColor: colors.accent,
                    paddingInline: 20,
                    fontSize: 16,
                    marginTop: 10,
                  }}
                >
                  <IconCircleButton
                    icon={<AiOutlineLeft />}
                    onClick={handlePreviousRound}
                  />

                  <Column
                    onClick={popUpOpenRound}
                    style={{
                      textAlign: "center",
                      color: colors.text,
                      cursor: "pointer",
                    }}
                  >
                    <Row
                      alignCenter
                      justifyCenter
                      gap={10}
                      style={{ borderRadius: 10, paddingBlock: 10 }}
                    >
                      {language?.isEnglish ? (
                        <p style={{ fontSize: "large" }}>
                          Round{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {Rounds?.at(selectedRoundIndex) ?? ""}
                          </span>
                        </p>
                      ) : (
                        <p style={{ fontSize: "large" }}>
                          Mzunguko wa{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {Rounds?.at(selectedRoundIndex) ?? ""}
                          </span>
                        </p>
                      )}
                      <FaChevronDown />
                    </Row>
                  </Column>

                  <ModalPopUp
                    open={roundPopUpOpen}
                    handleClose={popUpCloseRound}
                    backgroundColor={colors.background}
                    color={colors.text}
                    children={
                      <>
                        <Column alignCenter gap={10}>
                          <Label
                            xBold
                            color={colors.text}
                            xLarge
                            noWrap
                            text={
                              language?.isEnglish
                                ? "Select round"
                                : "Chagua mzunguko"
                            }
                          />

                          {Rounds.map((round, index) => (
                            <PopUpItemBox
                              key={index}
                              value={index}
                              currentValue={selectedRoundIndex}
                              label={round.num}
                              isRound
                              valueSetter={setSelectedRoundIndex}
                              handleClose={popUpCloseRound}
                            />
                          ))}
                        </Column>
                      </>
                    }
                  />
                  <IconCircleButton
                    icon={<AiOutlineRight />}
                    onClick={handleNextRound}
                  />
                </Row>
                <Label xBold capitalize noWrap text={"data not available"} />
              </Column>
            ) : (
              <>
                <Row
                  alignCenter
                  justifyBetween
                  gap={20}
                  style={{
                    height: 70,
                    backgroundColor: colors.accent,
                    paddingInline: 20,
                    fontSize: 16,
                    marginTop: 10,
                  }}
                >
                  <IconCircleButton
                    icon={<AiOutlineLeft />}
                    onClick={handlePreviousRound}
                  />

                  <Column
                    onClick={popUpOpenRound}
                    style={{
                      textAlign: "center",
                      color: colors.text,
                      cursor: "pointer",
                    }}
                  >
                    <Row
                      alignCenter
                      justifyCenter
                      gap={10}
                      style={{ borderRadius: 10, paddingBlock: 10 }}
                    >
                      {language.isEnglish ? (
                        <p style={{ fontSize: "large" }}>
                          Round{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {rounds.at(selectedRoundIndex) ?? ""}
                          </span>
                        </p>
                      ) : (
                        <p style={{ fontSize: "large" }}>
                          Mzunguko wa{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {rounds.at(selectedRoundIndex) ?? ""}
                          </span>
                        </p>
                      )}
                      <FaChevronDown />
                    </Row>
                  </Column>

                  <ModalPopUp
                    open={roundPopUpOpen}
                    handleClose={popUpCloseRound}
                    backgroundColor={colors.background}
                    color={colors.text}
                    children={
                      <>
                        <Column alignCenter gap={10}>
                          <Label
                            xBold
                            color={colors.text}
                            xLarge
                            noWrap
                            text={
                              language.isEnglish
                                ? "Select round"
                                : "Chagua mzunguko"
                            }
                          />

                          {Rounds.map((round, index) => (
                            <PopUpItemBox
                              key={index}
                              value={index}
                              currentValue={selectedRoundIndex}
                              label={round.num}
                              isRound
                              valueSetter={setSelectedRoundIndex}
                              handleClose={popUpCloseRound}
                            />
                          ))}
                        </Column>
                      </>
                    }
                  />
                  <IconCircleButton
                    icon={<AiOutlineRight />}
                    onClick={handleNextRound}
                  />
                </Row>

                {((matches) => {
                  // let requiredMatch = matches.find((mt) => mt.round === round);
                  return (
                    <Column gap={20} style={{ paddingInline: 20 }}>
                      {matches[0]?.matches?.map((match, matchIndex) => {
                        return (
                          <Column gap={30} key={matchIndex}>
                            <Row
                              alignCenter
                              justifyCenter
                              style={{
                                backgroundColor: colors.accent,
                                padding: 10,
                                borderRadius: BORDER_RADIUS,
                              }}
                            >
                              <h4>
                                {language.isEnglish
                                  ? match.date &&
                                    moment(match.date).format(
                                      "ddd MMM DD, YYYY"
                                    )
                                  : new Date(match.date).toLocaleDateString()}
                              </h4>
                            </Row>
                            {match?.fixtures?.map((fixture, fixtureIndex) => {
                              return (
                                <MatchDayFixture
                                  key={fixtureIndex}
                                  colors={colors}
                                  fixture={((fx) => {
                                    if (fx?.ground === "") return fx;
                                    return {
                                      ...fx,
                                      ground: fx.ground,
                                      // stadiums?.find(
                                      //   (stadium) =>
                                      //     stadium?._id === fx.ground
                                      // )?.name ?? "",
                                    };
                                  })(fixture)}
                                  setMatchStatsAtom={setMatchStatsAtom}
                                />
                              );
                            })}
                          </Column>
                        );
                      })}
                    </Column>
                  );
                })(matches)}
              </>
            )}
          </Column>
        </Column>
      </MatchDayV2Container>
    );
  }
}
