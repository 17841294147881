import { useAtom, useAtomValue } from "jotai";
import { Row } from "../../../../../shared-components/row";
import { colorAtom } from "../../../../../state/shared-state-atoms/colors";
import { leagueColors } from "../utils/league-color-map";

import styled from 'styled-components'
import { languageAtom } from "../../../../../state/shared-state-atoms/language";

export const TableRow = ({
  head,
  number,
  team,
  playedMatches,
  won,
  drawn,
  lost,
  plusMinus,
  goalDifference,
  points,
  teamCount,
}) => {
  const [colors] = useAtom(colorAtom)
  const language = useAtomValue(languageAtom)
  return (
    <CustomRow
      alignCenter
      justifyBetween
      head={ head }
      atomColors={ colors }
      style={ {
        paddingInline: 10,
        paddingBlock: 5,
        borderBottom: `1px solid ${ colors.faintText }`,
        borderLeft: head
          ? "none"
          : `3px solid ${ leftBorderColor(number, teamCount, language.isEnglish) }`,
        fontWeight: head ? 'bold' : 'normal'
      } }
      onClick={ () => alert(`${ team.name } clicked`) }
    >
      <Row alignCenter gap={ 10 } style={ { width: "40%" } }>
        <p style={ { width: 20, textAlign: 'right' } }>{ head ? "Club" : number }</p>
        <div style={ { width: "80%" } }>
          { head ? (
            ""
          ) : (
            <Row alignCenter gap={ 5 } style={ { width: "fit-content" } }>
              <img
                style={ { height: 25, aspectRatio: 1 / 1, borderRadius: "50%" } }
                src={ team.logo }
                alt=""
              />
              <p style={ { fontSize: 'small' } }>{ team.name }</p>
            </Row>
          ) }
        </div>
      </Row>
      <Row gap={ 10 } justifyBetween style={ { width: "60%" } }>
        <Label>{ head ? "Pl" : playedMatches }</Label>
        <Label>{ head ? "W" : won }</Label>
        <Label>{ head ? "D" : drawn }</Label>
        <Label>{ head ? "L" : lost }</Label>
        <LabelWide>{ head ? "+/-" : plusMinus }</LabelWide>
        <Label>{ head ? "GD" : goalDifference }</Label>
        <Label>{ head ? "Pts" : points }</Label>
      </Row>
    </CustomRow>
  );
};

const leftBorderColor = (number, teamCount, isEnglish) => {
  const colors = leagueColors(isEnglish)
  switch (number) {
    case 1:
      return colors.championsAndCAFChampionsLeague.color;

    case 2:
      return colors.CAFChampionsLeague.color;

    case 3:
      return colors.CAFConfederationCup.color;

    case teamCount - 3:
      return colors.relegationPlayOff.color;

    case teamCount - 2:
      return colors.relegationPlayOff.color;

    case teamCount - 1:
      return colors.relegation.color;

    case teamCount:
      return colors.relegation.color;

    default:
      return "transparent";
  }
};

const Label = styled.p`
  width: 30px;
  max-width: 30px;
  text-align : center;
`

const LabelWide = styled.p`
  width: 60px;
  max-width: 60px;
  text-align : center;
`

const CustomRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${ props => {
    if (props.head) return props.atomColors.background
    return props.atomColors.secondary
  } };

  &:hover {
    background-color: ${ props => props.atomColors.background };
    cursor: pointer;
  }

  @media (max-width: 490px) {
    font-size: small;
  }
`