import { useAtom, useAtomValue } from "jotai";
import { useNavigate } from "react-router-dom";
import { BORDER_RADIUS } from "../../core/measurements";
import { Column } from "../../shared-components/column";
import { InputField } from "../../shared-components/input-field";
import { Row } from "../../shared-components/row";
import { colorAtom } from "../../state/shared-state-atoms/colors";

import { AiOutlineLock } from "react-icons/ai";
import { BsPerson } from "react-icons/bs";
import { CgArrowLongLeft } from "react-icons/cg";
import { MdOutlineAlternateEmail } from "react-icons/md";

import { useState } from "react";

import styled from "styled-components";
import { postRequestWithToken } from "../../core/network-request-functions";
import { URLS } from "../../core/urls";
import { Footer } from "../../shared-components/footer";
import { FramerButtonFilled } from "../../shared-components/framer-button-filled";
import { languageAtom } from "../../state/shared-state-atoms/language";
import { tokenAtom } from "../../state/shared-state-atoms/token-atom";
import { userProfileAtom } from "../../state/shared-state-atoms/user-profile-atom";
import { toast } from "react-hot-toast";

export function ProfileChangeDetails() {
  const {
    colors,
    firstName,
    setfirstName,
    lastName,
    setlastName,
    email,
    setemail,
    phone,
    setphone,
    newPassword,
    oldPassword,
    setnewPassword,
    setOldPassword,
    confirmNewPassword,
    setconfirmNewPassword,
    changePassword,
    handleSetChangePassword,
    handleChangeInformation,
    socialLogin,
    handleChangePassword,
    loading,
  } = useProfileChangeDetailsHook();
  const language = useAtomValue(languageAtom);

  if (loading) {
    return (
      <Column
        alignCenter
        justifyCenter
        gap={10}
        style={{
          backgroundColor: colors.background,
          color: colors.text,
          minHeight: "100vh",
          position: "relative",
          paddingBlock: 100,
        }}
      >
        <p>{language.isEnglish ? "loading ..." : "Tafadhali subiri"}</p>
      </Column>
    );
  } else {
    return (
      <Column
        alignCenter
        justifyCenter
        gap={10}
        style={{
          backgroundColor: colors.background,
          color: colors.text,
          minHeight: "100vh",
          position: "relative",
          paddingTop: 100,
        }}
      >
        <Column
          gap={20}
          style={{ maxWidth: 600, width: "100%", paddingInline: 20 }}
        >
          <Label titleLabel atomColors={colors}>
            {language.isEnglish
              ? "Edit your account information"
              : "Badili taarifa za akaunti yako"}
          </Label>
          <Entry
            label={language.isEnglish ? "First name" : "Jina la kwanza"}
            Icon={() => <BsPerson />}
            value={firstName}
            setter={setfirstName}
          />
          <Entry
            label={language.isEnglish ? "Last name" : "Jina la mwisho"}
            Icon={() => <BsPerson />}
            value={lastName}
            setter={setlastName}
          />
          <Entry
            label={language.isEnglish ? "Email" : "Barua Pepe"}
            Icon={() => <MdOutlineAlternateEmail />}
            value={email}
            setter={setemail}
            disabled={socialLogin}
          />
          <Entry
            label={language.isEnglish ? "Phone" : "Namba ya simu"}
            Icon={() => <p>+255</p>}
            value={phone}
            setter={setphone}
          />
          <FramerButtonFilled
            label={language.isEnglish ? "Change Information" : "Badili taarifa"}
            onClick={handleChangeInformation}
          />

          <ChangePasswprdTextButton onClick={handleSetChangePassword}>
            {changePassword
              ? language.isEnglish
                ? "Cancel password change"
                : "Sitisha kubadili nenosiri"
              : language.isEnglish
              ? "Change password"
              : "Badili neno siri"}
          </ChangePasswprdTextButton>

          {changePassword ? (
            <>
              <Entry
                label={language.isEnglish ? "old password" : "Nenosiri zaman"}
                Icon={() => <AiOutlineLock />}
                value={oldPassword}
                setter={setOldPassword}
                password
              />
              <Entry
                label={language.isEnglish ? "New password" : "Nenosiri jipya"}
                Icon={() => <AiOutlineLock />}
                value={newPassword}
                setter={setnewPassword}
                password
              />
              <Entry
                label={
                  language.isEnglish
                    ? "Confirm new password"
                    : "Thibitisha nenosiri jipya"
                }
                Icon={() => <AiOutlineLock />}
                value={confirmNewPassword}
                setter={setconfirmNewPassword}
                password
              />
              <FramerButtonFilled
                label={
                  language.isEnglish ? "Change password" : "Badili nenosiri"
                }
                onClick={handleChangePassword}
              />
            </>
          ) : (
            ""
          )}
        </Column>
        <GoBack />
        <br />
        <Footer />
      </Column>
    );
  }
}

const Entry = ({ label, Icon, value, setter, disabled, password }) => {
  const colors = useAtomValue(colorAtom);
  return (
    <Column gap={15}>
      <Label atomColors={colors}>{label}</Label>
      <InputField
        type={password ? "password" : "text"}
        Icon={() => <Icon style={{ fontSize: 20 }} />}
        placeHolder={label}
        value={value}
        setter={setter}
        disabled={disabled}
      />
    </Column>
  );
};

const GoBack = () => {
  const { colors, handleBackButton } = useProfileChangeDetailsHook();
  const language = useAtomValue(languageAtom);
  return (
    <Row
      alignCenter
      gap={10}
      onClick={handleBackButton}
      style={{
        width: "fit-content",
        position: "absolute",
        top: 20,
        left: 20,
        cursor: "pointer",
        backgroundColor: colors.secondary,
        padding: 10,
        borderRadius: BORDER_RADIUS,
      }}
    >
      <CgArrowLongLeft />
      <p>{language.isEnglish ? "Go back" : "Rudi nyuma"}</p>
    </Row>
  );
};

const useProfileChangeDetailsHook = () => {
  let navigate = useNavigate();
  const colors = useAtomValue(colorAtom);
  const user = useAtomValue(userProfileAtom);
  const token = useAtomValue(tokenAtom);
  const language = useAtomValue(languageAtom);
  const [userProfileAtomData, setuserProfileAtomData] =
    useAtom(userProfileAtom);

  const socialLogin = userProfileAtomData.loginMethod === "social";

  const [loading, setloading] = useState(false);
  const [changePassword, setchangePassword] = useState(false);

  const [firstName, setfirstName] = useState(user.firstName);
  const [lastName, setlastName] = useState(user.lastName);
  const [email, setemail] = useState(user.email ?? "");
  const [phone, setphone] = useState(user.phone?.toString().slice(3) ?? "");
  const [newPassword, setnewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [confirmNewPassword, setconfirmNewPassword] = useState("");

  const handleSetChangePassword = () => {
    setchangePassword((prev) => {
      if (prev === false) return true;
      setnewPassword("");
      setconfirmNewPassword("");
      return false;
    });
  };

  const handleBackButton = () => {
    navigate(-1);
  }

  const handleChangeInformation = () => {
    if (firstName.length < 3) {
      toast.error(
        language.isEnglish
          ? "First name should have atleast 3 letters"
          : "Jina la kwanza linatakiwa liwe na angalau herufi 3"
      );
      return;
    }

    if (lastName.length < 3) {
      toast.error(
        language.isEnglish
          ? "Last name should have atleast 3 letters"
          : "Jina la mwisho linatakiwa liwe na angalau herufi 3"
      );
      return;
    }

    if (
      !String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      toast.error(
        language.isEnglish
          ? "Please enter a valid email address"
          : "Tafadhali weka baruapepe sahihi"
      );
      return;
    }

    const numberRegExp = new RegExp(/^[0-9]+$/);
    if (!numberRegExp.test(phone)) {
      toast.error(
        language.isEnglish
          ? "Please enter a valid phone number"
          : "Tafadhali weka namba ya simu sahihi"
      );
      return;
    }

    setloading(true);
    const _body = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: `255${phone}`,
    };

    postRequestWithToken(token, URLS.updateProfile, _body)
      .then((response) => {
      
        setloading(false);
        toast.success(
          language.isEnglish
            ? "Your request to change Profile information is approved. provide the code sent to you"
            : "ombi lako la kubadilisha Taarifa zako limekubaliwa, tafadhari weka code uliotumiwa"
        );
        navigate("/profile/update/verify-token");
      })
      .catch((error) => {
        console.log({ submitError: error });
        setloading(false);
        toast.error(
          language.isEnglish
            ? "Something went wrong try again later"
            : "Kuna tatizo limetokea jaribu tena baadae"
        )
      });
  };

  const handleChangePassword = () => {
    if (newPassword.length < 6) {
      toast.error(
        language.isEnglish
          ? "Password should have atleast 6 characters"
          : "Nenosiri linatakiwa liwe na walau tarakimu 6"
      );
      return;
    }
    if (confirmNewPassword.length < 6) {
      toast.error(
        language.isEnglish
          ? "Confirm Password should have atleast 6 characters"
          : "Thibitisha Nenosiri linatakiwa liwe na walau tarakimu 6"
      );
      return;
    }
    if (newPassword !== confirmNewPassword) {
      toast.error(
        language.isEnglish
          ? "Password and Confirm Password should be the same"
          : "Nenosiri na Thibitisha Nenosirivinatakiwa viwe sawa"
      );
      return;
    }

    setloading(true);
    const _body = {
      oldPassword: oldPassword,
      newPassword: newPassword,
      confirmNewPassword: confirmNewPassword,
    };
    postRequestWithToken(token, URLS.changePassword, _body)
      .then((response) => {
        console.log({ submitResponse: response });
        setuserProfileAtomData((prev) => {
          return {
            ...prev,
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            fullName: `${response.data.firstName} ${response.data.lastName}`,
            email: response.data.email,
            phone: response.data.phone,
            userId: response.data._id,
          };
        });
        setloading(false);
        toast.success(
          language.isEnglish
            ? "password updated successfully"
            : "neno siri imebadilishwa"
        );
        navigate("/profile");
      })
      .catch((error) => {
        setloading(false);

        console.log({ submitError: error });
        toast.error(
          language.isEnglish
            ? error.response.data
            : `tatizo limetokea ${error.response.data.message}`
        );
      });
  };

  return {
    colors,
    handleBackButton,
    firstName,
    setfirstName,
    lastName,
    setlastName,
    email,
    setemail,
    phone,
    setphone,
    newPassword,
    oldPassword,
    setnewPassword,
    setOldPassword,
    confirmNewPassword,
    setconfirmNewPassword,
    changePassword,
    handleSetChangePassword,
    handleChangeInformation,
    socialLogin,
    handleChangePassword,
    loading,
  };
};

const Label = styled.div`
  font-size: ${(props) => (props.titleLabel ? "xx-large" : "medium")};
  font-weight: bold;
  color: ${(props) => props.atomColors.text};
`;

const ChangePasswprdTextButton = styled.p`
  font-weight: bold;
  text-decoration: underline;
  margin-top: 10px;
  text-underline-offset: 3px;
  cursor: pointer;
`;
