import { useAtom, useAtomValue } from "jotai";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { BORDER_RADIUS } from "../../../../core/measurements";
import { useWindowWidth } from "../../../../hooks/use-window-width";
import { Column } from "../../../../shared-components/column";
import { Row } from "../../../../shared-components/row";
import { colorAtom } from "../../../../state/shared-state-atoms/colors";
import { LeagueColorRow } from "./components/league-color-row";
import { LeagueTableContainer } from "./components/league-table-container";
import { TableRow } from "./components/table-row";
import { useLeagueTable } from "./use-league-able";
import { leagueColorListGenerator } from "./utils/league-color-list-generator";
import { leagueColors } from "./utils/league-color-map";
import noImage from "../../../../assets/teams/noImage.jpg";

import { motion } from "framer-motion";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { FaChevronDown } from "react-icons/fa";
import useScrollToTop from "../../../../hooks/scroll-to-top";
import { languageAtom } from "../../../../state/shared-state-atoms/language";
import IconCircleButton from "../match-day-v2/components/icon_circle_button";

export function LeagueTable2({ height, team1, team2 }) {
  useScrollToTop();
  const {
    selectedTabIndex,
    tabs,
    handleChange,
    leagueTableText,
    standingsData,
    seasonQuerry,
    handleArrowRight,
    handleArrowLeft,
    loading,
  } = useLeagueTable();
  const { windowWidth } = useWindowWidth();
  let location = useLocation();
  const pathName = location.pathname.slice(1);
  const [colors] = useAtom(colorAtom);
  const language = useAtomValue(languageAtom);

  if (loading) {
    return (
      <LeagueTableContainer
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "0 auto",
        }}
      >
        <p>{language.isEnglish ? "loading" : "tafadhali subiri"} ...</p>
      </LeagueTableContainer>
    );
  }

  if (!loading) {
    return (
      <LeagueTableContainer
        style={{
          backgroundColor: colors.secondary,
          color: colors.text,
          position: "relative",
          maxWidth: pathName === "" && windowWidth > 1600 ? 550 : 700,
          margin: "0 auto",
          height: height,
          borderRadius: BORDER_RADIUS,
          overflow: "clip",
        }}
      >
        <Row
          alignCenter
          justifyBetween
          style={{
            height: 60,
            backgroundColor: colors.secondary,
            paddingInline: 20,
            paddingBlock: 10,
          }}
        >
          <IconCircleButton
            icon={<AiOutlineLeft />}
            onClick={handleArrowLeft}
          />

          <Column
            as={motion.div}
            gap={5}
            alignCenter
            justifyCenter
            style={{
              color: colors.text,
              height: "fit-content",
              cursor: "pointer",
            }}
          >
            <Row
              alignCenter
              justifyCenter
              gap={10}
              style={{
                width: "fit-content",
                borderRadius: 10,
                paddingBlock: 10,
                paddingInline: 15,
                fontSize: 16,
              }}
            >
              {language.isEnglish ? (
                <p style={{textTransform:"capitalize"}}>
                  <span style={{ fontWeight: "bold" }}>{seasonQuerry}</span>{" "}
                  season
                </p>
              ) : (
                <p>
                  Msimu wa{" "}
                  <span style={{ fontWeight: "bold" }}>{seasonQuerry}</span>
                </p>
              )}
              {/* <FaChevronDown /> */}
            </Row>
          </Column>

          <IconCircleButton
            icon={<AiOutlineRight />}
            onClick={handleArrowRight}
          />
        </Row>

        <LeagueStandingsContainer
          atomColors={colors}
          style={{
            backgroundColor: colors.accent,
            padding: "0 10px",
            marginBottom: 50,
            height: height,
            overflowY: height === null ? "clip" : "scroll",
          }}
        >
          <TableRow head />
          {standingsData.length === 0 ? (
            <Column alignCenter justifyCenter style={{ padding: 50 }}>
              <p>
                {language.isEnglish
                  ? "No data to display"
                  : "Samahani, hakuna taarifa za kuonesha kwa sasa"}
              </p>
            </Column>
          ) : (
            <>
              {standingsData.map((standing, index) => {
                return (
                  <TableRow
                    key={index}
                    number={index + 1}
                    team={{
                      name: standing.name,
                      logo: standing.logo ?? noImage,
                      id: standing.id,
                    }}
                    playedMatches={standing.plays}
                    won={standing.wins}
                    drawn={standing.draws}
                    lost={standing.lost}
                    plusMinus={`${standing.scored_goals} - ${standing.concided_goals}`}
                    scored={standing.scored_goals}
                    conceded={standing.concided_goals}
                    goalDifference={
                      standing.scored_goals - standing.concided_goals
                    }
                    points={standing.points}
                    teamCount={standingsData.length}
                    highlight={
                      standing.name === team1 || standing.name === team2
                    }
                  />
                );
              })}

              <Column
                gap={20}
                justifyCenter
                style={{
                  margin: "50px 0",
                  paddingLeft: "10%",
                  backgroundColor: colors.background,
                  paddingBlock: 40,
                }}
              >
                {leagueColorListGenerator(leagueColors(language.isEnglish)).map(
                  (item, index) => {
                    return (
                      <LeagueColorRow
                        key={index}
                        color={item.color}
                        label={item.label}
                      />
                    );
                  }
                )}
              </Column>
            </>
          )}
        </LeagueStandingsContainer>
      </LeagueTableContainer>
    );
  }
}

const LeagueStandingsContainer = styled.div`
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${($props) => $props.atomColors.secondary};
    margin-block: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${($props) => $props.atomColors.faintText};
    border-radius: ${BORDER_RADIUS};
  }
`;
