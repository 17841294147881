import { configureStore, combineReducers } from "@reduxjs/toolkit";
import themeReducer from "../state/redux-slices/themeSlice";
import tabReducer from "../state/redux-slices/tabSlice";
import modalReducer from "../state/redux-slices/ModalSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

const rootReducer = combineReducers({
  theme: themeReducer,
  nav: tabReducer,
  modal: modalReducer,
});

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["theme"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default configureStore({
  reducer: persistedReducer,
});
