import {useNavigate} from 'react-router-dom'

export function TeamInfo({ teamName, formation, isHome })
{
    let navigate = useNavigate()

    return (
        <div
            style={ {
                position: 'absolute',
                top: isHome ? 0 : '',
                bottom: !isHome ? 0 : '',
                left: 0,
                right: 0,
                height: 50,
                backgroundColor: '#0B8449',
                color: '#fff',
                zIndex: 50,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingInline: 20,
                cursor: 'pointer'
            } }
            onClick={ () => navigate(`/teams/${ teamName }`) }
        >
            <p>{ teamName }</p>
            <p>{ formation }</p>
        </div>
    )
}