export class AwardsStat
{
    constructor({ time, awardName, name, image, team })
    {
        this.time = time
        this.awardName = awardName
        this.name = name
        this.image = image
        this.team = team
    }
}