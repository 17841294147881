import { useAtomValue, useSetAtom } from "jotai";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { _routeNames } from "../../../../../../app/routes";
import { useWindowWidth } from "../../../../../../hooks/use-window-width";
import DataNotFound from "../../../../../../shared-components/data_not_found";
import Label from "../../../../../../shared-components/Label";
import { Row } from "../../../../../../shared-components/row";
import playerPlaceHolder from "../../../../../../assets/placeholder-images/player-placeholder-full.svg";
import noImage from "../../../../../../assets/teams/noImage.jpg";
import { colorAtom } from "../../../../../../state/shared-state-atoms/colors";
import { languageAtom } from "../../../../../../state/shared-state-atoms/language";
import { tabIndexAtom } from "../../../../../../state/shared-state-atoms/tab-index";
import awardsAtom from "../../../../../all-stats-list-awards/awards-atom";
import statTabIndex from "../../../stats/stats-index-atom";
import AwardCard from "./components/AwardCard";
import Container from "./components/Container";
import GridLayout from "./components/gridLayout";
import IconBox from "./components/IconBox";
import moment from "moment";

export default function PlayerOfTheMonth({ monthtostring, playersAwards }) {
  const { windowWidth } = useWindowWidth();
  const colors = useAtomValue(colorAtom);
  const language = useAtomValue(languageAtom);
  const words = playerOfTheMonthWords(language.isEnglish);
  let navigate = useNavigate();
  const setStatTabIndex = useSetAtom(statTabIndex);
  const setHomeTabIndex = useSetAtom(tabIndexAtom);
  const setAwardCategoryIndex = useSetAtom(awardsAtom);
  const go = () => {
    navigate(_routeNames.root.stats.index);
    setStatTabIndex(2);
    setAwardCategoryIndex(0);
    setHomeTabIndex(3);
  };
  return (
    <Container atomColors={colors}>
      <Row
        alignCenter
        justifyBetween={windowWidth > 600}
        style={{ flexDirection: windowWidth < 600 ? "column" : "" }}
      >
        <Label xBold xLarge capitalize text={words.mainLabel} />
        <Row
          alignCenter
          gap={10}
          style={{ justifyContent: windowWidth < 600 ? "center" : "flex-end" }}
        >
          <Row
            pointer
            onClick={go}
            alignCenter
            style={{ justifyContent: "flex-end" }}
            width={80}
          >
            <Label capitalize text={words.showAll} />
            <IconBox
              style={{ backgroundColor: "transparent", color: "#E21D24" }}
            >
              <HiOutlineArrowNarrowRight />
            </IconBox>
          </Row>
        </Row>
      </Row>
      {windowWidth > 1000 ? (
        <div
          style={{
            color: "#fff",
            borderWidth: 1,
            display: "flex",
            flexDirection: "row",
            padding: 10,
          }}
        >
          {playersAwards?.length === 0 ? (
            <DataNotFound message={words.noData} />
          ) : (
            <>
              {playersAwards
                ?.sort((a, b) => b.time.month - a.time.month)
                .slice(0, 3)
                .map((award, index) => {
                  return (
                    <AwardCard
                      key={index}
                      month={moment(award.date).format("MMMM")}
                      season={award.season}
                      name={award.player?.name ?? "N/A"}
                      image={award.player?.pic ?? playerPlaceHolder}
                      // image={ playerPlaceHolder }
                      teamLogo={award.player?.current_club?.logo ?? noImage}
                      teamName={award.player?.current_club?.name ?? "N/A"}
                      idx={index}
                    />
                  );
                })}
            </>
          )}
        </div>
      ) : (
        <GridLayout style={{ color: "#fff" }}>
          {playersAwards?.length === 0 ? (
            <DataNotFound message={words.noData} />
          ) : (
            <>
              {playersAwards
                ?.sort((a, b) => b.time.month - a.time.month)
                .slice(0, 3)
                .map((award, index) => {
                  return (
                    <AwardCard
                      key={index}
                      month={moment(award.date).format("MMMM")}
                      season={award.season}
                      name={award.player?.name ?? "n/a"}
                      image={award.player?.pic ?? playerPlaceHolder}
                      // image={ playerPlaceHolder }
                      teamLogo={award.player?.current_club?.logo ?? noImage}
                      teamName={award.player?.current_club?.name ?? "n/a"}
                      idx={index}
                      display={"Grid"}
                    />
                  );
                })}
            </>
          )}
        </GridLayout>
      )}
    </Container>
  );
}

function playerOfTheMonthWords(isEnglish) {
  return {
    mainLabel: isEnglish ? "Player of the month" : "Mchezaji wa mwezi",
    showAll: isEnglish ? "Show all" : "Angalia wote",
    noData: isEnglish ? "Data not available" : "Taarifa hizi hazipo kwa sasa",
  };
}
