import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { NavigationBar } from "../../shared-components/navigation-bar/navigation-bar";
import { MobileMenu } from "../../shared-components/navigation-bar/mobile-menu";
import styled from "styled-components";
import { BORDER_RADIUS } from "../../core/measurements";
import { Column } from "../../shared-components/column";
import { useTeams } from "./useTeams";

import { handleGoBack } from "../../functions/handleGoBack";
import { GoBack } from "../../shared-components/go-back";
import noImage from "../../assets/teams/noImage.jpg";

import { AiFillTrophy } from "react-icons/ai";
import { BiSearchAlt } from "react-icons/bi";
import { HiOutlineChevronRight, HiOutlineChevronLeft } from "react-icons/hi";

import { motion } from "framer-motion";
import { Row } from "../../shared-components/row";
import { useAtomValue } from "jotai";
import { languageAtom } from "../../state/shared-state-atoms/language";
import { Footer } from "../../shared-components/footer";

export function Teams() {
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  let navigate = useNavigate();
  const {
    colors,
    _allTeamInfo,
    _seasonTeamsInfo,
    loading,
    searchSeasonQuerry,
    seasonQuerryValue,
    onInputChange,
    slicedCurrentYear,
    handleSearch,
    previousSeason,
    nextSeason,
  } = useTeams();

  const language = useAtomValue(languageAtom);

  if (loading) {
    return (
      <>
        <NavigationBar setOpenMobileMenu={setOpenMobileMenu} />
        <AnimatePresence>
          {openMobileMenu ? (
            <MobileMenu setOpenMobileMenu={setOpenMobileMenu} />
          ) : (
            ""
          )}
        </AnimatePresence>
        <TeamsContainer
          atomColors={colors}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p>{language.isEnglish ? "loading ..." : "Tafadhali subiri"}</p>
        </TeamsContainer>
      </>
    );
  }

  if (!loading) {
    return (
      <>
        <NavigationBar setOpenMobileMenu={setOpenMobileMenu} />
        <AnimatePresence>
          {openMobileMenu ? (
            <MobileMenu setOpenMobileMenu={setOpenMobileMenu} />
          ) : (
            ""
          )}
        </AnimatePresence>
        <TeamsContainer atomColors={colors}>
          <Column gap={100} style={{ maxWidth: 1200, margin: "0 auto" }}>
            <Row alignCenter justifyBetween>
              <GoBack handleGoBack={() => handleGoBack(navigate)} />
              <Row
                alignCenter
                gap={5}
                style={{ width: "fit-content", display: "none" }}
              >
                <p>Season</p>
                <p>20</p>
                <input
                  value={seasonQuerryValue}
                  onChange={onInputChange}
                  type={"number"}
                  min={20}
                  max={slicedCurrentYear}
                  placeholder={"21"}
                  style={{
                    width: 45,
                    border: "none",
                    borderBottom: "1px solid #000",
                    borderRadius: BORDER_RADIUS,
                    paddingInline: 5,
                    paddingBlock: 3,
                    outline: "none",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  }}
                />
                <p>/{2000 + parseInt(seasonQuerryValue) + 1}</p>
                <SearchIconContainer
                  whileTap={{ scale: 0.6 }}
                  atomColors={colors}
                  onClick={handleSearch}
                >
                  <BiSearchAlt />
                </SearchIconContainer>
              </Row>
            </Row>

            <Column gap={10}>
              <Row alignCenter justifyBetween style={{}}>
                <SeasonWitchIcon
                  colors={colors}
                  icon={<HiOutlineChevronLeft />}
                  onClick={previousSeason}
                />
                <TeamsHeaderLabel atomColors={colors}>
                  {language.isEnglish ? (
                    <p>
                      Teams for the{" "}
                      <span style={{ textDecoration: "underline" }}>
                        {searchSeasonQuerry}
                      </span>{" "}
                      season
                    </p>
                  ) : (
                    <p>
                      Klabu za Msimu wa{" "}
                      <span style={{ textDecoration: "underline" }}>
                        {searchSeasonQuerry}
                      </span>
                    </p>
                  )}
                </TeamsHeaderLabel>
                <SeasonWitchIcon
                  colors={colors}
                  icon={<HiOutlineChevronRight />}
                  onClick={nextSeason}
                />
              </Row>
              {_seasonTeamsInfo.length === 0 ? (
                <>
                  <TeamsHeaderLabel atomColors={colors} noData>
                    {language.isEnglish
                      ? "Data not available"
                      : "Samahani, taarfia hizi hazipo"}
                  </TeamsHeaderLabel>
                </>
              ) : (
                <>
                  {_seasonTeamsInfo?.map((team, index) => {
                    return (
                      <TeamInfoContainer
                        key={index}
                        atomColors={colors}
                        teamColor={team.team_color}
                        onClick={() => navigate(`/teams/${team._id}`)}
                      >
                        <TeamLogo
                          src={team.logo ?? noImage}
                          alt="team logo"
                          onError={(error) => {
                            error.target.src =
                              "https://i.pinimg.com/564x/00/cf/8a/00cf8a3622f8d1707a5ac98ff3ea822e.jpg";
                          }}
                          backgroundFill={team.team_color}
                        />
                        <Column justifyCenter gap={5}>
                          <TeamName>{team?.name ?? ""}</TeamName>
                        </Column>
                        <TrophySection>
                          <p>{team?.no_trophy ?? 0}</p>
                          <AiFillTrophy />
                        </TrophySection>
                      </TeamInfoContainer>
                    );
                  })}
                </>
              )}
            </Column>

            <Column gap={10} style={{ alignItems: "flex-start" }}>
              <TeamsHeaderLabel
                atomColors={colors}
                style={{ alignSelf: "flex-start", textAlign: "left" }}
              >
                {language.isEnglish ? "All time teams" : "Timu zote"}
              </TeamsHeaderLabel>
              {_allTeamInfo?.map((team, index) => {
                return (
                  <TeamInfoContainer
                    key={index}
                    atomColors={colors}
                    teamColor={team.team_color}
                    onClick={() => navigate(`/teams/${team._id}`)}
                  >
                    <TeamLogo
                      src={team.logo}
                      alt="team logo"
                      onError={(error) => {
                        error.target.src =
                          "https://i.pinimg.com/564x/00/cf/8a/00cf8a3622f8d1707a5ac98ff3ea822e.jpg";
                      }}
                      backgroundFill={team.team_color}
                    />
                    <Column justifyCenter gap={5}>
                      <TeamName>{team?.name ?? ""}</TeamName>
                    </Column>
                    <TrophySection>
                      <p>{team?.no_trophy ?? 0}</p>
                      <AiFillTrophy />
                    </TrophySection>
                  </TeamInfoContainer>
                );
              })}
            </Column>
          </Column>
          <br />
          <Footer />
        </TeamsContainer>
      </>
    );
  }
}

const SeasonWitchIcon = ({ icon, onClick, colors }) => {
  return (
    <div
      onClick={onClick}
      style={{
        height: 40,
        aspectRatio: 1,
        borderRadius: "50%",
        backgroundColor: colors.accent,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
      }}
    >
      {icon}
    </div>
  );
};

const SearchIconContainer = styled(motion.div)`
  height: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: ${(props) => props.atomColors.secondary};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer;
  font-size: large;

  transition: 0.2s;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: ${(props) => props.atomColors.background};
  }
`;

const TeamsContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  color: ${(props) => props.atomColors.text};
  background-color: ${(props) => props.atomColors.background};
  position: relative;
  padding-top: 20px;
`;

const TeamLogo = styled.img`
  height: 50px;
  aspect-ratio: 1;
  border-radius: 20%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: ${(props) => props.backgroundFill};
`;

const TeamsHeaderLabel = styled.h3`
  font-size: ${(props) => (props.noData ? "large" : "xx-large")};
  color: ${(props) => props.atomColors.text};
  margin-bottom: ${(props) => (props.noData ? "" : "10px")};

  @media (max-width: 1000px) {
    width: 90%;
  }
`;

const TeamInfoContainer = styled.div`
  min-height: 85px;
  width: 100%;
  border-radius: ${BORDER_RADIUS};
  margin: 0 auto;
  padding-inline: 20px;
  padding-block: 10px;
  cursor: pointer;

  background-color: ${(props) => props.atomColors.secondary};
  border-left: ${(props) => `5px solid ${props.teamColor}`};

  display: flex;
  align-items: center;
  gap: 10px;

  transition: 0.3s;

  &:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  @media (max-width: 1000px) {
    width: 90%;
  }
`;

const TeamName = styled.h3`
  transition: 0.3s;

  ${TeamInfoContainer}:hover & {
    background-color: #222;
    color: #fff;
    padding: 5px;
    border-radius: ${BORDER_RADIUS};
  }
`;
const TeamNickname = styled.h5`
  transition: 0.3s;
  font-weight: normal;

  ${TeamInfoContainer}:hover & {
    background-color: #222;
    color: #fff;
    padding: 5px;
    border-radius: ${BORDER_RADIUS};
  }
`;

const TrophySection = styled.div`
  transition: 0.3s;
  display: flex;
  align-items: center;
  gap: 5px;

  ${TeamInfoContainer}:hover & {
    background-color: #222;
    color: #fff;
    padding: 5px;
    border-radius: ${BORDER_RADIUS};
  }
`;
