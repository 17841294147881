import styled from "styled-components";
import { Colors } from "../core/colors";
import { BORDER_RADIUS, INPUT_PADDING } from "../core/measurements";

export const TextArea = styled.textarea`
  width: 100%;
  border: none;
  outline: none;
  padding: ${INPUT_PADDING};
  border-radius: ${BORDER_RADIUS};
  background-color: ${Colors.secondary};
  color: ${Colors.text};
  letter-spacing: 2px;
`;
