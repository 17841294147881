import { useAtomValue } from "jotai";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { BORDER_RADIUS } from "../../core/measurements";
import { handleGoBack } from "../../functions/handleGoBack";
import { useWindowWidth } from "../../hooks/use-window-width";
import { Column } from "../../shared-components/column";
import noImage from '../../assets/teams/noImage.jpg'
import { GoBack } from "../../shared-components/go-back";
import Label from "../../shared-components/Label";
import { Row } from "../../shared-components/row";
import { colorAtom } from "../../state/shared-state-atoms/colors";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Footer } from "../../shared-components/footer";
import { languageAtom } from "../../state/shared-state-atoms/language";
import usePlayerProfile from "./use-player-profile";

export default function PlayerProfile() {
  let navigate = useNavigate();
  const colors = useAtomValue(colorAtom);
  const language = useAtomValue(languageAtom);
  const { windowWidth } = useWindowWidth();
  const playerProfileHook = usePlayerProfile();

  if (playerProfileHook.loading) {
    return (
      <PlayerProfileContainer atomColors={colors}>
        <Column
          alignCenter
          justifyCenter
          gap={20}
          style={{ maxWidth: 1200, width: "90%", margin: "0 auto" }}
        >
          <p>loading ...</p>
        </Column>
      </PlayerProfileContainer>
    );
  }

  return (
    <>
      <PlayerProfileContainer atomColors={colors}>
        <Column
          gap={40}
          style={{ maxWidth: 1200, width: "90%", margin: "0 auto" }}
        >
          <Row alignCenter justifyBetween>
            <GoBack handleGoBack={() => handleGoBack(navigate)} />
          </Row>

          <TopLabel name={playerProfileHook.playerInfo.name} />

          <Row gap={20} alignCenter>
            <img
              src={playerProfileHook.playerInfo.pic}
              alt="player image"
              onError={(error) => {
                error.target.src =
                  "https://www.pngitem.com/pimgs/m/22-224249_blank-person-hd-png-download.png";
              }}
              style={{
                width: windowWidth < 500 ? 120 : 180,
                aspectRatio: 1,
                borderRadius: "50%",
                backgroundColor: colors.secondary,
                objectFit: "cover",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              }}
            />

            <Column gap={15}>
              <Row alignCenter gap={10} style={{ flexWrap: "wrap" }}>
                <img
                  src={playerProfileHook.playerInfo.clubLogo ?? noImage}
                  alt="team logo"
                  onError={(error) => {
                    error.target.src =
                      "https://i.pinimg.com/564x/00/cf/8a/00cf8a3622f8d1707a5ac98ff3ea822e.jpg";
                  }}
                  style={{
                    height: 50,
                    aspectRatio: 1,
                    borderRadius: "50%",
                  }}
                />
                <p style={{ fontWeight: 800 }}>
                  {playerProfileHook.playerInfo.clubName}
                </p>
              </Row>
            </Column>
          </Row>

          <Column gap={15}>
            <Label
              capitalize
              xLarge
              xBold
              color={colors.text}
              text={
                language.isEnglish ? "personal information" : "Taarifa binafsi"
              }
            />

            <Column
              gap={10}
              style={{
                backgroundColor: colors.secondary,
                border: `1px solid ${colors.faintText}`,
                padding: 25,
                borderRadius: BORDER_RADIUS,
              }}
            >
              <PersonalDetailsRow
                label={language.isEnglish ? "Country" : "Nchi"}
                value={playerProfileHook.playerInfo.country}
              />
              <PersonalDetailsRow
                label={
                  language.isEnglish ? "Date of Birth" : "Tarehe ya kuzaliwa"
                }
                value={
                  language.isEnglish
                    ? new Date(playerProfileHook.playerInfo.dob).toDateString()
                    : ((date) => swahiliDateFormatter(date))(
                        new Date(playerProfileHook.playerInfo.dob)
                      )
                }
              />
              <PersonalDetailsRow
                label={language.isEnglish ? "Age" : "Umri"}
                value={
                  new Date().getFullYear() -
                  new Date(playerProfileHook.playerInfo.dob).getFullYear()
                }
              />
            </Column>
          </Column>

          <Column gap={15}>
            <Label
              capitalize
              xLarge
              xBold
              color={colors.text}
              text={language.isEnglish ? "history" : "historia"}
            />

            <TableContainer component={Paper} elevation={0}>
              <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                style={{ color: colors.text }}
              >
                <TableHead style={{ backgroundColor: colors.faintText }}>
                  <TableRow>
                    {playerProfileHook.tableHeaders?.map((header, index) => (
                      <CustomTableCell key={index} label={header} capitalize />
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody style={{ backgroundColor: colors.secondary }}>
                  {((stats, headers) => {
                    let missingStat = "n/a";
                    return stats.map((stat, index) => {
                      return (
                        <TableRow key={index}>
                          {headers.map((head, indexx) => {
                            if (head === "team") {
                              return (
                                <CustomTableCell
                                  key={indexx}
                                  label={stat[head].name ?? missingStat}
                                  capitalize
                                />
                              );
                            }
                            return (
                              <CustomTableCell
                                key={indexx}
                                label={
                                  stat[head.replace(" ", "_")] ?? missingStat
                                }
                                capitalize
                                bold={head === "season"}
                              />
                            );
                          })}
                        </TableRow>
                      );
                    });
                  })(
                    playerProfileHook.playerHistory ?? [],
                    playerProfileHook.tableHeaders ?? []
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Column>
        </Column>
      </PlayerProfileContainer>
      <Footer />
    </>
  );
}

const PlayerProfileContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 130vh;
  color: ${(props) => props.atomColors.text};
  background-color: ${(props) => props.atomColors.background};
  position: relative;
  padding-block: 50px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

function PersonalDetailsRow({ label, value }) {
  const colors = useAtomValue(colorAtom);

  return (
    <Row
      alignCenter
      justifyBetween
      style={{
        backgroundColor: colors.background,
        padding: 20,
        borderRadius: BORDER_RADIUS,
        fontWeight: "bold",
      }}
    >
      <p>{label}</p>
      <p>{value}</p>
    </Row>
  );
}

function CustomTableCell({ label, capitalize, bold }) {
  const colors = useAtomValue(colorAtom);
  return (
    <TableCell
      style={{
        color: colors.text,
        borderBottomColor: colors.faintText,
        textTransform: capitalize ? "capitalize" : "",
        whiteSpace: "nowrap",
        fontWeight: bold && 800,
      }}
    >
      {label}
    </TableCell>
  );
}

export const TopLabel = ({ name }) => {
  const colors = useAtomValue(colorAtom);
  const language = useAtomValue(languageAtom);
  return (
    <>
      {language.isEnglish ? (
        <p className="text-4xl" style={{ color: colors.text }}>
          <span className="capitalize underline font-[800]">{name}'s</span>{" "}
          details
        </p>
      ) : (
        <p className="text-4xl" style={{ color: colors.text }}>
          Wasifu wa{" "}
          <span className="capitalize underline font-[800]">{name}</span>
        </p>
      )}
    </>
  );
};

export const swahiliDateFormatter = (date) => {
  const day = ((d) => {
    if (d < 10) return `0${d}`;
    return d;
  })(date.getDate());

  const month = ((m) => {
    if (m < 10) return `0${m}`;
    return m;
  })(date.getMonth() + 1);

  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
};
