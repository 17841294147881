import { useAtomValue, useSetAtom } from "jotai";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { _routeNames } from "../../../../../../app/routes";
import { useWindowWidth } from "../../../../../../hooks/use-window-width";
import DataNotFound from "../../../../../../shared-components/data_not_found";
import Label from "../../../../../../shared-components/Label";
import { Row } from "../../../../../../shared-components/row";
import { colorAtom } from "../../../../../../state/shared-state-atoms/colors";
import { languageAtom } from "../../../../../../state/shared-state-atoms/language";
import { tabIndexAtom } from "../../../../../../state/shared-state-atoms/tab-index";
import statTabIndex from "../../../stats/stats-index-atom";
import CardItem from "./components/CardItem";
import GridLayout from "./components/gridLayout";
import IconBox from "./components/IconBox";
import PlayerStatsContainer from "./components/playerstats-container";
import teamsInfoList from "./data/teamsInfoList";
import useTeamStats from "./useTeamStats";

export default function TeamStats() {
  const teamStatsHook = useTeamStats();
  const { windowWidth } = useWindowWidth();
  const colors = useAtomValue(colorAtom);
  const language = useAtomValue(languageAtom);
  const words = teamStatsWords(language.isEnglish);
  let navigate = useNavigate();
  const setStatTabIndex = useSetAtom(statTabIndex);
  const setHomeTabIndex = useSetAtom(tabIndexAtom);
  const go = () => {
    navigate(_routeNames.root.stats.index);
    setStatTabIndex(1);
    setHomeTabIndex(3);
  };
  return (
    <PlayerStatsContainer atomColors={colors}>
      <Row
        alignCenter
        justifyBetween={windowWidth > 600}
        style={{ flexDirection: windowWidth < 600 ? "column" : "" }}
      >
        <Label xBold xLarge text={words.mainLabel} />
        <Row
          alignCenter
          gap={10}
          style={{ justifyContent: windowWidth < 600 ? "center" : "flex-end" }}
        >
          <Row
            pointer
            onClick={go}
            alignCenter
            style={{ justifyContent: "flex-end" }}
            width={80}
          >
            <Label text={words.showAll} />
            <IconBox>
              <HiOutlineArrowNarrowRight />
            </IconBox>
          </Row>
        </Row>
      </Row>

      {teamStatsHook.loading ? (
        <Label text={words.loading} />
      ) : (
        <>
          {teamStatsHook.teamData.length === 0 ? (
            <DataNotFound message={words.noData} />
          ) : (
            <>
              {((teamsInfo) => {
                let goalsCategory = {
                  category: language.isEnglish ? "Goals" : "Magoli",
                  data: teamsInfo
                    .sort(
                      (a, b) => a.season?.scored_goals - b.season?.scored_goals
                    )
                    .map((teamInfo) => {
                      return {
                        name: teamInfo.name,
                        logo: teamInfo.logo,
                        color: teamInfo.team_color,
                        stat: teamInfo.season?.scored_goals ?? 0,
                      };
                    }),
                };

                let shotsCategory = {
                  category: language.isEnglish ? "Wins" : "Ushindi",
                  data: teamsInfo
                    .sort((a, b) => a.season?.wins - b.season?.wins)
                    .map((teamInfo) => {
                      return {
                        name: teamInfo.name,
                        logo: teamInfo.logo,
                        color: teamInfo.team_color,
                        stat: teamInfo.season?.wins ?? 0,
                      };
                    }),
                };

                let pointsCategory = {
                  category: language.isEnglish ? "Points" : "Pointi",
                  data: teamsInfo
                    .sort((a, b) => a.season?.points - b.season?.points)
                    .map((teamInfo) => {
                      return {
                        name: teamInfo.name,
                        logo: teamInfo.logo,
                        color: teamInfo.team_color,
                        stat: teamInfo.season?.points ?? 0,
                      };
                    }),
                };

                let drawsCategory = {
                  category: language.isEnglish ? "Draws" : "Sare",
                  data: teamsInfo
                    .sort((a, b) => a.season?.draw - b.season?.draw)
                    .map((teamInfo) => {
                      return {
                        name: teamInfo.name,
                        logo: teamInfo.logo,
                        color: teamInfo.team_color,
                        stat: teamInfo.season?.draw ?? 0,
                      };
                    }),
                };

                const list = [
                  goalsCategory,
                  shotsCategory,
                  pointsCategory,
                  drawsCategory,
                ];

                return (
                  <GridLayout>
                    {list.map((_list, index) => (
                      <CardItem key={index} teamList={_list} />
                    ))}
                  </GridLayout>
                );
              })(teamStatsHook.teamData)}
            </>
          )}
          <GridLayout style={{ display: "none" }}>
            {teamsInfoList.map((list, index) => (
              <CardItem key={index} teamList={list} />
            ))}
          </GridLayout>
        </>
      )}
    </PlayerStatsContainer>
  );
}

function teamStatsWords(isEnglish) {
  return {
    mainLabel: isEnglish ? "Team Stats" : "Takwimu za timu",
    showAll: isEnglish ? "Show all" : "Angalia wote",
    noData: isEnglish
      ? "Team stats not available"
      : "Takwimu za klabu hazipo kwa sasa",
    loading: isEnglish ? "Loading ... " : "Tafadhali subiri",
  };
}
