import styled from 'styled-components'

const Card = styled.div`
    height: 100%;
    width: 100%;
    flex-grow: 1;
    background-color: beige;
    border-radius: 0px;
    overflow: clip;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`

export default Card