import { useAtomValue, useSetAtom } from "jotai";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { _routeNames } from "../../../../../../app/routes";
import { useWindowWidth } from "../../../../../../hooks/use-window-width";
import DataNotFound from "../../../../../../shared-components/data_not_found";
import Label from "../../../../../../shared-components/Label";
import { Row } from "../../../../../../shared-components/row";
import { colorAtom } from "../../../../../../state/shared-state-atoms/colors";
import { languageAtom } from "../../../../../../state/shared-state-atoms/language";
import { tabIndexAtom } from "../../../../../../state/shared-state-atoms/tab-index";
import statTabIndex from "../../../stats/stats-index-atom";
import CardItem from "./components/CardItem";
import GridLayout from "./components/gridLayout";
import IconBox from "./components/IconBox";
import PlayerStatsContainer from "./components/playerstats-container";
import playersInfoList from "./data/playersInfoList";
import usePlayerStats from "./usePlayerStats";

export default function PlayerStats() {
  const playerstatsHook = usePlayerStats();
  const { windowWidth } = useWindowWidth();
  const colors = useAtomValue(colorAtom);
  const language = useAtomValue(languageAtom);
  const words = playerStatsWords(language.isEnglish);
  let navigate = useNavigate();
  const setStatTabIndex = useSetAtom(statTabIndex);
  const setHomeTabIndex = useSetAtom(tabIndexAtom);
  const go = () => {
    navigate(_routeNames.root.stats.index);
    setStatTabIndex(0);
    setHomeTabIndex(3);
  };

  return (
    <PlayerStatsContainer atomColors={colors}>
      <Row
        alignCenter
        justifyBetween={windowWidth > 600}
        style={{ flexDirection: windowWidth < 600 ? "column" : "" }}
      >
        <Label xBold xLarge text={words.mainLabel} />
        <Row
          alignCenter
          gap={10}
          style={{ justifyContent: windowWidth < 600 ? "center" : "flex-end" }}
        >
          <Row
            pointer
            onClick={go}
            alignCenter
            style={{ justifyContent: "flex-end" }}
            width={80}
          >
            <Label text={words.showAll} />
            <IconBox>
              <HiOutlineArrowNarrowRight />
            </IconBox>
          </Row>
        </Row>
      </Row>
      <GridLayout>
        {playerstatsHook.playerData.map((list, index) => {
          return <CardItem key={index} playerList={list} />;
        })}
      </GridLayout>

      {/* <DataNotFound message={ words.noData } /> */}
    </PlayerStatsContainer>
  );
}

function playerStatsWords(isEnglish) {
  return {
    mainLabel: isEnglish ? "Player Stats" : "Takwimu za wachezaji",
    showAll: isEnglish ? "Show all" : "Angalia wote",
    noData: isEnglish
      ? "Player stats not available"
      : "Takwimu za wachezaji hazipo kwa sasa",
  };
}
