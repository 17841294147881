import styled from 'styled-components'

export const MatchDayContainer = styled.div`
    width: 100%;
    height: 100%;
    min-height: 100vh;

    padding-block: 50px;

    display: flex;
    flex-direction: column;
    align-items: center;
    
    color: ${ props => props.atomColors.text };
    background-color: ${ props => props.atomColors.background };
`