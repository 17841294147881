import { Row } from "./row";
import { Input } from "./input";
import { BORDER_RADIUS } from "../core/measurements";
import { useAtomValue } from "jotai";
import { colorAtom } from "../state/shared-state-atoms/colors";

export function InputField({
  placeHolder,
  type,
  value,
  setter,
  disabled,
  phone,
  code,
  backgroundColor,
  radius,
  color,
  Icon,
}) {
  const colors = useAtomValue(colorAtom);
  return (
    <Row
      gap={0}
      style={{
        backgroundColor: backgroundColor ?? colors.secondary,
        borderRadius: radius ?? BORDER_RADIUS,
        overflow: "clip",
        color: color ?? "",
        width: "90%",
      }}
    >
      <div
        style={{
          backgroundColor: backgroundColor ?? colors.secondary,
          color: color ?? colors.text,
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 20,
        }}
      >
        {Icon && <Icon />}
      </div>
      <Input
        disabled={disabled}
        type={type ?? "text"}
        maxLength={phone ? "9" : code ? "5" : "50"}
        value={value}
        onChange={(event) => setter(event.target.value)}
        placeholder={placeHolder}
        style={{
          backgroundColor: backgroundColor ?? colors.secondary,
          color: color ?? colors.text,
          fontSize: 11,
        }}
      />
    </Row>
  );
}
