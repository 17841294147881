import React from "react";
import { useWindowWidth } from "../../../../../hooks/use-window-width";
import { colorAtom } from "../../../../../state/shared-state-atoms/colors";
import { useAtomValue } from "jotai";

const Tabs = ({ value }) => {
  const tabList = ["All", "Home", "Away"];
  const { windowWidth } = useWindowWidth();
  const colors = useAtomValue(colorAtom);
  return (
    <div
      style={{
        padding: 10,
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      {tabList?.map((tab, idx) => {
        return (
          <div
            style={{
              borderRadius: 20,
              paddingBlock: 5,
              borderWidth: 1,
              borderColor: "grey",
              paddingInline: 10,
              marginInline: 5,
            }}
          >
            <p
              style={{
                fontSize: 12,
                fontWeight: "bold",
              }}
            >
              {tab}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default Tabs;
