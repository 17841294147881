import { BiFootball } from "react-icons/bi";
import { Column } from "../../../../shared-components/column";
import { Row } from "../../../../shared-components/row";
import { colorAtom } from "../../../../state/shared-state-atoms/colors";
import { useAtomValue } from "jotai";
import { useWindowWidth } from "../../../../hooks/use-window-width";
import { useParams } from "react-router-dom";
import Label from "../../../../shared-components/Label";
import { BORDER_RADIUS } from "../../../../core/measurements";
import { useEffect, useState } from "react";
import apiCall from "../../../../functions/api_call";
import { URLS } from "../../../../core/urls";
import { BsArrowRepeat } from "react-icons/bs";
import { FaCrown } from "react-icons/fa";
import { languageAtom } from "../../../../state/shared-state-atoms/language";

export function MatchFactsTab() {
  const matchFactsHook = useMatchFacts()
  const { kingOfTheMatch } = useMatchFacts()
  const colors = useAtomValue(colorAtom)
  const language = useAtomValue(languageAtom)
  if (matchFactsHook.factLoading) {
    return (
      <Column alignCenter justifyCenter style={ { padding: 50 } }>
        <Label text={ language.isEnglish ? "loading ..." : "tafadhali subiri ..." } />
      </Column>
    );
  }
  return (
    <Column
      gap={ 20 }
      style={ {
        marginInline: "auto",
        maxWidth: 700,
        height: "100%",
        maxHeight: 800,
        overflowY: "scroll",
        borderRadius: BORDER_RADIUS,
      } }>
      <br />
      { matchFactsHook.facts.length === 0 ? (
        <Column alignCenter justifyCenter style={ { padding: 50 } }>
          <Label text={ language.isEnglish ? "no facts found" : "hakuna taarifa za matukio kwa sasa" } />
        </Column>
      ) : (
        <>
          { matchFactsHook.facts?.map((fact, index) => (
            <FactMapper key={ index } fact={ fact } goalsBeforeHalf={ matchFactsHook.goalsBeforeHalf } />
          )) }
        
      { kingOfTheMatch !== null ?  <Row alignCenter style={ { backgroundColor: colors.background, justifyContent: "center" , paddingInline: 50 } }>
          <>
            <div style={{ margin: 15 }} >
            <FactIcon type={"king_of_the_match"} />
            </div>

            <Column style={ { width: "fit-content" } }>
              <Label xBold noWrap text={ "KING OF THE MATCH" } />
              <Label noWrap text={ kingOfTheMatch.name} />
            </Column>
          </>
        </Row>: null }
        </>
      ) }
      <br />
    </Column>
  );
}

function FactMapper({ fact, goalsBeforeHalf }) {
  if (Boolean(fact.event === "goal")) {
    return <MatchFact factType={ fact.event } player={ fact.player?.name ?? "n/a" } isHome={ fact.isHome } secondPlayer={ fact.assisted_by?.name ?? "" } minute={ fact.time } />;
  }

  if (Boolean(fact.event === "sub")) {
    return <MatchFact factType={ fact.event } player={ fact.in?.name ?? "n/a" } isHome={ fact.isHome } secondPlayer={ fact.out?.name ?? "n/a" } minute={ fact.time } />;
  }

  if (Boolean(fact.event === "ht")) {
    return <MatchFact factType={ fact.event } minute={ fact.time } goalsBeforeHalf={ goalsBeforeHalf } />;
  }

  if (Boolean(fact.card_type)) {
    return <MatchFact factType={ fact.card_type } player={ fact.player?.name ?? "n/a" } isHome={ fact.isHome } minute={ fact.time } />;
  }

  return "";
}

const useMatchFacts = () => {
  const { match_id } = useParams();
  const [factLoading, setFactLoading] = useState(false);
  const [facts, setFacts] = useState([]);
  const [ kingOfTheMatch, setKingOfTheMatch ] = useState(null)
  const [goalsBeforeHalf, setGoalsBeforeHalfTime] = useState({
    home: 2,
    away: 1,
  });

  const getKingOfTheMatch = async() => {
    try {
      apiCall({ url: `${ URLS.kingOfTheMatch }/${ match_id }` }).then(results=>{
         //console.log('king of the match', results)
         setKingOfTheMatch(results.data.player)
      }).catch(err => {
         setKingOfTheMatch(null)
      })
     } catch(err){
      console.log(err)
    }
  }

  useEffect(() => {
    setFactLoading(true);
    getKingOfTheMatch()
    let promises = [apiCall({ url: `${ URLS.matchFacts }/${ match_id }` }), apiCall({ url: `${ URLS.allMatches }/${ match_id }` })];
    Promise.allSettled(promises)
      .then((results) => {
        let homeTeamId = results[1].value.data.home_team._id;
        let sortedFacts = results[0].value.data.sort((a, b) => a.time - b.time);

        let newData = [];

        sortedFacts.forEach((fact) => {
          if (Boolean(fact.team === homeTeamId)) newData.push({ ...fact, isHome: true });
          else newData.push({ ...fact, isHome: false });
        });

        try {
          let halfTimeMinute = newData.find((dt) => dt.event === "ht").time ?? 45;
          for (let index = 0; index < newData.length; index++) {
            const dt = newData[index];
            let event = dt.event ?? dt.card_type;
            if (event === "ht") break;

            let fortyFive = 45;
            if (Boolean(dt.time > fortyFive && dt.time <= halfTimeMinute)) {
              let newTime = `${ fortyFive } + ${ dt.time - fortyFive }"`;
              newData[index] = { ...dt, time: newTime };
            }
          }
        } catch (error) { }

        setFacts((prev) => {
          prev = newData;
          return prev;
        });

        // getting goals before half time
        let _homeGoals = 0;
        let _awayGoals = 0;
        for (let index = 0; index < newData.length - 1; index++) {
          const fact = newData[index];
          if (fact.event && fact.event === "ht") return;
          if (fact.event && fact.event === "goal") {
            if (fact.isHome) _homeGoals++;
            else _awayGoals++;
            setGoalsBeforeHalfTime((prev) => ({ ...prev, home: _homeGoals, away: _awayGoals }));
          }
        }
      })
      .finally(() => setFactLoading(false));
  }, [])

  return { match_id, factLoading, facts, goalsBeforeHalf, kingOfTheMatch };
};

function MatchFact({ factType, player, isHome = false, secondPlayer, minute, goalsBeforeHalf }) {
  const colors = useAtomValue(colorAtom);
  if (factType === "ht") return <HalfTime goalsBeforeHalf={ goalsBeforeHalf } />

  return (
        <Row alignCenter style={ { backgroundColor: colors.background, justifyContent: isHome ? "flex-start" : "flex-end", paddingInline: 50 } }>
      <Row
        alignCenter
        gap={ 10 }
        style={ {
          width: "fit-content",
          paddingInline: 30,
          paddingBlock: 15,
          borderRadius: BORDER_RADIUS,
        } }>
        { isHome ? (
          <>
            <Label small text={ minute } />

            <FactIcon type={ factType } />

            <Column style={ { width: "fit-content" } }>
              <Label xBold noWrap text={ player } />
              <Label noWrap text={ secondPlayer } />
            </Column>
          </>
        ) : (
          <>
            <Column style={ { width: "fit-content", alignItems: "flex-end" } }>
              <Label noWrap xBold text={ player } />
              <Label noWrap text={ secondPlayer } />
            </Column>

            <FactIcon type={ factType } />

            <Label small text={ minute } />
          </>
        ) }
      </Row>
    </Row>
  
  );
}

const FactIcon = ({ type }) => {
  switch (type) {
    case "goal":
      return (
        <div style={ { height: 30, aspectRatio: 1 } }>
          <BiFootball style={ { height: "100%", width: "100%" } } />
        </div>
      );

    case "sub":
      return <BsArrowRepeat style={ { fontSize: "xx-large" } } />;

    case "yellow":
      return <Card yellow />;

    case "red":
      return <Card red />;

    case "king_of_the_match":
      return <FaCrown style={{ fontSize: "xx-large " }} /> 

    default:
      return "";
  }
};

const Card = ({ color, yellow, red }) => {
  return <div style={ { backgroundColor: yellow ? "orange" : red ? "tomato" : "", height: 40, aspectRatio: 7 / 10, borderRadius: 5 } } />;
};

function HalfTimeIcon({ label = "HT" }) {
  const colors = useAtomValue(colorAtom);

  return (
    <Column style={ { position: "relative", transform: "rotate(-30deg)", width: "fit-content", height: "fit-content" } }>
      <div
        style={ {
          height: 25,
          width: 50,
          borderTopLeftRadius: 500,
          borderTopRightRadius: 500,
          backgroundColor: colors.faintText,
        } }
      ></div>
      <div
        style={ {
          height: 25,
          width: 50,
          borderBottomLeftRadius: 500,
          borderBottomRightRadius: 500,
          backgroundColor: colors.text,
        } }
      ></div>
      <div
        style={ {
          height: 40,
          aspectRatio: 1,
          borderRadius: "50%",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translateY(-50%) translateX(-50%) rotate(30deg)",
          backgroundColor: colors.background,
          fontWeight: "bold",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        } }
      >
        <p>{ label }</p>
      </div>
    </Column>
  );
}

function HalfTime({ goalsBeforeHalf }) {
  const colors = useAtomValue(colorAtom);
  const { windowWidth } = useWindowWidth();
  return (
    <Row alignCenter justifyCente style={ { width: "90%", marginInline: "auto", position: "relative" } }>
      <Row
        gap={ 10 }
        alignCenter
        justifyCenter
        style={ { backgroundColor: colors.secondary, width: "30%", margin: "0 auto", zIndex: 50, flexDirection: windowWidth < 450 ? "column" : "row" } }
      >
        <HalfTimeIcon />
        <Label text={ `${ goalsBeforeHalf.home } - ${ goalsBeforeHalf.away }` } />
      </Row>
      <div
        style={ {
          position: "absolute",
          height: 2,
          width: "100%",
          backgroundColor: colors.faintText,
          top: "50%",
          transform: "translateY(-50%)",
        } }
      />
    </Row>
  );
}
