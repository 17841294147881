import { useAtomValue } from "jotai"
import { useState } from "react"
import { BORDER_RADIUS } from "../../../../../../core/measurements"
import { Row } from "../../../../../../shared-components/row"
import { colorAtom } from "../../../../../../state/shared-state-atoms/colors"

import styled from 'styled-components'
import { Column } from "../../../../../../shared-components/column"

import player from '../../../../../../assets/players/1n.png'
import teamLogo from '../../../../../../assets/teams/az.png'
import manager from '../../../../../../assets/managers/manager.png'

import { RiVipCrownLine } from 'react-icons/ri'

import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const _teamOfTheSeason = {
    goalKeeper: { name: 'Manula', jerseyNumber: 1, teamName: 'SSC', teamLogo: teamLogo, rating: 7.8 },
    defence: [
        { name: 'Kapombe', jerseyNumber: 23, teamName: 'SSC', teamLogo: teamLogo, rating: 7.5 },
        { name: 'Ndemla', jerseyNumber: 3, teamName: 'MBC', teamLogo: teamLogo, rating: 7.1 },
        { name: 'Kido', jerseyNumber: 5, teamName: 'MTB', teamLogo: teamLogo, rating: 7.6 },
        { name: 'Soko', jerseyNumber: 2, teamName: 'NMG', teamLogo: teamLogo, rating: 7.5 },
    ],
    midField: [
        { name: 'Ndemla', jerseyNumber: 3, teamName: 'MBC', teamLogo: teamLogo, rating: 7.1 },
        { name: 'Kido', jerseyNumber: 5, teamName: 'MTB', teamLogo: teamLogo, rating: 7.6 },
        { name: 'Soko', jerseyNumber: 2, teamName: 'NMG', teamLogo: teamLogo, rating: 7.5 },
    ],
    foward: [
        { name: 'Ndemla', jerseyNumber: 3, teamName: 'MBC', teamLogo: teamLogo, rating: 7.1 },
        { name: 'Kido', jerseyNumber: 5, teamName: 'MTB', teamLogo: teamLogo, rating: 7.6 },
        { name: 'Soko', jerseyNumber: 2, teamName: 'NMG', teamLogo: teamLogo, rating: 7.5 },
    ],
    formation: '4-3-3'
}
export function Awards() 
{
    const colors = useAtomValue(colorAtom)
    const {
        categories,
        category,
        setcategory,
        seasons,
        season,
        setseason,
        playersOfTheMonth,
        managersOfTheMonth
    } = useAwardsHook()

    return (
        <Column
            alignCenter
            style={ {
                minHeight: '70vh',
                width: '100%',
            } }
        >
            <Row
                alignCenter
                gap={ 20 }
                style={ {
                    backgroundColor: colors.secondary,
                    width: "100%",
                    maxWidth: 1200,
                    paddingInline: 20,
                    paddingBlock: 15,
                } }>
                <Row gap={ 10 }>
                    <DropDownComponent label={ "Filter" } list={ categories } value={ category } setter={ setcategory } />
                    <DropDownComponent label={ "Seasons" } list={ seasons } value={ season } setter={ setseason } />
                </Row>
            </Row>
            <Column
                gap={ 10 }
                style={ {
                    backgroundColor: colors.background,
                    width: "90%",
                    maxWidth: 1200,
                    borderRadius: BORDER_RADIUS,
                    overflowX: 'scroll',
                    paddingInline: 20,
                    marginTop: 20
                } }
            >
                {
                    (function (categories, category)
                    {
                        switch (category)
                        {
                            case categories[0]:
                                return (
                                    <CardGridContainer>
                                        {
                                            playersOfTheMonth.map((playerOfTheMonth, index) =>
                                            {
                                                return (
                                                    <CardItem2
                                                        key={ index }
                                                        awardedOn={ playerOfTheMonth.month }
                                                        teamLogo={ teamLogo }
                                                        individual={ playerOfTheMonth.individual }
                                                    />
                                                )
                                            })
                                        }
                                    </CardGridContainer>
                                )

                            case categories[1]:
                                return (
                                    <CardGridContainer>
                                        {
                                            managersOfTheMonth.map((managerOfTheMonth, index) =>
                                            {
                                                return (
                                                    <CardItem2
                                                        key={ index }
                                                        awardedOn={ managerOfTheMonth.month }
                                                        teamLogo={ teamLogo }
                                                        individual={ managerOfTheMonth.individual }
                                                    />
                                                )
                                            })
                                        }
                                    </CardGridContainer>
                                )

                            case categories[2]:
                                const borderColor = 'orange'
                                const background = colors.secondary
                                return (
                                    <Pitch background={ background }>
                                        <Goal borderColor={ borderColor }></Goal>

                                        <Formation433 background={ background } borderColor={ borderColor } _teamOfTheSeason={ _teamOfTheSeason } />

                                        <GoalBox borderColor={ borderColor } background={ background }></GoalBox>
                                        <GoalBoxCircle borderColor={ borderColor }></GoalBoxCircle>
                                        <Column style={ { width: '100%', position: 'relative', minHeight: 150, marginBottom: 100 } }>
                                            <CenterCircle borderColor={ borderColor }></CenterCircle>
                                            <CenterLine borderColor={ borderColor }></CenterLine>
                                        </Column>
                                    </Pitch>
                                )

                            default:
                                return <p>Coming soon ...</p>
                        }

                    }(categories, category))
                }
                <br />
            </Column>
        </Column>
    )
}

const Formation433 = ({ background, borderColor, _teamOfTheSeason }) => 
{
    return (
        <>
            <GoalKeeper background={ background } borderColor={ borderColor }>
                <PlayerPitchAvatar
                    background={ background }
                    jerseyNumber={ _teamOfTheSeason.goalKeeper.jerseyNumber }
                    name={ _teamOfTheSeason.goalKeeper.name }
                    rating={ _teamOfTheSeason.goalKeeper.rating }
                    teamLogo={ _teamOfTheSeason.goalKeeper.teamLogo }
                    teamName={ _teamOfTheSeason.goalKeeper.teamName }
                />
            </GoalKeeper>

            <Row gap={ 10 } justifyBetween style={ { padding: 10, position: 'absolute', top: '18%', zIndex: 60, paddingInline: 40 } }>
                {
                    _teamOfTheSeason.defence.map((entry, index) =>
                    {
                        return (
                            <PlayerPitchAvatar
                                key={ index }
                                background={ background }
                                jerseyNumber={ entry.jerseyNumber }
                                name={ entry.name }
                                rating={ entry.rating }
                                teamLogo={ entry.teamLogo }
                                teamName={ entry.teamName }
                                wingBack={ index === 0 || index === 3 }
                            />
                        )
                    })
                }
            </Row>

            <Row gap={ 10 } justifyBetween style={ { padding: 10, position: 'absolute', top: '40%', zIndex: 60, paddingInline: 100 } }>
                {
                    _teamOfTheSeason.midField.map((entry, index) =>
                    {
                        return (
                            <PlayerPitchAvatar
                                key={ index }
                                background={ background }
                                jerseyNumber={ entry.jerseyNumber }
                                name={ entry.name }
                                rating={ entry.rating }
                                teamLogo={ entry.teamLogo }
                                teamName={ entry.teamName }
                                wideMidFielders={ index === 0 || index === 2 }
                            />
                        )
                    })
                }
            </Row>

            <Row gap={ 10 } justifyBetween style={ { padding: 10, position: 'absolute', top: '65%', zIndex: 60, paddingInline: 80 } }>
                {
                    _teamOfTheSeason.foward.map((entry, index) =>
                    {
                        return (
                            <PlayerPitchAvatar
                                key={ index }
                                background={ background }
                                jerseyNumber={ entry.jerseyNumber }
                                name={ entry.name }
                                rating={ entry.rating }
                                teamLogo={ entry.teamLogo }
                                teamName={ entry.teamName }
                                striker={ index === 1 }
                            />
                        )
                    })
                }
            </Row>
        </>
    )
}

const PlayerPitchAvatar = ({ background, rating, jerseyNumber, name, teamName, teamLogo, wingBack, wideMidFielders, striker }) => 
{
    const colors = useAtomValue(colorAtom)
    return (
        <div style={ {
            width: 150,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'relative',
            marginTop: wingBack ? 20 : wideMidFielders ? 30 : striker ? 30 : 0
        } }>
            <div style={ {
                position: 'absolute',
                right: 0,
                backgroundColor: colors.background,
                border: `0.5px solid ${ colors.faintText }`,
                paddingInline: 10,
                paddingBlock: 2,
                borderRadius: BORDER_RADIUS
            } }>
                <p style={ { backgroundColor: colors.background } }>{ rating }</p>
            </div>
            <img src={ player } alt="" style={ { height: 50, borderRadius: '50%', backgroundColor: background, boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' } } />
            <p style={ { backgroundColor: colors.background } }><span style={ { fontWeight: 'bold' } }>{ jerseyNumber }.{ ' ' }</span>{ name }</p>
            <Row alignCenter gap={ 5 } style={ { width: 'fit-content' } }>
                <img src={ teamLogo } alt="" style={ { height: 20, borderRadius: '50%', backgroundColor: background } } />
                <p style={ { fontSize: 'small', backgroundColor: colors.background } }>{ teamName }</p>
            </Row>
        </div>
    )
}

const Pitch = styled.div`
    background-color: ${ props => props.background };
    width: 600px;
    height: 700px;
    margin: 0 auto;
    border-radius: 10px;
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`

const Goal = styled.div`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 20%;
    height: 8%;
    border: ${ props => `2px solid ${ props.borderColor }` };
    border-top: none;
    z-index: 11;
`

const GoalBox = styled.div`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 60%;
    height: 20%;
    background-color: ${ props => props.background };
    border: ${ props => `2px solid ${ props.borderColor }` };
    border-top: none;
    z-index: 10;
`

const GoalBoxCircle = styled.div`
    position: absolute;
    top: 10%;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 20%;
    aspect-ratio: 1;
    border-radius: 50%;
    border: ${ props => `2px solid ${ props.borderColor }` };
    z-index: 8;
`

const CenterCircle = styled.div`
    margin: 0 auto;
    height: 100%;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    border: ${ props => `2px solid ${ props.borderColor }` };
    z-index: 8;

    display: flex;
    align-items: center;
`

const CenterLine = styled.div`
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    width: 100%;
    height: 2px;
    background-color: ${ props => props.borderColor };
    z-index: 8;
`

const GoalKeeper = styled.div`
    height: 50px;
    width: 300px;
    aspect-ratio: 1;
    position: absolute;
    top: 15px;
    right: 0;
    left: 0;
    margin: 0 auto;
    z-index: 58;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
`

const DropDownComponent = ({ list, value, setter, label }) =>
{
    const colors = useAtomValue(colorAtom)

    const handleChange = (event, setter) =>
    {
        setter(event.target.value);
    };

    return (
        <FormControl fullWidth size="small" style={ { borderColor: colors.faintText } }>
            <InputLabel
                id="demo-simple-select-label"
                style={ { color: colors.text, backgroundColor: colors.secondary } }
            >{ label }</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={ value }
                label={ label }
                onChange={ (event) => handleChange(event, setter) }
                style={ { color: colors.text, border: `1px solid ${ colors.faintText }`, border: 'none', outline: 'none' } }
            >
                {
                    list.map((item, index) =>
                    {
                        return <MenuItem key={ index } value={ item }>{ item }</MenuItem>
                    })
                }
            </Select>
        </FormControl>
    )

}

const useAwardsHook = () => 
{
    const playersOfTheMonth = [
        { month: "August", individual: player },
        { month: "September", individual: player },
        { month: "October", individual: player },
        { month: "November", individual: player },
        { month: "December", individual: player },
        { month: "January", individual: player },
        { month: "February", individual: player },
        { month: "March", individual: player },
        { month: "April", individual: player },
        { month: "May", individual: player },
        { month: "June", individual: player },
        { month: "July", individual: player },
    ]

    const managersOfTheMonth = [
        { month: "August", individual: manager },
        { month: "September", individual: manager },
        { month: "October", individual: manager },
        { month: "November", individual: manager },
        { month: "December", individual: manager },
        { month: "January", individual: manager },
        { month: "February", individual: manager },
        { month: "March", individual: manager },
        { month: "April", individual: manager },
        { month: "May", individual: manager },
        { month: "June", individual: manager },
        { month: "July", individual: manager },
    ]

    const categories = [
        "Player of the month",
        "Manager of the month",
        "Team of the season",
        "Manager of the season"
    ]

    const seasons = [
        "2018/19",
        "2019/20",
        "2020/21",
        "2021/22"
    ]

    const [category, setcategory] = useState(categories[0]);
    const [season, setseason] = useState(seasons[3]);

    return {
        categories,
        category,
        setcategory,
        seasons,
        season,
        setseason,
        playersOfTheMonth,
        managersOfTheMonth
    }
}

const CardItem2 = ({ awardedOn, teamLogo, individual }) => 
{
    const textBackground = 'grey'
    return (
        <Column style={ { width: '100%', borderRadius: BORDER_RADIUS, overflow: 'clip' } }>
            <Column gap={ 20 } style={ { padding: 20, backgroundColor: textBackground, position: 'relative' } }>
                <CardItemCrown />
                <CardItemAwardTime awardedOn={ awardedOn } textBackground={ textBackground } />
                <CardAwardGoesTo textBackground={ textBackground } teamLogo={ teamLogo } />
                <CardAwardedPersonel individual={ individual } />
            </Column>
        </Column>
    )
}

const CardItemCrown = () =>
{
    const colors = useAtomValue(colorAtom)
    return (
        <div
            style={ {
                height: 50,
                width: 50,
                padding: 10,
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: colors.secondary,
            } }
        >
            <RiVipCrownLine />
        </div>
    )
}

const CardItemAwardTime = ({ awardedOn, textBackground }) =>
{
    return (
        <Column gap={ 10 }>
            <h2 style={ { color: '#fff', backgroundColor: textBackground, zIndex: 50 } }>{ awardedOn }</h2>
        </Column>
    )
}

const CardAwardGoesTo = ({ textBackground, teamLogo }) => 
{
    return (
        <Row alignCenter gap={ 10 }>
            <img
                src={ teamLogo }
                alt=""
                style={ { height: 50, borderRadius: '50%' } }
            />
            <Column>
                <h3 style={ { color: '#fff', backgroundColor: textBackground, zIndex: 50 } }>Aishi Manula</h3>
                <p style={ { color: '#fff', fontSize: 'small', backgroundColor: textBackground, zIndex: 50 } }>Simba SC</p>
            </Column>
        </Row>
    )
}

const CardAwardedPersonel = ({ individual }) => 
{
    return (
        <img
            src={ individual }
            alt=""
            style={ {
                position: 'absolute',
                right: 10,
                bottom: 0,
                height: 150,
                aspectRatio: 1,
                objectFit: 'cover'
            } }
        />
    )
}

const CardGridContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;

    @media (max-width: 1090px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 780px) {
        grid-template-columns: repeat(1, 1fr);
    }
`