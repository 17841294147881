import { atomWithStorage } from "jotai/utils";

export const matchStatsAtom = atomWithStorage({
    home: {
        logo: '',
        name: '',
        teamColor: ''
    },
    away: {
        logo: '',
        name: '',
        teamColor: ''
    }
})