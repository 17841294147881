import { useAtomValue } from "jotai"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { postRequestWithOutToken } from "../../core/network-request-functions"
import { URLS } from "../../core/urls"
import { languageAtom } from "../../state/shared-state-atoms/language"
import { userProfileAtom } from "../../state/shared-state-atoms/user-profile-atom"

import toast from "react-hot-toast"
import { colorAtom } from "../../state/shared-state-atoms/colors"

export const useFeedbackHook = () => {
    let navigate = useNavigate()
    const colors = useAtomValue(colorAtom)
    const language = useAtomValue(languageAtom)
    const userProfileinfo = useAtomValue(userProfileAtom)
    const _path = URLS.pages.privacyPolicy

    const [contents, setContents] = useState()

    const [loading, setloading] = useState(false)

    const [name, setname] = useState('')
    const [email, setemail] = useState('')
    const [subject, setsubject] = useState('')
    const [phone, setphone] = useState('')
    const [message, setmessage] = useState('')

    const [disableUserFields, setdisableUserFields] = useState(false)


    const [open, setOpen] = useState(false);
    const [severity, setseverity] = useState('');
    const [popUpMessage, setpopUpMessage] = useState('');

    const handleClick = () => {
        setOpen(true);
    };

    /**
     * 
     * @param {*} event
     * @param {*} reason 
     * @returns 
     */
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const clearFields = () => {
        setname("")
        setemail("")
        setsubject("")
        setphone("")
        setmessage("")
    }

    const handleSubmit = async () => {
        if (name === '') {
            toast.error(language.isEnglish ? "Fullname field is reguired" : "Jina kamili linatakiwa lijazwe")
            return
        }
        const emailRegEx = new RegExp(/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/)
        if (email === '') {
            toast.error(language.isEnglish ? "Email field is reguired" : "Baruapepe inatakiwa ijazwe")
            return
        }
        if (!emailRegEx.test(email)) {
            toast.error(language.isEnglish ? "Please enter a valid email" : "Tafadhali weka baruapepe sahihi")
            return
        }
        if (phone === '') {
            toast.error(language.isEnglish ? "Phone number field is reguired" : "Namba ya simu inatakiwa ijazwe")
            return
        }
        const numberRegEx = new RegExp(/^\d+$/)
        if (!numberRegEx.test(phone)) {
            toast.error(language.isEnglish ? "Phone number should contain numbers only" : "Namba ya simu inatakiwa iwe na namba tu")
            return
        }
        if (subject === '') {
            toast.error(language.isEnglish ? "Subject field is reguired" : "Kichwa cha habari kinatakiwa kijazwe")
            return
        }
        if (message === '') {
            toast.error(language.isEnglish ? "Message field is reguired" : "Ujumbe unatakiwa ujazwe")
            return
        }

        setloading(true)
        const _body = { name, email, subject, phone, message }
        try {
            const response = await postRequestWithOutToken(URLS.feedback, _body)
            if (response.data.success) {
                toast.success(
                    language.isEnglish ? "feedback uploaded successfully" : "maoni yako yamepokelewa"
                )
            }
            if (!response.data.success) {
                toast.error(
                    language.isEnglish ? "sorry, something went wrong" : "samahani, kuna shida imejitokeza"
                )
            }
            setloading(false)
            setOpen(true)
            clearFields()
        } catch (error) {
            console.log("an error occurred when sending feedback", error)
            toast.error(
                language.isEnglish ? "sorry, something went wrong" : "samahani, kuna shida imejitokeza"
            )
            setloading(false)
            setOpen(true)
        }
    }


    useEffect(() => {
        if (userProfileinfo?.fullName) setdisableUserFields(true)

        setname(userProfileinfo?.fullName ? userProfileinfo?.fullName : '')
        setemail(userProfileinfo?.email ? userProfileinfo?.email : '')
        setphone(userProfileinfo?.phone ? userProfileinfo?.phone : '')

    }, [])

    return {
        navigate,
        colors,
        contents,
        loading,
        name,
        setname,
        email,
        setemail,
        subject,
        setsubject,
        phone,
        setphone,
        message,
        setmessage,
        disableUserFields,
        open,
        handleClick,
        handleClose,
        handleSubmit,
        severity,
        popUpMessage
    }
}