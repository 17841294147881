import axios from "axios";

const apiCall = async ({ tokenRequired = false, method = "get", url, body, config }) => {
  if (tokenRequired) {
    if (method === "get") return axios.get(url, config);
    if (method === "post") return axios.post(url, body, config);
    if (method === "put") return axios.put(url, body, config);
  } else {
    if (method === "get") return axios.get(url);
    if (method === "post") return axios.post(url, body);
  }
};

export default apiCall;
