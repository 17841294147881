import styled from 'styled-components'
import { BORDER_RADIUS } from '../../../../../core/measurements'

export const StatTbContainer = styled.div`
    min-height: 70vh;
    width: 90%;
    max-width: 1600px;
    margin: 0 auto;
    background-color: ${ props => props.atomColors.accent };
    padding: 20px;;
    margin-bottom: 20px;
    border-radius: ${ BORDER_RADIUS };
`