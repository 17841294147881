import styled from 'styled-components'

export const StatTabLabel = styled.p`
    font-size: ${props => props.selected ? 'x-large' : 'small'};
    font-weight: ${props => props.selected ? 'bold' : 'normal'};
    cursor: pointer;
    transition: 0.3s;

    &:hover {
        font-weight: bold;
        font-size: x-large;
    }
`