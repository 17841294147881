export const matchStatisticsRoutePath = "/match-stats";
export const _routeNames = {
  root: {
    index: "/",
    news: "/news",
    league: {
      index: "league",
      leagueV2: "/league/v2",
      matchDay: "/league/match-day",
      matchDayV2: "/league/match-day-v2",
    },
    matchDay: "/match-day",
    stats: {
      index: "/stats",
      players: "players",
      teams: "teams",
      awards: "awards",
      listing: "listing",
    },
    fantasy: "/fantasy",
    privacyPolicy: "/privacy-policy",
  },
  matchStatistics: "/match-stats/:match_id",
  profile: {
    index: "/profile",
    update: "/profile/update",
    verify: "/profile/update/verify-token",
  },
  auth: {
    login: "/auth/login",
    signUpVerifyPhone: "/auth/sign-up-verify-phone",
    signUpVerifyPhoneCode: "/auth/sign-up-verify-phone-code",
    signUp: "/auth/sign-up",
    signUpForm: "/auth/sign-up-form",
    forgotPassword: "/forgot-password",
    resetPassword: "/reset-password",
  },
  privacyPolicy: "/privacy-policy",

  termsOfService: "/terms-of-service",

  aboutUs: "/about-us",

  feedback: "/feedback",

  team: "/teams/:team_id",
  allTeams: "/teams",

  allPlayers: "/players",
  playerProfile: "/players/:player_id",

  allManagers: "/managers",
  managerProfile: "/managers/:manager_id",

  allPlayerStats: "/all-player-stats",

  allTeamStats: "/all-team-stats",

  allAwardStats: "/all-award-stats",

  partners: "/partners",

  broadcasters: "/broadcasters",
};
