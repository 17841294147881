import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useAtom } from "jotai";
import { colorAtom } from "../state/shared-state-atoms/colors";

export const ScrollableTabs = ({
  fullWwidth,
  currentIndex,
  handleChange,
  tabs,
  backgroundColor,
  indicatorColor,
  centered = false,
  textColor,
  faintColor,
  activeColor,
}) => {
  const [colors] = useAtom(colorAtom);
  return (
    <Box
      sx={{ bgcolor: "background.paper" }}
      style={{
        // width: "100%",
        width: fullWwidth ? "100%" : "fit-content",
        backgroundColor: backgroundColor ? backgroundColor : colors.secondary,
        padding: "5px 0",
      }}
    >
      <Tabs
        value={currentIndex}
        onChange={handleChange}
        variant={centered ? "" : "scrollable"}
        scrollButtons="on"
        aria-label="scrollable auto tabs example"
        TabIndicatorProps={{
          style: {
            background: indicatorColor ?? colors.secondary,
            height: 3,
            borderRadius: 4,
          },
        }}
        centered={centered}
      >
        {tabs.map((tab, index) => (
          <Tab
            disableRipple
            key={index}
            label={tab}
            style={{
              textTransform: "capitalize",
              color: textColor
                ? textColor
                : index === currentIndex
                ? activeColor ?? colors.text
                : faintColor ?? colors.faintText,
              fontWeight: "bold",
              fontSize: index === currentIndex ? 16 : 13,
              height: "100%",
              transition: "0.3s",
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
};
