import { useAtom, useAtomValue, useSetAtom } from "jotai";
import styled from "styled-components";
import { useWindowWidth } from "../../hooks/use-window-width";
import { Column } from "../../shared-components/column";
import { Row } from "../../shared-components/row";
import { colorAtom } from "../../state/shared-state-atoms/colors";
import { userProfileAtom } from "../../state/shared-state-atoms/user-profile-atom";

import { CgArrowLongLeft } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import { BORDER_RADIUS } from "../../core/measurements";

import { AiOutlineArrowRight } from "react-icons/ai";
import { BsSun } from "react-icons/bs";
import { GiMoon } from "react-icons/gi";
import { MdDriveFileRenameOutline, MdLanguage } from "react-icons/md";
import { TiDelete } from "react-icons/ti";

import { navigationTextAtom } from "../../shared-components/navigation-bar/navigation-text-atom";

import { useEffect } from "react";
import { useChangeLanguage } from "../../hooks/use-change-language";
import { useChangeTheme } from "../../hooks/use-change-theme";
import { tokenAtom } from "../../state/shared-state-atoms/token-atom";

import { getRequestWithToken } from "../../core/network-request-functions";
import { URLS } from "../../core/urls";
import { languageAtom } from "../../state/shared-state-atoms/language";
import { Footer } from "../../shared-components/footer";
import { fontWeight } from "@mui/system";
import toast from "react-hot-toast";

export function Profile() {
  const { windowWidth } = useWindowWidth();
  const [colors] = useAtom(colorAtom);

  const { handleLogOut, handleChangeAccountDetails, handleDeactivate } =
    useProfileContentsHook();
  const { handleChangeLanguage } = useChangeLanguage();
  const { theme, handleChangeTheme } = useChangeTheme();
  const { userDetailsAtom, handleBackButton } = useProfileHook();
  const navigationTextAtomValue = useAtomValue(navigationTextAtom);
  const language = useAtomValue(languageAtom);

  return (
    <Column>
      <Row
        style={{
          width: "100vw",
          minHeight: "100vh",
          flexDirection: windowWidth < 1000 ? "column" : "row",
          color: colors.text,
        }}
      >
        <div style={{ flexGrow: 1, height: "100vh", width: "100%" }}>
          <LeftContainer atomColors={colors}>
            <ProfileCircle atomColors={colors}>
              <ProfileCircleLabel>
                {`${userDetailsAtom.firstName[0]} . ${userDetailsAtom.lastName[0]}`}
              </ProfileCircleLabel>
            </ProfileCircle>
            <Column alignCenter gap={10} style={{ width: "fit-content" }}>
              <LabelBold large>{userDetailsAtom.fullName}</LabelBold>
              <LabelBold medium>
                <span style={{ color: colors.text }}>
                  {userDetailsAtom.phone
                    ? language.isEnglish
                      ? "( phone )"
                      : "(Namba ya simu)"
                    : ""}
                </span>{" "}
                {userDetailsAtom.phone}
              </LabelBold>
              <LabelBold medium>
                <span style={{ color: colors.text }}>
                  ( {language.isEnglish ? "email" : "Barua pepe"} )
                </span>{" "}
                {userDetailsAtom.email}
              </LabelBold>
            </Column>
            <Row
              alignCenter
              gap={10}
              onClick={handleBackButton}
              style={{
                width: "fit-content",
                position: "absolute",
                top: 20,
                left: 20,
                cursor: "pointer",
                backgroundColor: colors.background,
                padding: 10,
                borderRadius: BORDER_RADIUS,
              }}
            >
              <CgArrowLongLeft />
              <p>{language.isEnglish ? "Back home" : "Rudi nyumbani"}</p>
            </Row>
          </LeftContainer>
        </div>
        <div
          style={{
            backgroundColor: "tomato",
            flexGrow: 1,
            height: "100vh",
            width: "100%",
          }}
        >
          <RightContainer atomColors={colors}>
            <RightContainerItemRow
              onClick={handleChangeAccountDetails}
              Content={() => {
                return (
                  <Row alignCenter gap={10}>
                    <MdDriveFileRenameOutline style={{ fontSize: "x-large" }} />
                    <p>
                      {language.isEnglish
                        ? "Change account details"
                        : "Badili taarifa ya akaunti"}
                    </p>
                  </Row>
                );
              }}
            />

            <RightContainerItemRow
              onClick={handleChangeTheme}
              Content={() => {
                return (
                  <Row alignCenter gap={10}>
                    {theme.lightTheme ? (
                      <>
                        <GiMoon
                          style={{ fontSize: "x-large", cursor: "pointer" }}
                        />
                        <p>
                          {language.isEnglish
                            ? "Switch to dark theme"
                            : "Badili kwenda kwenye mandhari ya giza"}
                        </p>
                      </>
                    ) : (
                      <>
                        <BsSun
                          style={{ fontSize: "x-large", cursor: "pointer" }}
                        />
                        <p>
                          {language.isEnglish
                            ? "Switch to light theme"
                            : "Badili kwenda kwenye mandhari mwanga"}
                        </p>
                      </>
                    )}
                  </Row>
                );
              }}
            />

            <RightContainerItemRow
              onClick={handleChangeLanguage}
              Content={() => {
                return (
                  <Row alignCenter gap={10}>
                    <MdLanguage style={{ fontSize: "x-large" }} />
                    <p>
                      {navigationTextAtomValue.language === "KIS"
                        ? "Badili kwenda Kiingereza"
                        : "Change to Kiswahili"}
                    </p>
                  </Row>
                );
              }}
            />

            <RightContainerItemRow
              backgroundColor="tomato"
              color="#fff"
              bold
              onClick={handleLogOut}
              Content={() => {
                return (
                  <Row alignCenter gap={10}>
                    <p>{language.isEnglish ? "Log out" : "Ondoka"}</p>
                  </Row>
                );
              }}
            />
            <RightContainerBottomItems
              atomColors={colors}
              onClick={handleDeactivate}
              style={{
                backgroundColor: "#ff6b6b",
                color: "#fff",
                fontWeight: "bold",
              }}
            >
              <Row alignCenter gap={10}>
                <TiDelete style={{ fontSize: "x-large" }} />
                <p>
                  {navigationTextAtomValue.language === "KIS"
                    ? "Funga Akaunti"
                    : "Deactivate Account"}
                </p>
              </Row>

              <AiOutlineArrowRight />
            </RightContainerBottomItems>
          </RightContainer>
        </div>
      </Row>
      <Footer />
    </Column>
  );
}

const RightContainerItemRow = ({
  Content,
  onClick,
  backgroundColor,
  color,
  bold,
}) => {
  const [colors] = useAtom(colorAtom);
  return (
    <RightContainerItems
      atomColors={colors}
      onClick={onClick}
      style={{
        backgroundColor: backgroundColor ?? "",
        color: color ?? "",
        fontWeight: bold ? "bold" : "",
      }}
    >
      <Content />
      <AiOutlineArrowRight />
    </RightContainerItems>
  );
};

const LeftContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.atomColors.secondary};
  overflow-y: scroll;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  @media (max-width: 1000px) {
    position: static;
    width: 100%;
    min-height: 50%;
  }
`;

const RightContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.atomColors.background};
  overflow-y: scroll;
  padding-inline: 5%;
  padding-block: 5%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;

  @media (max-width: 1000px) {
    width: 100vw;
    margin-left: 0;
    overflow-y: hidden;
    justify-content: flex-start;
  }
`;

const RightContainerItems = styled.div`
  width: 100%;
  height: 60px;
  background-color: ${(props) => props.atomColors.secondary};
  border-radius: ${BORDER_RADIUS};
  cursor: pointer;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding-inline: 20px;

  &:hover {
    background-color: ${(props) => props.atomColors.faintText};
  }
`;

const RightContainerBottomItems = styled.div`
  width: 100%;
  height: 60px;
  background-color: ${(props) => props.atomColors.secondary};
  border-radius: ${BORDER_RADIUS};
  cursor: pointer;
  transition: 0.2s;
  align-items: center;

  display: flex;

  justify-content: center;
  margin-top: 100px;

  gap: 10px;

  padding-inline: 20px;

  &:hover {
    background-color: ${(props) => props.atomColors.faintText};
  }
`;

const ProfileCircle = styled.div`
  height: 200px;
  width: 200px;
  border-radius: 50%;
  background-color: ${(props) => props.atomColors.background};
  border: ${(props) => `2px solid ${props.atomColors.faintText}`};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 1000px) {
    height: 100px;
    width: 100px;
  }
`;

const ProfileCircleLabel = styled.p`
  font-size: xx-large;
  font-weight: bold;

  @media (max-width: 1000px) {
    font-size: x-large;
  }
`;

const LabelBold = styled.p`
  font-weight: bold;
  font-size: ${(props) => {
    if (props.large) return "xx-large";
    if (props.medium) return "large";
    return "medium";
  }};
  color: ${(props) => props.textColor ?? ""};
`;

const useProfileHook = () => {
  let navigate = useNavigate();

  const userDetailsAtom = useAtomValue(userProfileAtom);

  const handleBackButton = () => {
    navigate("/");
  };

  return { userDetailsAtom, handleBackButton };
};

const useProfileContentsHook = () => {
  let navigate = useNavigate();
  const settokenAtom = useSetAtom(tokenAtom);
  const setuserProfileAtomData = useSetAtom(userProfileAtom);
  const token = useAtomValue(tokenAtom);
  const language = useAtomValue(languageAtom);

  useEffect(() => {
    getRequestWithToken(token, URLS.user.profile)
      .then((response) => {})
      .catch((error) => console.log({ error }));

    return () => {};
  }, []);

  const handleLogOut = () => {
    settokenAtom("");
    setuserProfileAtomData((prev) => {
      return {
        ...prev,
        firstName: "",
        lastName: "",
        fullName: "",
        email: "",
        phone: "",
        userId: "",
      };
    });
    navigate("/", { replace: true });
  };

  const handleDeactivate = () => {
    getRequestWithToken(token, URLS.deactivateAccount)
      .then((response) => {
        console.log(response);
        settokenAtom("");
        setuserProfileAtomData((prev) => {
          return {
            ...prev,
            firstName: "",
            lastName: "",
            fullName: "",
            email: "",
            phone: "",
            userId: "",
          };
        });
        navigate("/", { replace: true });
        toast.success(
          language.isEnglish
            ? "Account is deactivated succesfully"
            : "Umefanikiwa kufunga akaunti yako"
        );
      })
      .catch((error) => {
        toast.success(
          language.isEnglish
            ? "ASPomething went wrong try again later"
            : "Kuna tatizo limetokea jaribu tena baadae"
        );
        console.log({ error });
      });
  };

  const handleChangeAccountDetails = () => {
    navigate("/profile/update");
  };

  return {
    handleLogOut,
    handleChangeAccountDetails,
    handleDeactivate,
  };
};
