import { useAtomValue } from "jotai";
import { Column } from "../../../../shared-components/column";
import Label from "../../../../shared-components/Label";
import { languageAtom } from "../../../../state/shared-state-atoms/language";
import { PitchLineupComponent } from "../../components/pitch-lineup-component";
import { PitchName } from "../../components/pitch-name";
import { SubstitutionsAndManger } from "../../components/substitution-and-manager";

export function LineUpTab({ _borderColor, team1, team2, colors }) {
    const language = useAtomValue(languageAtom)
    // return (
    //     <Column alignCenter justifyCenter style={ { padding: 50 } }>
    //         <Label text={ language.isEnglish ? "No data at the moment" : "Samahani, hakuna taarifa kwa sasa" } />
    //     </Column>
    // )
    return (
        <>
            <PitchLineupComponent _borderColor={ _borderColor } team1={ team1 } team2={ team2 } />
            {/* <SubstitutionsAndManger colors={ colors } team1={ team1 } team2={ team2 } /> */}
            <PitchName colors={ colors } />
        </>
    )
}