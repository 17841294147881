import { atom } from "jotai";
import { languageAtom } from "../../state/shared-state-atoms/language";

import { IoIosPerson } from 'react-icons/io'
import { BiFootball } from 'react-icons/bi'
import { IoIosPeople } from 'react-icons/io'
import { FcManager } from 'react-icons/fc'
import { AiFillStar } from 'react-icons/ai'
import { GiThreeFriends } from 'react-icons/gi'
import { BsBroadcastPin } from 'react-icons/bs'
import { MdEmojiPeople } from 'react-icons/md'
import { FcRules } from 'react-icons/fc'
import { MdFeedback } from 'react-icons/md'
import { TiInfoLarge } from 'react-icons/ti'
import { MdPrivacyTip } from 'react-icons/md'
import { FaHandshake } from 'react-icons/fa'

export const navigationTextAtom = atom(get =>
{
    const isEnglish = get(languageAtom).isEnglish

    return {
        profile: { label: isEnglish ? 'My Profile' : 'Wasifu wangu', icon: <IoIosPerson /> },

        favouriteClub: { label: isEnglish ? 'Change favourite club' : 'Badili timu pendwa', icon: <AiFillStar /> },

        clubs: { label: isEnglish ? 'Clubs' : 'Vilabu', icon: <BiFootball /> },

        players: { label: isEnglish ? 'Players' : 'Wachezaji', icon: <IoIosPeople /> },

        coaches: { label: isEnglish ? 'Managers' : 'Kocha', icon: <FcManager /> },

        partners: { label: isEnglish ? 'Partners' : 'Washirika', icon: <GiThreeFriends /> },

        broadcasters: { label: isEnglish ? 'Broadcasters' : 'Warusha matangazo', icon: <BsBroadcastPin /> },

        inviteFriends: { label: isEnglish ? 'Invite friends' : 'Shirikisha marafiki', icon: <MdEmojiPeople /> },

        privacyPolicy: { label: isEnglish ? 'Privacy policy' : 'Sera ya faragha', icon: <MdPrivacyTip /> },

        termsOfService: { label: isEnglish ? 'Terms of service' : 'Vigezo na masharti', icon: <FaHandshake /> },

        feedback: { label: isEnglish ? 'Feedback' : 'Mrejesho', icon: <MdFeedback /> },

        aboutUs: { label: isEnglish ? 'About us' : 'Kuhusu sisi', icon: <TiInfoLarge /> },

        language: isEnglish ? 'EN' : 'KIS',

        menuItems: [
            isEnglish ? 'Home' : 'Nyumbani',
            isEnglish ? 'League' : 'Ligi',
            isEnglish ? 'News' : 'Habari',
            isEnglish ? 'Stats' : 'Takwimu',
            isEnglish ? 'Fantasy' : 'Fantasy',
        ]
    }
})