import { atom } from "jotai";
import { themeAtom } from "./_theme";

export const colorAtom = atom((get) => {
  const isLight = get(themeAtom).lightTheme;

  return {
    background: isLight ? "#EFF2F7" : "#031D4D",
    primary: "#042559",
    secondary: isLight ? "#fff" : "#132F65",
    accent: isLight ? '#FAFBFC' : '#042559',
    text: isLight ? "#222" : "#fff",
    action: "#244D93",
    placeHolder: "#757572",
    textButton: "orange",
    faintText: isLight ? "#EDEEF1" : "#425984",
    tableHighlight: "#556990",
  };
});
