import { motion } from "framer-motion";
import styled from "styled-components";
import { Colors } from "../core/colors";

export const TextButton = styled(motion.button)`
  display: inline;
  font-size: medium;
  outline: none;
  border: none;
  background-color: transparent;
  color: ${(props) => props.atomColors.text ?? "grey"};
  cursor: pointer;
`;
