import styled from 'styled-components'

const IconBox = styled.div`
    height: 40px;
    aspect-ratio: 1;
    border-radius: 50%;
    background-color: #fff;
    color: #212121;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export default IconBox