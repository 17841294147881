import React from "react";

const EnglishPolicy = () => {
  return (
    <div>
      <h2 className=" flex  mb-1">
        <strong>Data privacy statement</strong>
      </h2>
      <p>
        <span className=" text-[#999999] text-sm">
          National Bank of Commerce Limited is committed to keeping customers’
          information private. By 'our customers’ information' we mean any
          information about a customer that the customer or third parties
          provide to us.
        </span>
      </p>
      <h2 className=" flex  my-3">
        <strong>At NBC, we treat your personal information responsibly</strong>
      </h2>
      <ul>
        <li className=" mb-2">
          <span className=" text-[#999999] text-sm">
            National Bank of Commerce Limited, including all entities and
            subsidiaries, Registration Number 32700 (NBC, it, we, us or our) has
            its head office at NBC House, Sokoine Drive/Azikiwe Street, P. O.
            Box 1863, Dar Es Salaam, Tanzania.
          </span>
        </li>
        <li className=" mb-2">
          <span className=" text-[#999999] text-sm">
            NBC promises to treat all your personal information carefully and
            responsibly.
          </span>
        </li>
        <li className=" mb-2">
          <span className=" text-[#999999] text-sm">
            Personal information includes any information that lets NBC identify
            you as a unique individual, such as your name/s and surname combined
            with your physical address, contact details and/or passport/identity
            number.
          </span>
        </li>
        <li className=" mb-2">
          <span className=" text-[#999999] text-sm">
            Personal information also refers to the personal information that
            uniquely identifies a legal entity, such as the trading name of a
            company combined with the company registration number.
          </span>
        </li>
        <li className=" mb-2">
          <span className=" text-[#999999] text-sm">
            Special personal information includes that which details your race
            or ethnic origin, religious and philosophical beliefs, political
            persuasion, trade union membership, health or sex life, biometric
            information or any criminal behaviour which relates to alleged
            criminal offences or proceedings.
          </span>
        </li>
        <li className=" mb-2">
          <span className=" text-[#999999] text-sm">
            Personal information may be given to or collected by NBC in writing
            as part of a written application form, electronically (email),
            telephonically, online (NBC.co.tz) or via the NBC Banking App.
          </span>
        </li>
        <li className=" mb-2">
          <span className=" text-[#999999] text-sm">
            Processing of personal information includes any initial processing
            that NBC does when we first collect your personal information. It
            also includes any further and ongoing processing that NBC is allowed
            to carry out legitimately in terms of the reasons listed in
            paragraph 9 (below). The term ‘processing’ includes collecting,
            using, altering, merging, linking, organising, disseminating,
            storing, retrieving, disclosing, erasing, archiving, destroying or
            disposing of personal information.
          </span>
        </li>
      </ul>
      {/* second part */}
      <h2 className=" flex  my-3">
        <strong>
          Your personal information is an important part of NBC’s relationship
          with you
        </strong>
      </h2>
      <ul>
        <li className=" mb-2">
          <span className=" text-[#999999] text-sm">
            NBC will only collect and process your personal information for the
            reason you provided it to us, or to enable us to comply with the
            requirements of specific laws that we are governed by.
          </span>
        </li>
        <li className=" mb-2">
          <span className=" text-[#999999] text-sm">
            NBC may process your personal information to protect your or our
            legitimate interests. NBC will not collect and process personal
            information about you that we do not need for this purpose. The
            general purposes for which NBC collects and processes your personal
            information include, but are not limited to:
          </span>
        </li>
        <li className=" mb-2">
          <span className=" text-[#999999] text-sm">
            Creating a record of you on our system to verify your identity,
            provide you with the products and/or services you have applied for
            and then communicate with and keep you informed about these products
            and/or services;
          </span>
        </li>
        <li className=" mb-2">
          <span className=" text-[#999999] text-sm">
            Assessing whether you qualify for credit, or an increase or decrease
            of your credit limit;
          </span>
        </li>
        <li className=" mb-2">
          <span className=" text-[#999999] text-sm">
            Identifying you and verifying your physical address, source of
            income and similar information;
          </span>
        </li>
        <li className=" mb-2">
          <span className=" text-[#999999] text-sm">
            Assessing your personal financial circumstances and needs before
            providing advice to you;
          </span>
        </li>
        <li className=" mb-2">
          <span className=" text-[#999999] text-sm">
            Any purpose related to the prevention of financial crime, including
            sanctions screening, monitoring of anti-money laundering and any
            financing of terrorist activities;
          </span>
        </li>
        <li className=" mb-2">
          <span className=" text-[#999999] text-sm">
            Further processing for historical, statistical or research purposes
            where the outcomes will not be published in an identifiable format;
          </span>
        </li>
        <li className=" mb-2">
          <span className=" text-[#999999] text-sm">
            Providing income tax-related information to tax authorities;
          </span>
        </li>
        <li className=" mb-2">
          <span className=" text-[#999999] text-sm">
            For purposes relating to the sale or transfer of any of our
            businesses, legal entities or assets as part of corporate
            transactions;
          </span>
        </li>
        <li className=" mb-2">
          <span className=" text-[#999999] text-sm">
            Where you have applied for employment at NBC, we perform applicant
            screening and background checks;
          </span>
        </li>
        <li className=" mb-2">
          <span className=" text-[#999999] text-sm">
            Where you are a NBC employee (including contractors), we create an
            employment record of you on our system to facilitate continuous
            monitoring during your employment with us;
          </span>
        </li>
        <li className=" mb-2">
          <span className=" text-[#999999] text-sm">
            Where you are a NBC director, we create a record of you as a
            director on our system;
          </span>
        </li>
        <li className=" mb-2">
          <span className=" text-[#999999] text-sm">
            Where you’ve been identified as a next of kin by an employee or
            customer, we create a record of you on our system
          </span>
        </li>
        <li className=" mb-2">
          <span className=" text-[#999999] text-sm">
            Where you are a supplier to NBC, we process your personal
            information for due diligence, risk assessment, administrative and
            payment purposes.
          </span>
        </li>
        <li className=" mb-2">
          <span className=" text-[#999999] text-sm">
            Furthermore, NBC will not process your special personal information
            unless:
          </span>
        </li>
        <li className=" mb-1">
          <span className=" text-[#999999] text-sm">
            1 You have consented to NBC processing it;
          </span>
        </li>
        <li className=" mb-1">
          <span className=" text-[#999999] text-sm">
            2 It is necessary to exercise or defend a right or obligation in
            law;
          </span>
        </li>
        <li className=" mb-1">
          <span className=" text-[#999999] text-sm">
            3 It is necessary to comply with an international legal obligation
            of public interest;
          </span>
        </li>
        <li className=" mb-1">
          <span className=" text-[#999999] text-sm">
            4 It is for certain historical, research or statistical purposes
            that would not adversely affect your privacy;
          </span>
        </li>
        <li className=" mb-3">
          <span className=" text-[#999999] text-sm">
            5 You have deliberately made your information public.
          </span>
        </li>
        <li className=" mb-2">
          <span className=" text-[#999999] text-sm">
            There are some personal information fields that you have to fill in
            if you want NBC to provide you with your chosen product and/or
            service or onboard you as an employee, supplier, director or job
            applicant. This information can be provided in writing,
            electronically or telephonically, but it must be accurate and
            complete. These fields are indicated by an asterisk (or as otherwise
            indicated) on the respective forms/websites. If NBC does not receive
            the necessary personal information, we will not be able to continue
            with your application. If you are already a(n)
            customer/employee/supplier/director and NBC asks you for this
            information and you do not provide it, NBC will have to suspend the
            provision of the product and/or services for a period of time, or as
            the case may be, even terminate our relationship with you.
          </span>
        </li>
        <li className=" mb-2">
          <span className=" text-[#999999] text-sm">
            In most cases, personal information will be collected directly from
            you, but there may be other instances when NBC will collect personal
            information from other sources. These may include public records,
            places where you may already have made your personal information
            public (for example, on social media), credit bureaus, or
            individuals/directors whom you have appointed as your
            representative, where you are a corporate entity. NBC will only
            collect your personal information from other sources where we are
            legally entitled or obliged to do so, and you are entitled to ask
            NBC which sources they used to collect your personal information.
          </span>
        </li>
        <li className=" mb-2">
          <span className=" text-[#999999] text-sm">
            For the purposes outlined in paragraph 9 (above), NBC will, in most
            instances, collect and process your personal information internally.
            However, there are times when NBC needs to outsource these functions
            to third parties, either within NBC or external to NBC, including
            parties in other countries. Where your personal information is
            shared internally within NBC, such sharing will be carried out only
            for the purposes outlined in paragraph 9 (above). NBC may also need
            to share your personal information with external organisations, such
            as credit bureaus, tax authorities or other regulatory or industry
            bodies, so that we can meet our due diligence or regulatory
            requirements. We may need to share your personal information with
            our business partners or counter-parties, where we are involved in
            corporate transactions relating to the sale or transfer of any of
            our businesses, legal entities or assets. NBC will not share your
            personal information with third parties who do not need your
            personal information, or where NBC is not legally permitted to do
            so. When NBC decides to transfer your personal information to third
            parties, we will only provide it to organisations that have the same
            data privacy policies as NBC or those who are subject to laws
            relating to the processing of personal information that are similar
            to those that apply to NBC.
          </span>
        </li>
        <li className=" mb-2">
          <span className=" text-[#999999] text-sm">
            There may be instances where NBC will process your personal
            information through a secure automated tool, or perform profiling
            resulting in a decision that may affect you significantly. If you
            are unhappy about the outcome of such a decision, please contact:
          </span>
        </li>
        <li className=" mb-1">
          <span className=" text-[#999999] text-sm">
            1 your Local Customer Service centre (for customers);
          </span>
        </li>
        <li className=" mb-1">
          <span className=" text-[#999999] text-sm">
            2 your resourcing consultant (for job applicants);
          </span>
        </li>
        <li className=" mb-1">
          <span className=" text-[#999999] text-sm">
            3 your people business partner (for employees);
          </span>
        </li>
        <li className=" mb-1">
          <span className=" text-[#999999] text-sm">
            4 your supplier relationship manager (for suppliers);
          </span>
        </li>
        <li className=" mb-3">
          <span className=" text-[#999999] text-sm">
            5 NBC’s Secretariat office (for directors of NBC-owned companies)
          </span>
        </li>
      </ul>
      {/* Third part */}
      <h2 className=" flex  my-3">
        <strong>NBC respects your rights</strong>
      </h2>
      <ul>
        <li className=" mb-2">
          <span className=" text-[#999999] text-sm">
            As a customer, if you have opted out of receiving electronic
            marketing communications (on media such as SMS, email or automated
            calling machines), NBC will not market to you using electronic
            media. However, if you are receiving marketing via electronic media,
            you have the right to opt out at any time by following the
            instructions on the marketing material received. NBC will continue
            to market to you using telephonic means until such time you have
            opted out.
          </span>
        </li>
        <li className=" mb-2">
          <span className=" text-[#999999] text-sm">
            As a non-customer, if you would like to receive marketing
            information from NBC through electronic media, please submit your
            details to NBC in writing.
          </span>
        </li>
        <li className=" mb-2">
          <span className=" text-[#999999] text-sm">
            To the extent that local legislation permits or if you are a
            resident of the European Union or United Kingdom, you have the
            following rights regarding your personal information:
          </span>
        </li>
        <li className=" mb-1">
          <span className=" text-[#999999] text-sm">
            1 The right to access your personal information that we have on
            record, where applicable
          </span>
        </li>
        <li className=" mb-1">
          <span className=" text-[#999999] text-sm">
            2 You have the right to ask NBC to correct any of your personal
            information that is incorrect. These requests must be sent to us in
            writing using this form.
          </span>
        </li>
        <li className=" mb-1">
          <span className=" text-[#999999] text-sm">
            3 You can ask NBC to delete or destroy your personal information.
            You can also object to NBC processing your personal information.
            These requests must be sent to NBC in writing using this form.
            However, the result of such a request will be that NBC may have to
            suspend the provision of products and/or services for a period of
            time, or even terminate our relationship with you. NBC’s records are
            subject to regulatory retention periods, which means that NBC may
            not be able to delete or destroy your personal information
            immediately upon request.
          </span>
        </li>
        <li className=" mb-3">
          <span className=" text-[#999999] text-sm">
            4 You may also ask NBC to port your personal information to another
            party in terms of applicable European Union and United Kingdom data
            privacy legislation. These requests must be sent to us in writing
            using this form.
          </span>
        </li>
        <li className=" mb-2">
          <span className=" text-[#999999] text-sm">
            If you have a complaint relating to the protection of your personal
            information, including the way in which it has been collected or
            processed by NBC, please contact us using the local contact details
            as listed below. If you have not had your complaint dealt with
            satisfactorily, you may lodge a complaint with your local privacy
            regulator in terms of applicable privacy laws.
          </span>
        </li>
      </ul>
      <h2 className=" flex  mb-1">
        <strong>Right to amend this privacy statement</strong>
      </h2>
      <p>
        <span className=" text-[#999999] text-sm">
          NBC reserves the right to change this statement at any time. All
          changes to this statement will be posted on the website. Unless
          otherwise stated, the current version shall supersede and replace all
          previous versions of this statement.
        </span>
      </p>
      <h2 className=" flex  mb-1">
        <strong>Contact details</strong>
      </h2>
      <ul>
        <li className=" mb-2">
          <span className=" text-[#999999] text-sm">
            Tanzania: +255 (0) 768 984 000 or +255 (0) 222 193 000 or 0800711177
            (Free) or email contact.centre@nbc.co.tz
          </span>
        </li>
        <li className=" mb-2">
          <span className=" text-[#999999] text-sm">
            United Kingdom: email dataprivacy@absa.co.za
          </span>
        </li>
      </ul>
    </div>
  );
};

export default EnglishPolicy;
