import { useAtomValue } from "jotai";
import React, { useState } from "react";
import { AiOutlineLeft } from "react-icons/ai";
import { MdOutlineRefresh } from "react-icons/md";
import ReactPlayer from "react-player";
import { Column } from "../../../../shared-components/column";
import Label from "../../../../shared-components/Label";
import ModalPopUp from "../../../../shared-components/ModalPopUp";
import { Row } from "../../../../shared-components/row";
import { colorAtom } from "../../../../state/shared-state-atoms/colors";
import { languageAtom } from "../../../../state/shared-state-atoms/language";
import { themeAtom } from "../../../../state/shared-state-atoms/_theme";
import { Grid } from "./components/grid";
import { NewsFullContainer } from "./components/news-full-container";
import { useNewsFullHook } from "./news-full-hook";
import moment from "moment";
import { useWindowWidth } from "../../../../hooks/use-window-width";
import styled from "@emotion/styled";
import { FiClock } from "react-icons/fi";
import { NEW_BASE_URL } from "../../../../core/urls";

export function NewsFull() {
  const newsFullHook = useNewsFullHook();
  const { windowWidth } = useWindowWidth();
  const colors = useAtomValue(colorAtom);
  const language = useAtomValue(languageAtom);
  const theme = useAtomValue(themeAtom);
  const [selectedArticle, setSelectedArticle] = useState(undefined);
  const [open, setOpen] = useState(false);
  const tabList = ["All", "Trending", "Simba sc", "Select Team"];

  const { news_list } = newsFullHook;
  // console.log(news_list,'news')

  const handleOpen = (index) => {
    setOpen(true);
    setSelectedArticle(index);
  };
  const handleClose = () => {
    setOpen(false);
    setSelectedArticle(undefined);
  };
  const Tabs = ({ value }) => {
    return (
      <div
        style={{
          width: windowWidth,
          backgroundColor: colors.secondary,
          // height: 30,
          userSelect: "none",
          overflowY: "scroll",
          boxShadow: "rgba(0, 0, 0, 0.2) 0px 5px 15px",
          marginTop: -10,
          marginBottom: 10,
          padding: 10,
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        {tabList?.map((tab, idx) => {
          return (
            <div
              style={{
                borderRadius: 20,
                paddingBlock: 5,
                borderWidth: 1,
                borderColor: "grey",
                paddingInline: 10,
                marginInline: 5,
              }}
            >
              <p
                style={{
                  fontSize: 12,
                  fontWeight: "bold",
                }}
              >
                {tab}
              </p>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <NewsFullContainer atomColors={colors}>
      <Tabs />

      {newsFullHook.newsLoading ? (
        <Column alignCenter justifyCenter style={{ padding: 50 }}>
          <Label
            text={
              language.isEnglish ? "please wait ..." : "tafadhali subiri..."
            }
          />
        </Column>
      ) : (
        <>
          {/* <Row
            gap={10}
            alignCenter
            justifyCenter
            onClick={!newsFullHook.newsLoading && newsFullHook.refreshNews}
            style={{
              cursor: "pointer",
              backgroundColor: theme.lightTheme ? colors.primary : "#fff",
              borderRadius: 10,
              width: "fit-content",
              paddingInline: 15,
              paddingBlock: 5,
              marginBottom: 10,
              color: theme.lightTheme ? "#fff" : "#212121",
            }}
          >
            <MdOutlineRefresh
              style={{ color: theme.lightTheme ? "#fff" : "#212121" }}
            />
            <Label
              noWrap
              small
              text={language.isEnglish ? "Refresh" : "Angalia habari mpya"}
            />
          </Row> */}

          <ModalPopUp
            open={open}
            handleClose={handleClose}
            height="98%"
            width="98%"
            padding={20}
            backgroundColor={colors.secondary}
            color={colors.text}
            children={
              <NewsArticle
                article={newsFullHook.news_list[selectedArticle] ?? null}
                handleClose={handleClose}
              />
            }
          />
          <Column
            gap={10}
            paddingAll={50}
            style={{
              width: windowWidth * 0.9,
              // alignItems: "center",
              userSelect: "none",
              overflowY: "scroll",
              boxShadow: "rgba(0, 0, 0, 0.2) 0px 5px 15px",
              backgroundColor: colors.secondary,
              borderRadius: 20,
            }}
          >
            <div className=" flex flex-row">
              <div className=" bg-red-600 rounded-xl w-4 h-4 ">
                <FiClock color="white" />
              </div>
              <p className=" font-bold ml-3 -mt-1">Latest News</p>
            </div>

            <Grid>
              {newsFullHook.news_list.map((article, index) => {
                return (
                  // <div
                  //   key={index}
                  //   onClick={() => handleOpen(index)}
                  //   // style={{ backgroundColor: colors.secondary }}
                  //   className={`flex flex-col space-y-3  cursor-pointer`}
                  // >
                  //   {/* <div className="flex items-center space-x-2 p-3 pb-0">
                  //     <div className="flex flex-col space-y-1">
                  //       <Label text={article.category} noWrap bold />
                  //       <Label
                  //         text={moment(article.published_date).format(
                  //           "dddd MMM DD, YYYY"
                  //         )}
                  //         noWrap
                  //         small
                  //       />
                  //     </div>
                  //   </div> */}
                  //   <img
                  //     src={`https://api-v2.ligi.co.tz${article.image_url}`}
                  //     alt=""
                  //     className="h-[120px] object-cover"
                  //   />
                  //   <div className="p-3 pt-0">
                  //     <Label text={article.title} bold large />
                  //   </div>
                  // </div>

                  <MainNews
                    handle={() => handleOpen(index)}
                    key={index}
                    url={article.image_url}
                    index={index}
                    title={article.title}
                    description={article.description}
                    source={article.category}
                    author={article.author ?? "author"}
                    publishedAt={moment(article.published_date).format(
                      "dddd MMM DD, YYYY"
                    )}
                  />
                );
              })}
            </Grid>
          </Column>
        </>
      )}
    </NewsFullContainer>
  );
}

export const NewsArticle = ({ article, handleClose }) => {
  const colors = useAtomValue(colorAtom);
  return (
    <div className="w-full flex flex-col space-y-2 items-center px-2">
      <div className="w-full max-w-[700px] flex flex-col items-center space-y-2">
        <button
          onClick={handleClose}
          className="self-start text-sm flex items-center space-x-1 font-bold mb-4 "
        >
          <AiOutlineLeft />
          <Label text="go back" small noWrap />
        </button>
      </div>

      <div className="w-full max-w-[700px] flex flex-col items-center space-y-2">
        <p className="text-left w-full text-lg md:text-2xl font-bold">
          {article.tile}
        </p>
        <img
          src={`${NEW_BASE_URL}${article.image_url}`}
          alt=""
          className="w-full h-[70vh] object-cover"
        />

        <div className="w-full flex items-center space-x-2">
          <img
            src={`${NEW_BASE_URL}${article.image_url}`}
            alt=""
            className="h-[40px] aspect-square object-cover "
          />
          <div className="flex flex-col space-y-1">
            <Label text={article.category} noWrap bold />
            <Label
              text={moment(article.published_date).format("dddd MMM DD, YYYY")}
              noWrap
              small
            />
          </div>
        </div>
      </div>

      <div className="w-full max-w-[700px] flex flex-col space-y-4">
        <p>{article.description}</p>
      </div>
    </div>
  );
};

const MainNews = ({
  url,
  index,
  title,
  description,
  source,
  author,
  publishedAt,
  handle,
}) => {
  const colors = useAtomValue(colorAtom);
  // const handle = () =>{
  //   handleOpen(index)
  // }
  return (
    <>
      <MainNewsContainer atomColors={colors} onClick={() => handle()}>
        <MainNewsImage src={`${NEW_BASE_URL}${url}`} alt="" />
        <Column gap={2} style={{ paddingInline: 5 }}>
          <MainNewsTitle atomColors={colors}> {title} </MainNewsTitle>
          <Row alignCenter style={{ fontSize: 15, flexWrap: "wrap" }}>
            <p style={{ color: colors.text, opacity: 0.6, fontSize: 13 }}>
              {publishedAt}
            </p>
          </Row>
        </Column>
      </MainNewsContainer>
    </>
  );
};

const MainNewsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  padding-bottom: 20px;

  color: ${(props) => props.atomColors.text};
  cursor: pointer;

  transition: 0.4s;

  &:hover {
    background-color: ${(props) => props.atomColors.background};
    filter: grayscale();
  }
`;

const MainNewsImage = styled.img`
  width: 100%;
  aspect-ratio: 16 / 9;
  object-fit: cover;
  border-radius: 10;
  cursor: pointer;
  transition: 0.4s;

  &:hover {
    filter: grayscale();
  }
`;
const MainNewsTitle = styled.h2`
  margin-top: 10;
  margin-bottom: 5px;
  font-weight: bold;
  color: ${(props) => props.atomColors.text};
`;
