import { Column } from "../../../shared-components/column";
import { Row } from "../../../shared-components/row";
import { ScrollableTabs } from "../../../shared-components/scrollable-tabs";
import { FaChevronDown } from "react-icons/fa";

export function TabSwitcherComponent({
  fullWwidth = false,
  selectedTabIndex,
  handleChange,
  tabs,
  colors,
  background,
  centered,
  textColor,
  border,
  indicatorColor = "transparent",
  language,
}) {
  console.log("check it", selectedTabIndex);
  return (
    <Row
      widthPercent={95}
      justifyBetween
      style={{
        padding: 0,
        borderRadius: 10,
        // overflow: "clip",
        border: border ?? "",
        marginTop: 10,

        backgroundColor: colors.secondary,
        borderRadius: 10,
        boxShadow: "rgba(0, 0, 0, 0.2) 0px 5px 15px",
      }}
    >
      <div
        style={{
          // marginTop: -10,
          // marginBottom: 10,
          padding: 10,
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
        }}
      >
        {tabs?.map((tab, idx) => {
          return (
            <div
              onClick={() => handleChange(idx)}
              style={{
                borderRadius: 20,
                paddingBlock: 5,
                borderWidth: 1,
                borderColor: "grey",
                paddingInline: 10,
                marginInline: 5,
                backgroundColor:
                  selectedTabIndex === idx ? colors.primary : null,
                color: selectedTabIndex === idx ? "#FFFF" : null,
                fontSize: 12,
                fontWeight: "bold",
              }}
            >
              {/* <p
                style={{
                  fontSize: 12,
                  fontWeight: "bold",
                  color: selectedTabIndex === idx ? "#FFFF" : null,
                }}
              > */}
              {tab}
              {/* </p> */}
            </div>
          );
        })}
      </div>
      <div
        style={{
          width: "fit-content",
          borderRadius: 10,
          fontSize: 12,
          display: "flex",
          flexDirection: "row",
          paddingBlock: 10,
          paddingInline: 15,
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {language.isEnglish ? (
          <p>
            Season{"  "}
            <span style={{ fontWeight: "bold" }}>2022-2023</span>
          </p>
        ) : (
          <p>
            Msimu wa <span style={{ fontWeight: "bold" }}>2022-2023</span>
          </p>
        )}
        <FaChevronDown />
      </div>

      {/* <ScrollableTabs
        currentIndex={selectedTabIndex}
        handleChange={handleChange}
        tabs={tabs}
        backgroundColor={background ? background : colors.background}
        indicatorColor={indicatorColor}
        centered={centered}
        textColor={textColor}
        fullWwidth={fullWwidth}
      /> */}
    </Row>
  );
}
