import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { URLS } from "../../core/urls";
import apiCall from "../../functions/api_call";
import findCountry from "../../functions/find-country";

export default function usePlayerProfile() {
  const { player_id } = useParams();
  const [loading, setLoading] = useState(false);
  const [playerInfo, setPlayerInfo] = useState({
    name: "",
    pic: "",
    country: "",
    dob: "",
    clubName: "",
    clubLogo: "",
  });
  const [tableHeaders, setTableHeaders] = useState([]);
  const [playerHistory, setPlayerHistory] = useState([]);

  useEffect(() => {
    setLoading(true);
    let promises = [apiCall({ url: URLS.allPlayers }), apiCall({ url: `${URLS.playerHistory}/${player_id}` })];

    Promise.allSettled(promises)
      .then((results) => {
        let player = results[0].value.data.find((x) => x._id === player_id);
        setPlayerInfo((prev) => {
          prev = {
            ...prev,
            name: player.name,
            pic: player.pic,
            country: player.country ?? findCountry(player.country_code ? player.country_code : player.country),
            dob: player.dob,
            clubName: player.current_club.name,
            clubLogo: player.current_club.logo,
          };
          return prev;
        });

        if (results[1].status === "fulfilled") {
          let playerHistoryData = results[1].value.data;
          let headers = Object.keys(playerHistoryData.stats[0]);
          let modifiedHeaders = [];
          headers.forEach((header) => {
            if (header !== "_id") modifiedHeaders.push(header.replace("_", " "));
          });

          setTableHeaders((prev) => {
            prev = modifiedHeaders.sort((a, b) => (a > b ? 1 : -1)).filter((header) => header !== "market value");
            return prev;
          });

          setPlayerHistory((prev) => {
            prev = playerHistoryData.stats.sort((a, b) => (b.season > a.season ? 1 : -1));
            return prev;
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  return { loading, playerInfo, tableHeaders, playerHistory };
}
