import { useWindowWidth } from "../../../../hooks/use-window-width";
import { Column } from "../../../../shared-components/column";
import { Row } from "../../../../shared-components/row";
import { News } from "../news";
import { LeagueTable2 } from "../league-tableV2";
import { MatchDayV2 } from "../match-day-v2";
import { BORDER_RADIUS } from "../../../../core/measurements";
import StatsInviter from "./components/stats-inviter/StatsInviter";
import useScrollToTop from "../../../../hooks/scroll-to-top";
import PlayerStats from "./components/player-stats/PlayerStats";
import TeamStats from "./components/team-stats/TeamStats";
import PlayerOfTheMonth from "./components/player-of-the-month/PlayOfTheMonth";
import ManagerOfTheMonth from "./components/manager-of-the-month/ManagerOfTheMonth";
import { useAtomValue } from "jotai";
import { colorAtom } from "../../../../state/shared-state-atoms/colors";
import { BiArrowToTop } from "react-icons/bi";
import { themeAtom } from "../../../../state/shared-state-atoms/_theme";
import { useEffect, useState } from "react";
import useHomeTab from "./useHomeTab";

export function HomeTab() {
  const hook = useHomeTab();
  const { windowWidth } = useWindowWidth();
  useScrollToTop();

  return (
    <Column style={{ paddingInline: "2%", alignItems: "center" }}>
      {windowWidth > 1000 ? (
        <>
          <Row
            gap={20}
            style={{
              width: "100%",
              maxWidth: 2400,
              padding: 10,
              paddingTop: 30,
              marginBottom: 150,
              marginTop: 50,
              alignItems: "flex-start",
              userSelect: "none",
              overflowY: "scroll",
              height: 900,
              boxShadow: "rgba(0, 0, 0, 0.2) 0px 5px 15px",
              borderRadius: BORDER_RADIUS,
              overflow: "clip",
              borderColor: "white",
            }}
          >

            <div style={{ minWidth: 250, width: "100%", maxWidth: 600 }}>
                {/* The News component is rendered here with a height of 800 */}
              <News height={800} />
            </div>

            <MatchDayV2 height={800} />
            <LeagueTable2 height={860} />
          </Row>
        </>
      ) : (
        <>
          <Column
            gap={50}
            alignCenter
            style={{ width: "100%", maxWidth: 1700, margin: "50px 0" }}
          >
            <MatchDayV2 height={800} />
            <LeagueTable2 height={800} />
            <News height={800} />
          </Column>
        </>
      )}

      <PlayerOfTheMonth
        monthtostring={hook.monthtostring}
        playersAwards={hook.awards.filter((award) => award.kind === "player")}
      />

      <ManagerOfTheMonth
        monthtostring={hook.monthtostring}
        managersAwards={hook.awards.filter((award) => award.kind === "manager")}
      />

      <PlayerStats />
      <TeamStats />
      <ScrollToTopButton />
    </Column>
  );
}

function ScrollToTopButton() {
  const colors = useAtomValue(colorAtom);
  const theme = useAtomValue(themeAtom);

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      style={{
        position: "fixed",
        bottom: "5%",
        zIndex: 10,
        right: "1%",
        height: 50,
        aspectRatio: 1,
        borderRadius: "50%",
        backgroundColor: colors.text,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: !theme.lightTheme ? "#212121" : "#fff",
        fontSize: "large",
        cursor: "pointer",
        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        display: scrollPosition < 500 ? "none" : "flex",
      }}
      onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
    >
      <BiArrowToTop />
    </div>
  );
}
