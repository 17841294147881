import { useAtomValue } from "jotai";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { BORDER_RADIUS } from "../../../../core/measurements";
import { URLS } from "../../../../core/urls";
import apiCall from "../../../../functions/api_call";
import { useWindowWidth } from "../../../../hooks/use-window-width";
import { Column } from "../../../../shared-components/column";
import Label from "../../../../shared-components/Label";
import { LogoImage } from "../../../../shared-components/logo-image";
import { Row } from "../../../../shared-components/row";
import { colorAtom } from "../../../../state/shared-state-atoms/colors";
import { languageAtom } from "../../../../state/shared-state-atoms/language";
import { matchStatsAtom } from "../../match-stats-atom";

export default function H2H() {
  let { match_id } = useParams();
  const { h2hStats, h2hLoading } = useH2H(match_id);
  const colors = useAtomValue(colorAtom);
  const language = useAtomValue(languageAtom);

  if (h2hLoading) {
    return (
      <Column alignCenter justifyCenter style={{ padding: 50 }}>
        <Label text={language.isEnglish ? "loading ..." : "tafadhali subiri ..."} />
      </Column>
    );
  }

  return (
    <Column style={{ padding: 20 }}>
      {h2hStats.length === 0 ? (
        <>
          <Column alignCenter justifyCenter style={{ padding: 50 }}>
            <Label text={language.isEnglish ? "no h2h data to display" : "Samahani, hakuna taarifa za kuonesha"} />
          </Column>
        </>
      ) : (
        <>
          {h2hStats.map((matchStat, index) => (
            <Column
              key={index}
              alignCenter
              gap={10}
              style={{
                backgroundColor: colors.background,
                color: colors.text,
                borderRadius: BORDER_RADIUS,
                overflow: "clip",
                paddingBlock: 20,
                position: "relative",
              }}
            >
              <div style={{ position: "absolute", left: 20, top: 20 }}>
                <Label small xBold text={matchStat.status === "fin" ? "FT" : ""} />
              </div>

              <Label noWrap xBold small text={new Date(`${matchStat.playing_date.year}/${matchStat.playing_date.month}/${matchStat.playing_date.day}`).toDateString()} />

              <Row alignCenter justifyCenter gap={20} style={{ maxWidth: 500 }}>
                <TeamItem name={matchStat.home_team.name} img={matchStat.home_team.logo} isHome />
                <Label xBold noWrap text={`${matchStat.matchResults.home.goals} - ${matchStat.matchResults.away.goals}`} />
                <TeamItem name={matchStat.away_team.name} img={matchStat.away_team.logo} />
              </Row>

              <Label noWrap xBold small text={matchStat.ground} />
            </Column>
          ))}
        </>
      )}
    </Column>
  );
}

const useH2H = (match_id) => {
  const [h2hStats, setH2HStats] = useState([]);
  const [h2hLoading, setH2HLoading] = useState(false);
  useEffect(() => {
    setH2HLoading(true);
    apiCall({ url: `${URLS.matchStatistics}/${match_id}` })
      .then((resp) => {
        let home_team_id = resp.data[0].team;
        let away_team_id = resp.data[1].team;

        apiCall({ url: `${URLS.matchStatisticsH2H}/${home_team_id}/${away_team_id}` })
          .then((res) => {
            let _promises = res.data.map((dt) => apiCall({ url: `${URLS.matchStatistics}/${dt._id}` }));
            Promise.allSettled(_promises)
              .then((results) => {
                let h2hData = results.map((result, index) => {
                  let arr = res.data.at(index);
                  let obj = {
                    ...arr,
                    matchResults: {
                      home: { ...result.value.data[0] },
                      away: { ...result.value.data[1] },
                    },
                  };
                  return obj;
                });
                setH2HStats(h2hData);
              })
              .finally(() => setH2HLoading(false));
          })
          .finally(() => setH2HLoading(false));
      })
      .finally(() => setH2HLoading(false));
  }, []);

  return { h2hStats, h2hLoading };
};

const TeamLogo = ({ img }) => {
  return (
    <LogoImage
      src={img}
      alt={""}
      style={{
        height: 60,
        borderRadius: 10,
        margin: 0,
        padding: 0,
        boxSizing: "border-box",
      }}
    />
  );
};

const TeamItem = ({ name, img, isHome = false }) => {
  const { windowWidth } = useWindowWidth();
  return (
    <>
      {windowWidth < 500 ? (
        <Column alignCenter gap={5}>
          <TeamLogo img={img} />
          <Label noWrap small text={name} />
        </Column>
      ) : (
        <Row alignCenter gap={10} style={{ width: "50%" }}>
          {isHome ? (
            <Row alignCenter gap={10} style={{ justifyContent: "flex-end" }}>
              <Label noWrap small text={name} />
              <TeamLogo img={img} />
            </Row>
          ) : (
            <>
              <TeamLogo img={img} />
              <Label noWrap small text={name} />
            </>
          )}
        </Row>
      )}
    </>
  );
};
