import { useAtomValue } from 'jotai'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { URLS } from '../../core/urls'
import apiCall from '../../functions/api_call'
import { colorAtom } from '../../state/shared-state-atoms/colors'

export function usePartnersHook() {
    let navigate = useNavigate()
    const colors = useAtomValue(colorAtom)

    const [partnersList, setPartnersList] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const api_call = async () => {
            try {
                setLoading(true)
                const response = await apiCall({ url: URLS.partners })
                if (response.status === 200) {
                    setPartnersList(prev => { prev = response.data; return prev })
                }
                setLoading(false)
            } catch (error) {
                console.log("error getting partners", error)
                setLoading(false)
            }
        }
        api_call()
    }, [])

    return { colors, navigate, loading, partnersList }
}