import styled from "styled-components";

const ManagerCropped = styled.img`
  width: 150px;
  object-fit: cover;

  position: absolute;
  right: 0;
  top: 30%;
  transform: translateY(-30%);
`;

export default ManagerCropped;
