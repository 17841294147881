import styled from 'styled-components'

const PlayerStatsContainer = styled.div`
    height: 100%;
    width: 100%;
    background-color: ${ props => props.atomColors.secondary };
    margin-bottom: 100px;
    padding-block: 40px;
    padding-inline: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
`

export default PlayerStatsContainer