import { _routeNames } from "../../../app/routes";

export function routeNavigator(index, navigate, redirect) {
  switch (index) {
    case 0:
      navigate(_routeNames.root.index);
      break;

    case 1:
      navigate(`/${_routeNames.root.league.index}`);
      break;

    case 2:
      navigate(_routeNames.root.news);
      break;

    case 3:
      navigate(_routeNames.root.stats.index);
      break;

    case 4:
      navigate(_routeNames.root.fantasy);
      break;

    default:
      navigate("/");
      break;
  }
}
