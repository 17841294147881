import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { colorAtom } from "../../state/shared-state-atoms/colors";

import Pusher from 'pusher-js';
import awayTeamLogo from "../../assets/teams/az.png";
import homeTeamLogo from "../../assets/teams/sm.png";
import { URLS } from "../../core/urls";
import apiCall from "../../functions/api_call";
import { matchStatsAtom } from "./match-stats-atom";
import { languageAtom } from "../../state/shared-state-atoms/language";
import toast from 'react-hot-toast'
import axios from "axios"

const _matchTime = '52"';

export const YELLOW = "yellow";
export const RED = "red";

const _lineup1 = {
  teamName: "Simba SC",
  teamLogo: homeTeamLogo,
  score: 5,
  formation: "3:4:3",
  firstEleven: [
    { jerseyNumber: 28, name: "A. Manula", position: "GK", card: YELLOW },
    { jerseyNumber: 15, name: "M. H. Mohamed", position: "CB", card: YELLOW },
    { jerseyNumber: 16, name: "J. Onyango", position: "CB", card: YELLOW },
    { jerseyNumber: 29, name: "H. I. Baka", position: "CB", substitutedAt: 35 },
    { jerseyNumber: 12, name: "S. Kapombe", position: "CM" },
    { jerseyNumber: 19, name: "M. Yassin", position: "CM", substitutedAt: 85 },
    { jerseyNumber: 8, name: "L. Bwalya", position: "CM" },
    { jerseyNumber: 20, name: "J. Kunde", position: "CM", card: YELLOW },
    { jerseyNumber: 17, name: "P. O. Sakho", position: "RF", substitutedAt: 56 },
    { jerseyNumber: 14, name: "M. Kagere", position: "CF", goals: 3, assists: 1, card: YELLOW, substitutedAt: 71 },
    { jerseyNumber: 38, name: "K. Denis", position: "LF", assists: 1, card: YELLOW },
  ],
  goalScorers: [
    { jerseyNumber: 14, name: "M. Kagere", position: "CAM", time: [17, 25, 33] },
    { jerseyNumber: 38, name: "K. Denis", position: "LF", time: [65, 71] },
  ],
  substitutes: [
    { jerseyNumber: 29, name: "H. I. Baka", position: "CB" },
    { jerseyNumber: 12, name: "S. Kapombe", position: "CM" },
    { jerseyNumber: 19, name: "M. Yassin", position: "CM", substitutedAt: 56 },
    { jerseyNumber: 8, name: "L. Bwalya", position: "CM" },
    { jerseyNumber: 20, name: "J. Kunde", position: "CM", substitutedAt: 85 },
    { jerseyNumber: 17, name: "P. O. Sakho", position: "RF" },
    { jerseyNumber: 14, name: "M. Kagere", position: "CF" },
    { jerseyNumber: 38, name: "K. Denis", position: "LF", substitutedAt: 71 },
    { jerseyNumber: 29, name: "H. I. Baka", position: "CB", substitutedAt: 35 },
  ],
};

const _lineup2 = {
  teamName: "Azam FC",
  teamLogo: awayTeamLogo,
  score: 1,
  formation: "4:4:2",
  firstEleven: [
    { jerseyNumber: 28, name: "A. Manula", position: "GK", card: YELLOW },
    { jerseyNumber: 15, name: "M. H. Mohamed", position: "RB", substitutedAt: 52 },
    { jerseyNumber: 16, name: "J. Onyango", position: "CB" },
    { jerseyNumber: 29, name: "H. I. Baka", position: "CB", card: RED },
    { jerseyNumber: 12, name: "S. Kapombe", position: "LB", substitutedAt: 69 },
    { jerseyNumber: 19, name: "M. Yassin", position: "CM" },
    { jerseyNumber: 8, name: "L. Bwalya", position: "CM", substitutedAt: 74, card: YELLOW },
    { jerseyNumber: 20, name: "J. Kunde", position: "CM", card: YELLOW },
    { jerseyNumber: 17, name: "P. O. Sakho", position: "RF", card: YELLOW },
    { jerseyNumber: 14, name: "M. Kagere", position: "CM", substitutedAt: 86 },
    { jerseyNumber: 38, name: "K. Denis", position: "LF", card: YELLOW, goals: 1 },
  ],
  goalScorers: [{ jerseyNumber: 38, name: "K. Denis", position: "LF", time: [69] }],
  substitutes: [
    { jerseyNumber: 29, name: "H. I. Baka", position: "CB" },
    { jerseyNumber: 12, name: "S. Kapombe", position: "CM" },
    { jerseyNumber: 19, name: "M. Yassin", position: "CM", substitutedAt: 52 },
    { jerseyNumber: 8, name: "L. Bwalya", position: "CM" },
    { jerseyNumber: 20, name: "J. Kunde", position: "CM" },
    { jerseyNumber: 17, name: "P. O. Sakho", position: "RF", substitutedAt: 74 },
    { jerseyNumber: 14, name: "M. Kagere", position: "CF" },
  ],
};

export const useMatchDayStatsHook = () => {
  const [ team1, setteam1 ] = useState(null)
  const [ team2, setteam2 ] = useState(null)
  const [ homeLineUp, setHomeLineUp ] = useState(null)
  const [ awayLineUp, setAwayLineUp ] = useState(null)
  const [ homeTeam, setHomeTeam ] = useState(null)
  const [ awayTeam, setAwayTeam ] = useState(null)
  const [ homeTeamSorted, setHomeTeamSorted ] = useState(null)
  const [ awayTeamSorted, setAwayTeamSorted ] = useState(null)
  let { match_id } = useParams()

  const matchStatsAtomValue = useAtomValue(matchStatsAtom);

  const [matchStats, setmatchStats] = useState([]);
  const [loading, setloading] = useState(false);

  let startPusher

  const getMatchDetails = async() => {
    try { 
        const response = await axios.get(`https://api-v2.ligi.co.tz/match/${match_id}`)
        if(response.statusText === "OK") {
          setHomeTeam(response.data.home_team)
          setAwayTeam(response.data.away_team)
          listLineUps()
        }
  
        if(!response.statusText) {
           
        }
    } catch(err) {
        toast.error('Error while listing  match details')
    }
 }

useEffect(()=> {
  getMatchDetails()
},[match_id])

  useEffect(() => {
   

    //listLineUps()
   // getLineUp()
    (async () => {
      let _matchStats = []
      const apiRequest = async () => {
        setloading(true);
        try {
          const response = await apiCall({ url: `${URLS.matchStatistics}/${match_id}` })
          if (response.status === 200) {
            setmatchStats(response.data);
            _matchStats = response.data;
          } else setmatchStats([]);
          startPusher = true
          setloading(false);
        } catch (error) {
          startPusher = false
          console.log("error getting match stats", error)
          setloading(false);
        }

      }
      await apiRequest()

      const pusherLogic = async () => {
       // Pusher.logToConsole = true;
        const pusher = new Pusher(
          "d6e8aaf931dd9d014994",
          {
            cluster: "ap2",
            forceTLS: true,
            channelAuthorization: {
              endpoint: "http://localhost:5000/pusher/auth",
            }
          }
        );

        const channel = pusher.subscribe("stats");
        channel.bind("get-stats", (data) => {
          let updatedTeam = _matchStats.find(matchStat => matchStat.team === data.message.team)
          let indexOfUpdatedTeam = _matchStats.indexOf(updatedTeam)
          _matchStats[indexOfUpdatedTeam] = data.message
          setmatchStats(prev => { prev = [..._matchStats]; return prev })
        }
        );
      }

      //console.log("startPusher", startPusher)
      startPusher && pusherLogic()

    })()
  }, [match_id]);

const listLineUps = async() => {
  try { 
      const response = await axios.get(`https://api-v2.ligi.co.tz/lineup/match/${match_id}`)
      //console.log(response)
      if(response.statusText === "OK") {
         if(response.data.length > 0) {
          splitTeamId(response.data)
         } else {
          toast.success("No line-ups")
         }
      }

      if(!response.statusText === "OK") {
          toast.error("Faild to list match detais")
      }
  } catch(err) {
    console.log(err,'err while listing line-ups')
      toast.error('Error while listing line ups')
  }
}

const splitTeamId = (responseData) => { 
  const { _id:homeTeamId } = homeTeam || ""
  responseData.map( item => {
       if(item.team._id === homeTeamId) {
          //sortHomeLineUp(item)
          return  setHomeLineUp(item)
       }

       return setAwayLineUp(item)
  })
}

const sortHomeLineUp = (item) => {
     let goalKeeper = {}
     let defenders = []
     let midfielders = []
     let strikers = []

     const defendersPositions = ['LB','LCB','RCB','RB','CB']
     const midfieldersPositions = ['LM','RM','CAM','LCM','RCM','CDM','CM','LDM','RDM']
     const strickersPositions = ['LW','RW','LCF','RCF','CF']

     item.players.map(Player => {
      if(defendersPositions.includes(Player.position)) {
         return defenders.push(Player)
      }

      if(midfieldersPositions.includes(Player.position)) {
        return midfielders.push(Player)
      }

      if(strickersPositions.includes(Player.position)) {
        return strikers.push(Player)
      }

      return goalKeeper = Player

     })

     setHomeTeamSorted({
        goalKeeper: goalKeeper,
        defenders: defenders,
        midfielders: midfielders,
        strikers:strikers,
        formation:item.formation,
        team:homeTeam
     })
  
} 

useEffect(() => {
    let _goalKeeper = {};
    let _defenders = [];
    let _midfield = [];
    let _attackingmid = [];
    let _foward = [];

    _lineup2.firstEleven.forEach((element) => {
      if (element.position === "GK") _goalKeeper = element;
      if (element.position === "CB" || element.position === "RB" || element.position === "LB") _defenders.push(element);
      if (element.position === "CM") _midfield.push(element);
      if (element.position === "CAM") _attackingmid.push(element);
      if (element.position === "LF" || element.position === "CF" || element.position === "RF") _foward.push(element);
    });

    setteam2({
      teamName: _lineup2.teamName,
      teamLogo: _lineup2.teamLogo,
      score: _lineup2.score,
      zones: _attackingmid.length === 0 ? 3 : 4,
      formation: _lineup2.formation,
      goalKeeper: _goalKeeper,
      defence: _defenders,
      midField: _midfield,
      attackingMidField: _attackingmid,
      foward: _foward,
      goalScorers: _lineup2.goalScorers,
      substitutes: _lineup2.substitutes,
    }); }, []);

  const [matchInfoLoading, setMatchInfoLoading] = useState(false)
  const [matchInfo, setMatchInfo] = useState({})

  useEffect(() => {
    (async () => {
      setMatchInfoLoading(true)
      try {
        const response = await apiCall({ url: URLS.allMatches })
        const currentMatch = response.data.find(match => match._id === match_id)
        setMatchInfo(prev => { prev = currentMatch; return prev })
        setMatchInfoLoading(false)
      } catch (error) {
        console.error("error getting matches", error)
        setMatchInfoLoading(false)
      }
    })()
  }, [match_id])

  let navigate = useNavigate();
  const colors = useAtomValue(colorAtom);
  const language = useAtomValue(languageAtom);

  const tabs = [
    language.isEnglish ? "Facts" : "Matukio",
    language.isEnglish ? "Stats" : "Takwimu",
    language.isEnglish ? "Lineup" : "Kikosi",
    language.isEnglish ? "Table" : "Msimamo",
    language.isEnglish ? "H2H" : "Mechi walizokutana",
  ];

  const [selectedTabIndex, setselectedTabIndex] = useState(0);

  const handleChange = (_, newIndex) => {
    setselectedTabIndex(newIndex);
  }

  return {
    navigate,
    colors,
    loading,
    selectedTabIndex,
    handleChange,
    tabs,
    _matchTime,
    team1,
    matchStats,
    matchStatsAtomValue,
    matchInfoLoading,
    matchInfo
  }
}
