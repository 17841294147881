import { useAtom, useAtomValue } from "jotai";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { Colors } from "../../../../core/colors";
import { useWindowWidth } from "../../../../hooks/use-window-width";
import { Column } from "../../../../shared-components/column";
import { Row } from "../../../../shared-components/row";
import { ScrollableTabs } from "../../../../shared-components/scrollable-tabs";
import { colorAtom } from "../../../../state/shared-state-atoms/colors";
import { LeagueColorRow } from "./components/league-color-row";
import { LeagueTableContainer } from "./components/league-table-container";
import { TableRow } from "./components/table-row";
import { useLeagueTable } from "./use-league-able";
import { leagueColorListGenerator } from "./utils/league-color-list-generator";
import { leagueColors } from "./utils/league-color-map";
import { leagueStandings } from "./utils/league-standings-dummy";
import { BORDER_RADIUS } from "../../../../core/measurements";
import { leagueTableTextAtom } from "./league-table-text";

export function LeagueTable({ height })
{
  const { selectedTabIndex, tabs, handleChange, leagueTableText, standingsData } = useLeagueTable();
  const { windowWidth } = useWindowWidth();
  let location = useLocation();
  const pathName = location.pathname.slice(1);
  const [colors] = useAtom(colorAtom);

  return (
    <LeagueTableContainer
      style={ {
        backgroundColor: colors.secondary,
        color: colors.text,
        position: "relative",
        maxWidth: pathName === "" && windowWidth > 1600 ? 550 : 700,
        alignSelf:
          windowWidth > 1600 && height === null
            ? "center"
            : windowWidth > 1600 && height !== null
              ? "flex-start"
              : "center",
        margin: "0 auto",
        height: height,
        margin:
          pathName === "" && windowWidth < 1600
            ? "20px 0"
            : pathName === "" && windowWidth > 1600
              ? "0"
              : "50px 0",
        marginLeft: "auto",
        marginRight: "auto",
      } }
    >
      <Row style={ { height: 70, backgroundColor: colors.background } }>
        <p
          style={ {
            fontWeight: "bold",
            fontSize: "xx-large",
            color: colors.faintText,
          } }
        >
          { leagueTableText.title }
        </p>
      </Row>
      <ScrollableTabs
        currentIndex={ selectedTabIndex }
        handleChange={ handleChange }
        tabs={ tabs }
      />
      <LeagueStandingsContainer
        atomColors={ colors }
        style={ {
          backgroundColor: colors.secondary,
          padding: "0 10px",
          marginBottom: 50,
          height: height,
          overflowY: height === null ? "clip" : "scroll",
        } }
      >
        <TableRow head />
        { standingsData?.map((standing, index) =>
        {
          return (
            <TableRow
              key={ index }
              number={ standing.rank }
              team={ { name: standing.name, logo: standing.logoUrl } }
              playedMatches={ standing.gamesPlayed }
              won={ standing.wins }
              drawn={ standing.ties }
              lost={ standing.losses }
              plusMinus={ standing.pointsAgainst }
              goalDifference={ standing.goalDifference }
              points={ standing.points }
              teamCount={ leagueStandings.length }
            />
          );
        }) }
        <Column
          gap={ 20 }
          justifyCenter
          style={ {
            margin: "50px 0",
            paddingLeft: "10%",
            backgroundColor: colors.background,
            paddingBlock: 40
          } }
        >
          { leagueColorListGenerator(leagueColors).map((item, index) =>
          {
            return (
              <LeagueColorRow
                key={ index }
                color={ item.color }
                label={ item.label }
              />
            );
          }) }
        </Column>
      </LeagueStandingsContainer>
    </LeagueTableContainer>
  );
}

const LeagueStandingsContainer = styled.div`
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${ $props => $props.atomColors.secondary };
    margin-block: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${ $props => $props.atomColors.faintText };
    border-radius: ${ BORDER_RADIUS };
  }
`;
