import styled from "styled-components";

export const FormContainer = styled.div`
  max-width: 450px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;
