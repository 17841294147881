import { Slide } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { blue } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import { useAtom, useAtomValue } from 'jotai';
import * as React from 'react';
import { BORDER_RADIUS } from '../../core/measurements';
import { colorAtom } from '../../state/shared-state-atoms/colors';
import { languageAtom } from '../../state/shared-state-atoms/language';
import { Column } from '../column';
import { FramerButtonFilled } from '../framer-button-filled';
import { popUpAtom } from './pop-up-atom';

const Transition = React.forwardRef(function Transition(props, ref)
{
    return <Slide direction="up" ref={ ref } { ...props } />;
});

export function SimpleDialog(props)
{
    const { onClose, selectedValue, open, dialogItems } = props;

    const colors = useAtomValue(colorAtom)
    const language = useAtomValue(languageAtom)

    const { filteredList, popUpAtomSearchValue, onChange, handleClose, handleListItemClick, handleSearch } = useSimpleDialogHook({ list: dialogItems, onClose, selectedValue })

    return (
        <Dialog fullScreen onClose={ handleClose } open={ open } TransitionComponent={ Transition } >
            <Column style={ {} }>
                <Column gap={ 10 } style={ { maxWidth: 550, margin: '0 auto', paddingBlock: 20 } } >
                    <h2>{ language.isEnglish ? "Search for your country" : "Tafuta nchi yako" }</h2>
                    <input
                        placeholder={ language.isEnglish ? "enter your country's name" : "andika jina la nchi yako" }
                        value={ popUpAtomSearchValue }
                        onChange={ onChange }
                        style={ {
                            width: '100%',
                            paddingBlock: 15,
                            paddingInline: 10,
                            border: `1px solid ${ colors.faintText }`,
                            outline: 'none',
                            borderRadius: BORDER_RADIUS,
                        } }
                    />
                    <FramerButtonFilled label={ language.isEnglish ? "Search" : "Tafuta" } onClick={ handleSearch } />
                </Column>
                <List sx={ { pt: 0 } } style={ { maxWidth: 550, width: '100%', margin: '0 auto', marginTop: 20 } } >
                    { filteredList.map((item, index) => (
                        <ListItem
                            key={ index }
                            button onClick={ () => handleListItemClick(item) }
                            style={ { marginTop: 10, backgroundColor: 'whitesmoke', borderRadius: 8 } }
                        >
                            <ListItemAvatar>
                                <Avatar sx={ { bgcolor: blue[100], color: blue[600] } }>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={ item.countryNameEn } />
                        </ListItem>
                    )) }
                </List>
            </Column>
        </Dialog>
    );
}

const useSimpleDialogHook = ({ list, onClose, selectedValue }) =>
{
    const [filteredList, setfilteredList] = React.useState([])

    const [popUpAtomSearchValue, popUpAtomSearchSetter] = useAtom(popUpAtom)

    const handleSearch = () =>
    {
        const newFilter = list.filter(element => element.countryNameEn.toString().toLowerCase().replace(/\s/g, '').includes(popUpAtomSearchValue.toString().toLowerCase().replace(/\s/g, '')))
        setfilteredList([...newFilter])
    }

    const onChange = (event) => 
    {
        const _value = event.target.value
        popUpAtomSearchSetter(_value)
    }

    const handleClose = () =>
    {
        onClose(selectedValue);
        popUpAtomSearchSetter('')
        setfilteredList([])
    };

    const handleListItemClick = (value) =>
    {
        onClose(value);
        popUpAtomSearchSetter('')
        setfilteredList([])
    };

    return { filteredList, popUpAtomSearchValue, onChange, handleClose, handleListItemClick, handleSearch }
}