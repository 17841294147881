import React from "react";

const SwahiliAbout = () => {
  return (
    <div>
      <h2 className=" flex  mb-1">
        <strong>Kuhusu sisi</strong>
      </h2>
      <p>
        <span className=" text-[#999999] text-sm">
          Benki ya Taifa ya Biashara, NBC ni benki ya zamani kuliko zote nchini
          Tanzania ikiwa na uzoefu wa zaidi ya miongo mitano. Tunatoa huduma
          mbalimbali za kibenki kwa wateja wa rejareja, biashara, mashirika na
          misaada ya uwekezaji, bidhaa na huduma za usimamizi mzuri wa fedha.
        </span>
      </p>
      <h2 className=" flex  my-4">
        <strong>Historia yetu</strong>
      </h2>
      <p>
        <span className=" text-[#999999] text-sm">
          Historia ya Benki hii inaanzia mwaka 1967 ambapo Serikali ya Tanzania
          ilibinafsisha taasisi zote za fedha, zikiwamo benki. Mwaka 1991,
          sheria ya mabenki ilirekebishwa na miaka sita baadae, yaani mwaka
          1997, taasisi iliyojulikana kama Benki ya Taifa ya Biashara,
          iligawanywa katika tanzu tatu tofauti:
        </span>
      </p>
      <ul>
        <li className=" mb-1">
          <span className=" text-[#999999] text-sm">
            1 NBC Holding Corporation
          </span>
        </li>
        <li className=" mb-1">
          <span className=" text-[#999999] text-sm">
            2 National Microfinance Bank (NMB)
          </span>
        </li>
        <li className=" mb-4">
          <span className=" text-[#999999] text-sm">3 NBC (1997) Limited</span>
        </li>
        <li className=" mb-2">
          <span className=" text-[#999999] text-sm">
            Mwaka 2000, Benki ya Afrika Kusini, Absa Group Limited, ilipata hisa
            55% kutoka NBC (1997) Limited. Serikali ya Jamhuri ya Muungano ya
            Tanzania ilipata 30% ya hisa na Shirika la Fedha la Kimataifa (IFC)
            kama mwanachama wa Benki ya dunia (World Bank Group), lilipata 15%
            ya hisa za benki hii. Taasisi hiyo mpya ikaitwa NBC Limited.
          </span>
        </li>
      </ul>
      <h2 className=" flex  my-4">
        <strong>Mambo ya Kujivunia</strong>
      </h2>
      <ul>
        <li className=" mb-1">
          <span className=" text-[#999999] text-sm">
            Benki ya NBC ni benki pekee ya kimataifa inayopatikana katika maeneo
            mbalimbali nchini
          </span>
        </li>
        <li className=" mb-1">
          <span className=" text-[#999999] text-sm">
            Ikiwa na matawi 5 na mashine za ATM zaidi ya 230, Benki ya NBC
            inatoa huduma mbalimbali za ukusanyaji fedha na huduma nyingine za
            kibenki kwa wateja mbalimbali
          </span>
        </li>
        <li className=" mb-4">
          <span className=" text-[#999999] text-sm">
            Benki ya NBC sasa imeajiri wafanyakazi takriban 1,200 nchi nzima
          </span>
        </li>
      </ul>
      <h2 className=" flex  my-4">
        <strong>Tuzo na zawadi zetu</strong>
      </h2>
      <ul>
        <li className=" mb-1">
          <span className=" text-[#999999] text-sm">
            1 2005 Bankers Awards (Corporate Bank of the Year)
          </span>
        </li>
        <li className=" mb-1">
          <span className=" text-[#999999] text-sm">
            2 2005 Bankers Awards (Special Technology Awards)
          </span>
        </li>
        <li className=" mb-1">
          <span className=" text-[#999999] text-sm">
            3 2009 TRA Compliant Large Tax Payer Award
          </span>
        </li>
        <li className=" mb-1">
          <span className=" text-[#999999] text-sm">
            4 2009 African Banker Award (Best Local Bank in Africa)
          </span>
        </li>
        <li className=" mb-1">
          <span className=" text-[#999999] text-sm">
            5 2015 Asian Banker Award (Best Retail Bank)
          </span>
        </li>
        <li className=" mb-1">
          <span className=" text-[#999999] text-sm">
            6 2015 NBC Voted 2015 – 16 Superbrands
          </span>
        </li>
        <li className=" mb-1">
          <span className=" text-[#999999] text-sm">
            7 2017 Best Islamic Retail Bank Award
          </span>
        </li>
        <li className=" mb-1">
          <span className=" text-[#999999] text-sm">
            8 2017 NBC Voted 2017 – 18 Superbrands
          </span>
        </li>
        <li className=" mb-1">
          <span className=" text-[#999999] text-sm">
            9 2018: 1st in the Financial Category and 2nd Overall winner – 42nd
            Dar es Salaam International Trade Fair
          </span>
        </li>
        <li className=" mb-1">
          <span className=" text-[#999999] text-sm">
            10 2018: 1st in the Financial Category and 2nd Overall winner – 1st
            National Farmers Day
          </span>
        </li>
        <li className=" mb-1">
          <span className=" text-[#999999] text-sm">
            11 2018: 1st in the Financial Category and 2nd Overall winner – 1st
            National Mining Technology and Investment Exhibition
          </span>
        </li>
      </ul>
    </div>
  );
};

export default SwahiliAbout;
