import { Row } from "../../../../../shared-components/row";
import { Box } from "./box";

export const LeagueColorRow = ({ color, label }) =>
{
  return (
    <Row alignCenter gap={ 20 } style={ { width: "fit-content" } }>
      <Box color={ color } />
      <p style={ { fontSize: 'small' } }>{ label }</p>
    </Row>
  );
};
