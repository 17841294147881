import styled from "styled-components";
import { BORDER_RADIUS } from "../../../../../core/measurements";

const MatchDayV2Container = styled.div`
  width: 100%;
  color: ${(props) => props.atomColors.text};
  background-color: ${(props) => props.atomColors.secondary};
  user-select: none;
  margin: 0 auto;
  border-radius: ${BORDER_RADIUS};
`;

export default MatchDayV2Container;
