import { useAtomValue } from "jotai"
import { useEffect, useState } from "react"
import { colorAtom } from "../../state/shared-state-atoms/colors"
import { useParams } from "react-router-dom"
import apiCall from '../../functions/api_call'
import { URLS } from "../../core/urls"

export const useTeam = () => 
{
    let { team_id } = useParams()
    const colors = useAtomValue(colorAtom)

    const [selectedTabIndex, setSelectedTabIndex] = useState(1)

    const handleTabChange = (_, newIndex) => setSelectedTabIndex(newIndex)

    const [loading, setloading] = useState(false)
    const [loadingOnSeasonChange, setloadingOnSeasonChange] = useState(false)
    const [playerNationalities, setPlayerNationlities] = useState({
        nativesPlayerCount: null,
        foreignersPlayerCount: null
    })
    const [averageTeamAge, setAverageTeamAge] = useState()
    const [_teamInfo, set_teamInfo] = useState({})
    const [players, setPlayers] = useState([])
    const [clubManager, setClubManager] = useState({ name: "", id: "" })
    const [seasonStats, setSeasonStats] = useState({
        season: "",
        wins: 0,
        draws: 0,
        lost: 0,
        matchesPlayed: 0,
        points: 0,
        goalsScored: 0,
        goalsConceded: 0
    })
    const [selectedSeasonYear, setSelectedSeasonYear] = useState((prev) =>
    {
        prev = new Date().getFullYear()
        return prev
    })

    const nextSeason = () =>
    {
        if (parseInt(selectedSeasonYear) >= new Date().getFullYear() + 1) return
        setSelectedSeasonYear(prev => parseInt(prev) + 1)
    }

    const previousSeason = () =>
    {
        if (parseInt(selectedSeasonYear) <= 2000) return
        setSelectedSeasonYear(prev => parseInt(prev) - 1)
    }


    useEffect(() =>
    {
        let seasonString = `${ selectedSeasonYear }-${ parseInt(selectedSeasonYear) + 1 }`
        setloading(true)
        let promises = [
            apiCall({ url: `${ URLS.allTeams }/${ team_id }` }),
            apiCall({ url: `${ URLS.teamManager }/${ team_id }` }),
            apiCall({ url: URLS.allPlayers }),
        ]
        Promise.allSettled(promises)
            .then(results =>
            {
                let _team = results[0].value.data
                set_teamInfo(prev => { prev = _team; return prev })
                if (Boolean(_team.seasons))
                {
                    let x = _team.seasons?.find(el => el.year === seasonString)
                    setSeasonStats(prev =>
                    {
                        if (x)
                        {
                            prev = {
                                ...prev,
                                season: x.year ?? "",
                                wins: x.wins ?? "",
                                draws: x.draws ?? "",
                                lost: x.lost ?? "",
                                matchesPlayed: x.matchesPlayed ?? "",
                                points: x.points ?? "",
                                goalsScored: x.goalsScored ?? "",
                                goalsConceded: x.goalsConceded ?? "",
                            }
                        } else prev = { ...prev }
                        return prev
                    })
                }

                // team manager
                if (results[1].status === "fulfilled")
                {
                    let _manager = results[1].value.data
                    setClubManager(prev =>
                    {
                        prev = {
                            ...prev,
                            id: _manager._id,
                            name: _manager.name
                        }
                        return prev
                    })
                }

                // players in the team
                if (results[2].status === "fulfilled")
                {
                    let _players = results[2].value.data.filter(dt => dt.current_club._id === team_id)
                    setPlayers(prev =>
                    {
                        prev = [..._players]
                        return prev
                    })

                    // foreigners and natives player count
                    let nativesPlayerCount = 0
                    let foreignersPlayerCount = 0
                    _players.forEach(_player =>
                    {
                        if (
                            Boolean(_player?.country_code === "255")
                            ||
                            Boolean(_player?.country_code?.split(",")[0] === "Tanzania"))
                        {
                            setPlayerNationlities(prev =>
                            {
                                return {
                                    ...prev,
                                    nativesPlayerCount: prev.nativesPlayerCount + 1
                                }
                            })
                        } else
                        {
                            setPlayerNationlities(prev =>
                            {
                                return {
                                    ...prev,
                                    foreignersPlayerCount: prev.foreignersPlayerCount + 1
                                }
                            })
                        }
                    });

                    // average age
                    let ages = 0
                    _players.forEach(_player =>
                    {
                        if (_player.dob)
                        {
                            ages = ages + new Date().getFullYear() - new Date(_player.dob).getFullYear()
                        }
                    })

                    let averageAge = Math.round(ages / _players.length)
                    setAverageTeamAge(prev => { prev = averageAge; return prev })
                }

            })
            .finally(() => setloading(false))

    }, [])

    return {
        colors,
        _teamInfo,
        loading,
        selectedSeasonYear, selectedSeasonYear, nextSeason, previousSeason,
        seasonStats,
        loadingOnSeasonChange,
        clubManager,
        players,
        playerNationalities,
        averageTeamAge,
        selectedTabIndex, handleTabChange
    }
}   