import { createSlice } from "@reduxjs/toolkit";

export const themeSlice = createSlice({
  name: "theme",
  initialState: {
    lightTheme: true,
    colors: {},
  },
  reducers: {
    toggle: (state) => {
      state.lightTheme = !state.lightTheme;
    },
    getColorsFromTheme: (state) => {
      const isLight = state.lightTheme;
      state.colors = {
        background: isLight ? "#EFF2F7" : "#031D4D",
        primary: "#042559",
        secondary: isLight ? "#fff" : "#132F65",
        text: isLight ? "#222" : "#fff",
        action: "#244D93",
        placeHolder: "#757572",
        textButton: "orange",
        faintText: isLight ? "#C1C1C1" : "#485D87",
        tableHighlight: "#556990",
      };
    },
  },
});

export const getTheme = (state) => state.lightTheme;
export const getColors = (state) => state.colors;

export const { toggle, getColorsFromTheme } = themeSlice.actions;
export default themeSlice.reducer;
