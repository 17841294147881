import { useEffect, useState } from 'react'
import { URLS } from '../../../../core/urls'
import apiCall from '../../../../functions/api_call'

export const useNewsFullHook = () => 
{
    // for re-running the use effect
    const [reRun, setReRun] = useState(0)

    const [news_list, setNewsList] = useState([])
    const [newsLoading, setNewsLoading] = useState(false)

    const refreshNews = () => setReRun(prev => prev + 1)

    useEffect(() =>
    {
        // window.scrollTo(0, 0)
        // const _api_call = async () =>
        // {
        //     setNewsLoading(true)
        //     const response = await apiCall({ url: URLS.news })
        //     if (response.status === 200)
        //     {
        //         setNewsList(prev => { prev = response.data; return prev })
        //     }
        //     setNewsLoading(false)
        // }
        // _api_call()
        (async () => {
            setNewsLoading(true)
            try {
                const response = await apiCall({url: URLS.news})
                setNewsLoading(false)
                setNewsList(prev => {prev = response.data; return prev})
            } catch (error) {
                console.log(error)
                setNewsLoading(false)
            }
        })()
    }, [reRun])

    return { news_list, newsLoading, refreshNews }
}