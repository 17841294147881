import { motion } from 'framer-motion'
import { useAtomValue } from 'jotai'
import { colorAtom } from '../../../../../state/shared-state-atoms/colors'

export default function IconCircleButton({ icon, onClick })
{
    const colors = useAtomValue(colorAtom)
    return (
        <motion.div
            onClick={ onClick }
            style={ {
                backgroundColor: colors.faintText,
                height: 30,
                aspectRatio: 1,
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
            } }
        >
            { icon }
        </motion.div>
    )
}