import styled from 'styled-components'

const CardBottom = styled.div`
    height: 30%;
    height: fit-content;
    width: 100%;
    background-color: ${ props => props.atomColors.background };
    display: flex;
    flex-direction: column;
    padding-inline: 10px;
`

export default CardBottom