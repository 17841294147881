import teamlogo from '../../../../../../assets/teams/az.png'
import yn from '../../../../../../assets/teams/yn.png'
import playerImage from '../../../../../../assets/players/1n.png'
import { Team } from '../../../../../../models/team-model'
import { PlayerStat } from '../../../../../../models/player-stat'

export const playerStatsDummyData = [
    {
        season: '2021-2022',
        data: [
            {
                label: 'Top Scorer',
                values: [
                    new PlayerStat({ name: 'Fiston Mayele', stat: 16, image: playerImage, team: new Team({ name: 'Young Africans', logo: yn }) }),
                    new PlayerStat({ name: 'Medie Kagere', stat: 13, image: playerImage, team: new Team({ name: 'Azam FC', logo: teamlogo }) }),
                    new PlayerStat({ name: 'Reliant Lusajo', stat: 10, image: playerImage, team: new Team({ name: 'Azam FC', logo: teamlogo }) }),
                    new PlayerStat({ name: 'Pazi Zahoro', stat: 9, image: playerImage, team: new Team({ name: 'Azam FC', logo: teamlogo }) }),
                    new PlayerStat({ name: 'Saidi Ntibazankiza', stat: 9, image: playerImage, team: new Team({ name: 'Azam FC', logo: teamlogo }) }),
                ]
            },
            {
                label: 'Top Assists',
                values: [
                    new PlayerStat({ name: 'Fiston Mayele', stat: 16, image: playerImage, team: new Team({ name: 'Azam FC', logo: teamlogo, teamColor: 'beige' }) }),
                    new PlayerStat({ name: 'Fiston Mayele', stat: 16, image: playerImage, team: new Team({ name: 'Azam FC', logo: teamlogo, teamColor: 'beige' }) }),
                    new PlayerStat({ name: 'Fiston Mayele', stat: 16, image: playerImage, team: new Team({ name: 'Azam FC', logo: teamlogo, teamColor: 'beige' }) }),
                    new PlayerStat({ name: 'Fiston Mayele', stat: 16, image: playerImage, team: new Team({ name: 'Azam FC', logo: teamlogo, teamColor: 'beige' }) }),
                    new PlayerStat({ name: 'Fiston Mayele', stat: 16, image: playerImage, team: new Team({ name: 'Azam FC', logo: teamlogo, teamColor: 'beige' }) }),
                ]
            },
            {
                label: 'Shots on target',
                values: [
                    new PlayerStat({ name: 'Fiston Mayele', stat: 16, image: playerImage, team: new Team({ name: 'Azam FC', logo: teamlogo, teamColor: 'lightblue' }) }),
                    new PlayerStat({ name: 'Fiston Mayele', stat: 16, image: playerImage, team: new Team({ name: 'Azam FC', logo: teamlogo, teamColor: 'lightblue' }) }),
                    new PlayerStat({ name: 'Fiston Mayele', stat: 16, image: playerImage, team: new Team({ name: 'Azam FC', logo: teamlogo, teamColor: 'lightblue' }) }),
                    new PlayerStat({ name: 'Fiston Mayele', stat: 16, image: playerImage, team: new Team({ name: 'Azam FC', logo: teamlogo, teamColor: 'lightblue' }) }),
                    new PlayerStat({ name: 'Fiston Mayele', stat: 16, image: playerImage, team: new Team({ name: 'Azam FC', logo: teamlogo, teamColor: 'lightblue' }) }),
                ]
            },
            {
                label: 'Clean sheet',
                values: [
                    new PlayerStat({ name: 'Fiston Mayele', stat: 16, image: playerImage, team: new Team({ name: 'Azam FC', logo: teamlogo, teamColor: 'brown' }) }),
                    new PlayerStat({ name: 'Fiston Mayele', stat: 16, image: playerImage, team: new Team({ name: 'Azam FC', logo: teamlogo, teamColor: 'brown' }) }),
                    new PlayerStat({ name: 'Fiston Mayele', stat: 16, image: playerImage, team: new Team({ name: 'Azam FC', logo: teamlogo, teamColor: 'brown' }) }),
                    new PlayerStat({ name: 'Fiston Mayele', stat: 16, image: playerImage, team: new Team({ name: 'Azam FC', logo: teamlogo, teamColor: 'brown' }) }),
                    new PlayerStat({ name: 'Fiston Mayele', stat: 16, image: playerImage, team: new Team({ name: 'Azam FC', logo: teamlogo, teamColor: 'brown' }) }),
                ]
            },
        ],
    }
]