import styled from "styled-components";
import { Column } from "../../shared-components/column";
import { MainContainer } from "../../shared-components/main-container";
import logoWithoutBall from "../../assets/logo-without-ball.png";
import logoBall from "../../assets/ball.png";
import { motion } from "framer-motion";
import { useAtomValue, useSetAtom } from "jotai";
import { colorAtom } from "../../state/shared-state-atoms/colors";
import { useSelector, useDispatch } from 'react-redux'
import { getTheme, toggle } from "../../state/redux-slices/themeSlice";
import { tabIndexAtom } from "../../state/shared-state-atoms/tab-index";
import { useNavigate } from "react-router-dom";

export function Loading() {
  const theme = useSelector(getTheme)
  const colors = useAtomValue(colorAtom)
  const setTabBarIndex = useSetAtom(tabIndexAtom)
  setTabBarIndex(0)

  return <MainContainer
    style={ {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: '100vh',
      backgroundColor: colors.background
    } }
  >
    <Column alignCenter style={ { position: "relative" } }>
      <LogoWithoutBall src={ logoWithoutBall } alt="" />
      <LogoBall
        src={ logoBall }
        initial={ { rotate: 0 } }
        animate={ { rotate: 359 } }
        transition={ { duration: 1, repeat: Infinity, repeatDelay: 0, ease: 'linear' } }
      />
    </Column>
  </MainContainer>
}

const LogoWithoutBall = styled.img`
  width: 100px;
`;

const LogoBall = styled(motion.img)`
  width: 35px;
  position: absolute;
  bottom: 0;
`;
