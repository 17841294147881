import { useAtomValue } from "jotai";
import { useNavigate } from "react-router-dom";
import { SMALL_SPACING } from "../core/measurements";
import { colorAtom } from "../state/shared-state-atoms/colors";
import { languageAtom } from "../state/shared-state-atoms/language";
import { TextButton } from "./text-button";

export function ForgotPassword({ handleClick }) {
  const { handleForgotPassword } = useForgotPasseordHook();
  const language = useAtomValue(languageAtom);
  const colors = useAtomValue(colorAtom);

  return (
    <TextButton
      atomColors={colors}
      onClick={handleClick}
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      style={{
        margin: `${SMALL_SPACING} 0`,
        alignSelf: "flex-end",
      }}
    >
      {language.isEnglish ? "Forgot password ?" : "Je umesahau nenosiri ?"}
    </TextButton>
  );
}

const useForgotPasseordHook = () => {
  let navigate = useNavigate();

  const handleForgotPassword = () => {
    navigate("/forgot-password");
  };

  return { handleForgotPassword };
};
