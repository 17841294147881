import styled from 'styled-components'

const Container = styled.div`
    margin-bottom: 100px;
    width: 100%;
    height: fit-content;
    padding-block: 40px;
    padding-inline: 20px;
    background-color: ${ props => props.atomColors.secondary };
    display: flex;
    flex-direction: column;
    gap: 20px;
`

export default Container