import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { colorAtom } from "../../state/shared-state-atoms/colors";
import axios from "axios";
import { URLS } from "../../core/urls";
import getSeason from "../../functions/get_season";

export const useTeams = () => {
  const colors = useAtomValue(colorAtom);
  const [loading, setloading] = useState(false);
  const [_allTeamInfo, set_allTeamInfo] = useState([]);
  const [_seasonTeamsInfo, set_seasonTeamsInfo] = useState([]);

  const currentYear = parseInt(getSeason(new Date()).split("-")[0]);
  const slicedCurrentYear = parseInt(currentYear.toString().slice(2, 4));

  const [seasonQuerryValue, setseasonQuerryValue] = useState("");
  const [seasons, setSeasons] = useState(["2022-2023"]);
  const [searchSeasonQuerry, setsearchSeasonQuerry] = useState("");

  const onInputChange = (event) => setseasonQuerryValue(event.target.value);

  useEffect(() => {
    setseasonQuerryValue(seasons[0]);
    setsearchSeasonQuerry(seasons[0]);
  }, []);

  useEffect(() => {
    const _api_call = async () => {
      setloading(true);
      const response = await axios.get(`${URLS.allTeams}`);
      if (response.status === 200) {
        let sortedTeams = response.data.sort((a, b) =>
          a.name > b.name ? 1 : -1
        );
        set_allTeamInfo(sortedTeams);
      }
      setloading(false);
    };
    _api_call();
  }, []);

  const nextSeason = () => {
    setseasonQuerryValue((prev) => {
      const seasonIndex = seasons.findIndex(
        (element) => element === seasonQuerryValue
      );
      if (seasonIndex + 1 === seasons.length) return prev;
      prev = seasons[seasonIndex + 1];
      return prev;
    });
    setsearchSeasonQuerry((prev) => {
      const seasonIndex = seasons.findIndex(
        (element) => element === searchSeasonQuerry
      );
      if (seasonIndex + 1 === seasons.length) return prev;
      prev = seasons[seasonIndex + 1];
      return prev;
    });
  };

  const previousSeason = () => {
    setseasonQuerryValue((prev) => {
      const seasonIndex = seasons.findIndex(
        (element) => element === seasonQuerryValue
      );
      if (seasonIndex < 1) return prev;
      prev = seasons[seasonIndex - 1];
      return prev;
    });
    setsearchSeasonQuerry((prev) => {
      const seasonIndex = seasons.findIndex(
        (element) => element === searchSeasonQuerry
      );
      if (seasonIndex < 1) return prev;
      prev = seasons[seasonIndex - 1];
      return prev;
    });
  };

  useEffect(() => {
    const _api_call = async () => {
      setloading(true);
      const searchSeasonQuery = `${parseInt(seasonQuerryValue)}-${
        parseInt(seasonQuerryValue) + 1
      }`;
      const response = await axios.get(
        `${URLS.allTeamsBySeason}/${searchSeasonQuery}`
      );
      if (response.status === 200) {
        let sortedTeams = response.data.sort((a, b) =>
          a.name > b.name ? 1 : -1
        );
        set_seasonTeamsInfo(sortedTeams);
        // setsearchSeasonQuerry(searchSeasonQuery)
      }
      setloading(false);
    };

    _api_call();
  }, [seasonQuerryValue]);

  const handleSearch = () => {
    setloading(true);
    const searchSeasonQuerry = `${2000 + parseInt(seasonQuerryValue)}-${
      2000 + parseInt(seasonQuerryValue) + 1
    }`;

    axios
      .get(`${URLS.allTeamsBySeason}/${searchSeasonQuerry}`)
      .then((response2) => {
        let sortedTeams = response2.data.sort((a, b) =>
          a.name > b.name ? 1 : -1
        );
        set_seasonTeamsInfo(sortedTeams);
        setsearchSeasonQuerry(searchSeasonQuerry);
        setloading(false);
      });
  };

  return {
    colors,
    _allTeamInfo,
    _seasonTeamsInfo,
    loading,
    searchSeasonQuerry,
    seasonQuerryValue,
    onInputChange,
    slicedCurrentYear,
    handleSearch,
    previousSeason,
    nextSeason,
  };
};
