import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { NavigationBar } from "../../shared-components/navigation-bar/navigation-bar";
import { MobileMenu } from "../../shared-components/navigation-bar/mobile-menu";
import { Column } from "../../shared-components/column";
import { useAboutUsHook } from "./use-about-us";

import { handleGoBack } from "../../functions/handleGoBack";
import { Footer } from "../../shared-components/footer";
import { GoBack } from "../../shared-components/go-back";
import { AboutUsContainer } from "./components/about-us-container";
import EnglishAbout from "./components/EnglishAbout";
import SwahiliAbout from "./components/SwahiliAbout";

export function AboutUs() {
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const { navigate, colors, contents, loading, language } = useAboutUsHook();
  return (
    <>
      <NavigationBar setOpenMobileMenu={setOpenMobileMenu} />
      <AnimatePresence>
        {openMobileMenu ? (
          <MobileMenu setOpenMobileMenu={setOpenMobileMenu} />
        ) : (
          ""
        )}
      </AnimatePresence>
      <AboutUsContainer atomColors={colors}>
        <Column
          style={{
            width: "90%",
            maxWidth: 1000,
          }}
        >
          <GoBack handleGoBack={() => handleGoBack(navigate)} />
          <br />
          {loading ? (
            <p>{language.isEnglish ? "loading" : "tafadhali subiri"} ...</p>
          ) : (
            <>{language.isEnglish ? <EnglishAbout /> : <SwahiliAbout />}</>
            // <div dangerouslySetInnerHTML={{ __html: contents }} />
          )}
        </Column>
      </AboutUsContainer>
      <Footer />
    </>
  );
}
