import { useNavigate } from "react-router-dom";
import { useWindowWidth } from "../../../hooks/use-window-width";
import { Column } from "../../../shared-components/column";
import noImage from "../../../assets/teams/noImage.jpg";
import { Row } from "../../../shared-components/row";
import { useMatchDayStatsHook } from "../use-match-stats";

export function ScoreLineComponent({ matchStats, matchStatsAtomValue }) {
  const { windowWidth } = useWindowWidth();

  return (
    <Row alignCenter gap={ windowWidth < 400 ? 20 : 30 } style={ { height: "fit-content", marginBottom: 30 } }>
      <StatsTeamView logo={ matchStatsAtomValue.home.logo ?? noImage } name={ matchStatsAtomValue.home.name } />
      { matchStats.length === 0 ? (
        <Row alignCenter justifyCenter style={ { height: 80 } }>
          <Row gap={ 10 } style={ { width: "fit-content", height: "100%" } }>
            <h1></h1>
            <h1>-</h1>
            <h1></h1>
          </Row>
        </Row>
      ) : (
        <>
          <Column style={ { width: "fit-content", height: "100%" } }>
            <Row gap={ 10 } style={ { width: "fit-content", height: "100%" } }>
              <h1>{ matchStats[0].goals }</h1>
              <h1>-</h1>
              <h1>{ matchStats[1].goals }</h1>
            </Row>
          </Column>
        </>
      ) }
      <StatsTeamView logo={ matchStatsAtomValue.away.logo ?? noImage } name={ matchStatsAtomValue.away.name } />
    </Row>
  );
}

function StatsTeamView({ logo, name }) {
  let navigate = useNavigate();
  const { windowWidth } = useWindowWidth();

  const { matchStats } = useMatchDayStatsHook();

  return (
    <Column alignCenter gap={ 10 } style={ { height: "100%", cursor: "pointer" } } onClick={ () => navigate(`/teams/${ name }`) }>
      <img src={ logo } alt="home team logo" style={ { height: windowWidth < 400 ? 50 : 120 } } />
      <p>{ name }</p>
    </Column>
  );
}
