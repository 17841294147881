import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { URLS } from "../../core/urls";
import apiCall from "../../functions/api_call";
import { colorAtom } from "../../state/shared-state-atoms/colors";

export function useManagersHook() {
  let navigate = useNavigate();
  const colors = useAtomValue(colorAtom);

  const [loading, setLoading] = useState(false)
  const [managers, setManagers] = useState([]);

  useEffect(() => {
    const _call = async () => {
      try {
        setLoading(true)
        const resp = await apiCall({ url: URLS.managers })
        setManagers(prev => { prev = resp.data; return prev })
        setLoading(false)
      } catch (error) {
        setManagers(prev => { prev = []; return prev })
        setLoading(false)
      }
    }
    _call()
  }, []);

  return {
    colors,
    navigate,
    managers,
    loading
  };
}
