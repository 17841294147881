import { Colors } from "../../core/colors";
import { MdClear } from "react-icons/md";
import ClickAwayListener from "react-advanced-click-away";
import { handleMobileMenu } from "./functions/handle-mobile-menu";
import { motion } from "framer-motion";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { colorAtom } from "../../state/shared-state-atoms/colors";
import { themeAtom } from "../../state/shared-state-atoms/_theme";
import { GiMoon } from "react-icons/gi";
import { BsSun } from "react-icons/bs";
import { Row } from "../row";
import { tabIndexAtom } from "../../state/shared-state-atoms/tab-index";
import { routeNavigator } from "./functions/route-navigator";
import { navigationTextAtom } from "./navigation-text-atom";

import { AiOutlineArrowRight } from "react-icons/ai";

import { MdLanguage } from "react-icons/md";
import { languageAtom } from "../../state/shared-state-atoms/language";
import { FramerButtonFilled } from "../framer-button-filled";
import { FilledButton } from "../filled-button";
import { userProfileAtom } from "../../state/shared-state-atoms/user-profile-atom";
import { useChangeTheme } from "../../hooks/use-change-theme";
import { handleAboutUs } from "../../functions/abot-us";
import { handlePrivacyPolicy } from "../../functions/privacy-policy";
import { handleTermsOfService } from "../../functions/terms-of-service";
import { handleTeams } from "../../functions/teams";
import { _routeNames } from "../../app/routes";
import FavouriteTeamModel from "./components/FavouriteTeam";
import { useDispatch, useSelector } from "react-redux";
import { setOpen } from "../../state/redux-slices/ModalSlice";

export function MobileMenu({ setOpenMobileMenu }) {
  const [selectedTabIndex, setSelectedTabIndex] = useAtom(tabIndexAtom);
  const showModal = useSelector((state) => state.modal.Open);
  const dispatch = useDispatch();
  const {
    menuLinks,
    navigate,
    navigationTextAtomValue,
    handleProfileButton,
    handleLoginOrSignUp,
    handleLanguageChange,
    handleChangeTheme,
  } = useMobileMenu({ setSelectedTabIndex, setOpenMobileMenu });
  const [colors] = useAtom(colorAtom);
  const [userProfileAtomData, setuserProfileAtomData] =
    useAtom(userProfileAtom);
  const [theme, setTheme] = useAtom(themeAtom);
  const setLanguage = useSetAtom(languageAtom);
  const { isEnglish } = useAtomValue(languageAtom);
  const [openModal, setOpenModal] = useState(false);

  return (
    <ClickAwayListener onClickAway={() => handleMobileMenu(setOpenMobileMenu)}>
      <MobileMenuContainer
        exit={{ x: "100%" }}
        initial={{ x: "100%" }}
        animate={{ x: 0 }}
        transition={{ ease: "easeOut" }}
        style={{
          backgroundColor: colors.background,
          color: colors.text,
          overflowY: "scroll",
        }}
      >
        <Row gap={10} style={{ flexWrap: "wrap", flexDirection: "column" }}>
          <MdClear
            style={{ fontSize: 30, alignSelf: "flex-end", cursor: "pointer" }}
            onClick={() => handleMobileMenu(setOpenMobileMenu)}
          />

          {menuLinks.map((item, index) => (
            <p
              key={index}
              onClick={() =>
                handleMenuItemClick(
                  index,
                  setOpenMobileMenu,
                  navigate,
                  setSelectedTabIndex
                )
              }
              style={{
                color: colors.text,
                fontWeight: index === selectedTabIndex ? "bold" : "",
                fontSize: index === selectedTabIndex ? 25 : "",
                cursor: "pointer",
              }}
            >
              {item}
            </p>
          ))}
        </Row>
        <MenuSectionContainer atomColors={colors}>
          <MenuSectionButtonWithIcon
            label={!theme.lightTheme ? "Dark theme" : "Light theme"}
            Icon={() => (!theme.lightTheme ? <GiMoon /> : <BsSun />)}
            onClick={handleChangeTheme}
          />
          <MenuSectionButtonWithIcon
            label={navigationTextAtomValue.language}
            Icon={() => <MdLanguage />}
            onClick={handleLanguageChange}
          />
        </MenuSectionContainer>
        {openModal && (
          <FavouriteTeamModel
            open={openModal}
            handleClose={() => setOpenModal(false)}
            backgroundColor={colors.secondary}
            color={colors.text}
          />
        )}
        {userProfileAtomData?.firstName ? (
          <>
            <MenuSectionContainer atomColors={colors}>
              <MenuSectionButton
                label={navigationTextAtomValue.profile.label}
                icon={navigationTextAtomValue.profile.icon}
                onClick={handleProfileButton}
              />
              <MenuSectionButton
                label={isEnglish ? "Favourite Club" : "Klabu pendwa"}
                icon={navigationTextAtomValue.favouriteClub.icon}
                onClick={() => {
                  {
                    userProfileAtomData?.favTeam
                      ? navigate(
                          `${_routeNames.allTeams}/${userProfileAtomData.favTeam._id}`
                        )
                      : setOpenModal(true);
                  }
                }}
              />
              <MenuSectionButton
                label={navigationTextAtomValue.clubs.label}
                icon={navigationTextAtomValue.clubs.icon}
                onClick={() => {
                  handleTeams(navigate);
                }}
              />
              <MenuSectionButton
                label={navigationTextAtomValue.players.label}
                icon={navigationTextAtomValue.players.icon}
                onClick={() => navigate(_routeNames.allPlayers)}
              />
              <MenuSectionButton
                label={navigationTextAtomValue.coaches.label}
                icon={navigationTextAtomValue.coaches.icon}
                onClick={() => navigate(_routeNames.allManagers)}
              />
            </MenuSectionContainer>

            <MenuSectionContainer atomColors={colors}>
              <MenuSectionButton
                label={navigationTextAtomValue.partners.label}
                icon={navigationTextAtomValue.partners.icon}
                onClick={() => navigate(_routeNames.partners)}
              />
              <MenuSectionButton
                label={navigationTextAtomValue.broadcasters.label}
                icon={navigationTextAtomValue.broadcasters.icon}
                onClick={() => navigate(_routeNames.broadcasters)}
              />
              <MenuSectionButton
                label={navigationTextAtomValue.inviteFriends.label}
                icon={navigationTextAtomValue.inviteFriends.icon}
                onClick={() => {}}
              />
            </MenuSectionContainer>

            <MenuSectionContainer atomColors={colors}>
              <MenuSectionButton
                label={navigationTextAtomValue.privacyPolicy.label}
                icon={navigationTextAtomValue.privacyPolicy.icon}
                onClick={() => handlePrivacyPolicy(navigate)}
              />
              <MenuSectionButton
                label={navigationTextAtomValue.termsOfService.label}
                icon={navigationTextAtomValue.termsOfService.icon}
                onClick={() => handleTermsOfService(navigate)}
              />
              <MenuSectionButton
                label={navigationTextAtomValue.feedback.label}
                icon={navigationTextAtomValue.feedback.icon}
                onClick={() => {
                  navigate(_routeNames.feedback);
                }}
              />
              <MenuSectionButton
                label={navigationTextAtomValue.aboutUs.label}
                icon={navigationTextAtomValue.aboutUs.icon}
                onClick={() => handleAboutUs(navigate)}
              />
            </MenuSectionContainer>
          </>
        ) : (
          <>
            <MenuSectionContainer atomColors={colors}>
              <MenuSectionButton
                label={navigationTextAtomValue.clubs.label}
                icon={navigationTextAtomValue.clubs.icon}
                onClick={() => {
                  handleTeams(navigate);
                }}
              />
              <MenuSectionButton
                label={navigationTextAtomValue.players.label}
                icon={navigationTextAtomValue.players.icon}
                onClick={() => navigate(_routeNames.allPlayers)}
              />
              <MenuSectionButton
                label={navigationTextAtomValue.coaches.label}
                icon={navigationTextAtomValue.coaches.icon}
                onClick={() => navigate(_routeNames.allManagers)}
              />
            </MenuSectionContainer>

            <MenuSectionContainer atomColors={colors}>
              <MenuSectionButton
                label={navigationTextAtomValue.partners.label}
                icon={navigationTextAtomValue.partners.icon}
                onClick={() => navigate(_routeNames.partners)}
              />
              <MenuSectionButton
                label={navigationTextAtomValue.broadcasters.label}
                icon={navigationTextAtomValue.broadcasters.icon}
                onClick={() => navigate(_routeNames.broadcasters)}
              />
              <MenuSectionButton
                label={navigationTextAtomValue.inviteFriends.label}
                icon={navigationTextAtomValue.inviteFriends.icon}
                onClick={() => {}}
              />
            </MenuSectionContainer>

            <MenuSectionContainer atomColors={colors}>
              <MenuSectionButton
                label={navigationTextAtomValue.privacyPolicy.label}
                icon={navigationTextAtomValue.privacyPolicy.icon}
                onClick={() => handlePrivacyPolicy(navigate)}
              />
              <MenuSectionButton
                label={navigationTextAtomValue.termsOfService.label}
                icon={navigationTextAtomValue.termsOfService.icon}
                onClick={() => handleTermsOfService(navigate)}
              />
              <MenuSectionButton
                label={navigationTextAtomValue.feedback.label}
                icon={navigationTextAtomValue.feedback.icon}
                onClick={() => {
                  navigate(_routeNames.feedback);
                }}
              />
              <MenuSectionButton
                label={navigationTextAtomValue.aboutUs.label}
                icon={navigationTextAtomValue.aboutUs.icon}
                onClick={() => handleAboutUs(navigate)}
              />
              <MenuSectionButton
                label={"Login or sign up"}
                onClick={handleLoginOrSignUp}
                backgroundColor={colors.primary}
                textColor={"#fff"}
              />
            </MenuSectionContainer>
          </>
        )}
      </MobileMenuContainer>
    </ClickAwayListener>
  );
}

const MenuSectionButtonWithIcon = ({
  Icon,
  label,
  backgroundColor,
  textColor,
  onClick,
}) => {
  const [colors] = useAtom(colorAtom);
  return (
    <FilledButton
      style={{
        backgroundColor: backgroundColor ?? colors.background,
        color: textColor ?? colors.text,
      }}
      onClick={onClick}
    >
      <Row alignCenter justifyBetween>
        <Row alignCenter gap={10}>
          <Icon />
          <p>{label}</p>
        </Row>
        <AiOutlineArrowRight />
      </Row>
    </FilledButton>
  );
};

const MenuSectionButton = ({
  label,
  icon,
  backgroundColor,
  textColor,
  onClick,
}) => {
  const [colors] = useAtom(colorAtom);
  return (
    <FilledButton
      style={{
        backgroundColor: backgroundColor ? "" : colors.background,
        color: textColor ?? colors.text,
      }}
      onClick={onClick}
    >
      <Row alignCenter justifyBetween>
        <Row alignCenter gap={10} style={{ width: "fit-content" }}>
          {icon ?? ""}
          <p>{label}</p>
        </Row>
        <AiOutlineArrowRight />
      </Row>
    </FilledButton>
  );
};

const useMobileMenu = ({ setSelectedTabIndex, setOpenMobileMenu }) => {
  let location = useLocation();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const setLanguage = useSetAtom(languageAtom);
  const navigationTextAtomValue = useAtomValue(navigationTextAtom);
  const menuLinks = navigationTextAtomValue.menuItems;

  React.useEffect(() => {
    const pathName = location.pathname.slice(1);

    for (let index = 0; index < menuLinks.length; index++) {
      const transformedLink = menuLinks[index].toLowerCase().replace(" ", "");
      const transformedPath = pathName.replace("-", "");

      if (transformedLink.includes(transformedPath)) {
        setSelectedTabIndex(index);
        return;
      }
    }
  }, []);

  const handleProfileButton = () => navigate("/profile");
  const handleLoginOrSignUp = () => dispatch(setOpen());

  const handleLanguageChange = () => {
    setLanguage((prev) => {
      return { ...prev, isEnglish: !prev.isEnglish };
    });
    handleMobileMenu(setOpenMobileMenu);
  };

  const { handleChangeTheme } = useChangeTheme();

  return {
    menuLinks,
    navigate,
    navigationTextAtomValue,
    handleProfileButton,
    handleLoginOrSignUp,
    handleLanguageChange,
    handleChangeTheme,
  };
};

const handleMenuItemClick = (
  index,
  setOpenMobileMenu,
  navigate,
  setSelectedTabIndex
) => {
  setOpenMobileMenu(false);
  setSelectedTabIndex(index);
  routeNavigator(index, navigate);
};

const MobileMenuContainer = styled(motion.div)`
  height: 100vh;
  width: 100%;
  position: fixed;
  right: 0;
  top: 0;
  background-color: ${Colors.primary};
  display: flex;
  flex-direction: column;
  padding: 40px;
  gap: 30px;
  z-index: 500;

  @media (max-width: 400px) {
    padding: 20px;
  }
`;

const MenuSectionContainer = styled.div`
  width: 100%;
  padding-inline: 5px;
  padding-block: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  background-color: ${(props) => props.atomColors.secondary};
`;
