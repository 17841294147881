import logoGrouped from "../../assets/logo-grouped.png";
import { MainContainer } from "../../shared-components/main-container";
import { useEffect } from "react";

import { useAtomValue } from "jotai";
import { AiOutlineLock } from "react-icons/ai";
import { BsPerson } from "react-icons/bs";
import { ForgotPassword } from "../../shared-components/forgot-password";
import { FormContainer } from "../../shared-components/form-container";
import { FramerButtonFilled } from "../../shared-components/framer-button-filled";
import { InputField } from "../../shared-components/input-field";
import { LogoImage } from "../../shared-components/logo-image";
import { SignUpOrLoginInstead } from "../../shared-components/sign-up-instead";
import { colorAtom } from "../../state/shared-state-atoms/colors";


import { languageAtom } from "../../state/shared-state-atoms/language";
import useLoginHook from "./useLoginHook";

export function Login() {
  // useEffect(() => {
  //   console.log(isNaN("yes"))

  // }, [])
  


  const colors = useAtomValue(colorAtom)
  const language = useAtomValue(languageAtom)
  const { password, username, setUsername, setpassword, handleLogin, handleLoginGoogle, handleLoginFacebook, loading, errorMessage } = useLoginHook()
  return (
    <>
      <MainContainer
        style={ {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          minHeight: '100vh',
          backgroundColor: colors.background,
          paddingBlock: 50,
          color: colors.text
        } }
      >
        { loading ? <p>{ language.isEnglish ? "please wait" : "tafadhali subiri" } ... </p> : (
          <>
            <FormContainer>
              <LogoImage src={ logoGrouped } alt="" />
              <p style={ { color: "#757572" } } >{ errorMessage }</p>
              <InputField
                Icon={ () => <BsPerson style={ { fontSize: 20 } } /> }
                placeHolder={ language.isEnglish ? "Phone number or Email" : "Namba ya simu au email yako" }
                value={ username }
                setter={ setUsername }
              />
              <InputField
                Icon={ () => <AiOutlineLock style={ { fontSize: 20 } } /> }
                placeHolder={ language.isEnglish ? "Password" : "Nenosiri" }
                type="password"
                value={ password }
                setter={ setpassword }
              />

              <ForgotPassword />
              <FramerButtonFilled label={ language.isEnglish ? "Login" : 'Ingia' } onClick={ handleLogin } />
              <p style={ { color: "#757572" } } >{ language.isEnglish ? "or" : "au" }</p>
              <FramerButtonFilled label={ language.isEnglish ? "Continue with Google" : "Endelea na Google" } onClick={ handleLoginGoogle } />
              <FramerButtonFilled label={ language.isEnglish ? "Continue with Facebook" : "Endelea na Facebook" } onClick={ handleLoginFacebook } />
            </FormContainer>
            <SignUpOrLoginInstead />
          </>
        ) }
      </MainContainer>
    </>
  );
}

