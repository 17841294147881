import countryCodesList from "country-codes-list"

import { FacebookAuthProvider, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../../core/config/firebase-config";

import { useAtom, useAtomValue, useSetAtom } from "jotai"
import { useState } from "react"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import { _routeNames } from "../../app/routes"
import { postRequestWithOutToken } from "../../core/network-request-functions"
import { URLS } from "../../core/urls"
import { languageAtom } from "../../state/shared-state-atoms/language"
import { tokenAtom } from "../../state/shared-state-atoms/token-atom"
import { userProfileAtom } from "../../state/shared-state-atoms/user-profile-atom"
import { phoneNumberAtom } from "../sign-up-phone-verification-code/sign-up-verification-code"
import { countryCodeAtom } from "./country-code-atom"
import handleLoginWithGoogle from "../../functions/loginGoogle";

const useSignUpHook = () => {
    const language = useAtomValue(languageAtom)
    let _popUpList = []
    const myCountryCodesObject = countryCodesList.all()
    for (const [_, value] of Object.entries(myCountryCodesObject)) {
        _popUpList.push(value)
    }
    const [countryCode, setCountryCode] = useAtom(countryCodeAtom)
    const [selectedValue, setSelectedValue] = useState(_popUpList[1]);
    const [open, setOpen] = useState(false);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
        setSelectedValue(value);
        setCountryCode(value.countryCallingCode)
    };

    let navigate = useNavigate()

    const settokenAtom = useSetAtom(tokenAtom)
    const setuserProfileAtom = useSetAtom(userProfileAtom)

    const [firstName, setfirstName] = useState("")
    const [lastName, setlastName] = useState("")
    const [phoneNumber, setphoneNumber] = useState("")
    const [password, setpassword] = useState("")
    const [confirm_password, setconfirm_password] = useState("")

    const [loading, setLoading] = useState(false)

    const setPhoneNumberForVerification = useSetAtom(phoneNumberAtom)

    const handleContinue = async () => {
        if (!!phoneNumber === false) {
            toast.error(language.isEnglish ? `Phone number should not be empty` : "Unatakiwa uweke namba ya simu")
            return
        }

        const path = URLS.confirmPhoneNumber
        const body = { phone: `${ countryCode }${ phoneNumber }` }

        setLoading(true)
        try {
            const response = await postRequestWithOutToken(path, body)
            if (response.status === 200) {
                setPhoneNumberForVerification(body.phone)
                navigate(_routeNames.auth.signUpVerifyPhoneCode, { replace: true })
            }

            if (response.status === 422) {
                alert("response.data.message")
            }
            setLoading(false)
        } catch ({ response: { data: { message } } }) {
            toast.error(((msg) => {
                if (msg.toLowerCase().includes("already")) {
                    return language.isEnglish ? "The phone number is already registered" : "Namba ya simu uliyoweka tayari imeandikishwa"
                }
                if (msg.toLowerCase().includes("is not valid")) {
                    return language.isEnglish ? "The phone number is not valid" : "Namba ya simu uliyoweka sio sahihi"
                }
                return msg
            })(message))
            setLoading(false)
        }
    }

    const handleLoginGoogle = async () => {
        try {
            setLoading(true)
            const results = await handleLoginWithGoogle({ setuserProfileAtom, settokenAtom })
            if (!results) {
                setLoading(false)
                toast("something went wrong somewhere")
                return
            }
            setLoading(false)
            navigate('/')
        } catch (error) {
            setLoading(false)
            toast("something went wrong")
        }
    }

    const handleLoginFacebook = () => {
        setLoading(true)
        const provider = new FacebookAuthProvider();

        provider.setCustomParameters({
            'display': 'popup'
        });

        signInWithPopup(auth, provider)
            .then((result) => {
                // The signed-in user info.
                const user = result.user;

                // This gives you a Facebook Access Token. You can use it to access the Facebook API.
                const credential = FacebookAuthProvider.credentialFromResult(result);
                const accessToken = credential.accessToken;

                // ...

                postRequestWithOutToken(URLS.auth.social, { access_token: accessToken, provider: 'facebook' })
                    .then(response => {
                        navigate('/')
                    })
                    .catch(error => {
                        console.log({ error })
                    })
                setLoading(false)
            })
            .catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = FacebookAuthProvider.credentialFromError(error);

                // ...
                console.log({ errorCode, errorMessage, email })
                setLoading(false)
            });
    }

    return {
        handleContinue,
        loading,
        setLoading,
        firstName,
        setfirstName,
        lastName,
        setlastName,
        phoneNumber,
        setphoneNumber,
        password,
        setpassword,
        confirm_password,
        setconfirm_password,
        handleLoginGoogle,
        handleLoginFacebook,
        open,
        selectedValue,
        handleClickOpen,
        handleClose,
        _popUpList,
        countryCode,
        navigate
    }
}

export default useSignUpHook