import { useAtom, useAtomValue } from "jotai";
import { Row } from "../../../../../shared-components/row";
import { colorAtom } from "../../../../../state/shared-state-atoms/colors";
import { leagueColors } from "../utils/league-color-map";

import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { _routeNames } from "../../../../../app/routes";
import { useWindowWidth } from "../../../../../hooks/use-window-width";
import { Column } from "../../../../../shared-components/column";
import { languageAtom } from "../../../../../state/shared-state-atoms/language";

export const TableRow = ({
  head,
  number,
  team,
  playedMatches,
  won,
  drawn,
  lost,
  goalDifference,
  points,
  teamCount,
  plusMinus,
  scored,
  conceded,
  highlight,
}) => {
  const [colors] = useAtom(colorAtom);
  let navigate = useNavigate();
  const { windowWidth } = useWindowWidth();
  const language = useAtomValue(languageAtom);

  return (
    <CustomRow
      alignCenter
      justifyBetween
      head={head}
      atomColors={colors}
      style={{
        backgroundColor: highlight ? colors.tableHighlight : "transparent",
        color: highlight ? "#fff" : "",
        fontWeight: highlight ? "bold" : head ? "bold" : "normal",
        gap: 10,
        paddingInline: 10,
        paddingBlock: 5,
        borderBottom: `1px solid ${colors.faintText}`,
        borderLeft: head
          ? "none"
          : `3px solid ${leftBorderColor(
              number,
              teamCount,
              language.isEnglish
            )}`,
      }}
      onClick={() => {
        navigate(`${_routeNames.allTeams}/${team.id}`);
      }}
    >
      <Row alignCenter gap={10} style={{ width: "50%", minWidth: 150 }}>
        <Number>
          {head ? (language.isEnglish ? "Club" : "Klabu") : number}
        </Number>
        <div style={{ width: "80%" }}>
          {head ? (
            ""
          ) : (
            <Row alignCenter gap={5} style={{ width: "fit-content" }}>
              <img
                style={{ height: 25, aspectRatio: 1 / 1, borderRadius: "50%" }}
                src={team.logo}
                alt=""
              />
              <Name>{team.name}</Name>
            </Row>
          )}
        </div>
      </Row>
      <Row alignCenter gap={5} justifyBetween style={{ width: "50%" }}>
        <Label>{head ? "PL" : playedMatches}</Label>
        <Label>{head ? "W" : won}</Label>
        <Label>{head ? "D" : drawn}</Label>
        <Label>{head ? "L" : lost}</Label>
        <Label>{head ? "GF" : scored}</Label>
        <Label>{head ? "GA" : conceded}</Label>
        <Label>{head ? "GD" : goalDifference}</Label>
        <Label>{head ? "Pts" : points}</Label>
      </Row>
    </CustomRow>
  );
};

const leftBorderColor = (number, teamCount, isEnglish) => {
  const colors = leagueColors(isEnglish);
  switch (number) {
    case 1:
      return colors.championsAndCAFChampionsLeague.color;

    case 2:
      return colors.CAFChampionsLeague.color;

    case 3:
      return colors.CAFConfederationCup.color;

    case teamCount - 3:
      return colors.relegationPlayOff.color;

    case teamCount - 2:
      return colors.relegationPlayOff.color;

    case teamCount - 1:
      return colors.relegation.color;

    case teamCount:
      return colors.relegation.color;

    default:
      return "transparent";
  }
};

const CustomRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.2s;
  background-color: ${(props) => {
    if (props.head) return props.atomColors.background;
    return props.atomColors.secondary;
  }};

  &:hover {
    background-color: ${(props) => props.atomColors.background};
    cursor: pointer;
  }

  @media (max-width: 490px) {
    font-size: small;
  }
`;

const Number = styled.p`
  width: 20px;
  text-align: right;
  font-size: small;
  transition: 0.2s;

  ${CustomRow}:hover & {
    font-weight: bold;
  }
`;

const Name = styled.p`
  font-size: small;
  transition: 0.2s;

  ${CustomRow}:hover & {
    font-weight: bold;
  }
`;

const Label = styled.p`
  width: 20px;
  max-width: 20px;
  text-align: center;
  font-size: small;
  transition: 0.2s;

  ${CustomRow}:hover & {
    font-weight: bold;
  }
`;

const LabelWide = styled.p`
  width: 60px;
  max-width: 60px;
  text-align: center;
  font-size: small;
  transition: 0.2s;

  ${CustomRow}:hover & {
    font-weight: bold;
  }
`;
