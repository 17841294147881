import { useEffect, useState } from "react";
import { AnimatePresence } from "framer-motion";
import { NavigationBar } from "../../shared-components/navigation-bar/navigation-bar";
import { MobileMenu } from "../../shared-components/navigation-bar/mobile-menu";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { BORDER_RADIUS } from "../../core/measurements";
import { Column } from "../../shared-components/column";
import { usePlayers } from "./usePlayers";
import playerPlaceholder from "../../assets/placeholder-images/player-placeholder-full.svg";
import noImage from "../../assets/teams/noImage.jpg";

import { handleGoBack } from "../../functions/handleGoBack";
import { GoBack } from "../../shared-components/go-back";

import Modal from "@mui/material/Modal";
import { motion } from "framer-motion";
import { useAtomValue } from "jotai";
import React from "react";
import { _routeNames } from "../../app/routes";
import { Footer } from "../../shared-components/footer/index";
import { Row } from "../../shared-components/row";
import { languageAtom } from "../../state/shared-state-atoms/language";

export function Players() {
  let navigate = useNavigate();
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const language = useAtomValue(languageAtom);
  const {
    colors,
    loading,
    displayPlayersInfo,
    clubs,
    selectedClubIndex,
    handleClubClick,
    open,
    handleOpen,
    handleClose,
    modalLoading,
  } = usePlayers(language);

  if (loading) {
    return (
      <>
        <NavigationBar setOpenMobileMenu={setOpenMobileMenu} />
        <AnimatePresence>
          {openMobileMenu ? (
            <MobileMenu setOpenMobileMenu={setOpenMobileMenu} />
          ) : (
            ""
          )}
        </AnimatePresence>
        <PlayerContainer
          atomColors={colors}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p>{language.isEnglish ? "loading" : "Tafadhali subiri"} ...</p>
        </PlayerContainer>
      </>
    );
  }

  if (!loading) {
    return (
      <>
        <NavigationBar setOpenMobileMenu={setOpenMobileMenu} />
        <AnimatePresence>
          {openMobileMenu ? (
            <MobileMenu setOpenMobileMenu={setOpenMobileMenu} />
          ) : (
            ""
          )}
        </AnimatePresence>
        <PlayerContainer atomColors={colors}>
          <Column
            gap={100}
            style={{ maxWidth: 1200, width: "90%", margin: "0 auto" }}
          >
            <Row alignCenter justifyBetween>
              <GoBack handleGoBack={() => handleGoBack(navigate)} />
            </Row>
          </Column>

          <Row
            alignCenter
            gap={10}
            style={{
              maxWidth: 1200,
              width: "90%",
              margin: "0 auto",
            }}
          >
            <p style={{ fontSize: "small" }}>
              {language.isEnglish ? "Club filter" : "Chagua Klabu"}
            </p>
            <button
              onClick={handleOpen}
              style={{
                width: "fit-content",
                paddingBlock: 10,
                paddingInline: 15,
                border: "none",
                outline: "none",
                color: colors.text,
                backgroundColor: colors.secondary,
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                borderRadius: BORDER_RADIUS,
                fontWeight: 800,
                fontSize: "large",
                cursor: "pointer",
              }}
            >
              <p>
                {selectedClubIndex === 0
                  ? language.isEnglish
                    ? "All Clubs"
                    : "Klabu zote"
                  : clubs[selectedClubIndex].name}
              </p>
            </button>
          </Row>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Column
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                maxWidth: 800,
                width: "90%",
                height: "90%",
                border: "2px solid #000",
                backgroundColor: colors.background,
                color: colors.text,
                padding: 20,
                overflowY: "scroll",
              }}
            >
              {modalLoading ? (
                <p>{language.isEnglish ? "loading" : "Tafadhali subiri"} ...</p>
              ) : (
                <Column gap={20}>
                  <h3>{language.isEnglish ? "Select Club" : "Chagua Klabu"}</h3>
                  {clubs?.map((club, index) => {
                    return (
                      <Row
                        alignCenter
                        key={index}
                        gap={10}
                        style={{
                          fontWeight: selectedClubIndex === index ? "bold" : "",
                          cursor: "pointer",
                          backgroundColor:
                            selectedClubIndex === index
                              ? colors.faintText
                              : colors.secondary,
                          paddingBlock: 20,
                          paddingInline: 25,
                          border: `1px solid ${colors.faintText}`,
                          borderRadius: BORDER_RADIUS,
                        }}
                        onClick={() => handleClubClick(index)}
                      >
                        <p style={{ width: 20 }}>{index + 1}</p>
                        {index === 0 ? (
                          <p>
                            {language.isEnglish ? "All Clubs" : "Klabu zote"}
                          </p>
                        ) : (
                          <>
                            <TeamLogo src={club.logo ?? noImage} alt="" />
                            <p>{club?.name}</p>
                          </>
                        )}
                      </Row>
                    );
                  })}
                </Column>
              )}
            </Column>
          </Modal>

          {displayPlayersInfo.length === 0 ? (
            <Column
              gap={10}
              style={{
                maxWidth: 1200,
                width: "90%",
                margin: "0 auto",
                padding: 50,
                backgroundColor: colors.secondary,
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                borderRadius: BORDER_RADIUS,
              }}
            >
              <p>
                {language.isEnglish
                  ? "no data to display"
                  : "Hakuna taarifa hizi kwa sasa"}
              </p>
            </Column>
          ) : (
            <>
              <Column
                gap={10}
                style={{
                  maxWidth: 1200,
                  width: "90%",
                  margin: "0 auto",
                }}
              >
                {displayPlayersInfo
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((playerInfo, index) => {
                    return (
                      <Row
                        key={index}
                        alignCenter
                        gap={20}
                        style={{
                          backgroundColor: colors.secondary,
                        }}
                        className="p-4 rounded-lg cursor-pointer flex-col md:flex-row"
                        onClick={() =>
                          navigate(
                            `${_routeNames.allPlayers}/${playerInfo._id}`
                          )
                        }
                      >
                        <div className="flex gap-2 whitespace-nowrap items-center justify-between w-full px-2 py-1">
                          <div className="flex items-center gap-3">
                            <p style={{ fontWeight: "bold" }}>{index + 1}</p>
                            <PlayerImage
                              src={playerInfo?.pic}
                              alt=""
                              onError={(error) => {
                                error.target.src =
                                  "https://www.pngitem.com/pimgs/m/22-224249_blank-person-hd-png-download.png";
                              }}
                            />
                            <Column gap={5}>
                              <p
                                style={{
                                  fontWeight: "bold",
                                  textTransform: "capitalize",
                                }}
                              >
                                {playerInfo?.name.toLowerCase()}
                              </p>
                              {playerInfo?.position && (
                                <p style={{ fontSize: "small" }}>
                                  {playerInfo?.position},{" "}
                                  <span style={{ fontWeight: "bold" }}>
                                    {playerInfo?.position_short}
                                  </span>
                                </p>
                              )}
                            </Column>
                          </div>

                          <div className="flex items-center gap-3">
                            <p
                              style={{
                                fontSize: "small",
                                whiteSpace: "nowrap",
                                fontWeight: "bold",
                                textTransform: "capitalize",
                              }}
                              className="hidden md:block"
                            >
                              {playerInfo?.current_club?.name.toLowerCase()}
                            </p>
                            <TeamLogo
                              src={playerInfo.current_club.logo ?? noImage}
                              alt=""
                              onError={(error) => {
                                error.target.src =
                                  "https://i.pinimg.com/564x/00/cf/8a/00cf8a3622f8d1707a5ac98ff3ea822e.jpg";
                              }}
                            />
                          </div>
                        </div>
                      </Row>
                    );
                  })}
              </Column>
            </>
          )}
        </PlayerContainer>
        <Footer />
      </>
    );
  }
}

const SearchIconContainer = styled(motion.div)`
  height: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: ${(props) => props.atomColors.secondary};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer;
  font-size: large;

  transition: 0.2s;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: ${(props) => props.atomColors.background};
  }
`;

const PlayerContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  color: ${(props) => props.atomColors.text};
  background-color: ${(props) => props.atomColors.background};
  position: relative;
  padding-block: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const PlayerImage = styled.img`
  height: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;

const TeamLogo = styled.img`
  height: 30px;
  aspect-ratio: 1;
  border-radius: 50%;
`;

const PlayersHeaderLabel = styled.h3`
  width: 60%;
  margin: 0 auto;
  font-size: ${(props) => (props.noData ? "large" : "xx-large")};
  color: ${(props) => props.atomColors.faintText};
  margin-bottom: ${(props) => (props.noData ? "" : "10px")};

  @media (max-width: 1000px) {
    width: 90%;
  }
`;

const TeamInfoContainer = styled.div`
  min-height: 85px;
  width: 60%;
  border-radius: ${BORDER_RADIUS};
  margin: 0 auto;
  padding-inline: 20px;
  padding-block: 10px;
  cursor: pointer;

  background-color: ${(props) => props.atomColors.secondary};
  border-left: ${(props) => `5px solid ${props.teamColor}`};

  display: flex;
  align-items: center;
  gap: 10px;

  transition: 0.3s;

  &:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  @media (max-width: 1000px) {
    width: 90%;
  }
`;
