import { MainContainer } from "../../shared-components/main-container";
import logoGrouped from "../../assets/logo-grouped.png";

import { BsPerson } from "react-icons/bs";
import { AiOutlineLock } from "react-icons/ai";
import { BiMobile } from "react-icons/bi";
import { SignUpOrLoginInstead } from "../../shared-components/sign-up-instead";
import { FormContainer } from "../../shared-components/form-container";
import { LogoImage } from "../../shared-components/logo-image";
import { InputField } from "../../shared-components/input-field";
import { FramerButtonFilled } from "../../shared-components/framer-button-filled";
import { LARGE_SPACING } from "../../core/measurements";
import { useAtomValue, useSetAtom } from "jotai";
import { colorAtom } from "../../state/shared-state-atoms/colors";
import { _routeNames } from "../../app/routes";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { URLS } from "../../core/urls";
import { postRequestWithOutToken } from "../../core/network-request-functions";
import { tokenAtom } from "../../state/shared-state-atoms/token-atom";
import { userProfileAtom } from "../../state/shared-state-atoms/user-profile-atom";
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { auth } from "../../core/config/firebase-config";
import toast from "react-hot-toast";
import { languageAtom } from "../../state/shared-state-atoms/language";
import { phoneNumberAtom } from "../sign-up-phone-verification-code/sign-up-verification-code";

export function SignUp() {
  const colors = useAtomValue(colorAtom);
  const {
    handleSignUp,
    loading,
    firstName,
    setfirstName,
    lastName,
    setlastName,
    phoneNumber,
    setPhoneNumber,
    email,
    setemail,
    password,
    setpassword,
    confirm_password,
    setconfirm_password,
    handleLoginGoogle,
    handleLoginFacebook,
  } = useSignUpHook();
  return (
    <>
      <MainContainer
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          minHeight: "100vh",
          backgroundColor: colors.background,
          paddingBlock: 50,
        }}
      >
        <FormContainer>
          <LogoImage src={logoGrouped} alt="" />
          <InputField
            Icon={() => <BsPerson style={{ fontSize: 20 }} />}
            placeHolder="First name"
            type="text"
            value={firstName}
            setter={setfirstName}
          />
          <InputField
            Icon={() => <BsPerson style={{ fontSize: 20 }} />}
            placeHolder="Last name"
            type="text"
            value={lastName}
            setter={setlastName}
          />
          <InputField
            Icon={() => <BsPerson style={{ fontSize: 20 }} />}
            placeHolder="Email"
            type="email"
            value={email}
            setter={setemail}
          />
          <InputField
            Icon={() => <BiMobile style={{ fontSize: 20 }} />}
            placeHolder="Phone number"
            type="phone"
            value={phoneNumber}
            setter={setPhoneNumber}
          />
          <InputField
            Icon={() => <AiOutlineLock style={{ fontSize: 20 }} />}
            placeHolder="Password"
            type="password"
            value={password}
            setter={setpassword}
          />
          <InputField
            Icon={() => <AiOutlineLock style={{ fontSize: 20 }} />}
            placeHolder="Confirm password"
            type="password"
            value={confirm_password}
            setter={setconfirm_password}
          />
          <FramerButtonFilled
            label={loading ? "loading ..." : "Sign Up"}
            marginTop={LARGE_SPACING}
            onClick={handleSignUp}
          />
          <p style={{ color: "#757572" }}>or continue with</p>
          <FramerButtonFilled
            label="Continue with Google"
            onClick={handleLoginGoogle}
          />
          <FramerButtonFilled
            label="Continue with Facebook"
            onClick={handleLoginFacebook}
          />
        </FormContainer>
        <SignUpOrLoginInstead signUp />
      </MainContainer>
    </>
  );
}

export const useSignUpHook = ({ goBack }) => {
  let navigate = useNavigate();

  const settokenAtom = useSetAtom(tokenAtom);
  const setuserProfileAtom = useSetAtom(userProfileAtom);

  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [confirm_password, setconfirm_password] = useState("");

  const [loading, setLoading] = useState(false);

  const language = useAtomValue(languageAtom);

  const setPhoneNumberForVerification = useSetAtom(phoneNumberAtom);

  const handleSignUp = () => {
    if (!!firstName === false) {
      toast.error(
        language.isEnglish ? "first name required" : "weka jina la kwanza"
      );
      return;
    }

    if (firstName.length < 3) {
      toast.error(
        language.isEnglish
          ? "first name  must exceed 3 characters"
          : "jina la kwanza liwe na zaid ya tarakimu 3"
      );
      return;
    }
    if (lastName.length < 3) {
      toast.error(
        language.isEnglish
          ? "last name  must exceed 3 characters"
          : "jina la mwisho liwe na zaid ya tarakimu 3"
      );
      return;
    }
    if (!!lastName === false) {
      toast.error(
        language.isEnglish ? "last name required" : "weka jina la mwisho"
      );
      return;
    }
    const checkUserName = Boolean(Boolean(phoneNumber) || Boolean(email));

    if (!checkUserName) {
      toast.error(
        language.isEnglish
          ? "email or phone is required"
          : "email au namba ya simu inatakiwa"
      );
      return;
    }

    const checkPhone = Boolean(
      phoneNumber &&
        Boolean(phoneNumber.length === 10 || phoneNumber.length === 12)
    );

    if (!checkPhone) {
      toast.error(
        language.isEnglish
          ? "phone number must be valid and start with 0 or 255"
          : "namba ya simu lazima ianze na 0 au 255"
      );
      return;
    }

    if (!!password === false) {
      toast.error(language.isEnglish ? "password required" : "weka neno siri");
      return;
    }
    if (password.length < 6) {
      toast.error(
        language.isEnglish
          ? "password must not be less than 6"
          : "neno siri liwe na tarakimu 6"
      );
      return;
    }
    if (!!confirm_password === false) {
      toast.error(
        language.isEnglish
          ? "confirm password required"
          : "weka neno siri mara ya pili"
      );
      return;
    }

    if (password !== confirm_password) {
      toast.error(
        language.isEnglish ? "password mismatch" : "neno siri hazifanani"
      );
      return;
    }

    const path = URLS.signup;
    const body = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phoneNumber,
      password: password,
      confirmPassword: confirm_password,
    };

    setLoading(true);
    postRequestWithOutToken(path, body)
      .then((response) => {
        if (response.status === 200) {
          setPhoneNumberForVerification(body.phone ?? body.email);
          const path = URLS.auth.verifyPhone;
          const data = {
            username: body.phone ?? body.email,
            otp: response.data,
          };
          postRequestWithOutToken(path, data).then((res) => {
            if (res.status === 200 || res.status === 201) {
              setLoading(false);
              toast.success(
                language.isEnglish
                  ? "User Registered successffully"
                  : "Usajili umefanikiwa"
              );
              goBack();
            } else {
              setLoading(false);
            }
          });

          // navigate(_routeNames.auth.signUpVerifyPhoneCode, { replace: true });
        } else {
          setfirstName("");
          setlastName("");
          setemail("");
          setPhoneNumber("");
          setpassword("");
          setconfirm_password("");
          toast.error(
            language.isEnglish
              ? "Something went wrong, please try again later"
              : "Kuna tatizo limejitokeza, tafadhali jaribu tena baadae"
          );
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log({ error });
        toast.error(
          language.isEnglish
            ? error.response.data.message
            : `kuna tatzo limetokea, ${error.response.data.message}`
        );
        setLoading(false);
      });
  };

  const handleLoginGoogle = () => {
    setLoading(true);

    const provider = new GoogleAuthProvider();

    provider.setCustomParameters({
      login_hint: "user@example.com",
    });

    // const auth = getAuth();

    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        // ...
        postRequestWithOutToken(URLS.auth.social, {
          access_token: token,
          provider: "google",
        })
          .then((response) => {
            console.log(response);
            settokenAtom(response.data.access_token);
            setuserProfileAtom({
              firstName: response.data.user.firstName,
              lastName: response.data.user.lastName,
              fullName: `${response.data.user.firstName} ${response.data.user.lastName}`,
              email: response.data.user.email,
              phone: response.data.user.phone,
              userId: response.data.user.user._id,
              token: response.data.user.token,
              loginMethod: "social",
            });
            navigate("/");
          })
          .catch((error) => {
            console.log({ error });
          });
        setLoading(false);
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
        console.log({ errorCode, errorMessage, email });
        setLoading(false);
      });
  };

  const handleLoginFacebook = () => {
    setLoading(true);
    const provider = new FacebookAuthProvider();

    provider.setCustomParameters({
      display: "popup",
    });

    signInWithPopup(auth, provider)
      .then((result) => {
        // The signed-in user info.
        const user = result.user;

        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        const credential = FacebookAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;

        // ...

        postRequestWithOutToken(URLS.auth.social, {
          access_token: accessToken,
          provider: "facebook",
        })
          .then((response) => {
            navigate("/");
          })
          .catch((error) => {
            console.log({ error });
          });
        setLoading(false);
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = FacebookAuthProvider.credentialFromError(error);

        // ...
        console.log({ errorCode, errorMessage, email });
        setLoading(false);
      });
  };

  return {
    handleSignUp,
    loading,
    setLoading,
    firstName,
    setfirstName,
    lastName,
    setlastName,
    phoneNumber,
    setPhoneNumber,
    email,
    setemail,
    password,
    setpassword,
    confirm_password,
    setconfirm_password,
    handleLoginGoogle,
    handleLoginFacebook,
  };
};
