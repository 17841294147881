import styled from 'styled-components'

export const PlayerCircleText = ({ text }) => <PlayerLineupName> { text } </PlayerLineupName>

const PlayerLineupName = styled.p`
    width: fit-content;
    text-align: center;
    color: white;
    background-color: #0F9D58;
    margin-top: 5px;
`