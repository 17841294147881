import { useAtom } from "jotai";
import { themeAtom } from "../state/shared-state-atoms/_theme";

export const useChangeTheme = () => 
{
    const [theme, setTheme] = useAtom(themeAtom);

    const handleChangeTheme = () => 
    {
        setTheme((prev) =>
        {
            return { ...prev, lightTheme: !prev.lightTheme };
        })
    }

    return { theme, handleChangeTheme }
}