import { Row } from "../../../../../../shared-components/row";
import { Column } from "../../../../../../shared-components/column";
import { useAtom, useAtomValue } from "jotai";
import { colorAtom } from "../../../../../../state/shared-state-atoms/colors";
import { BORDER_RADIUS } from "../../../../../../core/measurements";

import logo from "../../../../../../assets/logo-grouped.png";
import { useWindowWidth } from "../../../../../../hooks/use-window-width";
import { StatFilterSelect } from "../../components/filter-select";
import { SeasonSelect } from "../../components/season-select";
import { StatRow } from "../../components/stat-row";
import { teamStatAtom } from "./team-stat-atom";
import { useState } from "react";
import Label from "../../../../../../shared-components/Label";
import { SelectField } from "../player-stats";
import { useEffect } from "react";
import noImage from "../../../../../../assets/teams/noImage.jpg";
import apiCall from "../../../../../../functions/api_call";
import { URLS } from "../../../../../../core/urls";
import { _routeNames } from "../../../../../../app/routes";
import { useNavigate } from "react-router-dom";
import { languageAtom } from "../../../../../../state/shared-state-atoms/language";

export function StatsPageTeamStats() {
  let navigate = useNavigate();
  const [colors] = useAtom(colorAtom);
  const teamStatsData = useAtomValue(teamStatAtom);
  const { windowWidth } = useWindowWidth();
  const statsPageTeamStatsHook = useStatsPageTeamStats();
  const lang = useAtomValue(languageAtom);
  const FullList = () => {
    return (
      <Column
        gap={0}
        style={{ marginTop: 10, marginBottom: 50, minHeight: "80vh" }}
      >
        {((teamsData) => {
          let data = [];
          teamsData.forEach((dt) => {
            let requiredSeason = dt.seasons.find(
              (seasonStat) => seasonStat.year === seasonQuerry
            );
            if (requiredSeason) {
              data.push({ ...dt, requiredSeason: requiredSeason });
            }
          });
          let sortedData = data.sort(
            (a, b) => b.requiredSeason[key] - a.requiredSeason[key]
          );
          return sortedData ?? [];
        })(statsPageTeamStatsHook.teamsData).map((team, index) => {
          return (
            <Row
              key={index}
              alignCenter
              gap={20}
              onClick={() => navigate(`${_routeNames.allTeams}/${team._id}`)}
              style={{
                paddingInline: 20,
                paddingBlock: 10,
                overflow: "clip",
                cursor: "pointer",
                borderTop: `1px solid #85F4FF`,
              }}
            >
              <Label xBold noWrap text={index + 1} />
              <img src={team.logo ?? noImage} style={teamLogoStyle()} />
              <Column justifyCenter gap={5}>
                <Label xBold noWrap text={team?.name} />
              </Column>
              <Label xBold text={team.requiredSeason[key] ?? 0} />
            </Row>
          );
        })}
      </Column>
    );
  };

  if (statsPageTeamStatsHook.loading) {
    return (
      <Column alignCenter justifyCenter style={{ padding: 50 }}>
        <Label text={"Loading ..."} />
      </Column>
    );
  }

  const seasonQuerry =
    statsPageTeamStatsHook.seasons[statsPageTeamStatsHook.selectedSeason];
  let key =
    statsPageTeamStatsHook.statCriteria[
      statsPageTeamStatsHook.selectedStatCriteria
    ];

  return (
    <>
      <Row
        alignCenter
        gap={10}
        style={{
          ...headerNavigationStyle(colors),
          flexDirection: windowWidth < 600 ? "column" : "row",
        }}
      >
        <Row alignCenter gap={10}>
          <img
            src={logo}
            alt=""
            style={{ height: windowWidth < 1000 ? 50 : 80 }}
          />
          <Column gap={10}>
            <p
              style={{
                fontSize: windowWidth < 1000 ? 15 : 20,
                whiteSpace: "nowrap",
                fontWeight: 800,
              }}
            >
              {lang.isEnglish ? "NBC Premier League" : "Ligi Kuu ya NBC"}
            </p>
            {lang.isEnglish ? (
              <p
                style={{
                  fontSize: "small",
                  whiteSpace: "nowrap",
                  fontWeight: "bold",
                }}
              >
                {seasonQuerry}
                <span style={{ fontWeight: "normal", marginLeft: 5 }}>
                  season
                </span>
              </p>
            ) : (
              <p
                style={{
                  fontSize: "small",
                  whiteSpace: "nowrap",
                  fontWeight: "bold",
                }}
              >
                <span style={{ fontWeight: "normal", marginLeft: 5 }}>
                  msimu wa{" "}
                </span>
                {seasonQuerry}
              </p>
            )}
          </Column>
        </Row>

        <Row
          alignCenter
          gap={10}
          style={{
            justifyContent: windowWidth < 600 ? "flex-start" : "flex-end",
          }}
        >
          {statsPageTeamStatsHook.selectOptions?.map((dt, index) => {
            return (
              <SelectField
                key={index}
                label={dt.label}
                value={dt.value}
                onChange={dt.onChange}
                options={dt.options}
                fullWidth={windowWidth < 600 ? true : false}
              />
            );
          })}
        </Row>
      </Row>
      {/* full list below */}

      {/* <FullList /> */}
    </>
  );

  function headerNavigationStyle(colors) {
    return {
      backgroundColor: colors.secondary,
      padding: "10px 20px",
      borderRadius: BORDER_RADIUS,
      width: "100%",
      margin: "0 auto",
    };
  }

  function teamLogoStyle() {
    return {
      height: 50,
      aspectRatio: 1,
      borderRadius: "50%",
      backgroundColor: colors.secondary,
      objectFit: "cover",
    };
  }
}

function useStatsPageTeamStats() {
  const [loading, setLoading] = useState(false);
  const language = useAtomValue(languageAtom);

  const statCriteria = [
    "points",
    "plays",
    "wins",
    "draw",
    "scored_goals",
    "concided_goals",
  ];
  const displayStatCriteria = [
    language.isEnglish ? "points" : "pointi",
    language.isEnglish ? "plays" : "mechi kuchezwa",
    language.isEnglish ? "wins" : "mechi kushinda",
    language.isEnglish ? "draw" : "sare",
    language.isEnglish ? "scored_goals" : "magoli",
    language.isEnglish ? "conceded_goals" : "Magoli ya kufungwa",
  ];
  const [selectedStatCriteria, setSelectedStatCriteria] = useState(0);
  const onChangeStatCriteria = (e) => setSelectedStatCriteria(e.target.value);
  const [seasons, setSeasons] = useState(["2022-2023"]);

  const [selectedSeason, setSelectedSeason] = useState((prev) => {
    prev = seasons.length - 1;
    return prev;
  });
  const onChangeSeason = (e) => setSelectedSeason(e.target.value);

  const [teamsData, setTeamsData] = useState([]);

  useEffect(() => {
    setLoading(true);
    apiCall({ url: `${URLS.allTeamsBySeason}/${seasons[selectedSeason]}` })
      .then((resp) => {
        setTeamsData((prev) => {
          prev = resp.data;
          return prev;
        });
      })
      .finally(() => setLoading(false));
  }, [selectedSeason]);

  const selectOptions = (() => {
    return [
      {
        label: language.isEnglish ? "Season filter" : "Msimu",
        value: selectedSeason,
        onChange: onChangeSeason,
        options: ((options) => {
          return options.map((option, index) => ({
            value: index,
            label: option,
          }));
        })(seasons ?? []),
      },
      {
        label: language.isEnglish ? "Stat filter" : "Takwimu",
        value: selectedStatCriteria,
        onChange: onChangeStatCriteria,
        options: ((options) => {
          return options.map((option, index) => ({
            value: index,
            label: option.replace("_", " "),
          }));
        })(displayStatCriteria ?? []),
      },
    ];
  })();

  return {
    loading,
    statCriteria,
    seasons,
    selectedStatCriteria,
    onChangeStatCriteria,
    selectedSeason,
    onChangeSeason,
    selectOptions,
    teamsData,
  };
}
