import styled from 'styled-components'

const RowItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: fit-content;
    padding-block: 10px;
`

export default RowItem