import { useMatchDayStatsHook } from "../../use-match-stats";
import { Row } from "../../../../shared-components/row";
import { Column } from "../../../../shared-components/column";
import { BORDER_RADIUS } from "../../../../core/measurements";
import Label from "../../../../shared-components/Label";
import { useAtomValue } from "jotai";
import { colorAtom } from "../../../../state/shared-state-atoms/colors";
import { matchStatsAtom } from "../../match-stats-atom";
import { languageAtom } from "../../../../state/shared-state-atoms/language";

export function StatsTab() {
  const { matchStats, loading } = useMatchDayStatsHook();
  const language = useAtomValue(languageAtom)

  if (loading) {
    return (
      <>
        <Row alignCenter justifyCenter style={{ height: 80 }}>
          <p>{language.isEnglish ? "loading" : "tafadhali subiri"} ...</p>
        </Row>
      </>
    );
  }

  if (!loading) {
    return (
      <>
        {matchStats.length === 0 ? (
          <Row alignCenter justifyCenter style={{ height: 80 }}>
            <p>{language.isEnglish ? "No data to display" : "samahani, hakuna taarifa za kuonesha kwa sasa"}</p>
          </Row>
        ) : (
          <>
            <Column gap={20} style={{ paddingBlock: 40 }}>
              <MatchStatsRow matchStats={matchStats} label={"AVERAGE TEAM POSSESSION"} statName={"passes"} percent />
              <MatchStatsRow matchStats={matchStats} label={"SHOTS"} statName={"shots"} />
              <MatchStatsRow matchStats={matchStats} label={"SHOTS ON TARGET"} statName={"shots_on_target"} />
              <MatchStatsRow matchStats={matchStats} label={"GOALS"} statName={"goals"} />
              <MatchStatsRow matchStats={matchStats} label={"CHANCES"} statName={"chances"} />
              <MatchStatsRow matchStats={matchStats} label={"PASSES"} statName={"passes"} />
              <MatchStatsRow matchStats={matchStats} label={"ACCURATE PASSES"} statName={"accurate_pass"} />
              <MatchStatsRow matchStats={matchStats} label={"FOULS"} statName={"fouls"} />
              <MatchStatsRow matchStats={matchStats} label={"YELLOW CARDS"} statName={"yellow_cards"} />
              <MatchStatsRow matchStats={matchStats} label={"RED CARDS"} statName={"red_cards"} />
              <MatchStatsRow matchStats={matchStats} label={"OFFSIDES"} statName={"offsides"} />
              <MatchStatsRow matchStats={matchStats} label={"CORNERS"} statName={"corner"} />
            </Column>
          </>
        )}
      </>
    );
  }
}

function MatchStatsRow({ matchStats, label, statName, percent }) {
  let statsSum = matchStats[0][statName] + matchStats[1][statName];
  if (!percent) {
    return (
      <Row alignCenter justifyBetween style={{ paddingInline: 40 }}>
        <Label small text={matchStats[0][statName]} />
        <Label bold small text={label} />
        <Label small text={matchStats[1][statName]} />
      </Row>
    );
  }

  console.log(statsSum)
  console.log(matchStats[0][statName])
  console.log(matchStats[1][statName])

  const pos1 = (matchStats[0][statName] / statsSum) * 100
  const pos2 = (matchStats[1][statName] / statsSum) * 100


  
  return (
    <Column alignCenter justifyCenter gap={10} style={{ paddingInline: 40 }}>
      <Label bold small text={label} />
      <Row alignCenter gap={5} style={{ height: 60 }}>
        <PossessionBar possession={isNaN(pos1) ? 50 : pos1} isHome />
        <PossessionBar possession={isNaN(pos2) ? 50 : pos2} isHome />
      </Row>
    </Column>
  );
}

function PossessionBar({ possession, isHome }) {
  const colors = useAtomValue(colorAtom);
  const matchStatsAtomValue = useAtomValue(matchStatsAtom);
  let sideAbsoluteSpacing = 15;

  return (
    <div style={possessionBarContainerStyle()}>
      <div style={possessionLabelStyle()}>
        <Label xBold text={`${Math.round(possession)}%`} />
      </div>
    </div>
  );

  function possessionBarContainerStyle() {
    return {
      width: !possession ? `50%` : `${possession}%`,
      backgroundColor: isHome ? matchStatsAtomValue.home.teamColor : !isHome ? matchStatsAtomValue.away.teamColor : colors.text,
      backgroundColor: 'blue',
      height: "100%",
      padding: 20,
      position: "relative",
      borderRadius: BORDER_RADIUS,
    };
  }

  function possessionLabelStyle() {
    return {
      position: "absolute",
      left: isHome ? sideAbsoluteSpacing : "",
      right: !isHome ? sideAbsoluteSpacing : "",
      top: "50%",
      transform: "translateY(-50%)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: 5,
      borderRadius: BORDER_RADIUS,
      color: "#fff",
    };
  }
}
