import styled from 'styled-components'

const IconBox = styled.div`
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #E21D24;
`

export default IconBox