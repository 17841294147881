import { useAtomValue } from "jotai"
import { useEffect, useState } from "react"
import { URLS } from "../../../../../../core/urls"
import apiCall from "../../../../../../functions/api_call"
import { languageAtom } from "../../../../../../state/shared-state-atoms/language"

export default function useStatsPagePlayerStats() {
    const [loading, setLoading] = useState(false)

    const language = useAtomValue(languageAtom)
    const statCriteria = ["assists", "clean_sheet", "goals"]
    const displayStatCriteria = [
        language.isEnglish ? "assists" : "asisti",
        language.isEnglish ? "clean_sheet" : "bila kufungwa",
        language.isEnglish ? "goals" : "magoli",
    ]
    const [selectedStatCriteria, setSelectedStatCriteria] = useState(0)
    const [seasons, setSeasons] = useState(['2022-2023'])
    const onChangeStatCriteria = (e) => setSelectedStatCriteria(e.target.value)
    const [selectedSeason, setSelectedSeason] = useState(prev => { prev = seasons.length - 1; return prev })
    const onChangeSeason = (e) => setSelectedSeason(e.target.value)

    const [playersData, setPlayersData] = useState([])

    useEffect(() => {
        setLoading(true)
        apiCall({ url: URLS.allPlayersHistory })
            .then(resp => {
                setPlayersData(prev => {
                    prev = resp.data ?? []
                    return prev
                })
            })
            .finally(() => { setLoading(false) })
    }, [])

    const selectOptions = (() => {
        return [
            {
                label: language.isEnglish ? "Season filter" : "Msimu",
                value: selectedSeason,
                onChange: onChangeSeason,
                options: ((options) => {
                    return options.map((option, index) => ({
                        value: index,
                        label: option,
                    }));
                })(seasons ?? [])
            },
            {
                label: language.isEnglish ? "Stat filter" : "Takwimu",
                value: selectedStatCriteria,
                onChange: onChangeStatCriteria,
                options: ((options) => {
                    return options.map((option, index) => ({
                        value: index,
                        label: option.replace("_", " "),
                    }));
                })(displayStatCriteria ?? [])
            }
        ]
    })()

    return {
        loading,
        statCriteria,
        seasons,
        playersData,
        selectedStatCriteria, onChangeStatCriteria,
        selectedSeason, onChangeSeason,
        selectOptions
    }
}