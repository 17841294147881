import CountryList from "country-list-with-dial-code-and-flag";

function findCountry(callCode)
{
  let country = CountryList.find((cty) => cty.dial_code.replace("+", "") === callCode);
  if (!country) country = CountryList.find((cty) => cty.name === callCode);
  return country?.name?.split(",")[0] ?? "n/a";
}

export default findCountry;
