import { useAtomValue } from "jotai";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { matchStatisticsRoutePath } from "../../../../../app/routes";
import { Column } from "../../../../../shared-components/column";
import { Row } from "../../../../../shared-components/row";
import { languageAtom } from "../../../../../state/shared-state-atoms/language";

import {
  HiOutlineTicket,
  HiOutlineVideoCamera,
  BsTicketPerforated,
} from "react-icons/hi";
import azamSportsTvLogo from "../../../../../assets/azam/sp2.png";
import noImage from "../../../../../assets/teams/noImage.jpg";

import { BORDER_RADIUS } from "../../../../../core/measurements";

export default function MatchDayFixture({
  colors,
  fixture,
  setMatchStatsAtom,
}) {
  let navigate = useNavigate();
  const language = useAtomValue(languageAtom);
  const handleClick = async () => {
    await setMatchStatsAtom({
      home: {
        logo: fixture?.home_team?.logo,
        name: fixture?.home_team?.nick_name,
        teamColor: fixture?.home_team?.team_color,
      },
      away: {
        logo: fixture?.away_team.logo,
        name: fixture?.away_team.nick_name,
        teamColor: fixture?.away_team.team_color,
      },
    });
    navigate(`${matchStatisticsRoutePath}/${fixture?._id}`);
  };

  return (
    <MatchDayRow
      atomColors={colors}
      onClick={handleClick}
      style={{ position: "relative", height: 150 }}
    >
      <Column gap={10} alignCenter justifyCenter style={{ width: "100%" }}>
        <Row alignCenter justifyCenter style={{ width: "100%" }}>
          {fixture.status === "fin" ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: 25,
                aspectRatio: 1,
                borderRadius: "50%",
                color: "#fff",
                backgroundColor: "#D28E0B",
                fontSize: "small",
                fontWeight: "bold",
              }}
            >
              <p>FT</p>
            </div>
          ) : fixture.status === "nor" ? (
            <div></div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: 20,
                width: 50,
                aspectRatio: 1,
                borderRadius: "25%",
                color: "#fff",
                backgroundColor: "green",
                fontSize: "small",
                fontWeight: "bold",
              }}
            >
              <p>Live</p>
            </div>
          )}
        </Row>

        <Row gap={10} alignCenter justifyBetween style={{ width: "100%" }}>
          <Row alignCenter gap={10} style={{ justifyContent: "flex-end" }}>
            <p style={{ fontSize: "13px", fontWeight: "bold" }}>
              {fixture.home_team.nick_name}
            </p>
            <img
              src={fixture.home_team.logo ? fixture.home_team.logo : noImage}
              alt=""
              style={{ height: 40, aspectRatio: 1, borderRadius: "50%" }}
            />
          </Row>
          <Column
            alignCenter
            justifyCenter
            gap={5}
            style={{ width: "fit-content" }}
          >
            {fixture.status === "nor" && (
              <h4 style={{ color: colors.text }}>{fixture.playing_time}</h4>
            )}

            {fixture.stats && (
              <h4
                style={{ color: colors.text }}
                className="font-bold whitespace-nowrap"
              >
                {fixture.stats[0]?.goals} - {fixture.stats[1]?.goals}
              </h4>
            )}
          </Column>
          <Row alignCenter gap={10}>
            <img
              src={fixture.away_team.logo ? fixture.away_team.logo : noImage}
              alt=""
              style={{ height: 40, aspectRatio: 1, borderRadius: "50%" }}
            />
            <p style={{ fontSize: "13px", fontWeight: "bold" }}>
              {fixture.away_team.nick_name}
            </p>
          </Row>
        </Row>

        <Row alignCenter justifyCenter style={{ width: "100%" }}>
          <Row alignCenter gap={10} style={{ width: "fit-content" }}>
            {fixture.status === "nor" && (
              <Row alignCenter gap={5} style={{ width: "fit-content" }}>
                <HiOutlineVideoCamera style={{ color: colors.text }} />
                <h5 style={{ fontSize: "small" }}>
                  {language.isEnglish ? "live on" : "angalia kwenye"}
                </h5>
              </Row>
            )}
            {fixture.status !== "nor" && (
              <p style={{ fontSize: "small", marginRight: 2 }}>
                {fixture.playing_time}
              </p>
            )}
            <img src={azamSportsTvLogo} alt="" style={{ width: 100 }} />
          </Row>
        </Row>
        {fixture?.ground ? (
          <Row alignCenter justifyCenter style={{ width: "100%" }}>
            <Row alignCenter gap={10} style={{ width: "fit-content" }}>
              <Row alignCenter gap={5} style={{ width: "fit-content" }}>
                <HiOutlineTicket style={{ color: colors.text }} />
                <h5 style={{ fontSize: "small" }}>
                  {language.isEnglish ? "tickets at" : "tiketi zinapatikana "}
                </h5>
              </Row>
              <h5 style={{ fontSize: "small", color: colors.text }}>
                {fixture?.ground}
              </h5>
            </Row>
          </Row>
        ) : (
          <></>
        )}
      </Column>
    </MatchDayRow>
  );
}

const MatchDayRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  cursor: pointer;
  background-color: ${(props) => props.atomColors.secondary};
  transition: 0.3s;
  padding-block: 30px;
  border-radius: ${BORDER_RADIUS};

  &:hover {
    background-color: ${(props) => props.atomColors.background};
    font-weight: bold;
  }
`;
