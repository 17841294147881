import { useAtom, useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import nbcLogo from "../../assets/logo-grouped.png";
import playerPlaceHolder from "../../assets/placeholder-images/player-placeholder-full.svg";
import noImage from "../../assets/teams/noImage.jpg";
import { URLS } from "../../core/urls";
import apiCall from "../../functions/api_call";
import { useWindowWidth } from "../../hooks/use-window-width";
import { Column } from "../../shared-components/column";
import DataNotFound from "../../shared-components/data_not_found";
import Label from "../../shared-components/Label";
import { Row } from "../../shared-components/row";
import { colorAtom } from "../../state/shared-state-atoms/colors";
import { languageAtom } from "../../state/shared-state-atoms/language";
import ManagerAwardCard from "../home/components/home-tab/components/manager-of-the-month/components/AwardCard";
import AwardCard from "../home/components/home-tab/components/player-of-the-month/components/AwardCard";
import GridLayout from "../home/components/home-tab/components/player-stats/components/gridLayout";
import { awardStatsDummyData } from "../home/components/stats/tabs-v2/awards-tab/awards-tab-dummy-data";
import { SelectField } from "../home/components/stats/tabs/player-stats";
import { TabSwitcherComponent } from "../match-stats/components/tab-switcher-component";
import { allStatsAwardsListAtom } from "./all-stats-list-awards-atom";
import awardsAtom from "./awards-atom";
import moment from "moment";

export function AllStatsAwardsList() {
  let navigate = useNavigate();
  const {
    seasonSelectOptions,
    selectedSeason,
    seasons,
    awards,
    managers,
    players,
    monthtostring,
    loading,
    setLoading,
  } = useAllStatsHook();
  const colors = useAtomValue(colorAtom);
  const { windowWidth } = useWindowWidth();
  const lang = useAtomValue(languageAtom);
  const [selectedTabIndex, set_selectedTabIndex] = useAtom(awardsAtom);
  const handle_selectedTabIndex_change = (newIndex) => {
    setLoading(true);
    set_selectedTabIndex(newIndex);
  };

  return (
    <>
      <TabSwitcherComponent
        tabs={[
          lang.isEnglish ? "player of the month" : "mchezaji wa mwezi",
          lang.isEnglish ? "manager of the month" : "kocha wa mwezi",
          lang.isEnglish ? "Team of the season" : "timu wa msimu",
          lang.isEnglish ? "manager of the season" : "kocha wa msimu",
        ]}
        selectedTabIndex={selectedTabIndex}
        handleChange={(e) => handle_selectedTabIndex_change(e)}
        colors={colors}
        textColor={colors.text}
        indicatorColor={colors.text}
        background={colors.secondary}
        fullWwidth
        language={lang}
      />

      {loading ? (
        <Column alignCenter justifyCenter style={{ padding: 50 }}>
          <Label
            capitalize
            text={lang.isEnglish ? "loading ..." : "tafadhali subiri"}
          />
        </Column>
      ) : (
        <>
          {((index) => {
            switch (index) {
              case 0:
                return (
                  <>
                    {awards?.length === 0 ? (
                      <Column alignCenter justifyCenter style={{ padding: 50 }}>
                        <DataNotFound
                          message={
                            lang.isEnglish
                              ? "data not available"
                              : "taarifa hizi hazipo kwa sasa"
                          }
                        />
                      </Column>
                    ) : (
                      <>
                        {(() => {
                          const filteredResultsBySeason = awards
                            ?.filter(
                              (award) =>
                                award.season === seasons[selectedSeason]
                            )
                            .filter((award) => award.kind === "player");
                          if (filteredResultsBySeason.length === 0) {
                            return (
                              <Column
                                alignCenter
                                justifyCenter
                                style={{ padding: 50, color: colors.text }}
                              >
                                <DataNotFound
                                  message={
                                    lang.isEnglish
                                      ? "data not available"
                                      : "taarifa hizi hazipo kwa sasa"
                                  }
                                />
                              </Column>
                            );
                          }

                          return (
                            <Column
                              style={{ padding: 30 }}
                              alignCenter
                              justifyCenter
                            >
                              <GridLayout
                                style={{ color: "#fff", width: "100%" }}
                              >
                                {awards
                                  ?.filter((award) => award.kind === "player")
                                  .sort((a, b) => b.time.month - a.time.month)
                                  .map((award, index) => {
                                    return (
                                      <AwardCard
                                        key={index}
                                        month={moment(award.date).format(
                                          "MMMM"
                                        )}
                                        season={award.season}
                                        name={award.player?.name ?? "n/a"}
                                        image={
                                          award.player?.pic ?? playerPlaceHolder
                                        }
                                        // image={playerPlaceHolder}
                                        teamLogo={
                                          award.player?.current_club?.logo ??
                                          noImage
                                        }
                                        teamName={
                                          award.player?.current_club?.name ??
                                          "n/a"
                                        }
                                        display={"Grid"}
                                        idx={index}
                                        window={windowWidth}
                                      />
                                    );
                                  })}
                              </GridLayout>
                            </Column>
                          );
                        })()}
                      </>
                    )}
                  </>
                );

              case 1:
                return (
                  <>
                    {awards?.length === 0 ? (
                      <Column alignCenter justifyCenter style={{ padding: 50 }}>
                        <DataNotFound
                          message={
                            lang.isEnglish
                              ? "data not available"
                              : "taarifa hizi hazipo kwa sasa"
                          }
                        />
                      </Column>
                    ) : (
                      <>
                        {(() => {
                          const filteredResultsBySeason = awards
                            ?.filter(
                              (award) =>
                                award.season === seasons[selectedSeason]
                            )
                            .filter((award) => award.kind === "manager");
                          if (filteredResultsBySeason.length === 0) {
                            return (
                              <Column
                                alignCenter
                                justifyCenter
                                style={{ padding: 50, color: colors.text }}
                              >
                                <DataNotFound
                                  message={
                                    lang.isEnglish
                                      ? "data not available"
                                      : "taarifa hizi hazipo kwa sasa"
                                  }
                                />
                              </Column>
                            );
                          }

                          return (
                            <>
                              <GridLayout
                                style={{ color: "#fff", width: "100%" }}
                              >
                                {awards
                                  ?.filter((award) => award.kind === "manager")
                                  .filter(
                                    (award) =>
                                      award.season === seasons[selectedSeason]
                                  )
                                  .sort((a, b) => b.time.month - a.time.month)
                                  .map((award, index) => {
                                    const manager = managers.find(
                                      (manager) => manager._id === award.coach
                                    );
                                    return (
                                      <ManagerAwardCard
                                        key={index}
                                        month={moment(award.date).format(
                                          "MMMM"
                                        )}
                                        season={award.season}
                                        name={award.manager?.name ?? "N/A"}
                                        image={
                                          award.manager?.pic ?? playerPlaceHolder
                                        }
                                        // image={playerPlaceHolder}
                                        teamLogo={
                                          award.manager?.current_club?.logo ??
                                          noImage
                                        }
                                        teamName={
                                          award.manager?.current_club?.name ??
                                          "n/a"
                                        }
                                      />
                                    );
                                  })}
                              </GridLayout>
                            </>
                          );
                        })()}
                      </>
                    )}
                  </>
                );

              default:
                break;
            }
          })(selectedTabIndex)}
        </>
      )}
    </>
    // <Column
    //   gap={10}
    //   style={{
    //     backgroundColor: colors.accent,
    //     minHeight: "110vh",
    //     width: "100%",
    //     margin: "0 auto",
    //     color: colors.text,
    //     borderRadius: 10,
    //     overflow: "clip",
    //   }}
    // >
    //   <Column
    //     gap={20}
    //     style={{
    //       backgroundColor: colors.secondary,
    //       height: "100%",
    //       width: "100%",
    //       padding: 20,
    //       margin: "0 auto",
    //       marginBottom: 10,
    //       alignItems: "center",
    //     }}
    //   >
    //     <Row
    //       alignCenter
    //       gap={10}
    //       onClick={() => navigate(-1)}
    //       style={{ cursor: "pointer", display: "none" }}
    //     >
    //       <BiArrowBack />
    //       <p>Back</p>
    //     </Row>
    //     <Row
    //       alignCenter
    //       gap={20}
    //       style={{ flexDirection: windowWidth < 700 ? "column" : "row" }}
    //     >
    //       <Row alignCenter gap={10}>
    //         <img src={nbcLogo} alt="" style={{ height: 80 }} />
    //         <Column gap={10}>
    //           <p
    //             style={{
    //               fontSize: windowWidth < 1000 ? 15 : 20,
    //               whiteSpace: "nowrap",
    //               fontWeight: 800,
    //             }}
    //           >
    //             {lang.isEnglish ? "NBC Premier League" : "Ligi Kuu ya NBC"}
    //           </p>
    //           {lang.isEnglish ? (
    //             <p>
    //               <span style={{ fontWeight: "bold" }}>
    //                 {seasons[selectedSeason]}
    //               </span>{" "}
    //               season
    //             </p>
    //           ) : (
    //             <p>
    //               Msimu wa{" "}
    //               <span style={{ fontWeight: "bold" }}>
    //                 {seasons[selectedSeason]}
    //               </span>
    //             </p>
    //           )}
    //         </Column>
    //       </Row>
    //       <SelectField
    //         label={lang.isEnglish ? "Season filter" : "Chagua Msimu"}
    //         value={seasonSelectOptions.value}
    //         onChange={seasonSelectOptions.onChange}
    //         options={seasonSelectOptions.options}
    //         fullWidth={windowWidth < 700 ? true : false}
    //       />
    //     </Row>
    //   </Column>

    // </Column>
  );
}

const useAllStatsHook = () => {
  const language = useAtomValue(languageAtom);
  const awardAtomValue = useAtomValue(awardsAtom);

  const months = [
    language.isEnglish ? "January" : "Januari",
    language.isEnglish ? "February" : "Februari",
    language.isEnglish ? "March" : "Machi",
    language.isEnglish ? "April" : "Aprili",
    language.isEnglish ? "May" : "Mei",
    language.isEnglish ? "June" : "Juni",
    language.isEnglish ? "July" : "Julai",
    language.isEnglish ? "August" : "Agosti",
    language.isEnglish ? "September" : "Septemba",
    language.isEnglish ? "October" : "Oktoba",
    language.isEnglish ? "November" : "Novemba",
    language.isEnglish ? "December" : "Desemba",
  ];
  const monthtostring = (month) => months[month - 1];

  const [statsCategory, setstatsCategory] = useAtom(allStatsAwardsListAtom);
  const [season, setseason] = useState("2021");
  const [displayData, setdisplayData] = useState();
  const [categories, setcategories] = useState([]);

  const [awards, setAwards] = useState([]);
  const [managers, setManagers] = useState([]);
  const [players, setPlayers] = useState([]);

  const [loading, setLoading] = useState(false);

  const handleCategoryClick = (label) => setstatsCategory({ category: label });

  const [seasons, setSeasons] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState((prev) => {
    prev = seasons.length - 1;
    return prev < 0 ? 0 : prev;
  });
  const onChangeSeason = (e) => {
    setLoading(true);
    setSelectedSeason(e.target.value);
  };

  const seasonSelectOptions = {
    label: "Season filter",
    value: selectedSeason,
    onChange: onChangeSeason,
    options: ((options) => {
      return options.map((option, index) => ({
        value: index,
        label: option,
      }));
    })(seasons),
  };

  useEffect(() => {
    const call = async () => {
      setLoading(true);
      const _awardsCall = apiCall({ url: URLS.getAwards });
      const _managersCall = apiCall({ url: URLS.managers });
      const _playersCall = apiCall({ url: URLS.allPlayers });
      const promises = [_awardsCall, _managersCall, _playersCall];
      try {
        const results = await Promise.allSettled(promises);
        const _awards = results[0].value.data;

        setAwards((prev) => {
          prev = _awards;
          return prev;
        });
        let _seasonSet = new Set();
        _awards.forEach((award) => _seasonSet.add(award.season));
        let _seasonList = [..._seasonSet];
        setSeasons((prev) => {
          prev = _seasonList;
          return prev;
        });

        const _managers = results[1].value.data;
        setManagers((prev) => {
          prev = _managers;
          return prev;
        });

        const _players = results[2].value.data;
        setPlayers((prev) => {
          prev = _players;
          return prev;
        });

        setLoading(false);
      } catch (error) {
        toast.error(
          language.isEnglish
            ? "An error occured when getting awards information"
            : "Tatizo limetokea kufuata taarifa za tuzo"
        );
        console.log("error getting awards", error);
        setLoading(false);
      }
    };
    call();
  }, [awardAtomValue, selectedSeason, language]);

  useEffect(() => {
    let data = awardStatsDummyData(language.isEnglish);

    let _displayData = {};

    data.forEach((element) => {
      if (element.season === `${season}-${parseInt(season) + 1}`) {
        element.data.forEach((el) => {
          if (el.label === statsCategory?.category) {
            _displayData.data = el.values;
            _displayData.season = season;
          }
        });
      }
    });
    setdisplayData(_displayData);

    let _categories = new Set();
    data.forEach((element) => {
      if (element.season === `${season}-${parseInt(season) + 1}`) {
        element.data.forEach((el) =>
          _categories.add({ label: el.label, displayLabel: el.displayLabel })
        );
      }
    });
    setcategories((prev) => {
      prev = [..._categories];
      return prev;
    });
  }, [season, statsCategory, language]);

  return {
    season,
    statsCategory,
    categories,
    handleCategoryClick,
    displayData,
    seasonSelectOptions,
    selectedSeason,
    setSelectedSeason,
    seasons,
    awards,
    managers,
    players,
    monthtostring,
    loading,
    setLoading,
  };
};
