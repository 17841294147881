import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { NEW_BASE_URL } from "../../core/urls";

export const useHome = () => {
  const [loading, setLoading] = useState(true);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  let navigate = useNavigate();

  const getIpAddress = async () => {
    try {
      const response = await fetch(
        `${NEW_BASE_URL}/analytics/visit`,
        {
          method: "POST",
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getIpAddress();
    setTimeout(() => {
      setLoading(false);
      // navigate('/')
    }, 2000);
  }, []);

  return { loading, openMobileMenu, setOpenMobileMenu };
};
