import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { NavigationBar } from "../../shared-components/navigation-bar/navigation-bar";
import { MobileMenu } from "../../shared-components/navigation-bar/mobile-menu";
import { Column } from "../../shared-components/column";
import { useTermsOfServiceHook } from "./use-terms-of-service";

import { handleGoBack } from "../../functions/handleGoBack";
import { Footer } from "../../shared-components/footer";
import { GoBack } from "../../shared-components/go-back";
import { TermsOfServiceContiner } from "./components/terms-of-service-container";

export function TermsOfService() {
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const { navigate, colors, contents, loading, language } =
    useTermsOfServiceHook();
  return (
    <>
      <NavigationBar setOpenMobileMenu={setOpenMobileMenu} />
      <AnimatePresence>
        {openMobileMenu ? (
          <MobileMenu setOpenMobileMenu={setOpenMobileMenu} />
        ) : (
          ""
        )}
      </AnimatePresence>
      <TermsOfServiceContiner atomColors={colors}>
        <Column
          style={{
            width: "90%",
            maxWidth: 1000,
          }}
        >
          <GoBack handleGoBack={() => handleGoBack(navigate)} />
          <br />
          {loading ? (
            <p>{language.isEnglish ? "loading" : "tafadhali subiri"} ...</p>
          ) : (
            <div dangerouslySetInnerHTML={{ __html: contents }} />
          )}
        </Column>
      </TermsOfServiceContiner>
      <Footer />
    </>
  );
}
