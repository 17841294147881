import { Column } from "../../shared-components/column";
import { useMatchDayStatsHook } from "./use-match-stats";

import { handleGoBack } from "../../functions/handleGoBack";
import { Footer } from "../../shared-components/footer";
import { GoBack } from "../../shared-components/go-back";

import { BORDER_RADIUS } from "../../core/measurements";
import { MatchDayContainer } from "./components/match-day-container";
import { ScoreLineComponent } from "./components/score-line-component";
import { TabSwitcherComponent } from "./components/tab-switcher-component";
import { MatchFactsTab } from "./tabs/facts";
import H2H from "./tabs/h2h/H2H";
import { LineUpTab } from "./tabs/lineup";
import { StatsTab } from "./tabs/stats";
import TableTab from "./tabs/table/Table";
import { useAtomValue } from "jotai";
import { languageAtom } from "../../state/shared-state-atoms/language";
import ReactPlayer from 'react-player'

export function MatchStats() {
  const { navigate, colors, loading, selectedTabIndex, handleChange, tabs, team1, team2, matchStats, matchStatsAtomValue, matchInfoLoading, matchInfo } = useMatchDayStatsHook()
  const language = useAtomValue(languageAtom)

  if (loading) {
    return (
      <MatchDayContainer atomColors={colors} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <p>{language.isEnglish ? "loading" : "tafadhali subiri"} ...</p>
      </MatchDayContainer>
    );
  }

  if (!loading) {
    return (
      <>
        <MatchDayContainer atomColors={colors}>
          <Column
            justifyCenter
            style={{
              width: "90%",
              maxWidth: 1000,
              backgroundColor: colors.secondary,
              borderRadius: BORDER_RADIUS,
              userSelect: "none",
            }}
          >
            <GoBack handleGoBack={() => handleGoBack(navigate)} style={{ marginTop: 20, marginLeft: 20 }} />
            <br />

            <Column gap={0} style={{ backgroundColor: colors.accent }}>
              <br />
              <ScoreLineComponent matchStats={matchStats} matchStatsAtomValue={matchStatsAtomValue} />
              <br />
              {matchInfoLoading
                ? 'matchInfoLoading'
                : matchInfo.highlights_url ? (
                  <div style={{ backgroundColor: colors.background }} className="rounded-lg overflow-clip w-[90%] aspect-video self-center ">
                    <ReactPlayer url={matchInfo.highlights_url} width="100%" height="100%" controls />
                  </div>
                ) : <></>}
              <br />
              <TabSwitcherComponent fullWwidth selectedTabIndex={selectedTabIndex} handleChange={handleChange} tabs={tabs} colors={colors} background={colors.background} indicatorColor={colors.text} textColor={colors.text} fullWidth={true} />
              <br />


              {(function () {
                switch (tabs[selectedTabIndex]) {
                  case tabs[0]:
                    return <MatchFactsTab />;

                  case tabs[1]:
                    return <StatsTab />;

                  case tabs[2]:
                    const _borderColor = "#56B988";
                    return <LineUpTab _borderColor={_borderColor} team1={team1} team2={team2} colors={colors} />;

                  case tabs[3]:
                    return <TableTab team1={matchStatsAtomValue?.home?.name} team2={matchStatsAtomValue?.away?.name} />;

                  case tabs[4]:
                    return <H2H />;

                  default:
                    return <p>no idea what you want</p>;
                }
              })()}
            </Column>
          </Column>
        </MatchDayContainer>
        <Footer />
      </>
    );
  }
}
