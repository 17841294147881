import React from "react";

const Form = ({ form }) => {
  return (
    <div className=" flex flex-row">
      {form.map((item, idx) => {
        return (
          <div
            key={idx}
            style={{
              //   height: 14,
              minWidth: 12,
              borderRadius: 5,
              backgroundColor:
                item === "W" ? "green" : item === "D" ? "grey" : "red",
              margin: 2,
              padding: 2,
            }}
          >
            <p style={{ fontSize: 10, color: "#FFFF" }}>{item}</p>
          </div>
        );
      })}
    </div>
  );
};

export default Form;
