import playerPlaceHolder from '../../../../../../../assets/placeholder-images/player-placeholder-full.svg'

const teamsInfoList = [
    {
        category: 'Goals',
        data: [
            {
                logo: 'https://upload.wikimedia.org/wikipedia/en/d/d1/Young_Africans_SC_%28logo%29.png',
                name: 'Young Africans',
                color: '#FFD703',
                stat: 40
            },
            {
                logo: 'https://upload.wikimedia.org/wikipedia/en/b/bf/Simba_Sports_Club.png',
                name: 'Simba SC',
                color: 'brown',
                stat: 36
            },
            {
                logo: 'https://tmssl.akamaized.net/images/wappen/big/42527.png?lm=1451813136',
                name: 'Mbeya City FC',
                color: 'brown',
                stat: 30
            },
        ]
    },
    {
        category: 'Shots',
        data: [
            {
                logo: 'https://upload.wikimedia.org/wikipedia/en/b/bf/Simba_Sports_Club.png',
                name: 'Simba SC',
                color: 'brown',
                stat: 407
            },
            {
                logo: 'https://upload.wikimedia.org/wikipedia/en/d/d1/Young_Africans_SC_%28logo%29.png',
                name: 'Young Africans',
                color: '#2F5B3B',
                stat: 305
            },
            {
                logo: 'https://tmssl.akamaized.net/images/wappen/big/42527.png?lm=1451813136',
                name: 'Mbeya City FC',
                color: 'brown',
                stat: 256
            },
        ]
    },
    {
        category: 'Penalties',
        data: [
            {
                logo: 'https://upload.wikimedia.org/wikipedia/en/d/d1/Young_Africans_SC_%28logo%29.png',
                name: 'Young Africans',
                color: '#2F5B3B',
                stat: 7
            },
            {
                logo: 'https://upload.wikimedia.org/wikipedia/en/b/bf/Simba_Sports_Club.png',
                name: 'Simba SC',
                color: 'brown',
                stat: 6
            },
            {
                logo: 'https://tmssl.akamaized.net/images/wappen/big/42527.png?lm=1451813136',
                name: 'Mbeya City FC',
                color: 'brown',
                stat: 4
            },
        ]
    },
    {
        category: 'Clean sheet',
        data: [
            {
                logo: 'https://upload.wikimedia.org/wikipedia/en/d/d1/Young_Africans_SC_%28logo%29.png',
                name: 'Young Africans',
                color: '#2F5B3B',
                stat: 19
            },
            {
                logo: 'https://upload.wikimedia.org/wikipedia/en/b/bf/Simba_Sports_Club.png',
                name: 'Simba SC',
                color: 'brown',
                stat: 15
            },
            {
                logo: 'https://tmssl.akamaized.net/images/wappen/big/42527.png?lm=1451813136',
                name: 'Mbeya City FC',
                color: 'brown',
                stat: 14
            },
        ]
    }
]

export default teamsInfoList