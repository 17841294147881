import { FacebookAuthProvider, signInWithPopup } from "firebase/auth";
import { useAtomValue, useSetAtom } from "jotai";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { auth } from "../../core/config/firebase-config";
import { postRequestWithOutToken } from "../../core/network-request-functions";
import { URLS } from "../../core/urls";
import handleLoginWithGoogle from "../../functions/loginGoogle";
import { languageAtom } from "../../state/shared-state-atoms/language";
import { tokenAtom } from "../../state/shared-state-atoms/token-atom";
import { userProfileAtom } from "../../state/shared-state-atoms/user-profile-atom";

const useLoginHook = ({ onClose, ChooseFav }) => {
  let navigate = useNavigate();
  const language = useAtomValue(languageAtom);
  const [email, setemail] = useState("");
  const [phone, setPhone] = useState("");
  const [username, setUsername] = useState("");
  const [password, setpassword] = useState("");

  const [loading, setloading] = useState(false);

  const settokenAtom = useSetAtom(tokenAtom);
  const setuserProfileAtom = useSetAtom(userProfileAtom);

  const [errorMessage, seterrorMessage] = useState("");

  const handleLogin = async () => {
    if (Boolean(!!username === false) || Boolean(!!password === false)) {
      toast.error(
        language.isEnglish
          ? "Username and password field should be filled"
          : "Namba ya simu au email na nenosiri vinatakiwa vijazwe"
      );
      return;
    }

    const path = URLS.login;
    const body = { username, password };

    try {
      setloading(true);
      const response = await postRequestWithOutToken(path, body);
      console.log(response);
      if (response.status === 200) {
        settokenAtom(response.data.user.token);
        setuserProfileAtom({
          firstName: response.data.user.firstName,
          lastName: response.data.user.lastName,
          fullName: `${response.data.user.firstName}  ${response.data.user.lastName} `,
          email: response.data.user.email,
          phone: response.data.user.phone,
          userId: response.data.user._id,
          token: response.data.user.token,
          loginMethod: "not-social",
        });
        // navigate("/");
        onClose();
        {
          response.data.user.favoriteTeams?.length < 1 && ChooseFav();
        }
      }
      setemail("");
      setpassword("");
      setloading(false);
    } catch (error) {
      console.log(error);

      toast.error(
        language.isEnglish
          ? error.response.data.message
          : `kuna tatizo limetokea ${error.response.data.message}`
      );
      setloading(false);

      // const message = error.message
      // const message = error.response.data.message

      // console.log(message)
      // if (message.toLowerCase().replaceAll(" ", "").includes("Wrong email".toLowerCase().replaceAll(" ", ""))) {
      //     toast.error(
      //         language.isEnglish
      //             ? "Either your email or your password is invalid"
      //             : "Baruapepe yako au nenosiri uliloweka sio sahihi"
      //     )
      // }
      // if (message.toLowerCase().replaceAll(" ", "").includes("password".toLowerCase().replaceAll(" ", ""))) {
      //     toast.error(
      //         language.isEnglish
      //             ? "Password is invalid"
      //             : "Neno siri uliloweka sio sahihi"
      //     )
      // }
      // setemail('')
      // setpassword('')
      // setloading(false)
    }
  };

  const handleLoginGoogle = async () => {
    try {
      setloading(true);
      const results = await handleLoginWithGoogle({
        setuserProfileAtom,
        settokenAtom,
      });
      if (!results) {
        setloading(false);
        toast("something went wrong somewhere");
        return;
      }
      setloading(false);
      navigate("/");
    } catch (error) {
      setloading(false);
      toast("something went wrong");
    }
  };

  const handleLoginFacebook = () => {
    setloading(true);
    const provider = new FacebookAuthProvider();

    // const auth = getAuth();

    provider.setCustomParameters({
      display: "popup",
    });

    signInWithPopup(auth, provider)
      .then((result) => {
        // The signed-in user info.
        const user = result.user;

        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        const credential = FacebookAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;

        // ...

        postRequestWithOutToken(URLS.auth.social, {
          access_token: accessToken,
          provider: "facebook",
        })
          .then((response) => {
            navigate("/");
          })
          .catch((error) => {
            console.log({ error });
          });
        setloading(false);
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = FacebookAuthProvider.credentialFromError(error);

        // ...
        console.log({ errorCode, errorMessage, email });
        setloading(false);
      });
  };

  return {
    email,
    setemail,
    username,
    setUsername,
    phone,
    setPhone,
    password,
    setpassword,
    handleLogin,
    handleLoginGoogle,
    handleLoginFacebook,
    loading,
    errorMessage,
  };
};

export default useLoginHook;
