import { useAtomValue, useSetAtom } from "jotai"
import { useState } from "react"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import { _routeNames } from "../../../app/routes"
import { postRequestWithOutToken } from "../../../core/network-request-functions"
import { URLS } from "../../../core/urls"
import handleLoginWithGoogle from "../../../functions/loginGoogle"
import { languageAtom } from "../../../state/shared-state-atoms/language"
import { tokenAtom } from "../../../state/shared-state-atoms/token-atom"
import { userProfileAtom } from "../../../state/shared-state-atoms/user-profile-atom"
import { atomWithStorage } from "jotai/utils";
import { postRequestWithToken } from "../../../core/network-request-functions";


const useVerificationCodeHook = () => {
    const phoneNumberAtom = atomWithStorage('')
    const token = useAtomValue(tokenAtom);


    let navigate = useNavigate()
    const language = useAtomValue(languageAtom)

    const settokenAtom = useSetAtom(tokenAtom)
    const setuserProfileAtom = useSetAtom(userProfileAtom)
    const phoneNumberAtomValue = useAtomValue(phoneNumberAtom)
    

    const [firstName, setfirstName] = useState("")
    const [lastName, setlastName] = useState("")
    const [code, setcode] = useState("")
    const [email, setemail] = useState("")
    const [password, setpassword] = useState("")
    const [confirm_password, setconfirm_password] = useState("")

    const [loading, setLoading] = useState(false)
    const [data, setdata] = useState(null)


    const handleContinue = async () => {
        if (!!code === false) {
            toast.error(
                language.isEnglish
                    ? "The code field should not be emoty"
                    : "Sehemu ya tarakimu inabidi ijazwe"
            )
            return
        }

        const path = URLS.verifyEdit
        const body = {
            token: code
        }

        setLoading(true)
        try {
            const response = await postRequestWithToken(token, path, body)
            console.log(response)
            if (response.status === 200 ) {
                settokenAtom(response.data.token)
                setuserProfileAtom({
                  firstName: response.data.firstName,
                  lastName: response.data.lastName,
                  fullName: `${response.data.firstName} ${response.data.lastName}`,
                  email: response.data.email,
                  phone: response.data.phone,
                  userId: response.data._id,
                  token: response.data.token
                })
                toast.success(
                    language.isEnglish
                        ? "Your profile information has been updated succesfully"
                        : "taarifa zako zimebadilishwa kikamilifu"
                )
                navigate('/')
            }
            else {
                toast.error(
                    language.isEnglish
                        ? "Something went wrong, please try again later"
                        : "Kuna tatizo limejitokeza, tafadhali jaribu tena baadae"
                )
            }
            setLoading(false)
        } catch ({ response: { data: { success } } }) {
            if (!success) {
                toast.error(
                    language.isEnglish
                        ? "Cannot verify your code, try again or request a new one"
                        : "Hatuwezi kuthibitisha tarakimu zako, jaribu tena au rudi nyuma na oombe tarakimu nyingine"
                )
            }
            setLoading(false)
        }
    }

    const handleLoginGoogle = async () => {
        try {
            setLoading(true)
            const results = await handleLoginWithGoogle({ setuserProfileAtom, settokenAtom })
            if (!results) {
                setLoading(false)
                toast("something went wrong somewhere")
                return
            }
            setLoading(false)
            navigate('/')
        } catch (error) {
            setLoading(false)
            toast("something went wrong")
        }
    }

    return {
        handleContinue,
        handleLoginGoogle,
        loading,
        setLoading,
        firstName,
        setfirstName,
        lastName,
        setlastName,
        code,
        setcode,
        email,
        setemail,
        password,
        setpassword,
        confirm_password,
        setconfirm_password,
        phoneNumberAtomValue,
        navigate
    }
}

export default useVerificationCodeHook