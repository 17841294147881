import styled from "styled-components";
import { Colors } from "../../../../../core/colors";
import { BORDER_RADIUS, MEDIUM_SPACING } from "../../../../../core/measurements";

export const MatchDayContents = styled.div`
  height: 100%;
  width: 100%;
  border-radius: ${BORDER_RADIUS};

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${$props => $props.atomColors.secondary};
    margin-block: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${$props => $props.atomColors.faintText};
    border-radius: ${BORDER_RADIUS};
  }
`;
