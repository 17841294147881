import { useAtomValue } from "jotai";
import { Column } from "../../shared-components/column";
import { InputField } from "../../shared-components/input-field";


import { useNavigate } from "react-router-dom";
import { FramerButtonFilled } from "../../shared-components/framer-button-filled";
import { GoBack } from "../../shared-components/go-back";
import { Row } from "../../shared-components/row";

import { IoChevronDown } from "react-icons/io5";
import { SimpleDialog } from "../../shared-components/pop-up";
import { languageAtom } from "../../state/shared-state-atoms/language";
import useForgotPasseordPageHook from "./useForgotPasseordPageHook";

export function ForgotPasswordPage() {
    let navigate = useNavigate()
    const {
        colors,
        handleSubmission,
        handleGoBack,
        loading,
        selectedValue,
        open,
        handleClose,
        handleClickOpen,
        username,
        countryCode,
        setUsername,
        _popUpList
    } = useForgotPasseordPageHook()
    const language = useAtomValue(languageAtom)
    return (
        <Column
            alignCenter
            justifyCenter
            style={ {
                width: '100vw',
                height: '100vh',
                backgroundColor: colors.background,
                color: colors.text,
            } }
        >
            {
                loading
                    ? <p>{ language.isEnglish ? "loading" : "tafadhali subiri" } ...</p>
                    : (
                        <Column
                            gap={ 10 }
                            style={ {
                                width: '90%',
                                maxWidth: 550,
                                color: colors.text,
                            } }
                        >
                            <GoBack handleGoBack={ () => handleGoBack(navigate) } />
                            <p style={ { color: colors.text, fontSize: 'xx-large', fontWeight: 'bold' } }>
                                {
                                    language.isEnglish
                                        ? "Forgot your password ?"
                                        : "Je, umesahau nenosiri ?"
                                }
                            </p>
                            <p style={ { marginBottom: 10 } }>
                                {
                                    language.isEnglish
                                        ? (
                                            <>
                                                Enter your phone number and we'll send a{ ' ' }
                                                <span style={ { fontWeight: 'bold', textDecoration: 'underline', textUnderlineOffset: 3, color: colors.faintText } }>
                                                    5 digit code
                                                </span>
                                                { ' ' }to your phone number.
                                            </>
                                        )
                                        : (
                                            <>
                                                Ingiza namba yako ya simu, sisi tutakutumia { ' ' }
                                                <span style={ { fontWeight: 'bold', textDecoration: 'underline', textUnderlineOffset: 3, color: colors.text } }>
                                                    tarakimu 5
                                                </span>
                                                { " " } kwenye SMS ili uweze kubadili neno siri.
                                            </>
                                        )
                                }

                            </p>
                            <InputField
                                Icon={ () => {
                                    return (
                                        <Row alignCenter gap={ 5 } onClick={ handleClickOpen } style={ { width: 'fit-content', cursor: 'pointer' } }>
                                            <h5>+{ countryCode }</h5>
                                            <IoChevronDown />
                                        </Row>
                                    )
                                } }
                                placeHolder={ language.isEnglish ? "Phone number or email" : "Namba ya simu au barua pepe" }
                                value={ username }
                                setter={ setUsername }
                            />
                            <SimpleDialog
                                selectedValue={ selectedValue }
                                open={ open }
                                onClose={ handleClose }
                                dialogItems={ _popUpList }
                            />
                            <FramerButtonFilled label={ language.isEnglish ? "Continue" : "Endelea" } onClick={ handleSubmission } marginTop={ 10 } />
                        </Column>
                    )
            }
        </Column>
    )
}
