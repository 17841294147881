import { useAtomValue, useSetAtom } from "jotai";
import { useState, useEffect } from "react";
import axios from "axios";
import { URLS } from "../../../../core/urls";
import { colorAtom } from "../../../../state/shared-state-atoms/colors";
import { matchStatsAtom } from "../../../match-stats/match-stats-atom";

import moment from "moment";

const useMatchDayV2Hook = () => {
  const [seasonPopUpOpen, setSeasonPopUpOpen] = useState(false);
  const [roundPopUpOpen, setRoundPopUpOpen] = useState(false);
  const [matches, setMatches] = useState([]);
  const [rounds, setRounds] = useState([]);
  const [selectedRoundIndex, setSelectedRoundIndex] = useState(0);
  const [seasons, setSeasons] = useState([]);
  const [season, setSeason] = useState("");
  const [loading, setloading] = useState(false);
  const [stadiums, setStadiums] = useState([]);
  const [loadingStadiums, setLoadingStadiums] = useState(false);
  const [Rounds, setRound] = useState([]);
  const [ActiveRounds, setActiveRounds] = useState("");
  const [seasonList, setSeasonList] = useState([]);
  const [ActiveSeason, setActiveSeason] = useState("");

  const colors = useAtomValue(colorAtom);
  const setMatchStatsAtom = useSetAtom(matchStatsAtom);

  const popUpOpenRound = () => setRoundPopUpOpen(true);
  const popUpCloseRound = () => setRoundPopUpOpen(false);
  const popUpOpenSeason = () => setSeasonPopUpOpen(true);
  const popUpCloseSeason = () => setSeasonPopUpOpen(false);

  const handleSeasonInput = (event) => setActiveSeason(event.target.value);

  const handleArrowLeft = () => {
    const seasonIndex = seasons.findIndex((ele) => ele === season);
    setSeason((prev) => {
      if (seasonIndex < 1) return prev;
      prev = seasons[seasonIndex - 1];
      return prev;
    });
  };
  const handleArrowRight = () => {
    const seasonIndex = seasons.findIndex((ele) => ele === season);
    setSeason((prev) => {
      if (seasonIndex + 1 === seasons.length) return prev;
      prev = seasons[seasonIndex + 1];
      return prev;
    });
  };

  const handleNextRound = () => {
    setSelectedRoundIndex((prev) => {
      if (prev === rounds.length - 1) return prev;
      return prev + 1;
    });
  };
  const handlePreviousRound = () => {
    setSelectedRoundIndex((prev) => {
      if (prev === 0) return prev;
      return prev - 1;
    });
  };
  const roundChange = (index, round) => {
    setSelectedRoundIndex(index);
    setActiveRounds(round);
  };

  const getMatchByStatus = (status) => {
    try {
      return axios.get(`${URLS.allMatches}/status/${status}`);
    } catch (error) {
      return Promise.resolve();
    }
  };
  const getMatches = (data) => {
    try {
      return axios.get(`${URLS.allMatches}`, {
        params: data,
      });
    } catch (error) {
      return error;
    }
  };

  const getRounds = () => {
    try {
      return axios.get(`${URLS.allRounds}`);
    } catch (error) {
      return error;
    }
  };
  

  const getMatchStats = (matchId) => {
    try {
      return axios.get(`${URLS.matchStatistics}/${matchId}`);
    } catch (error) {
      return Promise.resolve();
    }
  };

  const getMatchFacts = (matchId) => {
    try {
      return axios.get(`${URLS.matchFacts}/${matchId}`);
    } catch (error) {
      return Promise.resolve();
    }
  };

  const getSeasons = async () => {
    try {
      const response = await axios.get(`${URLS.allSeasons}`);
      const data = response.data;
      setSeasons(data.map(season => season.year));
      const currentseason = data.find(season => season.current);
      if (currentseason) {
        setActiveSeason(currentseason.year);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const api_call = async () => {
    setloading(true);

    try {
      const promises = [
        getMatchByStatus("nor"),
        getMatchByStatus("fin"),
        getMatchByStatus("live"),
      ];
      const results = await Promise.allSettled(promises);

      const upcomingMatches = results[0].value.data;
      const previousMatches = results[1].value.data;
      const liveMatches = results[2].value.data;

      let liveMatchWStats = [];
      for (let i = 0; i < liveMatches.length; i++) {
        const stats = await getMatchStats(liveMatches[i]._id);
        const facts = await getMatchFacts(liveMatches[i]._id);
        liveMatches[i].stats = stats.data;
        liveMatches[i].facts = facts.data;
        liveMatchWStats.push(liveMatches[i]);
      }

      let prevMatchWStats = [];
      for (let i = 0; i < previousMatches.length; i++) {
        const stats = await getMatchStats(previousMatches[i]._id);
        const facts = await getMatchFacts(previousMatches[i]._id);
        previousMatches[i].stats = stats.data;
        previousMatches[i].facts = facts.data;
        prevMatchWStats.push(previousMatches[i]);
      }

      const matches = [
        ...liveMatchWStats,
        ...prevMatchWStats,
        ...upcomingMatches,
      ];

      //  create distinct rounds from all incoming matches
      const distinctRounds = new Set();
      matches.forEach((match) => {
        distinctRounds.add(match.round.num);
      });

      const now = moment().format("YYYY-MM-DD");
      const today = new Date(now);

      const selectRound = matches.find(
        (match) => moment(match.playing_date) >= today
      );
      console.log("Selected Rounds", selectRound);
      setSelectedRoundIndex(selectRound.round.num - 1);
      const mappedRounds = [...distinctRounds];
      setRounds(mappedRounds);

      //  create distinct seasons from all incoming matches
      const distinctSeasons = new Set();
      matches.forEach((match) => {
        distinctSeasons.add(match.season);
      });
      const mappedSeasons = [...distinctSeasons];
      setSeasons(mappedSeasons);

      //  create match by groups in corresponding fixtures in their dates
      let matchByGroups = [];

      distinctRounds.forEach((round) => {
        // create distincts dates in corresponding round
        const filteredMatches = matches.filter(
          (match) => match.round.num === round
        );
        const distinctDates = new Set();

        filteredMatches.forEach((match) => {
          const date = moment(match.playing_date).format("YYYY-MM-DD");

          distinctDates.add(date);
        });

        //  create fixtures in distintictive dates
        let matchByDates = [];
        distinctDates.forEach((date) => {
          matchByDates.push({
            date: date,
            fixtures: [
              ...filteredMatches.filter((match) => {
                const index = match.playing_date.search(date);
                return index > -1;
              }),
            ],
          });
        });

        //  push everything to our final array
        matchByGroups.push({
          round: round,
          matches: matchByDates,
        });
        setMatches(matchByGroups);
        setloading(false);
      });
    } catch (error) {
      console.log(error);
      setloading(false);
      // toast.error("something went wrong");
    }
  };

  const apiCalls = async () => {
    try {
      setloading(true);
  
      // Fetch rounds
      const roundsResponse = await getRounds();
      if (roundsResponse.status === 200) {
        const roundsData = roundsResponse.data;
        setRound(roundsData);
      }
  
      // Prepare data for fetching matches
      let matchData = {
        page: 0,
      };
  
      if (ActiveRounds) {
        Object.assign(matchData, { round: ActiveRounds._id });
      }
  
      if (ActiveSeason) {
        Object.assign(matchData, { season: ActiveSeason.year });
      }
  
      // Fetch matches
      const matchesResponse = await getMatches(matchData);
      if (matchesResponse.status === 200) {
        const matchesData = matchesResponse.data;
        let tempMatch = [];
  
        for (let i = 0; i < matchesData.length; i++) {
          const stats = await getMatchStats(matchesData[i]._id);
          matchesData[i].stats = stats.data;
  
          if (!(matchesData[i].status === "nor")) {
            const facts = await getMatchFacts(matchesData[i]._id);
            matchesData[i].facts = facts.data;
          }
  
          tempMatch.push(matchesData[i]);
        }
  
        // Process matches and set state
        let matchByGroups = [];
        const distinctDates = new Set();
  
        tempMatch.forEach((match) => {
          const date = moment(match.playing_date).format("YYYY-MM-DD");
          distinctDates.add(date);
        });
  
        let matchByDates = [];
        distinctDates.forEach((date) => {
          matchByDates.push({
            date: date,
            fixtures: tempMatch.filter((match) => match.playing_date.includes(date)),
          });
        });
  
        matchByGroups.push({
          matches: matchByDates,
        });
  
        setMatches(matchByGroups);
        setloading(false);
      }
    } catch (error) {
      // Handle errors
      console.error("Error in apiCalls:", error);
      setloading(false);
    }
  };
    useEffect(() => {
    getSeasons();
  }, []);
  // Initial load and whenever ActiveRounds or ActiveSeason changes
  useEffect(() => {
    apiCalls();
    getSeasons();
  }, [ActiveRounds, ActiveSeason]);
  
  // useEffect(() => {
  //   (async () => {
  //     setLoadingStadiums(true);
  //     try {
  //       const response = await apiCall({ url: URLS.stadiums });
  //       setStadiums((prev) => {
  //         prev = response.data;
  //         return prev;
  //       });
  //       setLoadingStadiums(false);
  //     } catch (error) {
  //       console.error("error getting stadiums", error);
  //       setLoadingStadiums(false);
  //     }
  //   })();
  // }, []);

  return {
    loading,
    matches,
    colors,
    handleSeasonInput,
    season,
    seasons,
    setSeason,
    handleArrowLeft,
    handleArrowRight,
    setMatchStatsAtom,

    selectedRoundIndex,
    rounds,
    handleNextRound,
    handlePreviousRound,
    setSelectedRoundIndex,
    Rounds,
    setRound,
    roundChange,

    seasonPopUpOpen,
    setSeasonPopUpOpen,
    roundPopUpOpen,
    setRoundPopUpOpen,
    popUpOpenRound,
    popUpCloseRound,
    popUpOpenSeason,
    popUpCloseSeason,

    loadingStadiums,
    stadiums,
  };
};

export default useMatchDayV2Hook;
