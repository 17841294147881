import { atom } from "jotai";
import { languageAtom } from "../../../../state/shared-state-atoms/language";

export const matchDayTextAtom = atom(get => {
    const isEnglish = get(languageAtom).isEnglish

    return {
        title: isEnglish ? 'Match day' : 'Ligi',
        match_day: isEnglish ? 'Match day' : 'Mzunguko wa',
        date: isEnglish ? 'date' : 'tarehe'
    }
})