import logoGrouped from "../../../assets/logo-grouped.png";
import { MainContainer } from "../../../shared-components/main-container";

import { useAtomValue } from "jotai";
import { LARGE_SPACING } from "../../../core/measurements";
import { FormContainer } from "../../../shared-components/form-container";
import { FramerButtonFilled } from "../../../shared-components/framer-button-filled";
import { InputField } from "../../../shared-components/input-field";
import { LogoImage } from "../../../shared-components/logo-image";
// import { SignUpOrLoginInstead } from "../../shared-components/sign-up-instead";
import { colorAtom } from "../../../state/shared-state-atoms/colors";

import { AiOutlineCode } from 'react-icons/ai';
import { GoBack } from "../../../shared-components/go-back";
import { languageAtom } from "../../../state/shared-state-atoms/language";
import useVerificationCodeHook from "./useVerificationCodeHook";

export function VerifyChangeDetails() {

  const colors = useAtomValue(colorAtom)
  const language = useAtomValue(languageAtom)
  const {
    handleContinue,
    loading,
    code,
    setcode,
    handleLoginGoogle,
    handleLoginFacebook,
    phoneNumberAtomValue,
    navigate
  } = useVerificationCodeHook()
  return (
    <>
      <MainContainer
        style={ {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          minHeight: '100vh',
          backgroundColor: colors.background,
          paddingBlock: 50
        } }
      >
        <FormContainer>
          <GoBack style={ { alignSelf: 'flex-start' } } handleGoBack={ () => navigate(-1) } />
          <LogoImage src={ logoGrouped } alt="" />
          <p style={ { color: colors.text } }>{
            language.isEnglish
              ? "Enter the verification code sent to you"
              : "Weka tarakimu za uthibitisho tulizotuma kwenye namba yako"
          }.</p>
          <InputField
            type={ 'tel' }
            Icon={ () => <AiOutlineCode style={ { fontSize: 20 } } /> }
            placeHolder={ language.isEnglish ? "Code" : "Tarakimu" }
            value={ code }
            setter={ setcode }
            code
          />
          <FramerButtonFilled
            disabled={ loading }
            label={
              loading
                ? language.isEnglish ? "loading ..." : "tafadhali subiri"
                : language.isEnglish ? "Continue" : "Endelea"
            }
            marginTop={ LARGE_SPACING }
            onClick={ handleContinue } />

        </FormContainer>
      </MainContainer>
    </>
  );
}


