import { useAtomValue } from 'jotai'
import { colorAtom } from '../state/shared-state-atoms/colors'
import { Column } from './column'
import { GiHazardSign } from 'react-icons/gi'
import { Row } from './row'
import Label from './Label'

export default function DataNotFound({ message = "" }) {
    const colors = useAtomValue(colorAtom)
    return (
        <Column alignCenter justifyCenter gap={ 10 } style={ {} }>
            <div
                style={ {
                    height: 70,
                    aspectRatio: 1,
                    backgroundColor: colors.background,
                    color: colors.text,
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: 'xx-large'
                } }>
                <GiHazardSign />
            </div>
            { ((m) => {
                const firstWord = m.split(" ")[0]
                const theRest = m.split(" ").slice(1).join(" ")
                return (
                    <Row alignCenter justifyCenter gap={ 4 } style={ { width: 'fit-content', textTransform: 'capitalize' } }>
                        <Label xBold text={ firstWord } />
                        <Label noWrap text={ theRest } />
                    </Row>
                )
            })(message) }
        </Column>
    )
}
