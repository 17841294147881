export class PlayerStat
{
    constructor({ name, team, stat, image })
    {
        this.name = name
        this.team = team
        this.stat = stat
        this.image = image
    }

    getAllValues()
    {
        return {
            name: this.name,
            team: this.team,
            stat: this.stat
        }
    }
}