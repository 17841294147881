import { useAtomValue } from "jotai";
import { Column } from "../../../../../../../shared-components/column";
import Label from "../../../../../../../shared-components/Label";
import noImage from "../../../../../../../assets/teams/noImage.jpg";
import { Row } from "../../../../../../../shared-components/row";
import { colorAtom } from "../../../../../../../state/shared-state-atoms/colors";
import Card from "./card";
import CardBottom from "./cardBottom";
import CardTop from "./cardTop";
import PlayerImage from "./playerImage";
import RowItem from "./rowItem";
import TeamLogoSmall from "./teamLogoSmall";
import fontColorContrast from 'font-color-contrast'
import { themeAtom } from "../../../../../../../state/shared-state-atoms/_theme";

export default function CardItem({ playerList, teamList }) {
    const theme = useAtomValue(themeAtom)

    const colors = useAtomValue(colorAtom)
    return (
        <Card>
            { ((team, category) => {
                return (
                    <CardTop _bgColor={!theme.lightTheme ? team.color ? team.color: "#132F65" : '#fff'} style={ { color: fontColorContrast(team.color) } }>
                        <Column gap={ 10 } paddingAll={ 10 }>
                            <Label text={ category } />
                            { ((name) => {
                                const firstName = name.split(" ")[0];
                                const restOfName = name.split(" ").slice(1).join(" ");
                                return (
                                    <Row gap={ 4 } style={ { width: 'fit-content', color: theme.lightTheme && 'black'} }>
                                        <Label xBold text={ firstName } />
                                        <Label text={ restOfName } />
                                    </Row>
                                );
                            })(team.name) }
                            <Label  color={theme.lightTheme && "black"} xLarge xBold text={ team.stat } />
                        </Column>
                        <PlayerImage src={ team.logo? team.logo : noImage } alt="team logo" />
                    </CardTop>
                );
            })(teamList.data[0], teamList.category) }
            <CardBottom atomColors={ colors } style={ { backgroundColor: !theme.lightTheme? colors.action : "white" } }>
                { teamList.data.slice(1, 3).map((team, index) => {
                    return (
                        <RowItem key={ index } style={ { borderBottom: index === 1 ? '' : `0.5px solid ${ colors.text }` } }>
                            <Row gap={ 10 }>
                                <Label text={ index + 2 } />
                                { ((name) => {
                                    const firstName = name.split(" ")[0];
                                    const restOfName = name.split(" ").slice(1).join(" ");
                                    return (
                                        <Row gap={ 4 } style={ { width: 'fit-content' } }>
                                            <Label xBold text={ firstName } />
                                            <Label text={ restOfName } />
                                        </Row>
                                    );
                                })(team.name) }
                            </Row>
                            <Row alignCenter gap={ 10 } style={ { width: 'fit-content' } }>
                                <TeamLogoSmall src={ team.logo ? team.logo : noImage } alt="small team logo" />
                                <Label text={ team.stat } />
                            </Row>
                        </RowItem>
                    );
                }) }
            </CardBottom>
        </Card>
    );
}