import styled from 'styled-components'
import device from '../../../../../../../core/screenBreakPoints'

const GridLayout = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;

    @media ${ device.tablet } {
        grid-template-columns: repeat(2, 1fr);
    }
    @media ${ device.laptop } {
        grid-template-columns: repeat(3, 1fr);
    }
    @media ${ device.laptopL } {
        grid-template-columns: repeat(4, 1fr);
    }
`

export default GridLayout