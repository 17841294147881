import { getRequestWithOutToken } from "../../core/network-request-functions";
import { URLS } from "../../core/urls";
import { useAtomValue } from "jotai";
import { colorAtom } from "../../state/shared-state-atoms/colors";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { languageAtom } from "../../state/shared-state-atoms/language";

export const useAboutUsHook = () => {
  let navigate = useNavigate();
  const colors = useAtomValue(colorAtom);
  const language = useAtomValue(languageAtom);
  const _path = URLS.pages.aboutUs;

  const [contents, setContents] = useState();
  const [loading, setloading] = useState(false);

  // useEffect(() => {
  //     const call = async () => {
  //         try {
  //             setloading(true)
  //             const response = await getRequestWithOutToken(_path)
  //             setContents(language.isEnglish ? response.data.content.en : response.data.content.sw)
  //             setloading(false)
  //         } catch (error) {
  //             console.log("error getting privcy policy info", error)
  //             setContents(language.isEnglish ? "Sorry no data could be retrieved" : "Samahani, taarifa hizi hazipo kwa sasa")
  //             setloading(false)
  //         }
  //     }
  //     call()
  // }, [language])

  return { navigate, colors, contents, loading, language };
};
