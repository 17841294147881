import { Routes, Route } from "react-router-dom";
import { Login } from "../pages/login";
import { SignUp } from "../pages/sign-up";
import { Home } from "../pages/home";
import { HomeTab } from "../pages/home/components/home-tab";
import { MatchDay } from "../pages/home/components/match-day";
import { LeagueTable } from "../pages/home/components/league-table";
import { Stats } from "../pages/home/components/stats";
import { AllStats } from "../pages/home/components/stats/tabs/all-stats";
import { StatsPagePlayerStats } from "../pages/home/components/stats/tabs/player-stats";
import { StatsPageTeamStats } from "../pages/home/components/stats/tabs/team-stats";
import { Profile } from "../pages/profile/profile";
import { ProfileChangeDetails } from "../pages/profile-change-details/profile-change-dtails";
import { ForgotPasswordPage } from "../pages/forgot-password";
import { ResetPassword } from "../pages/reset-password";
import { SignUpPhoneVerification } from "../pages/sign-up-phone-verification";
import { VerifyChangeDetails } from "../pages/profile-change-details/verify-change-details";
import { SignUpPhoneVerificationCode } from "../pages/sign-up-phone-verification-code";
import { PrivacyPolicy } from "../pages/privacy-policy";
import { _routeNames } from "./routes";
import { TermsOfService } from "../pages/terms-of-service";
import { AboutUs } from "../pages/about-us";
import { NewsFull } from "../pages/home/components/news-full";
import { Feedback } from "../pages/feedback";
import { Fantasy } from "../pages/home/components/fantasy";
import { League } from "../pages/home/components/league";
import { Awards } from "../pages/home/components/stats/tabs/awards";
import { MatchStats } from "../pages/match-stats";
import { Teams } from "../pages/teams";
import { Team } from "../pages/team";
import { MatchDayV2 } from "../pages/home/components/match-day-v2";
import { LeagueTable2 } from "../pages/home/components/league-tableV2";
import { AllStatsPlayerList } from "../pages/all-stats-list-player";
import { AllStatsTeamList } from "../pages/all-stats-list-team";
import { AllStatsAwardsList } from "../pages/all-stats-list-awards";
import { Players } from "../pages/players";
import { Managers } from "../pages/managers";
import { ManagerProfile } from "../pages/manager-profile";
import { Partners } from "../pages/partners";
import { Broadcasters } from "../pages/broadcasters";
import PlayerProfile from "../pages/player-profile/PlayerProfile";
import { Toaster } from "react-hot-toast";
import StatsListing from "../pages/stats-listing/StatsListing";
import { useAtomValue } from "jotai";
import { colorAtom } from "../state/shared-state-atoms/colors";

function App() {
  const colors = useAtomValue(colorAtom);
  return (
    <>
      <Toaster
        toastOptions={{
          duration: 3000,
          style: {
            backgroundColor: colors.secondary,
            color: colors.text,
            fontSize: "small",
            fontWeight: "bold",
          },
        }}
      />
      <Routes>
        <Route path={_routeNames.root.index} element={<Home />}>
          <Route path={_routeNames.root.index} element={<HomeTab />} />
          <Route path={_routeNames.root.news} element={<NewsFull />} />
          <Route path={_routeNames.root.league.index} element={<League />}>
            {/* <Route index element={ <LeagueTable /> } /> */}
            <Route index element={<LeagueTable2 />} />
            <Route
              path={_routeNames.root.league.leagueV2}
              element={<LeagueTable2 />}
            />
            <Route
              path={_routeNames.root.league.matchDay}
              element={<MatchDay />}
            />
            <Route
              path={_routeNames.root.league.matchDayV2}
              element={<MatchDayV2 />}
            />
          </Route>
          <Route path={_routeNames.root.fantasy} element={<Fantasy />} />
          <Route path={_routeNames.root.stats.index} element={<Stats />}>
            {/* <Route index element={ <AllStats /> } /> */}
            <Route
              path={_routeNames.root.stats.listing}
              element={<StatsListing />}
            />
            <Route
              path={_routeNames.root.stats.players}
              element={<StatsPagePlayerStats />}
            />
            <Route
              path={_routeNames.root.stats.teams}
              element={<StatsPageTeamStats />}
            />
            <Route path={_routeNames.root.stats.awards} element={<Awards />} />
            <Route
              path="*"
              element={<p>route does not exist or is not yet implemented</p>}
            />
          </Route>
        </Route>

        <Route path={_routeNames.matchStatistics} element={<MatchStats />} />

        <Route path={_routeNames.profile.index} element={<Profile />} />
        <Route
          path={_routeNames.profile.update}
          element={<ProfileChangeDetails />}
        />
        <Route
          path={_routeNames.profile.verify}
          element={<VerifyChangeDetails />}
        />

        <Route path={_routeNames.auth.login} element={<Login />} />
        <Route
          path={_routeNames.auth.signUpVerifyPhone}
          element={<SignUpPhoneVerification />}
        />
        <Route
          path={_routeNames.auth.signUpVerifyPhoneCode}
          element={<SignUpPhoneVerificationCode />}
        />
        <Route path={_routeNames.auth.signUp} element={<SignUp />} />
        <Route
          path={_routeNames.auth.forgotPassword}
          element={<ForgotPasswordPage />}
        />
        <Route
          path={_routeNames.auth.resetPassword}
          element={<ResetPassword />}
        />

        <Route path={_routeNames.privacyPolicy} element={<PrivacyPolicy />} />
        <Route path={_routeNames.termsOfService} element={<TermsOfService />} />
        <Route path={_routeNames.aboutUs} element={<AboutUs />} />
        <Route path={_routeNames.feedback} element={<Feedback />} />

        <Route path={_routeNames.team} element={<Team />} />
        <Route path={_routeNames.allTeams} element={<Teams />} />

        <Route path={_routeNames.allPlayers} element={<Players />} />
        <Route path={_routeNames.playerProfile} element={<PlayerProfile />} />

        <Route path={_routeNames.allManagers} element={<Managers />} />
        <Route path={_routeNames.managerProfile} element={<ManagerProfile />} />

        <Route
          path={_routeNames.allPlayerStats}
          element={<AllStatsPlayerList />}
        />

        <Route path={_routeNames.allTeamStats} element={<AllStatsTeamList />} />

        <Route
          path={_routeNames.allAwardStats}
          element={<AllStatsAwardsList />}
        />

        <Route path={_routeNames.partners} element={<Partners />} />

        <Route path={_routeNames.broadcasters} element={<Broadcasters />} />

        <Route
          path="*"
          element={<p>route does not exist or is not yet implemented</p>}
        />
      </Routes>
    </>
  );
}

export default App;
