import styled from 'styled-components'

const PlayerImage = styled.img`
    /* height: 70%; */
    width: 40%;
    object-fit: cover;
    align-self: flex-end;
    padding: 10px;
`

export default PlayerImage