import { useAtom, useAtomValue } from "jotai";
import { useWindowWidth } from "../../hooks/use-window-width";
import { colorAtom } from "../../state/shared-state-atoms/colors";
import { Column } from "../column";
import { Row } from "../row";
import moment from 'moment'

import logo from "../../assets/logo-grouped.png";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { _routeNames } from "../../app/routes";
import { tabIndexAtom } from "../../state/shared-state-atoms/tab-index";
import { LogoImage } from "../logo-image";

import facebook from "../../assets/social-icons/facebook.png";
import instagram from "../../assets/social-icons/instagram.png";
import twitter from "../../assets/social-icons/twitter.png";
import youtube from "../../assets/social-icons/youtube.png";
import { URLS } from "../../core/urls";
import apiCall from "../../functions/api_call";
import { languageAtom } from "../../state/shared-state-atoms/language";
import statTabIndex from "../../pages/home/components/stats/stats-index-atom";
import zIndex from "@mui/material/styles/zIndex";

export function Footer() {
  let navigate = useNavigate();
  const footerHook = useFooter();
  const { windowWidth } = useWindowWidth();
  const { colors } = useFooterHook();
  const [_, setSelectedTabIndex] = useAtom(tabIndexAtom);
  const [__, setSelectedStatTabIndex] = useAtom(statTabIndex);
  const language = useAtomValue(languageAtom);
  const scrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <>
      <Column
        alignCenter
        style={{ backgroundColor: "#042559", color: "#fff", paddingBlock: 100 }}
      >
        <Row
          justifyBetween
          gap={40}
          style={{
            backgroundColor: "#042559",
            maxWidth: 1700,
            width: "90%",
            margin: "0 auto",
            flexDirection: windowWidth < 850 ? "column" : "row",
            alignItems: windowWidth < 850 ? "center" : "",
          }}
        >
          <Column
            style={{
              width: windowWidth < 850 ? "100%" : "fit-content",
              alignItems: "flex-start",
              flexGrow: 1,
            }}
          >
            <h3 style={{ marginBottom: 10 }}>NBC Premiere League</h3>
            <Row gap={20}>
              <Column style={{ width: "fit-content" }}>
                {[
                  language.isEnglish ? "Home" : "Nyumbani",
                  language.isEnglish ? "League" : "Ligi",
                  language.isEnglish ? "News" : "Habari",
                  language.isEnglish ? "Stats" : "Takwimu",
                  language.isEnglish ? "Fantasy" : "Fantasy",
                ].map((item, index) => {
                  return (
                    <FooterTextItem
                      atomColors={colors}
                      key={index}
                      onClick={() => {
                        switch (index) {
                          case 0:
                            scrollToTop();
                            setSelectedTabIndex(index);
                            navigate(_routeNames.root.index);
                            break;

                          case 1:
                            scrollToTop();
                            setSelectedTabIndex(index);
                            navigate(_routeNames.root.league.leagueV2);
                            break;

                          case 2:
                            scrollToTop();
                            setSelectedTabIndex(index);
                            navigate(_routeNames.root.news);
                            break;

                          case 3:
                            scrollToTop();
                            setSelectedTabIndex(index);
                            navigate(_routeNames.root.stats.index);
                            break;

                          case 4:
                            scrollToTop();
                            setSelectedTabIndex(index);
                            navigate(_routeNames.root.fantasy);
                            break;

                          default:
                            break;
                        }
                      }}
                    >
                      {item}
                    </FooterTextItem>
                  );
                })}
              </Column>
              <Column style={{ width: "fit-content", display: "flex" }}>
                {["Players", "Managers", "News"].map((item, index) => {
                  return (
                    <FooterTextItem atomColors={colors} key={index}>
                      {item}
                    </FooterTextItem>
                  );
                })}
              </Column>
            </Row>
          </Column>
          <Column
            style={{
              width: windowWidth < 850 ? "100%" : "fit-content",
              alignItems: "flex-start",
              flexGrow: 1,
            }}
          >
            <h3 style={{ marginBottom: 10 }}>
              {language.isEnglish ? "Stats" : "Takwimu"}
            </h3>
            {[
              language.isEnglish ? "Player Stats" : "Takwimu za wachezaji",
              language.isEnglish ? "Club Stats" : "Takwimu za klabu",
              language.isEnglish ? "Awards" : "Tuzo",
            ].map((item, index) => {
              return (
                <FooterTextItem
                  atomColors={colors}
                  key={index}
                  onClick={() => {
                    scrollToTop();
                    setSelectedTabIndex(3);
                    setSelectedStatTabIndex(index);
                    navigate(_routeNames.root.stats.index);
                  }}
                >
                  {item}
                </FooterTextItem>
              );
            })}
          </Column>
          <Column
            style={{
              width: windowWidth < 850 ? "100%" : "fit-content",
              alignItems: "flex-start",
              display: "none",
            }}
          >
            <h3 style={{ marginBottom: 10 }}>Fantasy</h3>
            {[
              "NPL Home",
              "My Team",
              "NPL Transfers",
              "Leagues",
              "NPL Fixtures",
            ].map((item, index) => {
              return (
                <FooterTextItem atomColors={colors} key={index}>
                  {item}
                </FooterTextItem>
              );
            })}
          </Column>
          <Column
            style={{
              width: windowWidth < 850 ? "100%" : "fit-content",
              alignItems: "flex-start",
              flexGrow: 1,
            }}
          >
            <h3 style={{ marginBottom: 10 }}>
              {language.isEnglish ? "Partners" : "Washirika"}
            </h3>
            {footerHook.partners?.map((partner, index) => {
              return (
                <Row
                  key={index}
                  gap={10}
                  alignCenter
                  style={{ width: "fit-content" }}
                  onClick={() => {
                    const openInNewTab = (url) => {
                      const newWindow = window.open(
                        url,
                        "_blank",
                        "noopener,noreferrer"
                      );
                      if (newWindow) newWindow.opener = null;
                    };
                  }}
                >
                  <FooterTextItem
                    atomColors={colors}
                    style={{ cursor: "default" }}
                  >
                    {partner.name}
                  </FooterTextItem>
                </Row>
              );
            })}
          </Column>
          <Column
            style={{
              width: windowWidth < 850 ? "100%" : "fit-content",
              alignItems: "flex-start",
              gap: 15,
              flexGrow: 1,
            }}
          >
            <h3 style={{ marginBottom: 10 }}>
              {language.isEnglish ? "Social" : "Mitandao ya Kijamii"}
            </h3>
            {[
              {
                logo: facebook,
                color: "#1877F2",
                link: "https://web.facebook.com/NBCTanzaniaLimited",
                name: "NPL on Facebook",
              },
              {
                logo: twitter,
                color: "#1C93E6",
                link: "https://twitter.com/NBCTanzania",
                name: "NPL on Twitter",
              },
              {
                logo: instagram,
                color: "#DE0023",
                link: "https://www.instagram.com/nbc_tanzania",
                name: "NPL on Instagram",
              },
              {
                logo: youtube,
                color: "#F20000",
                link: "https://www.youtube.com/c/NBCTANZANIALTD",
                name: "NPL on Youtube",
              },
            ].map(({ logo, color, link, name }, index) => {
              return (
                // <div
                //   key={index}
                //   onClick={() => openInNewTab(link)}
                //   style={{
                //     paddingInline: 10,
                //     backgroundColor: "transparent",
                //     fontWeight: "bold",
                //     borderRadius: 10,
                //     cursor: "pointer",
                //     fontSize: "xx-large",
                //     color,
                //     height: 40,
                //     aspectRatio: 1,
                //     display: "flex",
                //     alignItems: "center",
                //     justifyContent: "center",
                //     gap: 10,
                //   }}
                // >
                //   <img
                //     src={logo}
                //     alt=""
                //     style={{ width: 50, aspectRatio: 1, objectFit: "cover" }}
                //   />
                //   <p style={{ fontSize: "medium" }}>{name}</p>
                // </div>
                <FooterTextItem
                  key={index}
                  atomColors={colors}
                  // style={{ cursor: "default" }}
                  onClick={() => openInNewTab(link)}
                >
                  {name}
                </FooterTextItem>
              );
            })}
          </Column>
        </Row>
      </Column>
      <Row style={{ paddingBlock: 20, backgroundColor: "#E21D24" }}>
        <Row
          gap={20}
          style={{
            color: "#fff",
            padding: 0,
            maxWidth: 1700,
            width: "90%",
            margin: "0 auto",
            flexDirection: windowWidth < 850 ? "column" : "row",
            alignItems: windowWidth < 850 ? "center" : "",
            position: "relative",
          }}
        >
          {[
            `© NBC PREMIER LEAGUE ${moment().format("YYYY")}`,
            language.isEnglish ? "Terms & Conditions" : "Vigezo na Masharti",
            language.isEnglish ? "Privacy policy" : "Sera ya faragha",
            language.isEnglish ? "About us" : "Kuhusu sisi",
          ].map((item, index) => {
            return (
              <BottomStripText
                key={index}
                onClick={() => {
                  switch (index) {
                    case 1:
                      scrollToTop();
                      navigate(_routeNames.termsOfService);
                      break;

                    case 2:
                      scrollToTop();
                      navigate(_routeNames.privacyPolicy);
                      break;

                    case 3:
                      scrollToTop();
                      navigate(_routeNames.aboutUs);
                      break;

                    default:
                      break;
                  }
                }}
              >
                {" "}
                {item}{" "}
              </BottomStripText>
            );
          })}
          <LogoImage
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              height: '80px',
              zIndex: 1
            }}
            src={logo}
          />
        </Row>
      </Row>
    </>
  );
}

const useFooterHook = () => {
  const colors = useAtomValue(colorAtom);

  return { colors };
};

const FooterTextItem = styled.p`
  color: #999999;
  cursor: pointer;
`;

const BottomStripText = styled.p`
  color: #fff;
  cursor: pointer;
  font-weight: bold;
`;

const useFooter = () => {
  const [partners, setPartners] = useState([]);
  useEffect(() => {
    let promises = [apiCall({ url: URLS.partners })];

    Promise.allSettled(promises)
      .then((results) => {
        // partners
        setPartners((prev) => {
          prev = results[0]?.value?.data;
          return prev;
        });
      })
      .finally(() => {});
  }, []);
  return { partners };
};
