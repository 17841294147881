import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { BORDER_RADIUS } from "../../core/measurements";
import { Column } from "../../shared-components/column";

import { useAtomValue } from "jotai";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { _routeNames } from "../../app/routes";
import findCountry from "../../functions/find-country";
import { handleGoBack } from "../../functions/handleGoBack";
import { useWindowWidth } from "../../hooks/use-window-width";
import { Footer } from "../../shared-components/footer/index";
import { GoBack } from "../../shared-components/go-back";
import Label from "../../shared-components/Label";
import { Row } from "../../shared-components/row";
import noImage from '../../assets/teams/noImage.jpg'
import { languageAtom } from "../../state/shared-state-atoms/language";
import { Grid } from "../home/components/news-full/components/grid";
import { TabSwitcherComponent } from "../match-stats/components/tab-switcher-component";
import { useTeam } from "./useTeam";

export function Team() {
  let navigate = useNavigate();
  const { windowWidth } = useWindowWidth();

  const useTeamHook = useTeam();
  const _teamInfo = useTeamHook._teamInfo;
  const loading = useTeamHook.loading;
  const colors = useTeamHook.colors;
  const language = useAtomValue(languageAtom);
  const selectedSeasonYear = useTeamHook.selectedSeasonYear;

  const seasonQuerry = `${parseInt(selectedSeasonYear)}-${
    parseInt(selectedSeasonYear) + 1
  }`;

  const BackComponent = () => {
    return (
      <GoBack
        handleGoBack={() => handleGoBack(navigate)}
        style={goBackStyle()}
      />
    );
  };

  if (loading)
    return (
      <div style={loadingContainerStyle()}>
        {language.isEnglish ? "loading ..." : "Tafadhali subiri ..."}{" "}
      </div>
    );
  else {
    return (
      <>
        {_teamInfo === null || _teamInfo === undefined ? (
          <div style={teamDataNotFoundStyle()}>
            <h1 style={{ color: colors.faintText, fontWeight: "bold" }}>
              {language.isEnglish
                ? "Team data can not be found"
                : "Taarifa za klabu hazipatikani kwa sasa"}
            </h1>
            <BackComponent />
          </div>
        ) : (
          <>
            <TeamsContainer atomColors={colors}>
              <TeamSummary
                /**teamBackground={ _teamInfo?.team_color } **/ teamBackground={
                  colors.primary
                }
              >
                <BackComponent />
                <img
                  src={_teamInfo.logo ?? noImage}
                  alt="team logo"
                  onError={(error) => {
                    error.target.src =
                      "https://i.pinimg.com/564x/00/cf/8a/00cf8a3622f8d1707a5ac98ff3ea822e.jpg";
                  }}
                  className='h-[100px] w-[100px] md:h-[200px] md:w-[200px] rounded-xl'
                />

                <TeamSummaryEntry
                  value={_teamInfo.name}
                  label={language.isEnglish ? "Name" : "Jina"}
                />

                <TeamSummaryEntry
                  value={_teamInfo.nick_name}
                  label={language.isEnglish ? "Nickname" : "Jina lililozoeleka"}
                />

                {Boolean(
                  useTeamHook.clubManager && useTeamHook.clubManager.name
                ) && (
                  <TeamSummaryEntry
                    value={useTeamHook.clubManager.name}
                    label={
                      language.isEnglish ? "Club manager" : "Kocha wa timu"
                    }
                  />
                )}

                {Boolean(
                  useTeamHook.averageTeamAge &&
                    typeof useTeamHook.averageTeamAge === typeof 5
                ) && (
                  <TeamSummaryEntry
                    value={useTeamHook.averageTeamAge}
                    label={
                      language.isEnglish
                        ? "Average Team Age"
                        : "Wastani wa umri wa wachezaji"
                    }
                  />
                )}

                {useTeamHook._teamInfo.founded && (
                  <TeamSummaryEntry
                    value={useTeamHook._teamInfo.founded}
                    label={language.isEnglish ? "founded" : "Imeanzishwa"}
                  />
                )}
              </TeamSummary>
              <TeamDetails
                atomColors={colors}
                teamBackground={_teamInfo?.team_color}
              >
                <TabSwitcherComponent
                  selectedTabIndex={useTeamHook.selectedTabIndex}
                  tabs={[
                    language.isEnglish ? "NBC PLTrophies" : "Makombe ya NBC PL",
                    language.isEnglish ? "Season stats" : "Takwimu za msimu",
                    language.isEnglish ? "Players" : "Wachezaji",
                  ]}
                  background={colors.background}
                  colors={colors}
                  handleChange={useTeamHook.handleTabChange}
                  indicatorColor={colors.text}
                  textColor={colors.text}
                  fullWwidth
                />

                <br />

                {((index) => {
                  switch (index) {
                    case 0:
                      return (
                        <>
                          <Row
                            alignCenter
                            justifyCenter
                            style={{ height: "100%", width: "100%" }}
                          >
                            <Label
                              fontSize={200}
                              capitalize
                              xBold
                              text={_teamInfo?.no_trophy ?? 0}
                            />
                          </Row>
                        </>
                      );

                    case 1:
                      return (
                        <>
                          <Row
                            alignCenter
                            justifyBetween
                            gap={20}
                            style={{ overflowX: "scroll" }}
                          >
                            <TextAndIconButton
                              onClick={useTeamHook.previousSeason}
                              style={{
                                backgroundColor: colors.accent,
                                color: colors.text,
                                height: 50,
                                aspectRatio: 1,
                                borderRadius: "50%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <BsChevronLeft style={iconStyle(colors)} />
                            </TextAndIconButton>
                            {language.isEnglish ? (
                              <p>{`${seasonQuerry} season`}</p>
                            ) : (
                              <>
                                <p>
                                  Msimu wa{" "}
                                  <span style={{ fontWeight: "bold" }}>
                                    {seasonQuerry}
                                  </span>{" "}
                                </p>
                              </>
                            )}

                            <TextAndIconButton
                              onClick={useTeamHook.nextSeason}
                              style={{
                                backgroundColor: colors.background,
                                color: colors.text,
                                height: 50,
                                aspectRatio: 1,
                                borderRadius: "50%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <BsChevronRight style={iconStyle(colors)} />
                            </TextAndIconButton>
                          </Row>
                          {_teamInfo?.seasons?.filter(
                            (season) => season.year === seasonQuerry
                          ).length === 0 ? (
                            <Column
                              alignCenter
                              justifyCenter
                              style={{ padding: 50 }}
                            >
                              {language.isEnglish ? (
                                <Label
                                  noWrap
                                  text={`No data for the ${seasonQuerry} season`}
                                />
                              ) : (
                                <Label
                                  noWrap
                                  text={`Hakuna taarifa za msimu wa  ${seasonQuerry}`}
                                />
                              )}
                            </Column>
                          ) : (
                            <>
                              {_teamInfo?.seasons
                                ?.filter(
                                  (season) => season.year === seasonQuerry
                                )
                                .map((season, index) => {
                                  return (
                                    <Column
                                      key={index}
                                      gap={10}
                                      style={{ width: "100%" }}
                                    >
                                      <TeamSummaryEntry
                                        value={season.year}
                                        label={
                                          language.isEnglish
                                            ? "Season"
                                            : "Msimu"
                                        }
                                        full
                                      />
                                      <Column
                                        gap={10}
                                        style={{
                                          width: "100%",
                                          paddingLeft: 20,
                                          alignItems: "stretch",
                                        }}
                                      >
                                        <TeamSummaryEntry
                                          value={season.wins}
                                          label={
                                            language.isEnglish
                                              ? "Wins"
                                              : "Mechi walizoshinda"
                                          }
                                        />
                                        <TeamSummaryEntry
                                          value={season.draw}
                                          label={
                                            language.isEnglish
                                              ? "Draws"
                                              : "Mechi walizotoka sare"
                                          }
                                        />
                                        <TeamSummaryEntry
                                          value={season.lost}
                                          label={
                                            language.isEnglish
                                              ? "Lost"
                                              : "Mechi walizofungwa"
                                          }
                                        />
                                        <TeamSummaryEntry
                                          value={season.plays}
                                          label={
                                            language.isEnglish
                                              ? "Matches played"
                                              : "Mechi walizocheza"
                                          }
                                        />
                                        <TeamSummaryEntry
                                          value={season.points}
                                          label={
                                            language.isEnglish
                                              ? "Points"
                                              : "Pointi"
                                          }
                                        />
                                        <TeamSummaryEntry
                                          value={season.scored_goals}
                                          label={
                                            language.isEnglish
                                              ? "Goals scored"
                                              : "Magoli waliyoshinda"
                                          }
                                        />
                                        <TeamSummaryEntry
                                          value={season.concided_goals}
                                          label={
                                            language.isEnglish
                                              ? "Goals conceded"
                                              : "Magoli waliyofungwa"
                                          }
                                        />
                                      </Column>
                                    </Column>
                                  );
                                })}
                            </>
                          )}
                        </>
                      );

                    case 2:
                      return (
                        <>
                          {useTeamHook.players.length === 0 ? (
                            <Column alignCenter>
                              <Label
                                text={
                                  language.isEnglish
                                    ? "No player data found"
                                    : "Samahani, taarifa hazipo kwa sasa"
                                }
                              />
                            </Column>
                          ) : (
                            <>
                              <div style={{backgroundColor: colors.background}} className="w-full p-4 rounded-lg grid grid-cols-2 md:grid-cols-4 gap-2 h-full overflow-y-scroll">
                                {useTeamHook.players.map((player, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className={`
                                      grow flex flex-col gap-2 items-center capitalize cursor-pointer 
                                      group transition-all
                                      `}
                                      onClick={() =>
                                        navigate(
                                          `${_routeNames.allPlayers}/${player._id}`
                                        )
                                      }
                                    >
                                      <img
                                        className="h-20 w-20 rounded-full"
                                        src={player.pic}
                                        alt=""
                                        onError={(error) => {
                                          error.target.src =
                                            "https://www.pngitem.com/pimgs/m/22-224249_blank-person-hd-png-download.png";
                                        }}
                                      />
                                      <div className="flex flex-col gap-0 items-center group-hover:font-black transition-all">
                                        <p className="text-center">
                                          {player.name.toLowerCase() ?? "n/a"}
                                        </p>
                                        <p className="text-sm">
                                          {player.country.toLowerCase() ??
                                            findCountry(
                                              player.country_code ?? ""
                                            ).toLowerCase()}
                                        </p>
                                        {player?.dob && <p className="text-xs">
                                          { language.isEnglish ? 
                                          `${new Date().getFullYear() - new Date(player?.dob).getFullYear()} years Old`
                                        : `Miaka ${new Date().getFullYear() - new Date(player?.dob).getFullYear()}`}
                                        </p>}
                                        
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </>
                          )}
                        </>
                      );

                    default:
                      return "";
                  }
                })(useTeamHook.selectedTabIndex)}
              </TeamDetails>
            </TeamsContainer>
            <Footer />
          </>
        )}
      </>
    );
  }

  function iconStyle(colors) {
    return { fontSize: "large", color: colors.text, height: 30 };
  }

  function playerPicStyle() {
    return {
      width: "100%",
      aspectRatio: 1,
      borderRadius: BORDER_RADIUS,
      objectFit: "cover",
    };
  }

  function teamLogoStyle() {
    return {
      height: 200,
      aspectRatio: 1,
      borderRadius: "20%",
    };
  }

  function teamDataNotFoundStyle() {
    return {
      height: "100vh",
      width: "100vw",
      backgroundColor: colors.background,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
    };
  }

  function loadingContainerStyle() {
    return {
      height: "100vh",
      width: "100vw",
      color: colors.text,
      backgroundColor: colors.background,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };
  }

  function goBackStyle() {
    return {
      top: 20,
      left: 20,
      position: "absolute",
      color: "#fff",
      backgroundColor: "#212121",
      padding: 10,
      borderRadius: BORDER_RADIUS,
    };
  }
}

const TextAndIconButton = styled.button`
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: #212121;
  color: white;
`;

const TeamsContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 60vh;
  color: ${(props) => props.atomColors.text};
  background-color: ${(props) => props.atomColors.background};
  display: flex;
  position: relative;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

const TeamSummary = styled.div`
  position: relative;
  width: 50vw;
  height: 100vh;
  position: sticky;
  top: 0;
  bottom: 0;
  padding: 20px;
  background-color: ${(props) => props.teamBackground};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  @media (max-width: 1000px) {
    position: static;
    height: 70vh;
    width: 100vw;
  }
`;

const TeamDetails = styled.div`
  width: 50vw;
  height: 100vh;
  padding: 30px;
  background-color: ${(props) => props.atomColors.secondary};
  color: ${(props) => props.atomColors.text};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  overflow: scroll;

  @media (max-width: 1000px) {
    min-height: 50vh;
    width: 100vw;
  }
`;

function TeamSummaryEntry({ value, label, full }) {
  return (
    <p
      style={{
        width: full ? "100%" : "",
        fontWeight: "bold",
        backgroundColor: "#000",
        opacity: 0.8,
        color: "#fff",
        padding: 5,
        borderRadius: BORDER_RADIUS,
      }}
    >
      <span style={{ fontWeight: "bold", marginLeft: 10, opacity: 0.5 }}>
        {label}
      </span>{" "}
      {value}
    </p>
  );
}
