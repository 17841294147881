import { BORDER_RADIUS } from "../../../../core/measurements";
import { TeamLineup } from "./components/team-lineup";

export function PitchLineupComponent({_borderColor, team1, team2})
{
    return (
        <div
            style={ {
                maxWidth: 700,
                width: '100%',
                margin: '0 auto',
                aspectRatio: 9 / 16,
                backgroundColor: '#0F9D58',
                position: 'relative',
                borderRadius: BORDER_RADIUS,
                overflow: 'clip'
            } }
        >
            {/* goal top */ }
            <div
                style={ {
                    position: 'absolute',
                    top: 0,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: '30%',
                    aspectRatio: 16 / 9,
                    border: `2px solid ${ _borderColor }`,
                    borderTop: 'none',
                    zIndex: 50
                } } />

            {/* goal top circle */ }
            <div
                style={ {
                    position: 'absolute',
                    top: '4%',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: '30%',
                    aspectRatio: 1,
                    border: `2px solid ${ _borderColor }`,
                    borderRadius: '50%'
                } } />

            {/* goal top box */ }
            <div
                style={ {
                    position: 'absolute',
                    top: 0,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: '50%',
                    aspectRatio: 16 / 9,
                    border: `2px solid ${ _borderColor }`,
                    borderTop: 'none',
                    backgroundColor: '#0F9D58',
                } } />

            {/* goal bottom */ }
            <div
                style={ {
                    position: 'absolute',
                    bottom: 0,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: '30%',
                    aspectRatio: 16 / 9,
                    border: `2px solid ${ _borderColor }`,
                    borderBottom: 'none',
                    zIndex: 50
                } } />

            {/* goal bottom circle */ }
            <div
                style={ {
                    position: 'absolute',
                    bottom: '4%',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: '30%',
                    aspectRatio: 1,
                    border: `2px solid ${ _borderColor }`,
                    borderRadius: '50%'
                } } />

            {/* goal bottom box */ }
            <div
                style={ {
                    position: 'absolute',
                    bottom: 0,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: '50%',
                    aspectRatio: 16 / 9,
                    border: `2px solid ${ _borderColor }`,
                    borderBottom: 'none',
                    backgroundColor: '#0F9D58',
                } } />

            {/* center line */ }
            <div
                style={ {
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    height: 2,
                    width: '100%',
                    backgroundColor: _borderColor,
                } } />

            {/* center circle */ }
            <div
                style={ {
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translateY(-50%) translateX(-50%)',
                    width: '30%',
                    aspectRatio: 1,
                    borderRadius: '50%',
                    border: `2px solid ${ _borderColor }`,
                } } />

            <TeamLineup team1={ team1 } _borderColor={ _borderColor } team2={ team2 } />
        </div>
    )
}