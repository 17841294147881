import { useState } from "react";
import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { colorAtom } from "../../state/shared-state-atoms/colors";
import { useAtomValue } from "jotai";
import apiCall from "../../functions/api_call";
import { URLS } from "../../core/urls";
import findCountry from "../../functions/find-country";

export function useProfileManagerHook() {
  let { manager_id } = useParams();
  let navigate = useNavigate();
  const colors = useAtomValue(colorAtom);
  const [managerData, setmanagerData] = useState();
  const [managerHistory, setManagerHistory] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    let promises = [apiCall({ url: `${ URLS.managers }/${ manager_id }` }), apiCall({ url: `${ URLS.managerHistory }/${ manager_id }` })];
    Promise.allSettled(promises)
      .then((results) => {
        setmanagerData((prev) => {
          let country = findCountry(results[0].value.data.country_code ?? results[0].value.data.country);
          prev = { ...results[0].value.data, country: country.split(",")[0] ?? "n/a" };
          return prev;
        });

        if (results[1].status === "fulfilled") {
          setManagerHistory((prev) => {
            let sortedStats = results[1].value?.data?.stats?.sort((a, b) => parseInt(b.from.year) - parseInt(a.from.year));
            prev = { ...results[1].value.data, stats: sortedStats };
            return prev;
          });
        }
      })
      .finally(() => setLoading(false));
  }, []);

  return { managerData, colors, navigate, loading, managerHistory };
}
