import { Row } from "./row";
import { BORDER_RADIUS } from "../core/measurements";
import { useAtomValue } from "jotai";
import { colorAtom } from "../state/shared-state-atoms/colors";
import { TextArea } from "./text-area";

export function TextAreaField({ Icon, placeHolder, type, value, setter, disabled, phone, code })
{
  const colors = useAtomValue(colorAtom)
  return (
    <Row
      alignCenter
      gap={ 0 }
      style={ {
        backgroundColor: colors.secondary,
        borderRadius: BORDER_RADIUS,
      } }
    >
      <div
        style={ {
          backgroundColor: colors.secondary,
          color: colors.text,
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingLeft: 20,
        } }
      >
        <Icon />
      </div>
      <TextArea
        rows={ 5 }
        disabled={ disabled }
        type={"text" }
        maxLength={ 250 }
        value={ value }
        onChange={ (event) => setter(event.target.value) }
        placeholder={ placeHolder }
        style={ { backgroundColor: colors.secondary, color: colors.text } }
      />
    </Row>
  );
}