import styled from 'styled-components'

const CardTop = styled.div`
    height: 70%;
    width: 100%;
    padding-block: 20px;
    background-color: ${ props => props._bgColor };
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

export default CardTop