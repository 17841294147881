import { motion } from "framer-motion";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useState, useEffect } from "react";
import {
  BsFillMoonFill,
  BsStar,
  BsSun,
  BsFillPersonFill,
} from "react-icons/bs";
import { HiMenuAlt3, HiOutlineChevronDown } from "react-icons/hi";
import { BiMobile } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import logoGrouped from "../../assets/logo-grouped.png";
import { Colors } from "../../core/colors";
import { BORDER_RADIUS } from "../../core/measurements";
import { useWindowWidth } from "../../hooks/use-window-width";
import { useWindowHeight } from "../../hooks/use-window-height";
import { colorAtom } from "../../state/shared-state-atoms/colors";
import { languageAtom } from "../../state/shared-state-atoms/language";
import { tabIndexAtom } from "../../state/shared-state-atoms/tab-index";
import { tokenAtom } from "../../state/shared-state-atoms/token-atom";
import { userProfileAtom } from "../../state/shared-state-atoms/user-profile-atom";
import { themeAtom } from "../../state/shared-state-atoms/_theme";
import { Column } from "../column";
import { LogoImage } from "../logo-image";
import { Row } from "../row";
import { ScrollableTabs } from "../scrollable-tabs";
import { handleMobileMenu } from "./functions/handle-mobile-menu";
import { routeNavigator } from "./functions/route-navigator";
import { navigationTextAtom } from "./navigation-text-atom";

import { AiOutlineArrowRight } from "react-icons/ai";
import { _routeNames } from "../../app/routes";

import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";

import { BiFootball } from "react-icons/bi";
import { BsBroadcastPin, BsPerson } from "react-icons/bs";
import { FaHandshake } from "react-icons/fa";
import { FcManager } from "react-icons/fc";
import { GiThreeFriends } from "react-icons/gi";
import { IoIosPeople } from "react-icons/io";
import { IoChevronDown } from "react-icons/io5";
import { MdFeedback, MdPrivacyTip } from "react-icons/md";
import { RiSearch2Line } from "react-icons/ri";
import { AiOutlineLock } from "react-icons/ai";
import { FiX } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { setOpen, setFavOpen } from "../../state/redux-slices/ModalSlice";
import { setTab } from "../../state/redux-slices/tabSlice";
import { redirect } from "react-router-dom";
import ModalPopUp from "../ModalPopUp";
import { InputField } from "../input-field";
import FavouriteTeamModel from "./components/FavouriteTeam";
import { colors } from "@mui/material";
import useLoginHook from "../../pages/login/useLoginHook";
import { useSignUpHook } from "../../pages/sign-up";
import { ForgotPassword } from "../forgot-password";
import { FramerButtonFilled } from "../framer-button-filled";
import { SignUpOrLoginInstead } from "../sign-up-instead";
import axios from "axios";
import { URLS } from "../../core/urls";

export function NavigationBar({ setOpenMobileMenu }) {
  let navigate = useNavigate();
  const [open, setOpen2] = useState(false);
  const { windowWidth } = useWindowWidth();
  const { windowHeight } = useWindowHeight();
  const [selectedTabs, setSelectedTabIndex] = useAtom(tabIndexAtom);
  const selectedTabIndex = useSelector((state) => state.nav.Tab);
  const showModal = useSelector((state) => state.modal.Open);
  const openModal = useSelector((state) => state.modal.FavOpen);
  const [userProfileAtomData, setuserProfileAtomData] =
    useAtom(userProfileAtom);
  const settokenAtom = useSetAtom(tokenAtom);
  const { tabs, handleChange, teams } = useNavigation({
    setSelectedTabIndex,
    setuserProfileAtomData,
    settokenAtom,
  });
  const [colors] = useAtom(colorAtom);
  const [theme, setTheme] = useAtom(themeAtom);
  const lang = useAtomValue(languageAtom);
  const [LoginView, setLogin] = useState(true);
  const [forgotPasswordView, setForgot] = useState(false);
  const [selectTeamView, setSelectTeam] = useState(false);
  const [signUpView, setSignUp] = useState(false);
  const dispatch = useDispatch();
  const handleClose = () => {
    setOpen2(false);
  };
  const toggleForgot = () => {
    setForgot(!forgotPasswordView);
    setLogin(!LoginView);
  };
  const toggleRegister = () => {
    setSignUp(!signUpView);
    setLogin(!LoginView);
  };

  return (
    <NavigationBarContainer
      style={{
        backgroundColor: "#042559",
        color: "#fff",
        justifyContent: "center",
        // paddingInline: "5%",
        height: 110,
      }}
    >
      <Row alignCenter justifyBetween style={{ maxWidth: 1600 }}>
        {windowWidth < 1100 ? (
          <Row alignCenter justifyBetween>
            <Logo />

            <HiMenuAlt3
              style={{ fontSize: 30, cursor: "pointer" }}
              onClick={() => handleMobileMenu(setOpenMobileMenu)}
            />
          </Row>
        ) : (
          <Row gap={20} alignCenter justifyBetween style={{ width: "100%" }}>
            <div
              style={{
                flexGrow: 1,
                width: "100%",
                display: "flex",
                alignItems: "center",
                // borderWidth: 1,
                justifyContent: "center",
              }}
            >
              <Logo />
            </div>
            {/* After teams */}
            <Column gap={0}>
              <Row gap={20} style={{ height: 50, width: "100%" }}>
                <Row
                  alignCenter
                  gap={20}
                  style={{
                    height: 50,
                    borderLeftWidth: 0.5,
                    borderColor: '#243F70',
                    width: "80%",
                    paddingLeft: 5,
                    borderRightWidth: 0.5,
                  }}
                >
                  {teams?.map((team, idx) => {
                    return (
                      <img
                        style={{
                          height: 25,
                          aspectRatio: 1 / 1,
                          borderRadius: "50%",
                        }}
                        src={team.logo}
                        alt=""
                      />
                    );
                  })}
                </Row>
                {/* language toggle */}
                <LanguageSwitcher />
              </Row>
              <Row
                alignCenter
                // justifyBetween
                gap={10}
                style={{ 
                  borderWidth: 0.5,
                  borderColor: '#243F70'
                  }}
              >
                <ScrollableTabs
                  currentIndex={selectedTabIndex}
                  handleChange={handleChange}
                  tabs={tabs}
                  backgroundColor={"#042559"}
                  faintColor={colors.faintText}
                  activeColor={"#fff"}
                  indicatorColor={"#E21D24"}
                />
                <Row
                  alignCenter
                  justifyBetween
                  gap={20}
                  style={{ width: "100%" }}
                >
                  <MoreSection />
                  <Row
                    alignCenter
                    gap={20}
                    style={{ justifyContent: "flex-end" }}
                  >
                    <Row
                      alignCenter
                      gap={10}
                      style={{
                        width: "fit-content",
                        cursor: "pointer",
                        whiteSpace: "nowrap",
                        justifyContent: "flex-end",
                        marginInline: 40,
                      }}
                      onClick={() => {
                        if (userProfileAtomData?.firstName)
                          navigate(_routeNames.profile.index);
                        else dispatch(setOpen());
                      }}
                    >
                      <div
                        style={{
                          height: 40,
                          aspectRatio: 1,
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#1C345F",
                          color: "#9747FF",
                        }}
                      >
                        <BsPerson />
                      </div>
                      {userProfileAtomData?.firstName ? (
                        <p>
                          {userProfileAtomData?.firstName}{" "}
                          {userProfileAtomData?.lastName}
                        </p>
                      ) : (
                        <p>
                          {lang.isEnglish
                            ? "Login/Sign up"
                            : "Ingia/Jiandikishe"}
                        </p>
                      )}
                    </Row>

                    <Row alignCenter gap={20} style={{ width: "fit-content" }}>
                      {/* theme changer */}
                      <ThemeToggler
                        isLight={theme.lightTheme}
                        setTheme={setTheme}
                      />
                      {/* search */}
                      <RiSearch2Line />
                      {/* language toggle */}
                      <div style={{ width: 20 }} />
                    </Row>
                  </Row>
                </Row>
              </Row>
            </Column>
          </Row>
        )}
      </Row>
      {openModal && (
        <FavouriteTeamModel
          open={openModal}
          handleClose={() => dispatch(setFavOpen())}
          backgroundColor={colors.secondary}
          color={colors.text}
        />
      )}
      <ModalPopUp
        max={600}
        open={showModal}
        handleClose={handleClose}
        height="85%"
        width={windowWidth < 1000 ? windowWidth * 0.8 : windowWidth * 0.3}
        padding={5}
        backgroundColor={colors.secondary}
        color={colors.text}
        children={
          LoginView ? (
            <Login
              exit={() => dispatch(setOpen())}
              newChildren={() => toggleForgot()}
              registerScreen={() => toggleRegister()}
              register={signUpView}
              FavModel={() => dispatch(setFavOpen())}
            />
          ) : forgotPasswordView ? (
            <ForgotPasswordView newChildren={() => toggleForgot()} />
          ) : signUpView ? (
            <SignUp
              exit={() => dispatch(setOpen())}
              newChildren={() => toggleRegister()}
              register={signUpView}
            />
          ) : (
            <Login />
          )
        }
      />
    </NavigationBarContainer>
  );
}

const LanguageSwitcher = () => {
  const [colors] = useAtom(colorAtom);
  const navigationTextAtomValue = useAtomValue(navigationTextAtom);
  const theme = useAtomValue(themeAtom);
  const setlanguage = useSetAtom(languageAtom);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Row
        aria-describedby={id}
        onClick={handleClick}
        alignCenter
        gap={10}
        style={{ width: "fit-content", cursor: "pointer", marginTop: 10 }}
      >
        <p>{navigationTextAtomValue.language}</p>
        <HiOutlineChevronDown />
      </Row>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        style={{ marginTop: 15, borderRadius: 20, overflow: "clip" }}
      >
        <Column
          gap={10}
          style={{
            width: 200,
            backgroundColor: theme.lightTheme ? "#fff" : "#132F65",
            color: theme.lightTheme ? "#212121" : "#fff",
            padding: 10,
          }}
        >
          {[
            {
              label: "English",
              isSelected: navigationTextAtomValue.language === "EN",
            },
            {
              label: "Kiswahili",
              isSelected: navigationTextAtomValue.language === "KIS",
            },
          ].map((item, index) => {
            return (
              <PopOverLanguagePickItem
                key={index}
                bg={theme.lightTheme ? "#fff" : "#132F65"}
                hoverColor={theme.lightTheme ? "#C1C1C1" : "#1F3C6A"}
                onClick={() => {
                  setlanguage((prev) => {
                    return {
                      ...prev,
                      isEnglish: index === 0 ? true : false,
                    };
                  });
                  handleClose();
                }}
              >
                <p>{item.label}</p>
              </PopOverLanguagePickItem>
            );
          })}
        </Column>
      </Popover>
    </>
  );
};

const ForgotPasswordView = ({ newChildren }) => {
  return (
    <div>
      <p onClick={newChildren}> Forgot password</p>
    </div>
  );
};
const SignUp = ({ exit, newChildren, register }) => {
  const [theme, setTheme] = useAtom(themeAtom);
  const [colors] = useAtom(colorAtom);
  const lang = useAtomValue(languageAtom);
  const {
    handleSignUp,
    loading,
    firstName,
    setfirstName,
    lastName,
    setlastName,
    phoneNumber,
    setPhoneNumber,
    email,
    setemail,
    password,
    setpassword,
    confirm_password,
    setconfirm_password,
    handleLoginGoogle,
    handleLoginFacebook,
  } = useSignUpHook({ goBack: newChildren });
  return (
    <>
      <div className=" flex flex-row w-full   justify-end">
        <FiX onClick={exit} />
      </div>
      <div className=" flex flex-col mx-4  my-2  w-full p-5">
        <p
          style={{
            color: theme.lightTheme ? colors.primary : "",
            fontSize: 30,
            fontWeight: "300",
          }}
        >
          {lang.isEnglish ? "SignUp" : "Jisajili"}
        </p>
        <div className="  mt-3">
          {/* <p style={{ color: "#757572" }}>{errorMessage}</p> */}
          <InputField
            Icon={() => <BsPerson style={{ fontSize: 20 }} />}
            placeHolder="First name"
            type="text"
            value={firstName}
            setter={setfirstName}
            backgroundColor={theme.lightTheme ? "#F1F4FACC" : colors.primary}
          />
          <div className=" h-2"></div>
          <InputField
            Icon={() => <BsPerson style={{ fontSize: 20 }} />}
            placeHolder="Last name"
            type="text"
            value={lastName}
            setter={setlastName}
            backgroundColor={theme.lightTheme ? "#F1F4FACC" : colors.primary}
          />
          <div className=" h-2"></div>
          <InputField
            Icon={() => <BsPerson style={{ fontSize: 20 }} />}
            placeHolder="Email"
            type="email"
            value={email}
            setter={setemail}
            backgroundColor={theme.lightTheme ? "#F1F4FACC" : colors.primary}
          />
          <div className=" h-2"></div>
          <InputField
            Icon={() => <BiMobile style={{ fontSize: 20 }} />}
            placeHolder="Phone number"
            type="phone"
            value={phoneNumber}
            setter={setPhoneNumber}
            backgroundColor={theme.lightTheme ? "#F1F4FACC" : colors.primary}
          />
          <div className=" h-2"></div>
          <InputField
            Icon={() => <AiOutlineLock style={{ fontSize: 20 }} />}
            placeHolder="Password"
            type="password"
            value={password}
            setter={setpassword}
            backgroundColor={theme.lightTheme ? "#F1F4FACC" : colors.primary}
          />
          <div className=" h-2"></div>
          <InputField
            Icon={() => <AiOutlineLock style={{ fontSize: 20 }} />}
            placeHolder="Confirm password"
            type="password"
            value={confirm_password}
            setter={setconfirm_password}
            backgroundColor={theme.lightTheme ? "#F1F4FACC" : colors.primary}
          />
          <div className=" h-2"></div>
          {loading ? (
            <div role="status" className=" mx-10">
              <svg
                aria-hidden="true"
                class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span class="sr-only">Loading...</span>
            </div>
          ) : (
            <FramerButtonFilled
              label={lang.isEnglish ? "Signup" : "Jisajili"}
              onClick={handleSignUp}
              Radius={30}
              width={"90%"}
              marginTop={15}
            />
          )}

          <SignUpOrLoginInstead signUp={register} handleClick={newChildren} />
        </div>
      </div>
    </>
  );
};

const Login = ({ exit, newChildren, registerScreen, register, FavModel }) => {
  const [theme, setTheme] = useAtom(themeAtom);
  const [colors] = useAtom(colorAtom);
  const lang = useAtomValue(languageAtom);

  const {
    password,
    username,
    setUsername,
    setpassword,
    handleLogin,
    handleLoginGoogle,
    handleLoginFacebook,
    loading,
    errorMessage,
  } = useLoginHook({ onClose: exit, ChooseFav: FavModel });
  return (
    <>
      <div className=" flex flex-row w-full   justify-end">
        <FiX onClick={exit} />
      </div>
      <div className=" flex flex-col mx-4  my-5  w-full p-5">
        <p
          style={{
            color: theme.lightTheme ? colors.primary : "",
            fontSize: 30,
            fontWeight: "300",
          }}
        >
          {lang.isEnglish ? "Login" : "Ingia"}
        </p>
        <div className="  mt-10">
          <p style={{ color: "#757572" }}>{errorMessage}</p>
          <InputField
            Icon={() => <BsPerson style={{ fontSize: 20 }} />}
            placeHolder={
              lang.isEnglish
                ? "Your email or phone"
                : "Namba ya simu au Barua pepe yako"
            }
            value={username}
            setter={setUsername}
            backgroundColor={theme.lightTheme ? "#F1F4FACC" : colors.primary}
          />
          <div className=" h-2"></div>
          <InputField
            Icon={() => <AiOutlineLock style={{ fontSize: 20 }} />}
            placeHolder={lang.isEnglish ? "Password" : "Nenosiri"}
            type="password"
            value={password}
            setter={setpassword}
            backgroundColor={theme.lightTheme ? "#F1F4FACC" : colors.primary}
          />
          <ForgotPassword handleClick={newChildren} />
          {loading ? (
            <div role="status" className=" mx-10">
              <svg
                aria-hidden="true"
                class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span class="sr-only">Loading...</span>
            </div>
          ) : (
            <FramerButtonFilled
              label={lang.isEnglish ? "Login" : "Ingia"}
              onClick={handleLogin}
              Radius={30}
              width={"90%"}
              marginTop={15}
            />
          )}

          <SignUpOrLoginInstead
            signUp={register}
            handleClick={registerScreen}
          />
        </div>
      </div>
    </>
  );
};

const PopOverLanguagePickItem = styled.div`
  background-color: ${(props) => props.bg};
  transition: 0.2s;
  width: 100%;
  padding-inline: 10px;
  padding-block: 10px;
  cursor: pointer;
  border-radius: 10px;
  font-weight: bold;

  &:hover {
    background-color: ${(props) => props.hoverColor};
  }
`;

const MoreSection = () => {
  const [colors] = useAtom(colorAtom);
  const { isEnglish } = useAtomValue(languageAtom);
  const userProfile = useAtomValue(userProfileAtom);
  const theme = useAtomValue(themeAtom);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const menus = [
    {
      label: isEnglish ? "Clubs" : "Vilabu",
      icon: <BiFootball />,
      path: _routeNames.allTeams,
    },
    {
      label: isEnglish ? "Players" : "Wachezaji",
      icon: <IoIosPeople />,
      path: _routeNames.allPlayers,
    },
    {
      label: isEnglish ? "Team managers" : "Kocha",
      icon: <BsFillPersonFill />,
      path: _routeNames.allManagers,
    },
    {
      label: isEnglish ? "Partners" : "Washirika",
      icon: <GiThreeFriends />,
      path: _routeNames.partners,
    },
    {
      label: isEnglish ? "Broadcasters" : "Warusha matangazo",
      icon: <BsBroadcastPin />,
      path: _routeNames.broadcasters,
    },
    {
      label: isEnglish ? "Privacy policy" : "Sera ya faragha",
      icon: <MdPrivacyTip />,
      path: _routeNames.privacyPolicy,
    },
    {
      label: isEnglish ? "Terms of service" : "Vigezo na masharti",
      icon: <FaHandshake />,
      path: _routeNames.termsOfService,
    },
    {
      label: isEnglish ? "About us" : "Kuhusu sisi",
      icon: <LogoImage src={logoGrouped} style={{ height: 20 }} alt="" />,
      path: _routeNames.aboutUs,
    },
    // {
    //   label: isEnglish ? "Feedback" : "Maoni",
    //   icon: <MdFeedback />,
    //   path: _routeNames.feedback,
    // },
  ];

  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <div
        aria-describedby={id}
        onClick={handleClick}
        style={{
          fontWeight: "normal",
          color: colors.faintText,
          cursor: "pointer",
        }}
      >
        <Row alignCenter gap={5} style={{ width: "fit-content" }}>
          <p>{isEnglish ? "More" : "Zaidi"}</p>
          <IoChevronDown />
        </Row>
      </div>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            borderRadius: 10,
            borderTopRightRadius: 28,
            borderTopLeftRadius: 28,
          },
        }}
        // style={{ marginTop: 15, borderRadius: 20, overflow: "clip" }}
      >
        <Box
          sx={{
            position: "relative",
            mt: "10px",
            "&::before": {
              backgroundColor: theme.lightTheme ? "#fff" : "#132F65",
              content: '""',
              display: "block",
              position: "absolute",
              width: 12,
              height: 12,
              top: -6,
              transform: "rotate(45deg)",
              left: "calc(50% - 6px)",
            },
          }}
        />
        <Column
          gap={10}
          style={{
            width: "fit-content",
            backgroundColor: theme.lightTheme ? "#fff" : "#132F65",
            color: theme.lightTheme ? "#212121" : "#fff",
            padding: 10,
          }}
        >
          {userProfile?.firstName ? (
            <PopOverMoreItem
              bg={theme.lightTheme ? "#fff" : "#132F65"}
              hoverColor={theme.lightTheme ? "#C1C1C1" : "#1F3C6A"}
              onClick={() => {
                handleClose();
                {
                  userProfile?.favTeam
                    ? navigate(
                        `${_routeNames.allTeams}/${userProfile.favTeam._id}`
                      )
                    : setOpenModal(true);
                }
              }}
            >
              <Row paddingHorizontal={10} alignCenter gap={10}>
                <BsStar />
                {theme.lightTheme ? (
                  <p className=" text-[#132F65]  text-sm">
                    {isEnglish ? "Favourite Club" : "Klabu pendwa"}
                  </p>
                ) : (
                  <p>{isEnglish ? "Favourite Club" : "Klabu pendwa"}</p>
                )}
              </Row>
            </PopOverMoreItem>
          ) : (
            ""
          )}
          {menus.map((menu, index) => {
            return (
              <PopOverMoreItem
                key={index}
                bg={theme.lightTheme ? "#fff" : "#132F65"}
                hoverColor={theme.lightTheme ? "#C1C1C1" : "#1F3C6A"}
                onClick={() => {
                  menu.path && navigate(menu.path);
                  menu.path && dispatch(setTab(5));
                }}
              >
                <Row paddingHorizontal={10} alignCenter gap={10}>
                  {menu.icon}
                  {theme.lightTheme ? (
                    <p className=" text-[#132F65]  text-sm">{menu.label}</p>
                  ) : (
                    <p>{menu.label}</p>
                  )}
                </Row>
              </PopOverMoreItem>
            );
          })}
        </Column>
      </Popover>
      {openModal && (
        <FavouriteTeamModel
          open={openModal}
          handleClose={() => setOpenModal(false)}
          backgroundColor={colors.secondary}
          color={colors.text}
        />
      )}
    </>
  );
};

const PopOverMoreItem = styled.div`
  background-color: ${(props) => props.bg};
  transition: 0.2s;
  width: 100%;
  padding-inline: 10px;
  padding-block: 5px;
  cursor: pointer;
  border-radius: 10px;
  font-weight: bold;

  &:hover {
    background-color: ${(props) => props.hoverColor};
  }
`;

const Logo = () => {
  let navigate = useNavigate();
  const setTabIndex = useSetAtom(tabIndexAtom);
  const handleLogoClick = () => {
    setTabIndex(0);
    navigate("/");
  };

  return (
    <LogoImage
      style={{ height: 80 }}
      onClick={handleLogoClick}
      src={logoGrouped}
      alt=""
    />
  );
};

const NavigationDropDownItem = ({ text, onClick, icon }) => {
  const [colors] = useAtom(colorAtom);
  return (
    <NavigationDropDownItemContainer atomColors={colors} onClick={onClick}>
      <Row alignCenter gap={10}>
        {icon ?? ""}
        <ProfileDropDownItem>{text}</ProfileDropDownItem>
      </Row>
      <AiOutlineArrowRight />
    </NavigationDropDownItemContainer>
  );
};

const NavigationDropDownItemContainer = styled.div`
  width: 92%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  transition: 0.2s;
  border-radius: 5px;

  &:hover {
    background-color: ${(props) => props.atomColors.faintText};
  }
`;

const useNavigation = ({
  setSelectedTabIndex,
  settokenAtom,
  setuserProfileAtomData,
}) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const navigationTextAtomValue = useAtomValue(navigationTextAtom);
  const tabs = navigationTextAtomValue.menuItems;

  const [showDropDown, setshowDropDown] = useState(false);
  const [teams, setTeams] = useState();

  const [showMoreDropDown, setshowMoreDropDown] = useState(false);

  useEffect(() => {
    axios.get(`${URLS.allTeamsBySeason}/2022-2023`).then((res) => {
      if (res.status === 200) {
        setTeams(res.data);
      }
    });
  }, []);

  const handleSetShowMoreDropDown = () => {
    setshowMoreDropDown((prev) => !prev);
  };

  const handleProfileSectionClick = () => {
    setshowDropDown((prev) => !prev);
  };

  const handleLoginOrSignUp = () => {
    navigate("auth/login");
  };

  const handleChange = (_, newIndex) => {
    setSelectedTabIndex(newIndex);
    dispatch(setTab(newIndex));
    routeNavigator(newIndex, navigate, redirect);
  };

  return {
    tabs,
    handleChange,
    showDropDown,
    handleProfileSectionClick,
    handleLoginOrSignUp,
    showMoreDropDown,
    handleSetShowMoreDropDown,
    navigate,
    teams,
  };
};

const NavigationBarContainer = styled(motion.div)`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* padding: ${`0 10%`}; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${Colors.secondary};
  z-index: 400;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  user-select: none;
`;

const ProfileCircle = styled.div`
  height: 60px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: ${(props) => `1px solid ${props.colorsAtom.faintText}`};
  background-color: ${(props) => props.colorsAtom.background};
  color: ${(props) => props.colorsAtom.text};
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
`;

const ProfileDropDown = styled.div`
  position: absolute;
  right: 0;
  top: 60px;
  width: 300px;
  background-color: tomato;
  border-radius: ${BORDER_RADIUS};
  border: ${(props) => `0.5px solid ${props.colorsAtom.faintText}`};
  background-color: ${(props) => props.colorsAtom.secondary};
  cursor: pointer;
  padding: 10px;
`;

const ProfileDropDownItem = styled.div`
  width: 100%;
  height: 100%;
`;

const LoginorSignUp = styled.p`
  cursor: pointer;
`;

const ThemeToggler = ({ isLight, setTheme }) => {
  const _height = 30;
  return (
    <div
      onClick={async () =>
        await setTheme((prev) => {
          return { ...prev, lightTheme: !prev.lightTheme };
        })
      }
      style={{
        height: _height,
        width: _height * 2,
        borderRadius: 20,
        backgroundColor: isLight ? "#3B537B" : "#1F3C6A",
        display: "flex",
        alignItems: "center",
        justifyContent: isLight ? "flex-start" : "flex-end",
        paddingInline: 2,
        cursor: "pointer",
      }}
    >
      <div
        style={{
          height: _height - 4,
          aspectRatio: 1,
          borderRadius: "50%",
          backgroundColor: !isLight ? "#031D4D" : "#fff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: isLight ? "#212121" : "#fff",
        }}
      >
        {isLight ? <BsSun /> : <BsFillMoonFill />}
      </div>
    </div>
  );
};
