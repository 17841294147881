import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { BsArrowRight, BsCheck2 } from "react-icons/bs";
import { TiWarning } from "react-icons/ti";
import styled from "styled-components";
import { URLS } from "../../../core/urls";
import apiCall from "../../../functions/api_call";
import { colorAtom } from "../../../state/shared-state-atoms/colors";
import { languageAtom } from "../../../state/shared-state-atoms/language";
import { themeAtom } from "../../../state/shared-state-atoms/_theme";
import ModalPopUp from "../../ModalPopUp";
import { toast } from "react-hot-toast";
import { MdClear } from "react-icons/md";
import noImage from "../../../assets/teams/noImage.jpg";
import { userProfileAtom } from "../../../state/shared-state-atoms/user-profile-atom";
import axios from "axios";

export default function FavouriteTeamModel({
  open,
  handleClose,
  backgroundColor,
  color,
}) {
  const modalHook = useModal();
  const language = useAtomValue(languageAtom);
  const colors = useAtomValue(colorAtom);
  const theme = useAtomValue(themeAtom);

  return (
    <ModalPopUp
      open={open}
      handleClose={handleClose}
      backgroundColor={backgroundColor}
      color={color}
      width={"80%"}
      children={
        <div className="w-full flex flex-col space-y-4">
          {modalHook.loading ? (
            <p>loading ...</p>
          ) : (
            <div className="w-full flex flex-col space-y-4 items-center">
              <p className="text-2xl font-[800] self-start">
                {language.isEnglish
                  ? "Select your favourite Club"
                  : "Chagua klabu yako pendwa"}
              </p>
              <div className="w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2">
                {modalHook.teamList?.map((team, index) => {
                  const isSelected = modalHook.selectTeam._id === team._id;
                  return (
                    <ImageDiv
                      key={index}
                      team_color={team.team_color}
                      border_color={colors.text}
                      onClick={() => modalHook.setFavouriteTeam(team)}
                      isSelected={isSelected}
                      className={`p-4  aspect-square flex flex-col items-center justify-center rounded-lg relative`}
                    >
                      <img
                        className="w-[80px] aspect-square object-cover"
                        src={team?.logo ?? noImage}
                      />
                      <p style={{ marginTop: "5px", fontWeight: "bold" }}>
                        {team.nick_name}
                      </p>
                      {isSelected && (
                        <div
                          style={{
                            backgroundColor: theme.lightTheme
                              ? "#212121"
                              : "#fff",
                            color: theme.lightTheme ? "#fff" : "#212121",
                          }}
                          className="h-[25px] aspect-square rounded-full flex items-center justify-center absolute top-[10px] right-[10px] shadow-lg "
                        >
                          <BsCheck2 />
                        </div>
                      )}
                    </ImageDiv>
                  );
                })}
              </div>
              <div className="flex flex-col lg:flex-row gap-2 items-center w-full">
                <button
                  onClick={() => modalHook.handleRemove(handleClose)}
                  disabled={Boolean(
                    modalHook.selectedTeamCardIndex === null ||
                      modalHook.removeloading ||
                      modalHook.continueloading
                  )}
                  style={{
                    display: modalHook.teamList.length === 0 ? "none" : "flex",
                    backgroundColor: "tomato",
                    color: "#fff",
                    cursor: Boolean(
                      modalHook.selectedTeamCardIndex === null ||
                        modalHook.removeloading ||
                        modalHook.continueloading
                    )
                      ? "not-allowed"
                      : "pointer",
                  }}
                  className="h-[40px] w-full lg:w-[30%] px-4 py-3 bg-[#cc4028] rounded-lg space-x-2 items-center justify-center"
                >
                  <p className="font-[800] whitespace-nowrap">
                    {modalHook.removeloading
                      ? language.isEnglish
                        ? "Loading ..."
                        : "Tafadhali subiri ..."
                      : language.isEnglish
                      ? "Remove favourite team"
                      : "Ondoa timu pendwa"}
                  </p>
                  {!modalHook.removeloading && <MdClear />}
                </button>

                <button
                  onClick={() => modalHook.handleContinue(handleClose)}
                  disabled={Boolean(
                    modalHook.selectedTeamCardIndex === null ||
                      modalHook.removeloading ||
                      modalHook.continueloading
                  )}
                  style={{
                    display: modalHook.teamList.length === 0 ? "none" : "flex",
                    backgroundColor: Boolean(
                      modalHook.selectedTeamCardIndex === null ||
                        modalHook.removeloading ||
                        modalHook.continueloading
                    )
                      ? "grey"
                      : theme.lightTheme
                      ? "#031D4D"
                      : "#EFF2F7",
                    color: theme.lightTheme ? "#fff" : "#212121",
                    cursor: Boolean(
                      modalHook.selectedTeamCardIndex === null ||
                        modalHook.removeloading ||
                        modalHook.continueloading
                    )
                      ? "not-allowed"
                      : "pointer",
                  }}
                  className="h-[40px] w-full lg:w-[70%] px-4 py-3 bg-[tomato] rounded-lg space-x-2 items-center justify-center"
                >
                  <p className="text-sm lg:text-lg font-[800]">
                    {modalHook.continueloading
                      ? language.isEnglish
                        ? "Loading ..."
                        : "Tafadhali subiri ..."
                      : language.isEnglish
                      ? "Confirm favourite team"
                      : "Thibitisha team pendwa"}
                  </p>
                  {!modalHook.continueloading && <BsArrowRight />}
                </button>
              </div>
            </div>
          )}
        </div>
      }
    />
  );
}

const ImageDiv = styled.div`
  border: ${(props) => `0.5px solid ${props.border_color}`};
  background-color: ${(props) =>
    props.isSelected ? `${props.team_color}d1` : ""};
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background-color: ${(props) => `${props.team_color}d1`};
  }
`;

const useModal = () => {
  // this is for testing when incoming response has a fovourite team selected
  const favTeamID = "62f9f0e5dfc0dbf074997638";

  const language = useAtomValue(languageAtom);

  const [loading, setLoading] = useState(false);
  const [teamList, setTeamList] = useState([]);
  const [selectTeam, setSelectTeam] = useState({});
  const [favTeam, setFavTeam] = useState({});

  const user = useAtomValue(userProfileAtom);

  const [selectedTeamCardIndex, setSelectedTeamCardIndex] = useState(null);
  const setFavouriteTeam = (team) =>
    setSelectTeam((prev) => {
      prev = team;
      return prev;
    });

  const [continueloading, setContinueLoading] = useState(false);

  const handleContinue = async (handleClose) => {
    console.log(user.token);
    try {
      setContinueLoading(true);

      const response = await axios.post(
        `${URLS.favouriteTeam}`,
        { teams: [selectTeam._id] },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      if (response.status === 201 || response.status === 200) {
        handleClose();
        user.favTeam = selectTeam;
        setFavTeam(response.data.team);
        setSelectTeam(response.data.team);
        setContinueLoading(false);
        toast.success(
          language.isEnglish
            ? "Successfully updated your favourite team"
            : "Umefanikiwa kubadili timu yako pendwa"
        );
      } else {
        setContinueLoading(false);
        toast.error(language.isEnglish ? "Operation failed" : "Imeshindikana");
      }
    } catch (err) {
      setContinueLoading(false);

      console.log(err);
      toast.error(language.isEnglish ? "Operation failed" : "Imeshindikana");
    }
  };

  const [removeloading, setRemoveLoading] = useState(false);
  const handleRemove = (handleClose) => {
    setRemoveLoading(true);
    setTimeout(() => {
      handleClose();
      setRemoveLoading(false);
      toast.success(
        language.isEnglish
          ? "Successfully removed your favourite team"
          : "Umefanikiwa kuondoa timu yako pendwa"
      );
    }, 3000);
  };

  useEffect(() => {
    const call = async () => {
      try {
        setLoading(true);
        const response = await apiCall({ url: URLS.allTeams });
        const _teams = response.data.sort((a, b) => (a.name > b.name ? 1 : -1));
        setTeamList((prev) => {
          prev = _teams;
          return prev;
        });

        // handling figuuring what the fav team is currently
        const favTeam = _teams.find((team) => team._id === favTeamID);
        // getting the index from the sorted team list
        const requiredIndex = _teams.indexOf(favTeam);
        console.log("requiredIndex", requiredIndex);
        setSelectedTeamCardIndex((prev) => {
          prev = requiredIndex;
          return prev;
        });

        setLoading(false);
      } catch (error) {
        console.log("error getting team list in fav team", error);
        setLoading(false);
      }
    };
    call();
  }, []);

  // useEffect(() => {
  //   console.log(user.favTeam);
  //   if (!user.favTeam) {
  //     const call = async () => {
  //       const response = await axios.get(URLS.favouriteTeam, {
  //         headers: {
  //           Authorization: `Bearer ${user.token}`,
  //         },
  //       });
  //       console.log(response);

  //       if (response.status === 200) {
  //         user.favTeam = response.data.team;
  //         setFavTeam(response.data.team);
  //         setSelectTeam(response.data.team);
  //       }
  //     };
  //     call();
  //   } else {
  //     setFavTeam(user.favTeam);
  //     setSelectTeam(user.favTeam);
  //   }
  // }, []);

  return {
    loading,
    teamList,
    selectedTeamCardIndex,
    favTeam,
    selectTeam,
    setFavouriteTeam,
    handleContinue,
    continueloading,
    favTeamID,
    removeloading,
    handleRemove,
  };
};
