import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Colors } from "../../../../../core/colors";
import { ScrollableTabs } from "../../../../../shared-components/scrollable-tabs";

export function MatchDayTabs({matchDays, matchDayIndex, handleChange}) {
  return (
    <>
    <ScrollableTabs currentIndex={matchDayIndex} handleChange={handleChange} tabs={matchDays} />
    </>
  );
}
