import styled from "styled-components";
import { Colors } from "../../../../../core/colors";
import { BORDER_RADIUS } from "../../../../../core/measurements";

export const MatchDayContainer = styled.div`
  width: 90%;
  background-color: ${Colors.background};
  border-radius: ${BORDER_RADIUS};

  ::-webkit-scrollbar {
    width: 10px;
    background-color: #132f65;
  }

  ::-webkit-scrollbar-track {
    background-color: #132f65;
    margin-block: 20px;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    margin: 2px;
    background-color: #042559;
    border-radius: 5px;
  }
`;
