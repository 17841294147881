import styled from "styled-components";

export const NewsFullContainer = styled.div`
  height: 100%;

  width: 100%;
  color: ${(props) => props.atomColors.text};
  /* background-color: ${(props) => props.atomColors.background}; */
  background-color: ${(props) => props.atomColors.accent};

  display: flex;
  flex-direction: column;
  align-items: center;

  padding-block: 10px;
`;
