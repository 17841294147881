import { useAtom, useAtomValue } from "jotai";
import { Outlet } from "react-router-dom";
import { BORDER_RADIUS } from "../../../../core/measurements";
import { Row } from "../../../../shared-components/row";
import { colorAtom } from "../../../../state/shared-state-atoms/colors";
import { useStatsHook } from "./stats-hook";

import { _routeNames } from "../../../../app/routes";
import { StatTbContainer } from "./components/stat-tab-container";
import { StatTabLabel } from "./components/stat-tab-label";
import { PlayerTab } from "./tabs-v2/player-tab";
import { TeamTab } from "./tabs-v2/team-tab";
import { AwardsTab } from "./tabs-v2/awards-tab";
import { StatsPagePlayerStats } from "./tabs/player-stats";
import { StatsPageTeamStats } from "./tabs/team-stats";
import useScrollToTop from "../../../../hooks/scroll-to-top";
import { AllStatsAwardsList } from "../../../all-stats-list-awards";
import { languageAtom } from "../../../../state/shared-state-atoms/language";

export function Stats() {
  useScrollToTop();
  const { selectedTab, setSelctedTab, navigate, handleTabSwitch } =
    useStatsHook();
  const [colors] = useAtom(colorAtom);
  const lang = useAtomValue(languageAtom);
  const tabs = [
    {
      label: lang.isEnglish ? "Players" : "Wachezaji",
      path: _routeNames.root.stats.players,
    },
    {
      label: lang.isEnglish ? "Teams" : "Timu",
      path: _routeNames.root.stats.teams,
    },
    {
      label: lang.isEnglish ? "Awards" : "Tuzo",
      path: _routeNames.root.stats.awards,
    },
    {
      label: lang.isEnglish ? "Trophy" : "Makombe",
      path: _routeNames.root.stats.awards,
    },
  ];
  return (
    <>
      <Row alignCenter justifyCenter gap={20} style={mainRowStyle(colors)}>
        {tabs.map((tabItem, index) => {
          return (
            <div>
              <StatTabLabel
                key={index}
                selected={index === selectedTab}
                onClick={() => handleTabSwitch(index)}
              >
                {tabItem.label}
              </StatTabLabel>
              <div
                style={{
                  height: 5,
                  width: 100,
                  backgroundColor: "red",
                  borderWidth: 1,
                  borderColor: "red",
                  borderRadius: 10,
                  display: index === selectedTab ? "flex" : "none",
                }}
              ></div>
            </div>
          );
        })}
      </Row>

      {(function (tabLabel) {
        switch (tabLabel) {
          case tabs[0].label:
            return <StatsPagePlayerStats />;

          case tabs[1].label:
            return <StatsPageTeamStats />;

          case tabs[2].label:
            return <AllStatsAwardsList />;
          case tabs[3].label:
            return <AllStatsAwardsList />;

          default:
            break;
        }
      })(tabs[selectedTab].label)}

      <Outlet />
    </>
  );
}

const mainRowStyle = (colors) => {
  return {
    width: "100%",
    height: 60,
    paddingInline: "50px",
    // maxWidth: 1200,
    marginBottom: "20px auto",
    borderRadius: BORDER_RADIUS,
    backgroundColor: colors.secondary,
    // paddingBlock: 20,
  };
};
