import bg from "../../../../assets/fantasy/bg.svg";
import fantasy_man from "../../../../assets/fantasy/fantasy_man.svg";
import fantasy_start from "../../../../assets/fantasy/fantasy_start.svg";
import game_text from "../../../../assets/fantasy/game_text.svg";
import ellipse1 from "../../../../assets/fantasy/ellipse1.svg";
import ellipse2 from "../../../../assets/fantasy/ellipse2.svg";
import nbc_fantasy_logo from "../../../../assets/fantasy/nbc_fantasy_logo.svg";
import { Column } from "../../../../shared-components/column";
import { Row } from "../../../../shared-components/row";
import Label from "../../../../shared-components/Label";
import { URLS } from "../../../../core/urls";
import { useWindowWidth } from "../../../../hooks/use-window-width";
import { useAtomValue } from "jotai";
import { languageAtom } from "../../../../state/shared-state-atoms/language";
import { InputField } from "../../../../shared-components/input-field";
import { useState } from "react";
import { AiOutlineMail } from "react-icons/ai";
import { IoIosPhonePortrait } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { postRequestWithOutToken } from "../../../../core/network-request-functions";
import { RiSendPlaneFill } from "react-icons/ri";
import { toast } from "react-hot-toast";
import { userProfileAtom } from "../../../../state/shared-state-atoms/user-profile-atom";

export function Fantasy() {
  const { windowWidth } = useWindowWidth();
  const language = useAtomValue(languageAtom);
  const words = wordsForFantasyPage(language.isEnglish);
  const fantasyHook = useFantasy();
  const userProfile = useAtomValue(userProfileAtom);
  return (
    <div style={fantasyContainerStyle()}>
      <div style={blurredFormStyles(windowWidth)}>
        <Row alignCenter gap={10}>
          <img
            src={nbc_fantasy_logo}
            alt=""
            style={{ height: 70, width: 70 }}
          />
          <Column>
            <Label xBold large text={words.fantasyFormHeaderLabel.bold} />
            <Label text={words.fantasyFormHeaderLabel.light} />
          </Column>
        </Row>

        <Label a_s_left fontSize={14} text={words.instruction} />

        <Column gap={10}>
          <InputField
            Icon={() => <IoIosPhonePortrait style={{ fontSize: 20 }} />}
            placeHolder={language.isEnglish ? "Phone number" : "Namba ya simu"}
            type="tel"
            value={fantasyHook.phoneNumber}
            setter={fantasyHook.setPhoneNumber}
            backgroundColor={"#09152C"}
            color={"#fff"}
            disabled={fantasyHook.loading}
          />
          <p className=" self-center"> OR </p>
          <InputField
            Icon={() => <AiOutlineMail style={{ fontSize: 20 }} />}
            placeHolder={language.isEnglish ? "Email" : "Baruapepe"}
            type="email"
            value={fantasyHook.email}
            setter={fantasyHook.setEmail}
            backgroundColor={"#09152C"}
            color={"#fff"}
            disabled={fantasyHook.loading}
          />

          <button
            onClick={fantasyHook.handleSubmit}
            style={submitDetailsButtonStyle()}
          >
            <Row alignCenter justifyCenter gap={10}>
              <RiSendPlaneFill style={{ fontSize: "large" }} />
              <Label
                bold
                text={fantasyHook.loading ? words.wait : words.send}
              />
            </Row>
          </button>
        </Column>
      </div>

      {/* 
                Background image assembly from figma assets
            */}
      <img
        src={bg}
        alt=""
        style={{ height: "100%", width: "100%", objectFit: "cover" }}
      />
      <img
        src={fantasy_man}
        alt=""
        style={{
          height: "70%",
          width: "auto",
          objectFit: "cover",
          position: "absolute",
          right: 0,
          bottom: 0,
        }}
      />
      {windowWidth > 1450 && (
        <>
          <img
            src={ellipse1}
            alt=""
            style={{
              height: "30%",
              width: "50%",
              objectFit: "cover",
              position: "absolute",
              left: "40%",
              top: "-20%",
            }}
          />
          <img
            src={ellipse2}
            alt=""
            style={{
              height: "45%",
              width: "50%",
              objectFit: "cover",
              position: "absolute",
              left: -30,
              top: "-30%",
            }}
          />
        </>
      )}
      <div style={centerImagesContainerStyle()}>
        {windowWidth > 1000 && (
          <img
            src={fantasy_start}
            alt=""
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
          />
        )}
        <img src={game_text} alt="" style={{ height: "50%", marginTop: 23 }} />
      </div>
    </div>
  );
}

function submitDetailsButtonStyle() {
  return {
    border: "none",
    outline: "none",
    cursor: "pointer",
    width: "100%",
    paddingBlock: 15,
    borderRadius: 5,
    overflow: "clip",
    backgroundColor: "#E24B50",
    marginTop: 10,
  };
}

function wordsForFantasyPage(isEnglish) {
  return {
    fantasyFormHeaderLabel: {
      bold: "NBC Fantasy",
      light: "League",
    },
    instruction: isEnglish
      ? "Please provide your email and phone number and we will notify you when this feature is up and running."
      : "Tafadhali weka baruapepe yako na namba ya simu, sisi tutakutaarifu kipengele hichi kitakapokuwa tayari.",
    send: isEnglish ? "Submit details" : "Tuma taarifa",
    wait: isEnglish ? "Please wait" : "Tafadhali subiri",
  };
}

function useFantasy() {
  const language = useAtomValue(languageAtom);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  function validateEmail(email) {
    if (email) {
      return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    } else {
      return true;
    }
  }

  function clearFields() {
    setEmail("");
    setPhoneNumber("");
  }

  async function handleSubmit() {
    if (!email && !phoneNumber) {
      toast.error(
        language.isEnglish
          ? "The fields should not be empty"
          : "Weka baruapepe au namba ya simu kwenye fomu"
      );
      return;
    }

    if (!validateEmail(email)) {
      toast.error(
        language.isEnglish
          ? "The email field is invalid"
          : "Baruapepe uliyoweka haipo sahihi"
      );
      return;
    }

    if (phoneNumber.length != 10) {
      toast.error(
        language.isEnglish
          ? "The phone number is invalid"
          : " namba ya simu ulioingiza sio sahihi"
      );
      return;
    }

    const _body = {
      phone: phoneNumber,
      email: email,
    };

    setLoading(true);

    try {
      const response = await postRequestWithOutToken(URLS.fantasy, _body);
      if (response.status === 200) {
        setLoading(false);
        setPhoneNumber("");
        toast.success(
          language.isEnglish
            ? "You succesfully registered in fantasy leaugue"
            : "Umesajiliwa kwenye ligi ya fantasy"
        );
        // navigate('/')
      }
    } catch (error) {
      const {
        response: {
          data: { message },
        },
      } = error;
      toast.error(language.isEnglish ? "operation failed" : "imeshindikana");
      console.log({ message });
      setLoading(false);
    }

    // const formData = new FormData()
    // formData.append("email", email)
    // formData.append("phone_number", phoneNumber)

    // setLoading(true)
    // setTimeout(() => {
    //     setLoading(false)
    //     // after sending the appropriate request
    //     clearFields()
    // }, 3000);
  }
  return {
    email,
    setEmail,
    phoneNumber,
    setPhoneNumber,
    handleSubmit,
    loading,
  };
}

function fantasyContainerStyle() {
  return {
    height: "90vh",
    width: "100%",
    textAlign: "center",
    overflow: "clip",
    position: "relative",
  };
}

function centerImagesContainerStyle() {
  return {
    height: "40%",
    width: "auto",
    objectFit: "cover",
    position: "absolute",
    left: "40%",
    top: "50%",
    transform: "translateX(-40%) translateY(-50%)",
    zIndex: 50,
    display: "flex",
    flexDirection: "row",
    gap: 10,
  };
}

function blurredFormStyles(windowWidth) {
  return {
    backgroundColor: "rgba(0,0,0,0.4)",
    color: "#fff",
    position: "absolute",
    top: "50%",
    left: windowWidth < 580 ? 20 : "10%",
    right: windowWidth < 580 ? 20 : "",
    transform: "translateY(-50%)",
    height: "fit-content",
    width: windowWidth < 580 ? "90%" : 450,
    zIndex: 60,
    backdropFilter: "blur(10px)",
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: 20,
    padding: 20,
    textAlign: "left",
  };
}
