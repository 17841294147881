import styled from "styled-components";
import { Colors } from "../../../../../core/colors";
import { BORDER_RADIUS } from "../../../../../core/measurements";

export const LeagueTableContainer = styled.div`
  width: 100%;
  /* background-color: ${Colors.background}; */
  border-radius: ${BORDER_RADIUS};
  min-height: 100vh;
  user-select: none;
`;
