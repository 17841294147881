import { useAtomValue } from "jotai";
import styled from "styled-components";
import { BORDER_RADIUS } from "../../../../../core/measurements";
import Label from "../../../../../shared-components/Label";
import { colorAtom } from "../../../../../state/shared-state-atoms/colors";

const PopUpItemBox = ({ value, label, isRound = false, currentValue, valueSetter, handleClose }) => {
  const colors = useAtomValue(colorAtom);
  return (
    <PopUpItemBoxContainer
      atomColors={colors}
      onClick={() => {
        valueSetter((prev) => {
          prev = value;
          return prev;
        });
        handleClose();
      }}
      style={{
        backgroundColor: value === currentValue ? colors.secondary : "",
        fontWeight: value === currentValue ? 800 : "",
        fontSize: value === currentValue ? "x-large" : "",
        color: colors.text,
        width: "100%",
        paddingBlock: 15,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: BORDER_RADIUS,
      }}
    >
      <Label text={label} />
    </PopUpItemBoxContainer>
  );
};

export default PopUpItemBox;

const PopUpItemBoxContainer = styled.div`
  transition: 0.3s;
  &:hover {
    background-color: ${(props) => props["atomColors"].secondary};
    font-weight: bold;
    cursor: pointer;
  }
`;
