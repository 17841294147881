import axios from "axios";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { URLS } from "../../../../core/urls";
import { leagueTableTextAtom } from "./league-table-text";

export const useLeagueTable = () => {
  const leagueTableText = useAtomValue(leagueTableTextAtom);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const tabs = [leagueTableText.league];

  const [standingsData, setstandingsData] = useState([]);
  const [seasonQuerry, setseasonQuerry] = useState("");
  const [seasons, setSeasons] = useState([]); //get current season year from api Eg:2022-2023
  const [loading, setloading] = useState(false);

  const handleArrowRight = () =>
    setseasonQuerry((prev) => {
      const seasonIndex = seasons.findIndex(
        (element) => element === seasonQuerry
      );
      if (seasonIndex + 1 === seasons.length) return prev;
      prev = seasons[seasonIndex + 1];
      return prev;
    });

  const handleArrowLeft = () =>
    setseasonQuerry((prev) => {
      const seasonIndex = seasons.findIndex(
        (element) => element === seasonQuerry
      );
      if (seasonIndex < 1) return prev;
      prev = seasons[seasonIndex - 1];
      return prev;
    });
  //get current season use effect
  useEffect(() => {
    const fetchCurrentSeason = async () => {
      try{
        const response = await axios.get(URLS.allSeasons);
        const data = response.data;

        console.log(data)

        setSeasons(data.map(season => season.year));

        const currentseason = data.find(season =>season.current);
        if (currentseason){
          setseasonQuerry(currentseason.year);        }
      } catch (error){
        return error;
      }
    }
    fetchCurrentSeason();
  }, []);

  //End

  // useEffect(() => {
  //   setseasonQuerry(seasons[0]);
  // }, [seasons]);

  useEffect(() => {
    setloading(true);
    let selectedSeason = seasonQuerry;

    axios
      .get(`${URLS.allTeamsBySeason}/${selectedSeason}`)
      .then((response) => {
        let _seasons = new Set();
        response.data.forEach((element) => {
          element.seasons?.forEach((el) => _seasons.add(el.year));
        });

        let _seasonList = [..._seasons];
        let data = [];
        _seasonList.forEach((season) => {
          response.data?.forEach((dt) => {
            dt.seasons?.forEach((s) => {
              if (s.year === season) {
                data.push({
                  season: s.year,
                  name: dt.name,
                  logo: dt.logo,
                  points: s.points,
                  plays: s.plays,
                  draws: s.draw,
                  lost: s.lost,
                  wins: s.wins,
                  scored_goals: s.scored_goals,
                  concided_goals: s.concided_goals,
                  id: dt._id,
                });
              }
            });
          });
        });

        let requiredData = data
          .filter((el) => el.season === selectedSeason)
          .sort((a, b) => b.points - a.points);
        setstandingsData(requiredData);
        setloading(false);
      })
      .catch((error) => {
        console.log({ error });
        setloading(false);
      });
  }, [seasonQuerry]);

  const handleChange = (_, newTabIndex) => {
    setSelectedTabIndex(newTabIndex);
  };

  return {
    selectedTabIndex,
    setSelectedTabIndex,
    tabs,
    handleChange,
    leagueTableText,
    standingsData,
    seasonQuerry,
    setseasonQuerry,
    handleArrowRight,
    handleArrowLeft,
    loading,
  };
};
