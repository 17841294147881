import styled from "styled-components";
import { Colors } from "../core/colors";
import { BORDER_RADIUS } from "../core/measurements";

export const MainContainer = styled.div`
  min-height: 100vh;
  width: 100%;
  color: ${props => props.atomColors ? props.atomColors.text: ""};
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${props => props.atomColors ? props.atomColors.background : ""};
    margin-block: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${props => props.atomColors ? props.atomColors.faintText : ""};
    border-radius: ${BORDER_RADIUS};
  }
`;
