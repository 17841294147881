import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { NavigationBar } from "../../shared-components/navigation-bar/navigation-bar";
import { MobileMenu } from "../../shared-components/navigation-bar/mobile-menu";
import { Column } from "../../shared-components/column";
import { usePrivacyPolicyHook } from "./use-privacy-policy";

import { handleGoBack } from "../../functions/handleGoBack";
import { Footer } from "../../shared-components/footer";
import { GoBack } from "../../shared-components/go-back";
import { PrivacyPolicyContainer } from "./components/privacy-policy-container";
import EnglishPolicy from "./components/EnglishPolicy";
import SwahiliPolicy from "./components/SwahiliPolicy";

export function PrivacyPolicy() {
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const { navigate, colors, contents, loading, language } =
    usePrivacyPolicyHook();
  return (
    <>
      <NavigationBar setOpenMobileMenu={setOpenMobileMenu} />
      <AnimatePresence>
        {openMobileMenu ? (
          <MobileMenu setOpenMobileMenu={setOpenMobileMenu} />
        ) : (
          ""
        )}
      </AnimatePresence>
      <PrivacyPolicyContainer atomColors={colors}>
        <Column
          style={{
            width: "90%",
            maxWidth: 1000,
          }}
        >
          <GoBack handleGoBack={() => handleGoBack(navigate)} />
          <br />
          {loading ? (
            <p>{language.isEnglish ? "loading" : "Tafadhali subiri"} ...</p>
          ) : (
            // <div dangerouslySetInnerHTML={{ __html: contents }} />
            <>{language.isEnglish ? <EnglishPolicy /> : <SwahiliPolicy />}</>
          )}
        </Column>
      </PrivacyPolicyContainer>
      <Footer />
    </>
  );
}
