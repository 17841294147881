import { useAtomValue, useSetAtom } from "jotai";
import styled from "styled-components";
import { useWindowWidth } from "../../../../../../hooks/use-window-width";
import Label from "../../../../../../shared-components/Label";
import { colorAtom } from "../../../../../../state/shared-state-atoms/colors";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { _routeNames } from "../../../../../../app/routes";
import { tabIndexAtom } from "../../../../../../state/shared-state-atoms/tab-index";
import { languageAtom } from "../../../../../../state/shared-state-atoms/language";

export default function StatsInviter() {
  const setIndex = useSetAtom(tabIndexAtom);
  let navigate = useNavigate();
  const { windowWidth } = useWindowWidth();
  const colors = useAtomValue(colorAtom);
  const language = useAtomValue(languageAtom);

  return (
    <Container>
      <Label xBold xLarge color={colors.faintText} noWrap text={language.isEnglish ? "Statistics and awards" : "Takwimu na Tuzo"} />
      <StatInviterRow
        onClick={() => {
          setIndex(3);
          navigate(_routeNames.root.stats.index);
        }}
        style={{ flexDirection: windowWidth < 800 ? "column" : "row" }}
      >
        <Box colors={colors}>
          <Label xBold color={colors.text} noWrap text={language.isEnglish ? "Player statitics" : "Takwimu za wachezaji"} />
        </Box>
        <Box colors={colors}>
          <Label xBold color={colors.text} noWrap text={language.isEnglish ? "Team statitics" : "Takwimu za timu"} />
        </Box>
        <Box colors={colors}>
          <Label xBold color={colors.text} noWrap text={language.isEnglish ? "Awards" : "Tuzo"} />
        </Box>
        <BorderButton></BorderButton>
      </StatInviterRow>
    </Container>
  );
}

const Container = styled.div`
  height: 50vh;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 150px;
  border-radius: 10px;
  gap: 20px;
`;

const ShowCaseImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

const BorderButton = styled(motion.div)`
  height: 100%;
  width: 100%;
  border-radius: 10px;
  /* border: 2px solid tomato; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  padding-block: 15px;
  padding-inline: 20px;
  cursor: pointer;
  display: none;
  transition: 0.5s;
  opacity: 0;
`;

const Box = styled.div`
  height: 200px;
  width: 100%;
  background-color: ${(props) => (props["colors"] ? props["colors"].secondary : "")};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StatInviterRow = styled(motion.div)`
  display: flex;
  width: 100%;
  gap: 20px;
  cursor: pointer;
  transition: 0.5s;
  position: relative;

  &:hover {
    font-size: large;
    filter: blur(5px);
  }
`;
