import { useAtom } from "jotai";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import statTabIndex from "./stats-index-atom";

export const useStatsHook = () => {
    let navigate = useNavigate();
    const [tabIndex, setTabIndex] = useAtom(statTabIndex)

    const [selectedTab, setSelctedTab] = useState(0);

    const handleTabSwitch = (index) => setTabIndex(index)

    return { selectedTab: tabIndex, setSelctedTab: setTabIndex, navigate, handleTabSwitch }
}

