import styled from "styled-components";
import { BORDER_RADIUS } from "../../core/measurements";
import { handleGoBack } from "../../functions/handleGoBack";
import { useWindowWidth } from "../../hooks/use-window-width";
import { Column } from "../../shared-components/column";
import { GoBack } from "../../shared-components/go-back";
import Label from "../../shared-components/Label";
import { Row } from "../../shared-components/row";
import { useProfileManagerHook } from "./use-manager-profile";
import noImage from "../../assets/teams/noImage.jpg";
import avatar from "../../assets/avatar.png";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { useAtomValue } from "jotai";
import { Footer } from "../../shared-components/footer";
import { colorAtom } from "../../state/shared-state-atoms/colors";
import { languageAtom } from "../../state/shared-state-atoms/language";
import {
  swahiliDateFormatter,
  TopLabel,
} from "../player-profile/PlayerProfile";

export function ManagerProfile() {
  const { windowWidth } = useWindowWidth();
  const language = useAtomValue(languageAtom);
  const { managerData, colors, navigate, loading, managerHistory } =
    useProfileManagerHook();

  if (loading) {
    return (
      <ManagerProfileContainer atomColors={colors}>
        <Column
          alignCenter
          justifyCenter
          gap={20}
          style={{ maxWidth: 1200, width: "90%", margin: "0 auto" }}
        >
          <p>loading ...</p>
        </Column>
      </ManagerProfileContainer>
    );
  }

  if (!loading) {
    return (
      <>
        {managerData && (
          <ManagerProfileContainer atomColors={colors}>
            <Column
              gap={40}
              style={{ maxWidth: 1200, width: "90%", margin: "0 auto" }}
            >
              <Row alignCenter justifyBetween>
                <GoBack handleGoBack={() => handleGoBack(navigate)} />
              </Row>

              <TopLabel name={managerData.name} />

              <Row gap={20} alignCenter>
                <img
                  src={managerData.pic ?? avatar}
                  alt="coach image"
                  style={{
                    width: windowWidth < 500 ? 120 : 180,
                    aspectRatio: 1,
                    borderRadius: "50%",
                    backgroundColor: colors.secondary,
                    objectFit: "cover",
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  }}
                />

                <Column gap={15}>
                  <Row alignCenter gap={10} style={{ flexWrap: "wrap" }}>
                    <img
                      src={managerData.current_club.logo ?? noImage}
                      alt=""
                      style={{
                        height: 50,
                        aspectRatio: 1,
                        borderRadius: "50%",
                      }}
                    />
                    <p style={{ fontWeight: 800 }}>
                      {managerData.current_club.name}
                    </p>
                  </Row>
                </Column>
              </Row>

              <Column gap={15}>
                <Label
                  capitalize
                  xLarge
                  xBold
                  color={colors.text}
                  text={
                    language.isEnglish
                      ? "personal information"
                      : "Taarifa binafsi"
                  }
                />

                <Column
                  gap={10}
                  style={{
                    backgroundColor: colors.secondary,
                    border: `1px solid ${colors.faintText}`,
                    padding: 25,
                    borderRadius: BORDER_RADIUS,
                  }}
                >
                  <PersonalDetailsRow
                    label={language.isEnglish ? "Country" : "Nchi"}
                    value={managerData.country}
                  />
                  <PersonalDetailsRow
                    label={
                      language.isEnglish
                        ? "Date of Birth"
                        : "Tarehe ya kuzaliwa"
                    }
                    value={
                      language.isEnglish
                        ? managerData.dob
                          ? new Date(managerData.dob).toDateString()
                          : "n/a"
                        : managerData.dob
                        ? ((date) => swahiliDateFormatter(date))(
                            new Date(managerData.dob)
                          )
                        : "n/a"
                    }
                  />
                  <PersonalDetailsRow
                    label={language.isEnglish ? "Age" : "Umri"}
                    value={
                      managerData.dob
                        ? new Date().getFullYear() -
                          new Date(managerData?.dob).getFullYear()
                        : "n/a"
                    }
                  />
                </Column>
              </Column>

              <Column gap={15}>
                <Label
                  capitalize
                  xLarge
                  xBold
                  color={colors.text}
                  text={language.isEnglish ? "history" : "historia"}
                />

                <TableContainer component={Paper} elevation={0}>
                  <Table
                    sx={{ minWidth: 650 }}
                    aria-label="simple table"
                    style={{ color: colors.text }}
                  >
                    <TableHead style={{ backgroundColor: colors.faintText }}>
                      <TableRow>
                        {[
                          language.isEnglish ? "Team" : "Klabu",
                          language.isEnglish ? "From" : "Kuanzia",
                          language.isEnglish ? "To" : "Hadi",
                        ].map((header, index) => (
                          <CustomTableCell key={index} label={header} />
                        ))}
                      </TableRow>
                    </TableHead>

                    <TableBody style={{ backgroundColor: colors.secondary }}>
                      {((stats) => {
                        let missingStat = "n/a";
                        return stats.map((stat, index) => {
                          return (
                            <TableRow key={index}>
                              <CustomTableCell
                                label={stat?.team?.name ?? missingStat}
                              />
                              <CustomTableCell
                                label={stat?.from?.year ?? missingStat}
                              />
                              <CustomTableCell
                                label={stat?.to?.year ?? missingStat}
                              />
                            </TableRow>
                          );
                        });
                      })(managerHistory?.stats ?? [])}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Column>
            </Column>
          </ManagerProfileContainer>
        )}
        <Footer />
      </>
    );
  }
}

const ManagerProfileContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 130vh;
  color: ${(props) => props.atomColors.text};
  background-color: ${(props) => props.atomColors.background};
  position: relative;
  padding-block: 50px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

function PersonalDetailsRow({ label, value }) {
  const colors = useAtomValue(colorAtom);

  return (
    <Row
      alignCenter
      justifyBetween
      style={{
        backgroundColor: colors.background,
        padding: 20,
        borderRadius: BORDER_RADIUS,
        fontWeight: "bold",
      }}
    >
      <p>{label}</p>
      <p>{value}</p>
    </Row>
  );
}

function CustomTableCell({ label }) {
  const colors = useAtomValue(colorAtom);
  return (
    <TableCell
      style={{ color: colors.text, borderBottomColor: colors.faintText }}
    >
      {label}
    </TableCell>
  );
}
