export const leagueColors = (isEnglish) => {
  return {
    championsAndCAFChampionsLeague: {
      color: "#00C853",
      label: isEnglish ? "Champions and CAF Champions League" : "Kombe la Klabu Bingwa na Shirikisho Afrika",
    },
    CAFChampionsLeague: { color: "#69F0AE", label: isEnglish ? "CAF Champions League" : "Kombe la Klabu Bingwa Afrika" },
    CAFConfederationCup: { color: "#AEEA00", label: isEnglish ? "CAF Confederation Cup" : "Kombe la Shirikisho Afrika" },
    relegationPlayOff: { color: "#FFC400", label: isEnglish ? "Relegation playOff" : "Mchujo wa kushuka daraja" },
    relegation: { color: "#D32F2F", label: isEnglish ? "Relegation" : "Kushuka daraja" },
  };
}