import { useWindowWidth } from "../../../../../hooks/use-window-width"
import { Column } from "../../../../../shared-components/column"
import { PlayerCircle } from "./player-circle"
import { PlayerCircleText } from "./player-circle-text"

export function TeamArrangement({ zones, isHome, _team, _borderColor })
{
    const { windowWidth } = useWindowWidth()
    const circleSize = windowWidth < 500 ? 30 : 40

    const handleClick = (name) => alert('navigate to ' + name + "'s details page.")

    switch (zones.length)
    {
        case 3:
            return (
                <>
                    {/* goal keeper*/ }
                    <div
                        style={ {
                            position: 'absolute',
                            top: isHome ? '7%' : '',
                            bottom: !isHome ? '7%' : '',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            width: '10%',
                            aspectRatio: 1,
                            zIndex: 60,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 5,
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontSize: 'small',
                        } }

                    >
                        <PlayerCircle
                            circleSize={ circleSize }
                            isHome={ isHome }
                            jerseyNumber={ 1 }
                            _borderColor={ _borderColor }
                            onClick={ () => handleClick(_team.goalKeeper.name) }
                        />
                        <PlayerCircleText text={ _team.goalKeeper.name } />
                    </div>

                    {/* defenders */ }
                    <div
                        style={ {
                            position: 'absolute',
                            top: isHome ? '16%' : '',
                            bottom: !isHome ? '16%' : '',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            zIndex: 60,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-evenly',
                            gap: 20,
                            width: '100%',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontSize: 'small',
                        } }
                    >
                        {
                            _team.defenders.map((element, index) =>
                            {
                                return (
                                    <Column
                                        justifyCenter
                                        alignCenter
                                        key={ index }
                                        gap={ 5 }
                                        style={ { width: '100%' } }

                                    >
                                        <PlayerCircle
                                            onClick={ () => handleClick(element.player.name) }
                                            circleSize={ circleSize }
                                            isHome={ isHome }
                                            jerseyNumber={ 1 }
                                            _borderColor={ _borderColor }
                                        />
                                        <PlayerCircleText text={ element.player.name } />
                                    </Column>
                                )
                            })
                        }
                    </div>

                    {/* mid field */ }
                    <div
                        style={ {
                            position: 'absolute',
                            top: isHome ? '27%' : '',
                            bottom: !isHome ? '27%' : '',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            zIndex: 60,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-evenly',
                            width: '100%',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontSize: 'small'
                        } }
                    >
                        {
                            _team.midfielders.map((element, index) =>
                            {
                                return (
                                    <Column
                                        justifyCenter
                                        alignCenter
                                        key={ index }
                                        gap={ 5 }
                                        style={ { width: '100%' } }

                                    >
                                        <PlayerCircle
                                            onClick={ () => handleClick(element.player.name) }
                                            circleSize={ circleSize } isHome={ isHome }
                                            jerseyNumber={ 2 }
                                            _borderColor={ _borderColor }
                                        />
                                        <PlayerCircleText text={ element.player.name } />
                                    </Column>
                                )
                            })
                        }
                    </div>

                    {/* foward */ }
                    <div
                        style={ {
                            position: 'absolute',
                            top: isHome ? '38%' : '',
                            bottom: !isHome ? '38%' : '',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            width: '10%',
                            zIndex: 60,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-evenly',
                            width: '100%',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontSize: 'small'
                        } }
                    >
                        {
                            _team.strikers.map((element, index) =>
                            {
                                return (
                                    <Column
                                        justifyCenter
                                        alignCenter
                                        key={ index }
                                        gap={ 5 }
                                        style={ { width: '100%' } }

                                    >
                                        <PlayerCircle
                                            onClick={ () => handleClick(element.player.name) }
                                            circleSize={ circleSize }
                                            isHome={ isHome }
                                            jerseyNumber={ 2 }
                                            _borderColor={ _borderColor }
                                        />
                                        <PlayerCircleText text={ element.player.name } />
                                    </Column>
                                )
                            })
                        }
                    </div>
                </>
            )

        case 4:
            return (
                <>
                    {/* goal keeper*/ }
                    <div
                        style={ {
                            position: 'absolute',
                            top: isHome ? '7%' : '',
                            bottom: !isHome ? '7%' : '',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            width: '10%',
                            aspectRatio: 1,
                            zIndex: 60,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 5,
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontSize: 'small',
                        } }

                    >
                        <PlayerCircle
                            onClick={ () => handleClick(_team.goalKeeper.name) }
                            circleSize={ circleSize }
                            isHome={ isHome }
                            jerseyNumber={ 0 }
                            _borderColor={ _borderColor }
                        />
                        <PlayerCircleText text={ _team.goalKeeper.name } />
                    </div>

                    {/* defenders */ }
                    <div
                        style={ {
                            position: 'absolute',
                            top: isHome ? '16%' : '',
                            bottom: !isHome ? '16%' : '',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            zIndex: 60,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-evenly',
                            gap: 20,
                            width: '100%',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontSize: 'small',
                        } }
                    >
                        {
                            _team.defenders.map((element, index) =>
                            {
                                return (
                                    <Column
                                        justifyCenter
                                        alignCenter
                                        key={ index } gap={ 5 }
                                        style={ { width: '100%' } }

                                    >
                                        <PlayerCircle
                                            onClick={ () => handleClick(element.player.name) }
                                            circleSize={ circleSize }
                                            isHome={ isHome }
                                            jerseyNumber={ 0 }
                                            _borderColor={ _borderColor }
                                        />
                                        <PlayerCircleText text={ element.player.name } />
                                    </Column>
                                )
                            })
                        }
                    </div>

                    {/* mid field */ }
                    <div
                        style={ {
                            position: 'absolute',
                            top: isHome ? '23%' : '',
                            bottom: !isHome ? '23%' : '',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            zIndex: 60,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-evenly',
                            width: '100%',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontSize: 'small'
                        } }
                    >
                        {
                            _team.midfielders.map((element, index) =>
                            {
                                return (
                                    <Column
                                        justifyCenter
                                        alignCenter
                                        key={ index } gap={ 5 }
                                        style={ { width: '100%' } }

                                    >
                                        <PlayerCircle
                                            onClick={ () => handleClick(element.player.name) }
                                            circleSize={ circleSize }
                                            isHome={ isHome }
                                            jerseyNumber={ 0 }
                                            _borderColor={ _borderColor }
                                        />
                                        <PlayerCircleText text={ element.player.name } />
                                    </Column>
                                )
                            })
                        }
                    </div>

                    {/* foward */ }
                    <div
                        style={ {
                            position: 'absolute',
                            top: isHome ? '39%' : '',
                            bottom: !isHome ? '39%' : '',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            width: '10%',
                            zIndex: 60,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-evenly',
                            width: '100%',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontSize: 'small'
                        } }
                    >
                        {
                            _team.strikers.map((element, index) =>
                            {
                                return (
                                    <Column
                                        justifyCenter
                                        alignCenter
                                        key={ index }
                                        gap={ 5 }
                                        style={ { width: '100%' } }
                                    >
                                        <PlayerCircle
                                            onClick={ () => handleClick(element.player.name) }
                                            circleSize={ circleSize }
                                            isHome={ isHome }
                                            jerseyNumber={ 9 }
                                        />
                                        <PlayerCircleText text={ element.player.name } />
                                    </Column>
                                )
                            })
                        }
                    </div>
                </>
            )

        default:
            break;
    }
}