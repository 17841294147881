import { useNavigate } from "react-router-dom";
import { LARGE_SPACING } from "../core/measurements";
import { TextButton } from "./text-button";
import { Column } from "./column";
import { useAtomValue } from "jotai";
import { languageAtom } from "../state/shared-state-atoms/language";
import { colorAtom } from "../state/shared-state-atoms/colors";

export function SignUpOrLoginInstead({ signUp, handleClick }) {
  let navigate = useNavigate();
  const language = useAtomValue(languageAtom);
  const colors = useAtomValue(colorAtom);

  return (
    <Column
      gap={20}
      style={{
        maxWidth: 450,
        width: "100%",
        marginTop: 10,
      }}
    >
      <p style={{ color: "#757572", fontSize: 12 }}>
        {signUp
          ? language.isEnglish
            ? "Already have an account?"
            : "Tayari una akaunti?"
          : language.isEnglish
          ? "Don't have an account?"
          : "Je bado hauna akaunti?"}
        <TextButton
          atomColors={colors}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          style={{ marginLeft: 5, color: "red", fontWeight: "bold" }}
          onClick={handleClick}
        >
          {signUp
            ? language.isEnglish
              ? "Login"
              : "Ingia"
            : language.isEnglish
            ? "Sign up"
            : "Jiandikishe"}
        </TextButton>
      </p>
    </Column>
  );
}
